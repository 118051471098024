
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import { Cl_common } from "@/functions/Cl_common";
import { DtoMastersGetRes } from "furukawa-common/src/common/dto/DtoMastersGetRes";
import { DtoSearchProjectGetRes } from "furukawa-common/src/common/dto/DtoSearchProjectGetRes";
import { DtoSearchProjectGetReq } from "furukawa-common/src/common/dto/DtoSearchProjectGetReq";
import { CONST_LIST_DEFO_COUNT } from "@/constants/constant";
import { Cl_searchProject } from "@/functions/Cl_searchProject";
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersgetReq } from "furukawa-common/src/common/dto/DtoMastersGetReq";
import { DtoMdDtp } from "furukawa-common/src/common/dto/DtoMdDtp";
import Mt_dtp4month from "@/components/atoms/Mt_dtp4month.vue";
import Mt_dtp from "@/components/atoms/Mt_dtp.vue";
import { CONST_OP_PROJECT_TYPE } from "furukawa-common/src/common/constant/Constants";
import { RouteLocationRaw } from "vue-router";
import { Cl_filereport } from "@/functions/Cl_filereport";
import { DtoOutputqueuesGetReqImple } from "furukawa-common/src/common/dto/DtoOutputqueuesGetReqImple";
import { Cl_outputqueue } from "@/functions/Cl_outputqueue";
import { DtoOutputqueuesGetResImple } from "furukawa-common/src/common/dto/DtoOutputqueuesGetResImple";
import { DtoFileReportsGetReqImple } from "furukawa-common/src/common/dto/DtoFileReportsGetReqImple";
// import { Cl_filereport } from "@/functions/Cl_filereport";
// import { Cl_outputqueue } from "@/functions/Cl_outputqueue";
// import { DtoFileReportsGetReqImple } from "furukawa-common/src/common/dto/DtoFileReportsGetReqImple";
// import { DtoOutputqueuesGetReqImple } from "furukawa-common/src/common/dto/DtoOutputqueuesGetReqImple";
// import { DtoOutputqueuesGetResImple } from "furukawa-common/src/common/dto/DtoOutputqueuesGetResImple";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_modal_alert,
    Mt_dtp,
    Mt_dtp4month,
  },
})
export default class ProjectSearch extends Vue {
  /**
   * パフォーマンス問題の解決案としてテスト的に実装中
   * 残課題：グローバルなメソッドとして宣言して、どこからでも呼び出せるようにしたい
   * 　　　　色々なパターンでも問題ないかの検証
   * @param url
   * @param event
   */
  goTo(url: string, event?: MouseEvent) {
    if (event && (event.ctrlKey || event.metaKey)) {
      window.open(url);
    } else {
      this.$router.push(url);
    }
  }

  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoSearchProjectGetRes();
  delegate4list = new DtoMastersGetRes();
  delegateStaff = new DtoMastersGetRes();
  delegateClient = new DtoMastersGetRes();

  //ボタン制御用
  btnDisable = false;

  //検索条件dom
  domProjectNo_start = "";
  domProjectNo_end = "";
  domProjectName = "";
  domProClientid = "";
  domProjectSponsorName = "";
  domProtype = "";
  domProStaffId = "";
  domSalesIssueDate_start: Date | null = null;
  domSalesIssueDate_end: Date | null = null;
  domSalesMonth_start = new DtoMdDtp();
  domSalesMonth_end = new DtoMdDtp();
  domSupplyMonth_start: Date | null = null;
  domSupplyMonth_end: Date | null = null;
  domsalePrice_start = "";
  domsalePrice_end = "";
  domSupplyPrice_start = "";
  domSupplyPrice_end = "";
  domProRemarks = "";
  domProFreeWord = "";
  domBranchId = "";

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  opPjtype = CONST_OP_PROJECT_TYPE;

  columns = [
    {
      label: "詳細",
      field: "btnDet",
    },
    {
      label: "案件番号",
      field: "c_project_number",
    },
    {
      label: "案件名",
      field: "c_project_name",
    },
    {
      label: "売上先",
      field: "c_client_name",
    },
    {
      label: "広告主",
      field: "c_project_sponsor_name",
    },
    {
      label: "担当者",
      field: "c_staff_name",
    },
  ];

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getMastersList();
    await this.getlist(1);
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      // dtoMaster.getStaff = 1;
      // dtoMaster.delFlag4staff = 0;
      //検索選択肢用
      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4Staff4sortNum = 1;

      dtoMaster.getBranch = 1;
      dtoMaster.delFlag4Branch = 0;

      dtoMaster.getClients = 1;
      dtoMaster.delFlag4clients = 0;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);

      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました"; //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * 一覧データを読み込む
   */
  async getlist(currentPage: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoSearchProjectGetReq();
      //検索条件設定
      dto.pager.count = CONST_LIST_DEFO_COUNT;
      dto.pager.cursor = currentPage;

      if (this.domProjectNo_start != "")
        dto.projectNo_start = Number(this.domProjectNo_start);

      if (this.domProjectNo_end != "")
        dto.projectNo_end = Number(this.domProjectNo_end);

      dto.projectName = this.domProjectName;

      if (this.domProClientid != "" && this.domProClientid != null)
        dto.proClientid = Number(this.domProClientid);

      dto.projectSponsorName = this.domProjectSponsorName;

      if (this.domProtype != "" && this.domProtype != null)
        dto.projectType = Number(this.domProtype);

      if (this.domProStaffId != "" && this.domProStaffId != null)
        dto.staffId = Number(this.domProStaffId);

      if (this.domBranchId != "" && this.domBranchId != null)
        dto.branchId = this.domBranchId;

      dto.salesIssueDate_start = this.domSalesIssueDate_start;

      dto.salesIssueDate_end = this.domSalesIssueDate_end;

      // dto.salesMonth_start = this.domSalesMonth_start;

      if (this.domSalesMonth_start.date != null) {
        dto.salesMonth_start = new Date(this.domSalesMonth_start.date);
      }

      // dto.salesMonth_end = this.domSalesMonth_end;

      if (this.domSalesMonth_end.date != null) {
        dto.salesMonth_end = new Date(this.domSalesMonth_end.date);
      }

      dto.supplyMonth_start = this.domSupplyMonth_start;

      dto.supplyMonth_end = this.domSupplyMonth_end;

      if (this.domsalePrice_start != "")
        dto.salesPrice_start = Number(this.domsalePrice_start);

      if (this.domsalePrice_end != "")
        dto.salesPrice_end = Number(this.domsalePrice_end);

      if (this.domSupplyPrice_start != "")
        dto.supplyPrice_start = Number(this.domSupplyPrice_start);

      if (this.domSupplyPrice_end != "")
        dto.supplyPrice_end = Number(this.domSupplyPrice_end);

      dto.proRemarks = this.domProRemarks;

      dto.proFreeWord = this.domProFreeWord;

      //API実行
      //-------
      this.delegate = await Cl_searchProject.getList(dto);

      //レスポンス処理
      //-------
      Cl_common.logger("結果");
      Cl_common.logger(this.delegate);
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      //エラー発生で一覧を初期化
      this.delegate = new DtoSearchProjectGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行ダウンロード");

      //準備
      //-------
      let dto = new DtoFileReportsGetReqImple();
      dto.type = "AnkenList";

      if (this.domProjectNo_start != "")
        dto.projectNo_start = Number(this.domProjectNo_start);

      if (this.domProjectNo_end != "")
        dto.projectNo_end = Number(this.domProjectNo_end);

      dto.projectName = this.domProjectName;

      if (this.domProClientid != "" && this.domProClientid != null)
        dto.proClientid = Number(this.domProClientid);

      dto.projectSponsorName = this.domProjectSponsorName;

      if (this.domProtype != "" && this.domProtype != null)
        dto.projectType = Number(this.domProtype);

      if (this.domProStaffId != "" && this.domProStaffId != null)
        dto.staffId = this.domProStaffId;

      if (this.domBranchId != "" && this.domBranchId != null)
        dto.branchId = Number(this.domBranchId);

      dto.salesIssueDate_start = this.domSalesIssueDate_start;

      dto.salesIssueDate_end = this.domSalesIssueDate_end;

      // dto.salesMonth_start = this.domSalesMonth_start;

      if (this.domSalesMonth_start.date != null) {
        dto.salesMonth_start = new Date(this.domSalesMonth_start.date);
      }

      // dto.salesMonth_end = this.domSalesMonth_end;

      if (this.domSalesMonth_end.date != null) {
        dto.salesMonth_end = new Date(this.domSalesMonth_end.date);
      }

      dto.supplyMonth_start = this.domSupplyMonth_start;

      dto.supplyMonth_end = this.domSupplyMonth_end;

      if (this.domsalePrice_start != "")
        dto.salesPrice_start = Number(this.domsalePrice_start);

      if (this.domsalePrice_end != "")
        dto.salesPrice_end = Number(this.domsalePrice_end);

      if (this.domSupplyPrice_start != "")
        dto.supplyPrice_start = Number(this.domSupplyPrice_start);

      if (this.domSupplyPrice_end != "")
        dto.supplyPrice_end = Number(this.domSupplyPrice_end);

      dto.proRemarks = this.domProRemarks;

      dto.proFreeWord = this.domProFreeWord;

      //API実行
      //-------
      const res = await Cl_filereport.get(dto);
      if (0 == res.outputqueue_code.length) {
        throw new Error("出力失敗");
      }

      let timeLimit = Date.now() + 15 * 60 * 1000; // 15分
      let now = Date.now();
      let dto2 = new DtoOutputqueuesGetReqImple();
      dto2.c_outputqueue_code = res.outputqueue_code;
      let res2 = new DtoOutputqueuesGetResImple();
      res2 = await Cl_outputqueue.get(dto2);
      while (now < timeLimit) {
        await new Promise((resolve) => setTimeout(resolve, 10000)); // 10秒待つ

        now = Date.now();

        res2 = await Cl_outputqueue.get(dto2);
        if (res2.c_outputqueue_flag_end) {
          break;
        }
      }

      //レスポンス処理
      //-------
      if (res2.c_outputqueue_flag_success) {
        location.href = res2.url;
      } else {
        throw new Error("出力失敗");
      }
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   *社員プルダウン取得用
   */
  async getStaffList() {
    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getStaff = 1;
      dtoMaster.delFlag4staff = 0;

      //API実行
      //-------
      this.delegateStaff = await Cl_master.get(dtoMaster);
      // Cl_common.logger("結果");
      // Cl_common.logger(this.delegateStaff);
      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegateStaff = new DtoMastersGetRes();
      Cl_common.logger(error);
    }
  }

  /**
   *顧客プルダウン取得用
   */
  async getClientList() {
    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行顧客マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getClients = 1;
      dtoMaster.delFlag4clients = 0;

      //API実行
      //-------
      this.delegateClient = await Cl_master.get(dtoMaster);
      // Cl_common.logger("結果");
      // Cl_common.logger(this.delegateClient);
      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegateClient = new DtoMastersGetRes();
      Cl_common.logger(error);
    }
  }
  async moveDet(id: string) {
    //詳細ページに移動
    this.$router.push("/projectInput?record_id=" + id);
  }

  /**
   * ページ更新時の処理
   * @param params
   */
  async onPageChange(params: any) {
    //テーブル更新処理
    await this.getlist(params.currentPage);
  }
  /**
   * ソート変更時の処理
   * @param params
   */
  async onSortChange(params: any) {
    //テーブル更新処理
  }
  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
