import { DtoAttendancesPostReq } from "./abst/DtoAttendancesPostReq";

export class DtoAttendancesPostReqImple extends DtoAttendancesPostReq {
  /**
   * 動作モード 対応する定数を入力
   *
   * 出勤操作 WORK
   * 退勤操作 LEAVING
   * 外出操作 GOOUTSTART
   * 戻り操作 GOOUTEND
   */
  public postmode: string | null = null;

  public lati: number | null = null;
  public long: number | null = null;
}
