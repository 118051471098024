
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { Cl_common } from "@/functions/Cl_common";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import { DtoBanksGetReqImple } from "furukawa-common/src/common/dto/DtoBanksGetReqImple";
import { DtoBanksGetResImple } from "furukawa-common/src/common/dto/DtoBanksGetResImple";
import { Cl_bank } from "@/functions/Cl_bank";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_modal_alert,
  },
})
export default class BankAccountList extends Vue {
  /**
   * パフォーマンス問題の解決案としてテスト的に実装中
   * 残課題：グローバルなメソッドとして宣言して、どこからでも呼び出せるようにしたい
   * 　　　　色々なパターンでも問題ないかの検証
   * @param url
   * @param event
   */
  goTo(url: string, event?: MouseEvent) {
    if (event && (event.ctrlKey || event.metaKey)) {
      window.open(url);
    } else {
      this.$router.push(url);
    }
  }
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoBanksGetResImple();
  //ボタン制御用
  btnDisable = false;

  domBankName = "";
  domBranchName = "";
  domBankNumber = "";
  domBankType = "";

  columns = [
    {
      label: "詳細",
      field: "btnDet",
      thClass: "text-center",
      tdClass: "text-center",
      width: "10%",
    },
    {
      label: "銀行名",
      field: "c_bank_name",
      thClass: "text-center",
    },
    {
      label: "支店名",
      field: "c_bank_branch_name",
      thClass: "text-center",
    },
    {
      label: "口座種別",
      field: "c_bank_type",
      thClass: "text-center",
    },
    {
      label: "口座番号",
      field: "c_bank_number",
      thClass: "text-center",
    },
    {
      label: "管轄拠点",
      field: "c_branch_name",
      thClass: "text-center",
    },
  ];

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getlist(1);
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * 一覧データを読み込む
   */
  async getlist(currentPage: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoBanksGetReqImple();

      //検索条件設定
      // dto.pager.count = CONST_LIST_DEFO_COUNT;
      dto.pager.count = 9999;
      dto.pager.cursor = currentPage;

      dto.bankName = this.domBankName;
      dto.branchName = this.domBranchName;
      dto.bankNumber = this.domBankNumber;
      if (this.domBankType != "" && this.domBankType != null)
        dto.bankType = Number(this.domBankType);

      //API実行
      //-------
      this.delegate = await Cl_bank.getList(dto);
      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      //エラー発生で一覧を初期化
      // this.delegate = new DtoBanksGetResImple();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      // let dto = new DtoFileMastersGetReqImple();
      // dto.type = "KouzaList";
      // dto.name = this.domBankName;
      // dto.name2 = this.domBranchName;
      // dto.type2 = Number(this.domBankType);
      // dto.strNumber = this.domBankNumber;
      //API実行
      //-------
      // const res = await Cl_filemaster.get(dto);

      //レスポンス処理
      //-------
      // location.href = res.url;
      //window.open(res.url, "_blank");
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  async moveDet(id: string) {
    //詳細ページに移動
    this.$router.push("/bankAccountEdit?id=" + id);
  }
  /**
   * ページ更新時の処理
   * @param params
   */
  async onPageChange(params: any) {
    //テーブル更新処理
    await this.getlist(params.currentPage);
  }
  /**
   * ソート変更時の処理
   * @param params
   */
  async onSortChange(params: any) {
    //テーブル更新処理
  }

  /**
   * 銀行名を返す
   */
  getBankName(bankNumber: number) {
    // return CONST_BANK_NAME[bankNumber];
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
