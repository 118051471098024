
import { Prop, Options, Vue } from "vue-property-decorator";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { DtoMdDtp } from "furukawa-common/src/common/dto/DtoMdDtp";

@Options({
  components: { VueDatePicker },
})
export default class Mt_dtp4month extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  @Prop({ required: true })
  contdate: DtoMdDtp;
  @Prop()
  minDate: Date | undefined;

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------

  //----------------------
  //モーダル用プロパティ
  //----------------------

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    //
  }

  getDayClass(date: any) {
    const weekDay = new Date(date).getDay();
    if (weekDay == 6) {
      // 土曜日の場合、classに"saturday"を追加
      return "saturday";
    }
    if (weekDay == 0) {
      // 日曜日の場合、classに"sunday"を追加
      return "sunday";
    }
    return "";
  }

  //----------------------
  //基本操作用メソッド
  //----------------------

  //----------------------
  //モーダル用メソッド
  //----------------------
}
