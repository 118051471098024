export class EntitySponsor {
public c_sponsor_id:number | null = null;
public c_sponsor_name:string | null = null;
public c_sponsor_genre:number | null = null;
public c_sponsor_item:number | null = null;
public c_sponsor_type:number | null = null;
public c_sponsor_flag_del:number | null = null;
public c_sponsor_reg_user:string | null = null;
public c_sponsor_reg_at:Date | null = null;
public c_sponsor_upd_user:string | null = null;
public c_sponsor_upd_at:Date | null = null;
}