import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoRequestRwdacctsGetReqImple } from "furukawa-common/src/common/dto/DtoRequestRwdacctsGetReqImple";
import { DtoRequestRwdacctsGetResImple } from "furukawa-common/src/common/dto/DtoRequestRwdacctsGetResImple";
import { DtoRequestRwdacctsPostReq } from "furukawa-common/src/common/dto/abst/DtoRequestRwdacctsPostReq";
import { DtoRequestRwdacctsPostRes } from "furukawa-common/src/common/dto/abst/DtoRequestRwdacctsPostRes";
import { DtoRequestRwdacctsPutReq } from "furukawa-common/src/common/dto/abst/DtoRequestRwdacctsPutReq";
import { DtoRequestRwdacctsPutRes } from "furukawa-common/src/common/dto/abst/DtoRequestRwdacctsPutRes";
import { DtoRequestRwdacctsDeleteReq } from "furukawa-common/src/common/dto/abst/DtoRequestRwdacctsDeleteReq";
import { DtoRequestRwdacctsDeleteRes } from "furukawa-common/src/common/dto/abst/DtoRequestRwdacctsDeleteRes";

export class Cl_requestrwdacct {
  public static async getList(
    dto: DtoRequestRwdacctsGetReqImple
  ): Promise<DtoRequestRwdacctsGetResImple> {
    return Object.assign(
      new DtoRequestRwdacctsGetResImple(),
      await CallAPI.getRequest(
        "/requestRwdaccts?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoRequestRwdacctsGetReqImple
  ): Promise<DtoRequestRwdacctsGetResImple> {
    return Object.assign(
      new DtoRequestRwdacctsGetResImple(),
      await CallAPI.getRequest("/requestRwdaccts/" + dto.c_request_rwdacct_id)
    );
  }

  public static async post(
    dto: DtoRequestRwdacctsPostReq
  ): Promise<DtoRequestRwdacctsPostRes> {
    return Object.assign(
      new DtoRequestRwdacctsPostRes(),
      await CallAPI.postRequest("/requestRwdaccts", dto)
    );
  }

  public static async put(
    dto: DtoRequestRwdacctsPutReq
  ): Promise<DtoRequestRwdacctsPutRes> {
    return Object.assign(
      new DtoRequestRwdacctsPutRes(),
      await CallAPI.putRequest(
        "/requestRwdaccts/" + dto.c_request_rwdacct_id,
        dto
      )
    );
  }

  public static async del(
    dto: DtoRequestRwdacctsDeleteReq
  ): Promise<DtoRequestRwdacctsDeleteRes> {
    return Object.assign(
      new DtoRequestRwdacctsDeleteRes(),
      await CallAPI.deleteRequest(
        "/requestRwdaccts?" + Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
