
import { Options, Vue } from "vue-class-component";
import { Cl_Authorization_menu } from "../../constants/Cl_Authorization_menu";
import { Cl_common } from "@/functions/Cl_common";
import { isProdEnv } from "furukawa-common/src/common/constant/Constants";

@Options({})
export default class Tm_leftnavi extends Vue {
  /**
   * パフォーマンス問題の解決案としてテスト的に実装中
   * 残課題：グローバルなメソッドとして宣言して、どこからでも呼び出せるようにしたい
   * 　　　　色々なパターンでも問題ないかの検証
   * @param url
   * @param event
   */
  goTo(url: string, event?: MouseEvent) {
    if (event && (event.ctrlKey || event.metaKey)) {
      window.open(url);
    } else {
      this.$router.push(url);
    }
  }
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  showFlags = new Cl_Authorization_menu();

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  //----------------------
  //モーダル用プロパティ
  //----------------------
  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    //設定された権限からメニューの表示非表示を制御
    this.showFlags.getAuthorization(this.$store);
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * Menuクリック時の処理
   * @param event
   */
  async selectMenu(event: any) {
    // console.log(event);
    //開閉Menuかの判断
    if (event.target.closest("li").classList.contains("open")) {
      // console.log("toggle");
      event.target.closest("li").classList.toggle("active");
    } else {
      //ルーターの処理に移動
      // // console.log("add");
      // // 1.他のactiveを削除
      // let elements = document.getElementsByTagName("li");
      // Array.prototype.forEach.call(elements, function (element) {
      //   if (!element.classList.contains("open")) {
      //     element.classList.remove("active");
      //   }
      // });
      // //2.自身のactivを付ける
      // event.target.closest("li").classList.add("active");
    }
  }
  isProd(): boolean {
    return isProdEnv();
  }
  //----------------------
  //モーダル用メソッド
  //----------------------
}
