
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import "vue-select/dist/vue-select.css";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import {
  CONST_OP_REQUEST_TYPE,
  CONST_OP_REQUEST_STATUS,
  cnvRequestStatusNum2Name,
  cnvRequestTypeNum2Name,
  CONST_REQUEST_TYPE_KARIBARAI,
  CONST_REQUEST_TYPE_JUSHOHENKOTSUKINTEATE,
  CONST_REQUEST_TYPE_KEIHIKOZATOROKU,
  CONST_REQUEST_TYPE_KEIHISEISAN,
  CONST_REQUEST_TYPE_KYUKATODOKE,
  CONST_REQUEST_TYPE_KYUSHOKUNEGAI,
  CONST_REQUEST_TYPE_KYUYOSHOYOKOZATOROKU,
  CONST_REQUEST_TYPE_REMOTEWORK,
  CONST_REQUEST_TYPE_RINGISHO,
  CONST_REQUEST_TYPE_SETTAI,
  CONST_REQUEST_TYPE_SHAYOSHASHIYO,
  CONST_REQUEST_TYPE_SHUTCHO,
  CONST_REQUEST_TYPE_ZANGYO,
  CONST_REQUEST_STATUS_MISINSEI,
  CONST_REQUEST_STATUS_SINSEITYU,
  CONST_REQUEST_STATUS_SASIMODOSI,
  CONST_REQUEST_STATUS_SYOUNINZUMI,
  CONST_REQUEST_STATUS_TORISAGE,
  CONST_OP_DOC_TYPE,
  CONST_OP_DOC_MANAGETYPE,
} from "furukawa-common/src/common/constant/Constants";
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersgetReq } from "furukawa-common/src/common/dto/DtoMastersGetReq";
import { DtoMastersGetRes } from "furukawa-common/src/common/dto/DtoMastersGetRes";
import { CommonStaff } from "furukawa-common/src/common/service/CommonStaff";
import Mo_modal_l from "@/components/molecules/Mo_modal_n.vue";
import { Cl_common } from "@/functions/Cl_common";
import { CONST_LIST_DEFO_COUNT } from "@/constants/constant";
import { Cl_request } from "@/functions/Cl_request";
import { DtoRequestsGetReqImple } from "furukawa-common/src/common/dto/DtoRequestsGetReqImple";
import { DtoRequestsGetResImple } from "furukawa-common/src/common/dto/DtoRequestsGetResImple";
import { Cl_filemaster } from "@/functions/Cl_filemaster";
import { DtoFileMastersGetReqImple } from "furukawa-common/src/common/dto/DtoFileMastersGetReqImple";
import { DtoMdDtp } from "furukawa-common/src/common/dto/DtoMdDtp";
import Mt_dtp from "@/components/atoms/Mt_dtp.vue";
import { DtoDoc4detsGetReqImple } from "furukawa-common/src/common/dto/DtoDoc4detsGetReqImple";
import { Cl_doc4det } from "@/functions/Cl_doc4det";
import { DtoDoc4detsGetResImple } from "furukawa-common/src/common/dto/DtoDoc4detsGetResImple";
import { EntityHistoryImple } from "furukawa-common/src/common/entity/EntityHistoryImple";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_modal_l,
    Mo_modal_alert,
    Mt_dtp,
  },
})
export default class Doclist extends Vue {
  //----------------------
  //バリデーション
  //----------------------
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoDoc4detsGetResImple();
  delegate4list = new DtoMastersGetRes();
  btnDisable = false;

  //検索条件dom
  domIdStart = "";
  domIdEnd = "";
  domDocType = "";
  domDocManageType = "";
  domPriceStart = "";
  domPriceEnd = "";
  domDate_start = new DtoMdDtp();
  domDate_end = new DtoMdDtp();
  domCustomer = "";
  domStaffId = "";
  domStaffId4Project = "";
  domEnable: number | null = null;

  columns = [
    {
      label: "詳細",
      field: "btnDet",
    },
    {
      label: "文書番号",
      field: "c_doc_id",
    },
    // {
    //   label: "管理名",
    //   field: "c_doc_name",
    // },
    {
      label: "文書種別",
      field: "c_doc_managtype",
    },
    {
      label: "文書種類",
      field: "c_doc_type",
    },
    {
      label: "登録者",
      field: "c_staff_name",
    },
    {
      label: "金額",
      field: "c_doc_price",
    },
    {
      label: "取引先",
      field: "c_doc_customer",
    },
    {
      label: "案件担当者",
      field: "c_staff_name4project",
    },
    {
      label: "発行年月日",
      field: "c_doc_date",
    },
    // {
    //   label: "最終更新日時",
    //   field: "c_doc_upd_at",
    // },
    {
      label: "有効/無効",
      field: "c_doc_flag_enab",
    },
  ];

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  opDocType = CONST_OP_DOC_TYPE;
  opDocManageType = CONST_OP_DOC_MANAGETYPE;
  opEnable = [
    { label: "有効な文書を表示", code: 0 },
    { label: "無効な文書を表示", code: 1 },
  ];

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";
  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    //初期値設定
    this.domEnable = 0;
    await this.getMastersList();
    await this.getlist(1);
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("masters取得処理実行");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      //検索選択肢用
      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4Staff4sortNum = 1;

      //カレンダー休日反映用
      dtoMaster.getHoliday = 1;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);

      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * 一覧データを読み込む
   */
  async getlist(currentPage: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoDoc4detsGetReqImple();

      // //検索条件設定
      dto.pager.count = CONST_LIST_DEFO_COUNT;
      dto.pager.cursor = currentPage;

      if (this.domIdStart != "") {
        dto.no_start = Number(this.domIdStart);
      }
      if (this.domIdEnd != "") {
        dto.no_end = Number(this.domIdEnd);
      }
      if (this.domDocType != null && this.domDocType != "") {
        dto.type = Number(this.domDocType);
      }
      if (this.domPriceStart != "") {
        dto.price_start = Number(this.domPriceStart);
      }
      if (this.domPriceEnd != "") {
        dto.price_end = Number(this.domPriceEnd);
      }
      if (this.domDate_start.date != null) {
        dto.date_start = new Date(this.domDate_start.date).toLocaleDateString();
      }
      if (this.domDate_end.date != null) {
        dto.date_end = new Date(this.domDate_end.date).toLocaleDateString();
      }
      if (this.domCustomer != null && this.domCustomer != "") {
        dto.customer = this.domCustomer;
      }
      if (this.domStaffId != null && this.domStaffId != "") {
        dto.staff_id = Number(this.domStaffId);
      }
      if (this.domEnable != null) {
        dto.flag_enab = this.domEnable;
      }
      if (this.domDocManageType != null && this.domDocManageType != "") {
        dto.manag_type = Number(this.domDocManageType);
      }
      if (this.domStaffId4Project != null && this.domStaffId4Project != "") {
        dto.staff_id4project = Number(this.domStaffId4Project);
      }

      Cl_common.logger(dto);

      //API実行
      //-------
      this.delegate = await Cl_doc4det.getList(dto);
      //レスポンス処理
      //-------
      Cl_common.logger(this.delegate);
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      //エラー発生で一覧を初期化
      this.delegate = new DtoDoc4detsGetResImple();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /*
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //レスポンス処理
      //-------
      // location.href = res.url;
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * ページ更新時の処理
   * @param params
   */
  async onPageChange(params: any) {
    //テーブル更新処理
    await this.getlist(params.currentPage);
  }
  /**
   * ソート変更時の処理
   * @param params
   */
  async onSortChange(params: any) {
    //テーブル更新処理
  }
  goTo(url: string, event?: MouseEvent) {
    if (event && (event.ctrlKey || event.metaKey)) {
      window.open(url);
    } else {
      this.$router.push(url);
    }
  }

  /**
   * 金額列の値を処理する
   * @param num
   */
  cnvPrice2Str(num: number | null): string {
    if (num == null) {
      return "-";
    }

    return num.toLocaleString("ja-JP");
  }
  /**
   * 文書種別コードを名称に変換する
   * @param num
   */
  cnvManageTypeNum2Str(num: number | null): string {
    if (num == null) {
      return "-";
    }

    var found = CONST_OP_DOC_MANAGETYPE.find((e) => e.code == num);
    return found!.label;
  }
  /**
   * 文書種類コードを名称に変換する
   * @param num
   */
  cnvDocTypeNum2Str(num: number | null): string {
    if (num == null) {
      return "-";
    }

    var found = CONST_OP_DOC_TYPE.find((e) => e.code == num);
    return found!.label;
  }

  /**
   * ステータスの番号を受け取り、名称にして返す
   */
  cnvEnabNum2Name(statusNum: number): string {
    if (statusNum == 1) {
      return '<span  class="badge badge-success badge-pill" style="font-size: 1em;">有効</span>';
    } else {
      return '<span class="badge badge-danger badge-pill" style="font-size: 1em;">無効</span>';
    }
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  //----------------------
  //その他のメソッド（画面独自のもの等）
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
  cnvDate(date: Date | null) {
    if (date == null) {
      return "-";
    }

    return date.toString().split("T")[0].replaceAll("-", "/");
  }
}
