
import { Options, Vue, Watch } from "vue-property-decorator";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { Cl_common } from "@/functions/Cl_common";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import Mo_modal_right_l from "@/components/molecules/Mo_modal_right_l.vue";
import {
  CONST_APPROVAL_STATUS_SYOUNIN,
  CONST_REQUEST_STATUS_MISINSEI,
  CONST_REQUEST_STATUS_SASIMODOSI,
  CONST_REQUEST_STATUS_SINSEITYU,
  CONST_REQUEST_STATUS_SYOUNINZUMI,
  CONST_REQUEST_STATUS_TORISAGE,
  CONST_REQUEST_TYPE_KEIHISEISAN,
  CONST_DOC_TYPE_RYOUSYUSYO,
} from "furukawa-common/src/common/constant/Constants";

// masters
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersgetReq } from "furukawa-common/src/common/dto/DtoMastersGetReq";
import { DtoMastersGetRes } from "furukawa-common/src/common/dto/DtoMastersGetRes";

//経費清算
import { Cl_requestexpenses } from "@/functions/Cl_requestexpenses";
import { DtoRequestExpensessGetReqImple } from "furukawa-common/src/common/dto/DtoRequestExpensessGetReqImple";
import { DtoRequestExpensessGetResImple } from "furukawa-common/src/common/dto/DtoRequestExpensessGetResImple";
import { DtoRequestExpensessPutReqImple } from "furukawa-common/src/common/dto/DtoRequestExpensessPutReqImple";
import { DtoRequestExpensessPostResImple } from "furukawa-common/src/common/dto/DtoRequestExpensessPutResImple";
import { DtoRequestExpensessPostReqImple } from "furukawa-common/src/common/dto/DtoRequestExpensessPostReqImple";
import { DtoRequestOvertimesPostResImple } from "furukawa-common/src/common/dto/DtoRequestOvertimesPostResImple";
import { EntityRequestExpensesList } from "furukawa-common/src/common/entity/EntityRequestExpensesList";
import Mo_expenses_row from "@/components/molecules/Mo_expenses_row.vue";

//申請ヘッダ
import { Cl_request } from "@/functions/Cl_request";
import { DtoRequestsPutReqImple } from "furukawa-common/src/common/dto/DtoRequestsPutReqImple";
import { DtoRequestsPutResImple } from "furukawa-common/src/common/dto/DtoRequestsPutResImple";

//store
import store from "@/store";
import { CommonStaff } from "furukawa-common/src/common/service/CommonStaff";

// バリデーション
import * as Yup from "yup";
import { Cl_filemaster } from "@/functions/Cl_filemaster";
import { DtoFileMastersGetReqImple } from "furukawa-common/src/common/dto/DtoFileMastersGetReqImple";
import { EntityStaff } from "furukawa-common/src/common/entity/EntityStaff";

// ファイル
import dropzone from "@/components/organisms/Or_fileupload.vue";
import Or_pdfviewer from "@/components/organisms/Or_pdfviewer.vue";
import axios from "axios";
import { Cl_doc } from "@/functions/Cl_doc";
import { DtoDocsPostReqImple } from "furukawa-common/src/common/dto/DtoDocsPostReqImple";
import { DtoDocsPutReqImple } from "furukawa-common/src/common/dto/DtoDocsPutReqImple";

@Options({
  components: {
    vSelect,
    Mo_modal_alert,
    Mo_expenses_row,
    Mo_modal_right_l,
    dropzone,
    Or_pdfviewer,
  },
})
export default class shinsei_Keihiseisanedit extends Vue {
  //----------------------
  //バリデーション
  //----------------------
  //スキーマ宣言
  schemaAdd = Yup.object().shape({
    startDate: Yup.string().required("起案日を入力してください"),
  });

  schemaAdd_tmppayprice = Yup.object().shape({
    tmppayPrice: Yup.number()
      .nullable()
      .required("仮払金を半角数字で入力してください")
      .typeError("仮払金を半角数字で入力してください"),
  });

  // schemaAdd_payprice = Yup.object().shape({
  //   payPrice: Yup.number()
  //     .nullable()
  //     .required("精算金額を半角数字で入力してください")
  //     .typeError("精算金額を半角数字で入力してください"),
  // });

  //エラー格納用
  errors: Yup.ValidationError | null = null;
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoRequestExpensessGetResImple();
  delegate4list = new DtoMastersGetRes();
  expensesList: EntityRequestExpensesList[] = [];
  isDisableExpensesList = true;

  btnDisable = false;
  isAdmin = false;
  isMine = false;
  isEditMode = true;
  showReceptImg = true;

  domApplicantName = "";
  domContent = "";
  domSumPrice = "";
  domTmppay_price = "0";
  domPay_price = "";
  domRegistNum = "";

  //ソートの向き
  isDescSort4Invoice = true;
  isDescSort4Date = true;

  //日付関係
  domStartDate: string | undefined = ""; //delegate.c_request_overtime_ot_date

  //承認フォームの表示非表示フラグ
  fmShowConf = false;
  fmShowConfEx = false;
  fmShowSasimodosi = false;
  fmShowTorisage = false;

  //新規時のファイル用
  //-------
  fileInfo: {
    path: string;
    name: string;
    size: number;
    type: string;
    lastMidified: number;
    data: any;
    isExcute: boolean;
  } = {
    path: "",
    name: "",
    size: -1,
    type: "",
    lastMidified: 0,
    data: null,
    isExcute: false,
  };

  //----------------------
  //DOM連携用メソッド
  //----------------------
  openRecept() {
    window.open(this.delegate.docfile!.url4Show!, "_blank");
  }
  /**
   * ファイルアップロードモーダルで処理されたデータの受け渡しに使用される。
   *
   * @param newFileInfo
   */
  updateFileInfo(newFileInfo: {
    path: string;
    name: string;
    size: number;
    type: string;
    lastMidified: number;
    data: any;
    isExcute: boolean;
  }) {
    this.fileInfo = newFileInfo;
  }
  convDateTime(val: Date | null): string {
    let res = "";

    if (val == null) {
      res = "";
    } else {
      res = val
        .toString()
        .replaceAll("-", "/")
        .replace("T", " ")
        .replace(".000Z", "");
    }

    return res;
  }

  /**
   * 承認者の順序を入力して、承認者名を得る
   * @param num
   */
  getAuthoriser(num: number): string {
    let res = "-";

    if (this.delegate4list.items4staffs == null) {
      return "-";
    }

    switch (num) {
      case 1:
        if (this.delegate.request.c_request_1_approval == null) {
          res = "-";
        } else {
          res = CommonStaff.cnvStaffNum2Name(
            this.delegate4list.items4staffs,
            this.delegate.request.c_request_1_approval
          );
        }

        break;
      case 2:
        if (this.delegate.request.c_request_2_approval == null) {
          res = "-";
        } else {
          res = CommonStaff.cnvStaffNum2Name(
            this.delegate4list.items4staffs,
            this.delegate.request.c_request_2_approval
          );
        }

        break;
      case 3:
        if (this.delegate.request.c_request_3_approval == null) {
          res = "-";
        } else {
          res = CommonStaff.cnvStaffNum2Name(
            this.delegate4list.items4staffs,
            this.delegate.request.c_request_3_approval
          );
        }

        break;
      default:
        break;
    }

    return res;
  }

  /**
   * 承認日時を受け取り、表示用の文字列にして返す
   * @param val
   */
  getAuthDate(authorizerId: number | null, val: Date | null): string {
    let res = "";

    if (authorizerId == null) {
      return "-";
    }

    if (val == null) {
      res = "未承認";
    } else {
      res = val
        .toString()
        .replaceAll("-", "/")
        .replace("T", " ")
        .replace(".000Z", "");
    }

    return res;
  }

  /**
   * 仕様月日列をソート
   */
  async sortRow4Date() {
    const elmUp = document.getElementById("sort4DateUp");
    const elmDown = document.getElementById("sort4DateDown");

    const compareInvoices = (
      a: { c_expenses_date: Date | null },
      b: { c_expenses_date: Date | null }
    ) => {
      // a または b が null の場合、後ろに配置
      if (a.c_expenses_date === null && b.c_expenses_date !== null) return 1;
      if (b.c_expenses_date === null && a.c_expenses_date !== null) return -1;

      // 両方とも null または 両方とも null でない場合の比較
      return this.isDescSort4Date
        ? new Date(b.c_expenses_date!).getTime() -
            new Date(a.c_expenses_date!).getTime()
        : new Date(a.c_expenses_date!).getTime() -
            new Date(b.c_expenses_date!).getTime();
    };

    // リストをソート
    this.expensesList.sort(compareInvoices);
    // this.expensesList[0].c_expenses_date
    // UIの更新
    if (this.isDescSort4Date) {
      elmUp?.classList.remove("dispNone");
      elmDown?.classList.add("dispNone");
    } else {
      elmUp?.classList.add("dispNone");
      elmDown?.classList.remove("dispNone");
    }

    // 昇順/降順フラグの切り替え
    this.isDescSort4Date = !this.isDescSort4Date;
  }
  /**
   * インボイス列をソート
   */
  async sortRow4invoice() {
    const elmUp = document.getElementById("sort4InvoiceUp");
    const elmDown = document.getElementById("sort4InvoiceDown");

    const compareInvoices = (
      a: { c_expenses_invoice: number | null },
      b: { c_expenses_invoice: number | null }
    ) => {
      // a または b が null の場合、後ろに配置
      if (a.c_expenses_invoice === null && b.c_expenses_invoice !== null)
        return 1;
      if (b.c_expenses_invoice === null && a.c_expenses_invoice !== null)
        return -1;

      // 両方とも null または 両方とも null でない場合の比較
      return this.isDescSort4Invoice
        ? b.c_expenses_invoice! - a.c_expenses_invoice!
        : a.c_expenses_invoice! - b.c_expenses_invoice!;
    };

    // リストをソート
    this.expensesList.sort(compareInvoices);

    // UIの更新
    if (this.isDescSort4Invoice) {
      elmUp?.classList.remove("dispNone");
      elmDown?.classList.add("dispNone");
    } else {
      elmUp?.classList.add("dispNone");
      elmDown?.classList.remove("dispNone");
    }

    // 昇順/降順フラグの切り替え
    this.isDescSort4Invoice = !this.isDescSort4Invoice;
  }

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  /**
   * 社員の選択肢を構築する用
   */
  getOpStaffs(): EntityStaff[] {
    return this.delegate4list.getStaffSortNum4NoneDelete([
      this.delegate.request.c_staff_id!,
    ]);
  }
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalDelConf = "";
  message4ModalDisableConf = "";
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getMastersList();
    await this.get();
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * 合計金額の計算
   */
  async sumCalc() {
    // Cl_common.logger("sumCalc");

    let tmp = this.expensesList
      .reduce((acc, obj) => {
        // 文字列を数値に変換し、カンマを除去
        let value = obj.cnvPrice ? parseInt(obj.cnvPrice.replace(/,/g, "")) : 0;
        // isNaN関数で数値変換が正しく行われたかチェック
        if (isNaN(value)) {
          value = 0;
        }
        return acc + value;
      }, 0)
      .toString();

    while (tmp != (tmp = tmp.replace(/^(-?\d+)(\d{3})/, "$1,$2")));

    this.domSumPrice = tmp + "円";
  }

  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4Staff4sortNum = 1;
      dtoMaster.getStaff = 1;
      dtoMaster.delFlag4staff = 0;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);

      // Cl_common.logger(this.delegate4list);

      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  async get() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //パラメータにIDが指定されていなければ、新規追加モードでデータを読み込まない
      //-------
      let id = Cl_common.getParam("id");
      store.commit("revStore");
      const authType = store.state.authType;
      // Cl_common.logger(authType);
      this.isAdmin = authType == 4; //管理者判定

      if (id == "") {
        //新規追加
        this.isEditMode = false;

        //初期値設定
        this.delegate.request.c_staff_id = store.state.id;
        // this.delegate.c_request_overtime_type = 1;
        this.fmShowConf = false;
        this.fmShowConfEx = false;

        let tmp: EntityRequestExpensesList;
        for (let index = 0; index < 30; index++) {
          tmp = new EntityRequestExpensesList();
          tmp.c_expenses_rownum = index + 1;
          await tmp.cnvData2dom();
          this.expensesList.push(tmp);
        }

        Cl_common.logger("this.expensesList");
        Cl_common.logger(this.expensesList);

        this.isDisableExpensesList = false;

        return;
      } else {
        //編集
        this.isEditMode = true;
        this.isDisableExpensesList = true;
      }

      //準備
      //-------
      let dto = new DtoRequestExpensessGetReqImple();

      //※fanctions内のクラスの修正コスト軽減の為、c_request_expenses_idに入れているが、
      //  API側ではrequestIDとして扱われるので注意
      dto.c_request_expenses_id = Number(id);

      //API実行
      //-------
      this.delegate = await Cl_requestexpenses.get(dto);
      //レスポンス処理
      //-------
      //IDがnullなら一覧へ遷移
      if (this.delegate.c_request_expenses_id == null) {
        this.$router.push("/shinseisyolist");
      }

      //UI連携用変数に入力
      //---
      await this.cnvData4Get();

      //フラグ更新
      //---
      store.commit("revStore");
      const loginStaffId = store.state.id;
      this.isMine = loginStaffId == this.delegate.request.c_staff_id!; //申請者判定

      //承認関係の表記
      //---
      await this.chgAuthCont();

      //一覧更新
      //---
      this.expensesList = JSON.parse(this.delegate.c_request_expenses_list!);

      //ステータスが未申請なら編集可能に変更
      //---
      if (
        this.isMine &&
        (this.delegate.request.c_request_status ==
          CONST_REQUEST_STATUS_MISINSEI ||
          this.delegate.request.c_request_status ==
            CONST_REQUEST_STATUS_SASIMODOSI)
      ) {
        this.isEditMode = false;
        this.isDisableExpensesList = false;
      }

      await this.sumCalc();

      //領収書画像の再読み込み
      this.showReceptImg = !this.showReceptImg;
      await this.delay(1000);
      this.showReceptImg = !this.showReceptImg;
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
      this.expensesList = [];
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  async delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  /**
   * DOM連携用の変換処理　GET用。
   */
  async cnvData4Get() {
    Cl_common.logger("this.delegate4list.items4staffs");
    Cl_common.logger(this.delegate4list.items4staffs);
    //申請者
    this.domApplicantName = CommonStaff.cnvStaffNum2Name(
      this.delegate4list.items4staffs,
      this.delegate.request.c_staff_id
    );

    //起案日
    if (this.delegate.c_request_expenses_date_draft != null) {
      this.domStartDate = this.delegate
        .c_request_expenses_date_draft!.toString()
        .split("T")[0];
    }

    //仮払金額
    if (this.delegate.c_request_expenses_tmppay_price != null) {
      this.domTmppay_price =
        this.delegate.c_request_expenses_tmppay_price.toString();
      while (
        this.domTmppay_price !=
        (this.domTmppay_price = this.domTmppay_price.replace(
          /^(-?\d+)(\d{3})/,
          "$1,$2"
        ))
      );
    }

    //精算金額
    if (this.delegate.c_request_expenses_total != null) {
      this.domPay_price = this.delegate.c_request_expenses_total.toString();
    }
  }

  /**
   * 承認に関するコンテンツを制御する
   */
  async chgAuthCont() {
    let loginStaffId = -1;

    switch (this.delegate.request.c_request_status) {
      case CONST_REQUEST_STATUS_SINSEITYU:
        //申請中
        //-----------
        store.commit("revStore");
        loginStaffId = store.state.id;
        if (
          (loginStaffId == this.delegate.request.c_request_1_approval &&
            this.delegate.request.c_request_1_approval_datetime == null) ||
          (loginStaffId == this.delegate.request.c_request_2_approval &&
            this.delegate.request.c_request_2_approval_datetime == null) ||
          (loginStaffId == this.delegate.request.c_request_3_approval &&
            this.delegate.request.c_request_3_approval_datetime == null)
        ) {
          //自身が承認者で未承認
          //--------
          this.fmShowConf = true;
          this.fmShowConfEx = false;
          this.fmShowSasimodosi = false;
          this.fmShowTorisage = false;
        } else {
          //自身が承認者でない、もしくは承認済み
          //--------
          this.fmShowConf = false;
          this.fmShowConfEx = true;
          this.fmShowSasimodosi = false;
          this.fmShowTorisage = false;
        }

        break;
      case CONST_REQUEST_STATUS_SASIMODOSI:
        //差戻
        //-----------
        this.fmShowConf = false;
        this.fmShowConfEx = false;
        this.fmShowSasimodosi = true;
        this.fmShowTorisage = false;
        break;
      case CONST_REQUEST_STATUS_SYOUNINZUMI:
        //承認済
        //-----------
        this.fmShowConf = false;
        this.fmShowConfEx = false;
        this.fmShowSasimodosi = false;
        this.fmShowTorisage = false;
        break;
      case CONST_REQUEST_STATUS_TORISAGE:
        //取下
        //-----------
        this.fmShowConf = false;
        this.fmShowConfEx = false;
        this.fmShowSasimodosi = false;
        this.fmShowTorisage = true;

        break;
      case CONST_REQUEST_STATUS_MISINSEI:
        //未申請
        //-----------
        this.fmShowConf = false;
        this.fmShowConfEx = false;
        this.fmShowSasimodosi = false;
        this.fmShowTorisage = false;

        break;
      default:
        throw new Error("不正なstatus");
    }
  }

  /**
   * データを更新する
   * 申請書用
   */
  async upd(isDel: boolean, isDisable: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoRequestsPutReqImple();
      let id = Cl_common.getParam("id");
      dto.c_request_id = Number(id);

      if (isDel) {
        //削除処理：削除フラグを立てる。
        dto.c_request_flag_del = 1;
      } else {
        //取下処理：取下フラグを立てる。
        dto.c_request_status = 4;
      }

      //API実行
      //-------
      await Cl_request.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      if (isDel) {
        //削除処理：
        this.message4ModalSuccess = "正常に削除されました";
      } else {
        //更新処理：
        this.message4ModalSuccess = "正常に変更されました";
      }

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * データを更新する
   * 変更保存用
   * 一時保存・編集が可能な申請書で使用
   */
  async updNormal(isNoModal: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //バリデーション
      //-----------------
      this.domTmppay_price = this.domTmppay_price.replaceAll(",", "");
      this.errors = null;
      this.schemaAdd.validateSync({
        startDate: this.domStartDate,
      });

      this.schemaAdd_tmppayprice.validateSync({
        tmppayPrice: this.domTmppay_price,
      });

      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      //UI連携用変数からdelegateに戻す
      //---
      await this.cnvData4Add();

      let dto = Object.assign(
        new DtoRequestExpensessPutReqImple(),
        this.delegate
      );

      //一覧の変換メソッド実行
      let expensesListTmp: EntityRequestExpensesList[] = [];
      let EntityRequestExpensesTmp: EntityRequestExpensesList;
      for (let index = 0; index < this.expensesList.length; index++) {
        EntityRequestExpensesTmp = Object.assign(
          new EntityRequestExpensesList(),
          this.expensesList[index]
        );
        EntityRequestExpensesTmp.cnvDom2data();
        // this.expensesList[index].cnvDom2data();

        expensesListTmp.push(EntityRequestExpensesTmp);
      }

      dto.c_request_expenses_list = JSON.stringify(expensesListTmp);

      //API実行
      //-------
      const res = await Cl_requestexpenses.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定

      this.message4ModalSuccess = "正常に変更されました";

      //モーダルを表示
      if (!isNoModal) {
        const modal: any = this.$refs.Mo_modal_success;
        modal.showModal();
      }
    } catch (error) {
      if (isNoModal) {
        //noModalの場合、例外を呼び出し元に投げる
        throw error;
      } else {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();

        Cl_common.logger(error);
      }
    } finally {
      if (!isNoModal) {
        //ボタン無効解除＋ローダー非表示
        //-----------------
        this.btnDisable = false;
        Array.from(panel).map((element: Element) => {
          element.classList.remove("enable-loader");
        });
      }
    }
  }

  /**
   * データを更新する
   * 承認・差戻用
   */
  async upd4Approval(isApproval: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //バリデーション
      // //-------

      /* *****************************************************
      仕様確認の為、コメントアウト。
      20231217_領収書がないと最終更新できない
      ***************************************************** */
      // //経費精算書は、領収書がないと最終承認できない。
      // store.commit("revStore");
      // const loginStaffId = store.state.id;
      // if (
      //   isApproval &&
      //   (this.delegate.request.c_request_3_approval == loginStaffId || //最終承認者が自分の場合
      //     (this.delegate.request.c_request_2_approval == loginStaffId &&
      //       this.delegate.request.c_request_3_approval == null) || //第二承認者が自分で、最終承認者がいない場合
      //     (this.delegate.request.c_request_1_approval == loginStaffId &&
      //       this.delegate.request.c_request_2_approval == null &&
      //       this.delegate.request.c_request_3_approval == null)) //第一承認者が自分で、第二承認者と最終承認者がいないの場合
      // ) {
      //   //領収書がない
      //   if (
      //     this.delegate.docfile == null ||
      //     this.delegate.docfile.url4Show == null ||
      //     this.delegate.docfile.url4Show == ""
      //   ) {
      //     //モーダルのメッセージを設定
      //     this.message4ModalErrorConf =
      //       "領収書が添付されていない場合、最終承認ができません";
      //     //モーダル表示
      //     const modal: any = this.$refs.Mo_modal_errorConf;
      //     modal.showModal();

      //     return;
      //   }
      // }
      // *****************************************************

      // //準備
      // //-------
      let dto = new DtoRequestsPutReqImple();
      let id = Cl_common.getParam("id");
      dto.c_request_id = Number(id);

      if (isApproval) {
        //承認
        dto.isApproval = 1;
      } else {
        //差戻
        dto.isApproval = 0;
      }

      //API実行
      //-------
      await Cl_request.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      if (isApproval) {
        //削除処理：
        this.message4ModalSuccess = "正常に承認されました";
      } else {
        //更新処理：
        this.message4ModalSuccess = "正常に差戻されました";
      }

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * 承認申請実行
   */
  async approvalRequest() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      /* *****************************************************
      仕様確認の為、コメントアウト。
      20231217_領収書がないと最終更新できない
      ***************************************************** */
      // //経費精算書は、領収書がないと最終承認できない。
      // store.commit("revStore");
      // const loginStaffId = store.state.id;
      // if (
      //   //承認者が設定されておらず、申請=承認済みの場合
      //   this.delegate.request.c_request_1_approval == null &&
      //   this.delegate.request.c_request_2_approval == null &&
      //   this.delegate.request.c_request_3_approval == null
      // ) {
      //   //領収書がない
      //   if (
      //     this.delegate.docfile == null ||
      //     this.delegate.docfile.url4Show == null ||
      //     this.delegate.docfile.url4Show == ""
      //   ) {
      //     //モーダルのメッセージを設定
      //     this.message4ModalErrorConf =
      //       "領収書が添付されていない場合、最終承認ができません";
      //     //モーダル表示
      //     const modal: any = this.$refs.Mo_modal_errorConf;
      //     modal.showModal();

      //     return;
      //   }
      // }
      //*****************************************************

      //保存処理実行
      await this.updNormal(true);

      // //準備
      // //-------
      let dto = new DtoRequestsPutReqImple();
      let id = Cl_common.getParam("id");
      dto.c_request_id = Number(id);
      dto.isApprovalRequest = 1;

      //API実行
      //-------
      await Cl_request.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      this.message4ModalSuccess = "正常に承認申請されました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを新たに登録する
   */
  async add() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //バリデーション
      //-----------------
      this.domTmppay_price = this.domTmppay_price.replaceAll(",", "");
      this.errors = null;
      this.schemaAdd.validateSync({
        startDate: this.domStartDate,
      });

      this.schemaAdd_tmppayprice.validateSync({
        tmppayPrice: this.domTmppay_price,
      });

      // this.schemaAdd_payprice.validateSync({
      //   payPrice: this.domPay_price,
      // });

      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      //UI連携用変数からdelegateに戻す
      //---
      await this.cnvData4Add();

      let dto = Object.assign(
        new DtoRequestExpensessPostReqImple(),
        this.delegate
      );

      //一覧の変換メソッド実行
      for (let index = 0; index < this.expensesList.length; index++) {
        this.expensesList[index].cnvDom2data();
      }

      dto.c_request_expenses_list = JSON.stringify(this.expensesList);

      //API実行
      //-------
      const res = await Cl_requestexpenses.post(dto);

      //レスポンスの処理
      //-------
      //編集画面のURL作成
      this.$router.push({
        path: "/shinsei_Keihiseisanedit",
        query: { id: res.c_request_id },
      });

      //モーダルのメッセージを設定
      this.message4ModalSuccess = "正常に追加されました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * 領収書を新たに登録する
   * Or_fileuploadにファイルが投入されると、このメソッドが呼ばれる。
   */
  @Watch("fileInfo")
  async add4Recept() {
    Cl_common.logger(this.fileInfo);
    if (this.fileInfo.size <= 0) {
      return;
    }

    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //バリデーション
      //-----------------

      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      //UI連携用変数からdelegateに戻す
      //---

      // docfileがあれば更新だし、なければPOST。文書管理画面と同じようにする。
      // UPDの場合全消しフラグをたてて、DocsPutを使う？

      let url4Upload = "";

      if (
        this.delegate.docfile != null &&
        this.delegate.docfile.c_doc_id != null
      ) {
        //更新
        let dto = new DtoDocsPutReqImple();

        dto.c_doc_id = this.delegate.docfile.c_doc_id;

        dto.c_doc_name = this.domStartDate + "起案　領収書";
        dto.c_staff_id = this.delegate.request.c_staff_id;
        dto.c_request_expenses_id = this.delegate.c_request_expenses_id;
        dto.c_doc_type = CONST_DOC_TYPE_RYOUSYUSYO;
        dto.c_doc_price = this.delegate.c_request_expenses_total;
        dto.c_doc_flag_issuer = 0;
        dto.c_doc_flag_del = 0;
        dto.c_doc_flag_enab = 1;
        dto.c_doc_flag_reserve = 1;

        dto.filename = this.fileInfo.name;
        dto.filetype = this.fileInfo.type;
        dto.filesize = this.fileInfo.size;

        Cl_common.logger("API実行");
        //API実行
        //-------
        const res = await Cl_doc.put(dto);

        Cl_common.logger(res);

        url4Upload = res.url!;
      } else {
        //登録
        let dto = new DtoDocsPostReqImple();

        dto.c_doc_name = this.domStartDate + "起案　領収書";
        dto.c_staff_id = this.delegate.request.c_staff_id;
        dto.c_request_expenses_id = this.delegate.c_request_expenses_id;
        dto.c_doc_type = CONST_DOC_TYPE_RYOUSYUSYO;
        dto.c_doc_price = this.delegate.c_request_expenses_total;
        dto.c_doc_flag_issuer = 0;
        dto.c_doc_flag_del = 0;
        dto.c_doc_flag_enab = 1;
        dto.c_doc_flag_reserve = 1;

        dto.filename = this.fileInfo.name;
        dto.filetype = this.fileInfo.type;
        dto.filesize = this.fileInfo.size;

        Cl_common.logger("API実行");
        //API実行
        //-------
        const res = await Cl_doc.post(dto);

        Cl_common.logger(res);

        url4Upload = res.url!;
      }

      //レスポンスの処理
      //-------
      //アップロード用のURLが返されたら、アップロード処理を行う。
      if (url4Upload == "") {
        this.message4ModalErrorConf = "ファイルのアップロードに失敗しました";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      } else {
        await axios.put(url4Upload, this.fileInfo.data);
      }

      //-------
      //編集画面のURL作成
      // this.$router.push({
      //   path: "/shinsei_Keihiseisanedit",
      //   query: { id: res.c_doc_id },
      // });

      //モーダルのメッセージを設定
      this.message4ModalSuccess = "正常に追加されました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoFileMastersGetReqImple();
      dto.type = "Keihiseisan";
      let id = Cl_common.getParam("id");
      dto.requestId = Number(id);
      //API実行
      //-------
      const res = await Cl_filemaster.get(dto);

      //レスポンス処理
      //-------
      location.href = res.url;
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * DOM連携用の変換処理　ADD用。
   */
  async cnvData4Add() {
    //起案日
    if (this.domStartDate != undefined) {
      this.delegate.c_request_expenses_date_draft = new Date(this.domStartDate);
    }

    //仮払金額
    if (this.domTmppay_price != null) {
      this.delegate.c_request_expenses_tmppay_price = Number(
        this.domTmppay_price
      );
    }

    //精算金額
    if (this.domPay_price != null) {
      this.delegate.c_request_expenses_total = Number(this.domPay_price);
    }
  }

  /**
   * データ削除用のモーダルを表示
   */
  async delConf() {
    //モーダルのメッセージを設定
    this.message4ModalDelConf =
      "【" +
      this.delegate.c_request_id +
      "】" +
      this.delegate.request.c_request_name +
      "を削除します。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.showModal();
  }

  /**
   * 取り下げ用のモーダルを表示
   */
  async disableConf() {
    //モーダルのメッセージを設定
    this.message4ModalDisableConf =
      "【" +
      this.delegate.c_request_id +
      "】" +
      this.delegate.request.c_request_name +
      "を取り下げます。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_disableConf;
    modal.showModal();
  }

  /**
   * データを削除する
   */
  async del() {
    //削除フラグのあるテーブルなので更新処理。
    this.closeModal4deleteConf();
    this.upd(true, false);
  }
  /**
   * データを取り下げる
   */
  async disab() {
    //削除フラグのあるテーブルなので更新処理。
    this.closeModal4disableConf();
    this.upd(false, true);
  }

  /**
   * エクセル出力ボタンの表示・非表示を制御する。
   */
  isShowExportBtn() {
    if (!this.isEditMode) {
      return false;
    }

    //データがなければ非表示
    if (this.delegate == null || this.delegate.c_request_id == null) {
      return false;
    }

    //差戻かつ本人でない場合、非表示
    store.commit("revStore");
    const loginStaffId = store.state.id;
    if (
      this.delegate.request.c_request_status ==
        CONST_REQUEST_STATUS_SASIMODOSI &&
      this.delegate.request.c_staff_id != loginStaffId
    ) {
      return false;
    }

    //承認済みステータスであれば表示
    if (
      this.delegate.request.c_request_status == CONST_REQUEST_STATUS_SYOUNINZUMI
    ) {
      return true;
    }

    //一人でも承認して入れば表示
    if (
      this.delegate.request.c_request_1_approval_status ==
        CONST_APPROVAL_STATUS_SYOUNIN ||
      this.delegate.request.c_request_2_approval_status ==
        CONST_APPROVAL_STATUS_SYOUNIN ||
      this.delegate.request.c_request_3_approval_status ==
        CONST_APPROVAL_STATUS_SYOUNIN
    ) {
      return true;
    }

    // それ以外は非表示
    return false;
  }

  /**
   * 金額セル入力時の処理
   * カンマを消す
   * @param obj
   */
  kanmaDel(obj: any) {
    // //カンマを消す
    obj.target.value = obj.target.value.replace(/,/g, "");
    if (obj.target.value == "0") obj.target.value = "";
  }
  /**
   * 金額セル入力時の処理
   * カンマを付ける
   * @param obj
   */
  kanmaIns(obj: any) {
    let val: string = obj.target.value;

    //うっかり入力しているかもしれないカンマを消す
    val = val.replace(/,/g, "");
    //整数に変換したのち文字列に戻す
    //この時点で数字とマイナス記号だけが残る
    let num = "" + parseInt(val);
    //正規表現で桁区切りするための良く見かける関数的な何か
    //変数 num の中身が、桁区切りされる
    while (num != (num = num.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
    //numに入っている値が数値じゃないときは0とする
    if (isNaN(parseInt(num))) num = "0";
    //桁区切りした結果（変数 num）でテキストボックスの中身を書き換える
    obj.target.value = num;
  }
  goTo(url: string, event?: MouseEvent) {
    if (event && (event.ctrlKey || event.metaKey)) {
      window.open(url);
    } else {
      this.$router.push(url);
    }
  }
  /**
   * 国税庁のインボイス検索画面に遷移する
   */
  goToTaxSite() {
    if (this.domRegistNum != null) {
      window.open(
        "https://www.invoice-kohyo.nta.go.jp/regno-search/detail?selRegNo=" +
          this.domRegistNum
      );
    }
  }
  //----------------------
  //モーダル用メソッド
  //----------------------
  async showModal4Receipt(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_receipt;
    modal.showModal();
  }
  async closeModal4Receipt(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_receipt;
    modal.closeModal();
  }
  //-------
  async closeModal4deleteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.closeModal();
  }
  async closeModal4disableConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_disableConf;
    modal.closeModal();
  }
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
