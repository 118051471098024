
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";

@Options({
  components: {
    vSelect,
    Mo_modal_alert,
  },
})
export default class SalesForecastNonConsolidated extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  btnDisable = false;

  optionsOp = [
    { label: "59" },
    { label: "60" },
    { label: "61" },
    { label: "62" },
  ];
  optionsOp2 = [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
    { label: "11" },
    { label: "12" },
  ];
}
