
import { Options, Vue } from "vue-class-component";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";

@Options({
  components: {
    Mo_modal_alert,
  },
})
export default class MakeNextYearData extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  btnDisable = false;
}
