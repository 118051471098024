
import { Prop, Options, Vue, Watch } from "vue-property-decorator";
import { EntityClaimDet } from "furukawa-common/src/common/entity/EntityClaimDet";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import {
  CONST_OP_CLAIM_ROUDING,
  CONST_OP_TAXRATE,
} from "furukawa-common/src/common/constant/Constants";
import { CommonPrice4FU } from "furukawa-common/src/common/function/CommonPrice4FU";

@Options({
  components: {
    vSelect,
  },
})
export default class Mo_claimlist_row extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  @Prop({ required: true })
  item: EntityClaimDet;

  @Prop({ required: true })
  btnDisable: boolean;

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  opTaxtate = CONST_OP_TAXRATE;
  opRouding = CONST_OP_CLAIM_ROUDING;
  //----------------------
  //モーダル用プロパティ
  //----------------------

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    //カンマ補完
    //---------------------
    // if (this.item.c_claim_det_unitprice != null) {
    //   while (
    //     this.item.c_claim_det_unitprice !=
    //     (this.item.c_claim_det_unitprice =
    //       this.item.c_claim_det_unitprice!.replace(/^(-?\d+)(\d{3})/, "$1,$2"))
    //   );
    // }
    // if (this.item.c_claim_det_price_sum != null) {
    //   while (
    //     this.item.c_claim_det_price_sum !=
    //     (this.item.c_claim_det_price_sum =
    //       this.item.c_claim_det_price_sum!.replace(/^(-?\d+)(\d{3})/, "$1,$2"))
    //   );
    // }
  }
  /**
   * 再計算イベントが発生した事を上位のコンポーネントに伝えるイベント発生
   */
  @Watch("item.c_claim_det_price_sum")
  eventCalc() {
    //再計算イベント発生
    //-----------------------
    this.$emit("reCalc");
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * 品名の文字数オーバーを判定
   */
  isOverLength() {
    if (this.item.c_claim_det_item == null) {
      return false;
    }

    let count = 0;
    const str = this.item.c_claim_det_item;

    for (let i = 0; i < str.length; i++) {
      const char = str.charAt(i);
      // 文字が半角の場合
      if (char.charCodeAt(0) <= 0x7f) {
        count += 1;
      } else {
        // 文字が全角の場合
        count += 2;
      }
    }

    return count > 48;
  }

  /**
   * 金額セル入力時の処理
   * カンマを消す
   * @param obj
   */
  kanmaDel(obj: any) {
    // //カンマを消す
    obj.target.value = obj.target.value.replace(/,/g, "");
    if (obj.target.value == "0") obj.target.value = "";
  }
  /**
   * 金額セル入力時の処理
   * カンマを付ける
   * @param obj
   */
  kanmaIns(obj: any) {
    let val: string = obj.target.value;

    //うっかり入力しているかもしれないカンマを消す
    val = val.replace(/,/g, "");
    //整数に変換したのち文字列に戻す
    //この時点で数字とマイナス記号だけが残る
    let num = "" + parseInt(val);
    //正規表現で桁区切りするための良く見かける関数的な何か
    //変数 num の中身が、桁区切りされる
    while (num != (num = num.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
    //numに入っている値が数値じゃないときは空白とする
    if (isNaN(parseInt(num))) num = "";
    //桁区切りした結果（変数 num）でテキストボックスの中身を書き換える
    obj.target.value = num;
  }
  /**
   * 税込み金額計算
   */
  calcTax() {
    //開始判定
    //-------------
    if (
      // this.item.c_claim_det_taxrate == null ||
      // this.item.c_claim_det_taxrate == 0 ||
      this.item.c_claim_det_price == null ||
      this.item.c_claim_det_price == "" ||
      this.item.c_claim_det_price == "0"
    ) {
      this.item.c_claim_det_price_sum = "0";
      return;
    }

    //計算開始
    //-------------
    let sum = 0;
    let sum_str = this.item.c_claim_det_price;

    //税込金額計算
    //-------------

    //数字にパース
    sum_str = sum_str.replace(/,/g, "");
    sum = Number(sum_str);
    let sumTmp = "";

    switch (this.item.c_claim_det_taxrate) {
      //--MD参照前--------------------
      // case null:
      //   //消費税未設定：金額がそのまま税込み金額に入る
      //   sumTmp = sum.toString();
      //   while (sumTmp != (sumTmp = sumTmp.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
      //   this.item.c_claim_det_price_sum = sumTmp;
      //   break;
      // case 1:
      //   //5%
      //   sumTmp = Math.round((1 + 5 / 100) * sum).toString();
      //   while (sumTmp != (sumTmp = sumTmp.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
      //   this.item.c_claim_det_price_sum = sumTmp;
      //   break;
      // case 2:
      //   //8%
      //   sumTmp = Math.round((1 + 8 / 100) * sum).toString();
      //   while (sumTmp != (sumTmp = sumTmp.replace(/^(-?\d+)(\d{3})/, "$1,$2")));

      //   this.item.c_claim_det_price_sum = sumTmp;
      //   break;
      // case 3:
      //   //10%
      //   sumTmp = Math.round((1 + 10 / 100) * sum).toString();
      //   while (sumTmp != (sumTmp = sumTmp.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
      //   this.item.c_claim_det_price_sum = sumTmp;
      //   break;
      // case 4:
      //   //内税　ここでは金額がそのまま税込み金額に入る
      //   sumTmp = sum.toString();
      //   while (sumTmp != (sumTmp = sumTmp.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
      //   this.item.c_claim_det_price_sum = sumTmp;
      //   break;
      // default:
      //   //消費税未設定：金額がそのまま税込み金額に入る
      //   sumTmp = sum.toString();
      //   while (sumTmp != (sumTmp = sumTmp.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
      //   this.item.c_claim_det_price_sum = sumTmp;
      //   break;
      //----------------------
      //--MD参照後--------------------
      case null:
        //消費税未設定：金額がそのまま税込み金額に入る
        sumTmp = CommonPrice4FU.cnvIntPrice(
          sum,
          this.item.c_claim_det_rounding!
        ).toString();
        while (sumTmp != (sumTmp = sumTmp.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
        this.item.c_claim_det_price_sum = sumTmp;
        break;
      case 1:
        //5%
        sumTmp = Math.round(
          CommonPrice4FU.cnvIntPrice(
            (1 + 5 / 100) * sum,
            this.item.c_claim_det_rounding!
          )
        ).toString();
        while (sumTmp != (sumTmp = sumTmp.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
        this.item.c_claim_det_price_sum = sumTmp;
        break;
      case 2:
        //8%
        sumTmp = Math.round(
          CommonPrice4FU.cnvIntPrice(
            (1 + 8 / 100) * sum,
            this.item.c_claim_det_rounding!
          )
        ).toString();
        while (sumTmp != (sumTmp = sumTmp.replace(/^(-?\d+)(\d{3})/, "$1,$2")));

        this.item.c_claim_det_price_sum = sumTmp;
        break;
      case 3:
        //10%
        sumTmp = Math.round(
          CommonPrice4FU.cnvIntPrice(
            (1 + 10 / 100) * sum,
            this.item.c_claim_det_rounding!
          )
        ).toString();
        while (sumTmp != (sumTmp = sumTmp.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
        this.item.c_claim_det_price_sum = sumTmp;
        break;
      case 4:
        //内税　ここでは金額がそのまま税込み金額に入る
        sumTmp = CommonPrice4FU.cnvIntPrice(
          sum,
          this.item.c_claim_det_rounding!
        ).toString();
        while (sumTmp != (sumTmp = sumTmp.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
        this.item.c_claim_det_price_sum = sumTmp;
        break;
      default:
        //消費税未設定：金額がそのまま税込み金額に入る
        sumTmp = CommonPrice4FU.cnvIntPrice(
          sum,
          this.item.c_claim_det_rounding!
        ).toString();
        while (sumTmp != (sumTmp = sumTmp.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
        this.item.c_claim_det_price_sum = sumTmp;
        break;
    }
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
}
