import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoSearchSalesGetReq } from "furukawa-common/src/common/dto/DtoSearchSalesGetReq";
import { DtoSearchSalesGetRes } from "furukawa-common/src/common/dto/DtoSearchSalesGetRes";

export class Cl_searchSales {
  public static async getList(
    dto: DtoSearchSalesGetReq
  ): Promise<DtoSearchSalesGetRes> {
    return Object.assign(
      new DtoSearchSalesGetRes(),
      await CallAPI.getRequest(
        "/searchsales?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
