import { CONST_BASE_IS_DEBAG } from "@/constants/constant";
import { Router } from "vue-router";

export class Cl_common {
  /**
   * Get the URL parameter value
   *
   * @param  name {string} パラメータのキー文字列
   * @return  url {url} 対象のURL文字列（任意）
   */
  static getParam(name: string): string {
    const url = window.location.href;
    // name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return "";
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
  /**
   * DTOを受け取り、get用のパラメータ文字列にして返す。
   * @param date
   */
  static cnvObj2GetParam(date: any): string {
    let param = "";
    for (const key in date) {
      if (
        date[key] == null ||
        key == "constructor" ||
        key == "IsValid" ||
        key == "IsValid4Pager" ||
        key == "pager"
      ) {
        continue;
      }
      if (param == "") {
        param += key + "=" + date[key];
      } else {
        param += "&" + key + "=" + date[key];
      }
    }
    return param;
  }

  static logger(message: any): void {
    if (CONST_BASE_IS_DEBAG) {
      console.log(message);
    }
  }
  /**
   * 画面遷移用共通メソッド
   * @param url
   * @param event
   */
  static goTo(url: string, router: Router, event?: MouseEvent) {
    if (event && (event.ctrlKey || event.metaKey)) {
      window.open(url);
    } else {
      router.push(url);
    }
  }
}
