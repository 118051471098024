
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { Cl_common } from "@/functions/Cl_common";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import {
  CONST_APPROVAL_STATUS_SYOUNIN,
  CONST_REQUEST_STATUS_SASIMODOSI,
  CONST_REQUEST_STATUS_SINSEITYU,
  CONST_REQUEST_STATUS_SYOUNINZUMI,
  CONST_REQUEST_STATUS_TORISAGE,
} from "furukawa-common/src/common/constant/Constants";
// masters
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersgetReq } from "furukawa-common/src/common/dto/DtoMastersGetReq";
import { DtoMastersGetRes } from "furukawa-common/src/common/dto/DtoMastersGetRes";
//残業時間
import { Cl_requestcaruse } from "@/functions/Cl_requestcaruse";
import { DtoRequestCarusesGetReqImple } from "furukawa-common/src/common/dto/DtoRequestCarusesGetReqImple";
import { DtoRequestCarusesGetResImple } from "furukawa-common/src/common/dto/DtoRequestCarusesGetResImple";
import { DtoRequestOvertimesPutReq } from "furukawa-common/src/common/dto/abst/DtoRequestOvertimesPutReq";
import { DtoRequestOvertimesPutRes } from "furukawa-common/src/common/dto/abst/DtoRequestOvertimesPutRes";
import { DtoRequestCarusesPostReqImple } from "furukawa-common/src/common/dto/DtoRequestCarusesPostReqImple";
import { DtoRequestOvertimesPostResImple } from "furukawa-common/src/common/dto/DtoRequestOvertimesPostResImple";
//申請ヘッダ
import { Cl_request } from "@/functions/Cl_request";
import { DtoRequestsPutReqImple } from "furukawa-common/src/common/dto/DtoRequestsPutReqImple";
import { DtoRequestsPutResImple } from "furukawa-common/src/common/dto/DtoRequestsPutResImple";
//store
import store from "@/store";
import { CommonStaff } from "furukawa-common/src/common/service/CommonStaff";
// バリデーション
import * as Yup from "yup";
import { Cl_filemaster } from "@/functions/Cl_filemaster";
import { DtoFileMastersGetReqImple } from "furukawa-common/src/common/dto/DtoFileMastersGetReqImple";
import { EntityStaff } from "../../../furukawa-common/src/common/entity/EntityStaff";

@Options({
  components: {
    vSelect,
    Mo_modal_alert,
  },
})
export default class shinsei_Shayoshashiyoedit extends Vue {
  //----------------------
  //バリデーション
  //----------------------
  //スキーマ宣言
  schemaAdd = Yup.object().shape({
    // licDate: Yup.string().required("免許取得年月日を入力してください"),
    startDate: Yup.string().required("使用期間 開始を入力してください"),
    endDate: Yup.string().required("使用期間 終了を入力してください"),
    dest: Yup.string().required("行先を入力してください"),
    reason: Yup.string().required("使用理由を入力してください"),
  });

  //エラー格納用
  errors: Yup.ValidationError | null = null;
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoRequestCarusesGetResImple();
  delegate4list = new DtoMastersGetRes();
  btnDisable = false;
  isAdmin = false;
  isMine = false;
  isEditMode = true;

  domApplicantName = "";
  domContent = "";
  domReason = "";

  //日付関係
  domLicDate: string | undefined = ""; //delegate.c_request_caruse_lic_date
  domDate_start: string | undefined = ""; //delegate.c_request_caruse_start
  domDate_end: string | undefined = ""; //delegate.c_request_caruse_end
  domDays: string | undefined = "";

  //承認フォームの表示非表示フラグ
  fmShowConf = false;
  fmShowConfEx = false;
  fmShowSasimodosi = false;
  fmShowTorisage = false;

  //----------------------
  //DOM連携用メソッド
  //----------------------
  convDateTime(val: Date | null): string {
    let res = "";

    if (val == null) {
      res = "";
    } else {
      res = val
        .toString()
        .replaceAll("-", "/")
        .replace("T", " ")
        .replace(".000Z", "");
    }

    return res;
  }

  /**
   * 承認者の順序を入力して、承認者名を得る
   * @param num
   */
  getAuthoriser(num: number): string {
    let res = "-";

    if (this.delegate4list.items4staffs == null) {
      return "-";
    }

    switch (num) {
      case 1:
        if (this.delegate.request.c_request_1_approval == null) {
          res = "-";
        } else {
          res = CommonStaff.cnvStaffNum2Name(
            this.delegate4list.items4staffs,
            this.delegate.request.c_request_1_approval
          );
        }

        break;
      case 2:
        if (this.delegate.request.c_request_2_approval == null) {
          res = "-";
        } else {
          res = CommonStaff.cnvStaffNum2Name(
            this.delegate4list.items4staffs,
            this.delegate.request.c_request_2_approval
          );
        }

        break;
      case 3:
        if (this.delegate.request.c_request_3_approval == null) {
          res = "-";
        } else {
          res = CommonStaff.cnvStaffNum2Name(
            this.delegate4list.items4staffs,
            this.delegate.request.c_request_3_approval
          );
        }

        break;
      default:
        break;
    }

    return res;
  }

  /**
   * 承認日時を受け取り、表示用の文字列にして返す
   * @param val
   */
  getAuthDate(authorizerId: number | null, val: Date | null): string {
    let res = "";

    if (authorizerId == null) {
      return "-";
    }

    if (val == null) {
      res = "未承認";
    } else {
      res = val
        .toString()
        .replaceAll("-", "/")
        .replace("T", " ")
        .replace(".000Z", "");
    }

    return res;
  }

  optionsOp = [
    { label: "ユーザー1", code: 1 },
    { label: "ユーザー2", code: 2 },
    { label: "ユーザー3", code: 3 },
  ];

  optionsOp2 = [
    { label: "営業部", code: 1 },
    { label: "営業推進部", code: 2 },
  ];

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  /**
   * 社員の選択肢を構築する用
   */
  getOpStaffs(): EntityStaff[] {
    return this.delegate4list.getStaffSortNum4NoneDelete([
      this.delegate.request.c_staff_id!,
    ]);
  }
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalDelConf = "";
  message4ModalDisableConf = "";
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getMastersList();
    await this.get();
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4Staff4sortNum = 1;
      dtoMaster.getStaff = 1;
      dtoMaster.delFlag4staff = 0;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);

      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  async get() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //パラメータにIDが指定されていなければ、新規追加モードでデータを読み込まない
      //-------
      let id = Cl_common.getParam("id");
      store.commit("revStore");
      const authType = store.state.authType;
      // Cl_common.logger(authType);
      this.isAdmin = authType == 4; //管理者判定

      if (id == "") {
        //新規追加
        this.isEditMode = false;

        //初期値設定
        this.delegate.request.c_staff_id = store.state.id;
        this.delegate.c_request_caruse_pax = 1;
        this.fmShowConf = false;
        this.fmShowConfEx = false;

        return;
      } else {
        //編集
        this.isEditMode = true;
      }

      //準備
      //-------
      let dto = new DtoRequestCarusesGetReqImple();

      dto.c_request_caruse_id = Number(id);

      //API実行
      //-------
      this.delegate = await Cl_requestcaruse.get(dto);
      //レスポンス処理
      //-------
      //IDがnullなら一覧へ遷移
      if (this.delegate.c_request_caruse_id == null) {
        this.$router.push("/shinseisyolist");
      }

      //UI連携用変数に入力
      //---
      await this.cnvData4Get();

      //フラグ更新
      //---
      store.commit("revStore");
      const loginStaffId = store.state.id;
      this.isMine = loginStaffId == this.delegate.request.c_staff_id!; //申請者判定

      //承認関係の表記
      //---
      await this.chgAuthCont();

      //何日間かの計算
      await this.calculateDateDifference();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * DOM連携用の変換処理　GET用。
   */
  async cnvData4Get() {
    //申請者
    this.domApplicantName = CommonStaff.cnvStaffNum2Name(
      this.delegate4list.items4staffs,
      this.delegate.request.c_staff_id
    );

    //免許取得年月日
    if (this.delegate.c_request_caruse_lic_date != null) {
      this.domLicDate = this.delegate
        .c_request_caruse_lic_date!.toString()
        .split("T")[0];
    }

    //使用期間
    if (this.delegate.c_request_caruse_start != null) {
      this.domDate_start = this.delegate
        .c_request_caruse_start!.toString()
        .split("T")[0];
    }
    if (this.delegate.c_request_caruse_end != null) {
      this.domDate_end = this.delegate
        .c_request_caruse_end!.toString()
        .split("T")[0];
    }
    //事由
    if (this.delegate.c_request_caruse_cont != null) {
      this.domReason = this.delegate.c_request_caruse_cont;
    }
  }
  /**
   * 承認に関するコンテンツを制御する
   */
  async chgAuthCont() {
    let loginStaffId = -1;

    switch (this.delegate.request.c_request_status) {
      case CONST_REQUEST_STATUS_SINSEITYU:
        //申請中
        //-----------
        store.commit("revStore");
        loginStaffId = store.state.id;
        if (
          (loginStaffId == this.delegate.request.c_request_1_approval &&
            this.delegate.request.c_request_1_approval_datetime == null) ||
          (loginStaffId == this.delegate.request.c_request_2_approval &&
            this.delegate.request.c_request_2_approval_datetime == null) ||
          (loginStaffId == this.delegate.request.c_request_3_approval &&
            this.delegate.request.c_request_3_approval_datetime == null)
        ) {
          //自身が承認者で未承認
          //--------
          this.fmShowConf = true;
          this.fmShowConfEx = false;
          this.fmShowSasimodosi = false;
          this.fmShowTorisage = false;
        } else {
          //自身が承認者でない、もしくは承認済み
          //--------
          this.fmShowConf = false;
          this.fmShowConfEx = true;
          this.fmShowSasimodosi = false;
          this.fmShowTorisage = false;
        }

        break;
      case CONST_REQUEST_STATUS_SASIMODOSI:
        //差戻
        //-----------
        this.fmShowConf = false;
        this.fmShowConfEx = false;
        this.fmShowSasimodosi = true;
        this.fmShowTorisage = false;
        break;
      case CONST_REQUEST_STATUS_SYOUNINZUMI:
        //承認済
        //-----------
        this.fmShowConf = false;
        this.fmShowConfEx = false;
        this.fmShowSasimodosi = false;
        this.fmShowTorisage = false;
        break;
      case CONST_REQUEST_STATUS_TORISAGE:
        //取下
        //-----------
        this.fmShowConf = false;
        this.fmShowConfEx = false;
        this.fmShowSasimodosi = false;
        this.fmShowTorisage = true;

        break;
      default:
        throw new Error("不正なstatus");
    }
  }

  /**
   * データを更新する
   */
  async upd(isDel: boolean, isDisable: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoRequestsPutReqImple();
      let id = Cl_common.getParam("id");
      dto.c_request_id = Number(id);

      if (isDel) {
        //削除処理：削除フラグを立てる。
        dto.c_request_flag_del = 1;
      } else {
        //差戻処理：ステータス変更。
        dto.c_request_status = 4;
      }

      //API実行
      //-------
      await Cl_request.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      if (isDel) {
        //削除処理：
        this.message4ModalSuccess = "正常に削除されました";
      } else {
        //更新処理：
        this.message4ModalSuccess = "正常に変更されました";
      }

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * データを更新する
   * 承認・差戻用
   */
  async upd4Approval(isApproval: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      // //準備
      // //-------
      let dto = new DtoRequestsPutReqImple();
      let id = Cl_common.getParam("id");
      dto.c_request_id = Number(id);

      if (isApproval) {
        //承認
        dto.isApproval = 1;
      } else {
        //差戻
        dto.isApproval = 0;
      }

      //API実行
      //-------
      await Cl_request.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      if (isApproval) {
        //削除処理：
        this.message4ModalSuccess = "正常に承認されました";
      } else {
        //更新処理：
        this.message4ModalSuccess = "正常に差戻されました";
      }

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * データを新たに登録する
   */
  async add() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //バリデーション
      //-----------------
      this.errors = null;
      this.schemaAdd.validateSync({
        // licDate: this.domLicDate,
        startDate: this.domDate_start,
        endDate: this.domDate_end,
        dest: this.delegate.c_request_caruse_dest,
        reason: this.domReason,
      });
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      //UI連携用変数からdelegateに戻す
      //---
      await this.cnvData4Add();

      let dto = Object.assign(
        new DtoRequestCarusesPostReqImple(),
        this.delegate
      );

      //API実行
      //-------
      const res = await Cl_requestcaruse.post(dto);

      //レスポンスの処理
      //-------
      //編集画面のURL作成
      this.$router.push({
        path: "/shinsei_Shayoshashiyoedit",
        query: { id: res.c_request_id },
      });

      //モーダルのメッセージを設定
      this.message4ModalSuccess = "正常に追加されました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        Cl_common.logger("error");
        Cl_common.logger(error);
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * DOM連携用の変換処理　ADD用。
   */
  async cnvData4Add() {
    //免許取得年月日
    if (this.domLicDate != undefined) {
      this.delegate.c_request_caruse_lic_date = new Date(this.domLicDate);
    }
    //使用期間
    if (this.domDate_start != undefined) {
      this.delegate.c_request_caruse_start = new Date(this.domDate_start);
    }
    if (this.domDate_end != undefined) {
      this.delegate.c_request_caruse_end = new Date(this.domDate_end);
    }
    //事由
    if (this.domReason != null) {
      this.delegate.c_request_caruse_cont = this.domReason;
    }
  }

  /**
   * データ削除用のモーダルを表示
   */
  async delConf() {
    //モーダルのメッセージを設定
    this.message4ModalDelConf =
      "【" +
      this.delegate.c_request_id +
      "】" +
      this.delegate.request.c_request_name +
      "を削除します。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.showModal();
  }
  /**
   * 取り下げ用のモーダルを表示
   */
  async disableConf() {
    //モーダルのメッセージを設定
    this.message4ModalDisableConf =
      "【" +
      this.delegate.c_request_id +
      "】" +
      this.delegate.request.c_request_name +
      "を取り下げます。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_disableConf;
    modal.showModal();
  }
  /**
   * データを削除する
   */
  async del() {
    //削除フラグのあるテーブルなので更新処理。
    this.closeModal4deleteConf();
    this.upd(true, false);
  }
  /**
   * データを取り下げる
   */
  async disab() {
    //削除フラグのあるテーブルなので更新処理。
    this.closeModal4disableConf();
    this.upd(false, true);
  }

  /**
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoFileMastersGetReqImple();
      dto.type = "Shayoshashiyo";
      let id = Cl_common.getParam("id");
      dto.requestId = Number(id);
      //API実行
      //-------
      const res = await Cl_filemaster.get(dto);

      //レスポンス処理
      //-------
      location.href = res.url;
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  //----------------------
  //日にち計算用
  //----------------------
  async calculateDateDifference() {
    let diffDays = 0;

    if (this.domDate_start && this.domDate_end) {
      // const date1 = new Date(this.domDate_start);
      // const date2 = new Date(this.domDate_end);

      // const diffTime = Math.abs(date2.getTime() - date1.getTime());
      // diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      const date1 = new Date(this.domDate_start);
      const date2 = new Date(this.domDate_end);

      const diffTime = Math.abs(date2.getTime() - date1.getTime());
      diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffTime === 0) {
        diffDays = 1; // 日付が同じ場合、結果を1日とする
      } else if (date2 > date1) {
        diffDays++; // 日付が翌日の場合、結果を2日とする
      }
    } else {
      diffDays = 0;
    }
    Cl_common.logger(diffDays);

    this.domDays = String(diffDays);
  }

  /**
   * エクセル出力ボタンの表示・非表示を制御する。
   */
  isShowExportBtn() {
    if (!this.isEditMode) {
      return false;
    }

    //データがなければ非表示
    if (this.delegate == null || this.delegate.c_request_id == null) {
      return false;
    }

    //差戻かつ本人でない場合、非表示
    store.commit("revStore");
    const loginStaffId = store.state.id;
    if (
      this.delegate.request.c_request_status ==
        CONST_REQUEST_STATUS_SASIMODOSI &&
      this.delegate.request.c_staff_id != loginStaffId
    ) {
      return false;
    }

    //承認済みステータスであれば表示
    if (
      this.delegate.request.c_request_status == CONST_REQUEST_STATUS_SYOUNINZUMI
    ) {
      return true;
    }

    //一人でも承認して入れば表示
    if (
      this.delegate.request.c_request_1_approval_status ==
        CONST_APPROVAL_STATUS_SYOUNIN ||
      this.delegate.request.c_request_2_approval_status ==
        CONST_APPROVAL_STATUS_SYOUNIN ||
      this.delegate.request.c_request_3_approval_status ==
        CONST_APPROVAL_STATUS_SYOUNIN
    ) {
      return true;
    }

    // それ以外は非表示
    return false;
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4deleteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.closeModal();
  }
  async closeModal4disableConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_disableConf;
    modal.closeModal();
  }
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
