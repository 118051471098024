import { DtoClaimsPutReq } from "./abst/DtoClaimsPutReq";

export class DtoClaimsPutReqImple extends DtoClaimsPutReq {
  public isUpdDepoDate: number | null = null;

  public IsValid(): string[] {
    let res: string[] = new Array();

    return res;
  }
}
