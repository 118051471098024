import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoRequestRemotesGetReqImple } from "furukawa-common/src/common/dto/DtoRequestRemotesGetReqImple";
import { DtoRequestRemotesGetResImple } from "furukawa-common/src/common/dto/DtoRequestRemotesGetResImple";
import { DtoRequestRemotesPostReq } from "furukawa-common/src/common/dto/abst/DtoRequestRemotesPostReq";
import { DtoRequestRemotesPostRes } from "furukawa-common/src/common/dto/abst/DtoRequestRemotesPostRes";
import { DtoRequestRemotesPutReq } from "furukawa-common/src/common/dto/abst/DtoRequestRemotesPutReq";
import { DtoRequestRemotesPutRes } from "furukawa-common/src/common/dto/abst/DtoRequestRemotesPutRes";
import { DtoRequestRemotesDeleteReq } from "furukawa-common/src/common/dto/abst/DtoRequestRemotesDeleteReq";
import { DtoRequestRemotesDeleteRes } from "furukawa-common/src/common/dto/abst/DtoRequestRemotesDeleteRes";

export class Cl_requestremote {
  public static async getList(
    dto: DtoRequestRemotesGetReqImple
  ): Promise<DtoRequestRemotesGetResImple> {
    return Object.assign(
      new DtoRequestRemotesGetResImple(),
      await CallAPI.getRequest(
        "/requestremotes?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoRequestRemotesGetReqImple
  ): Promise<DtoRequestRemotesGetResImple> {
    return Object.assign(
      new DtoRequestRemotesGetResImple(),
      await CallAPI.getRequest("/requestremotes/" + dto.c_request_remote_id)
    );
  }

  public static async post(
    dto: DtoRequestRemotesPostReq
  ): Promise<DtoRequestRemotesPostRes> {
    return Object.assign(
      new DtoRequestRemotesPostRes(),
      await CallAPI.postRequest("/requestremotes", dto)
    );
  }

  public static async put(
    dto: DtoRequestRemotesPutReq
  ): Promise<DtoRequestRemotesPutRes> {
    return Object.assign(
      new DtoRequestRemotesPutRes(),
      await CallAPI.putRequest(
        "/requestremotes/" + dto.c_request_remote_id,
        dto
      )
    );
  }

  public static async del(
    dto: DtoRequestRemotesDeleteReq
  ): Promise<DtoRequestRemotesDeleteRes> {
    return Object.assign(
      new DtoRequestRemotesDeleteRes(),
      await CallAPI.deleteRequest(
        "/requestremotes?" + Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
