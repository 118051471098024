import { EntityClaim } from "../entity/EntityClaim";
import { EntityFile } from "../entity/EntityFile";
import { EntitySalesImple } from "../entity/EntitySalesImple";
import { EntitySupplyImple } from "../entity/EntitySupplyImple";
import { DtoMdDtp } from "./DtoMdDtp";
import { DtoApiPager } from "./abst/DtoApiPager";
import { DtoProjectsGetRes } from "./abst/DtoProjectsGetRes";

export class DtoProjectsGetResImple extends DtoProjectsGetRes {
  public pager = new DtoApiPager();

  //詳細用
  //-----------------------
  /**
   * 売上用
   */
  public sales: EntitySalesImple[] = [];
  /**
   * 仕入用
   */
  public supplys: EntitySupplyImple[] = [];
  /**
   * 請求書用
   */
  public claims: EntityClaim[] = [];
  /**
   * ファイル用
   */
  public files: EntityFile[] = [];

  //前後の案件番号
  public jyutyuNumberPrev: number | null = null;
  public jyutyuNumberNext: number | null = null;

  //フロントでの日付変換用
  public cnvSalesdateStart: DtoMdDtp = new DtoMdDtp();
  public cnvSalesdateEnd: DtoMdDtp = new DtoMdDtp();
  public cnvSupplydateStart: DtoMdDtp = new DtoMdDtp();
  public cnvSupplydateEnd: DtoMdDtp = new DtoMdDtp();
}
