import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoClaimNumsGetReq } from "furukawa-common/src/common/dto/DtoClaimNumsGetReq";
import { DtoClaimNumsGetRes } from "furukawa-common/src/common/dto/DtoClaimNumsGetRes";
import { DtoClaimNumsPostReq } from "furukawa-common/src/common/dto/DtoClaimNumsPostReq";
import { DtoClaimNumsPostRes } from "furukawa-common/src/common/dto/DtoClaimNumsPostRes";

export class Cl_claimNum {
  // public static async getList(
  //   dto: DtoClaimsGetReqImple
  // ): Promise<DtoClaimsGetResImple> {
  //   return Object.assign(
  //     new DtoClaimsGetResImple(),
  //     await CallAPI.getRequest(
  //       "/claims?" +
  //         Cl_common.cnvObj2GetParam(dto.pager) +
  //         "&" +
  //         Cl_common.cnvObj2GetParam(dto)
  //     )
  //   );
  // }

  public static async get(
    dto: DtoClaimNumsGetReq
  ): Promise<DtoClaimNumsGetRes> {
    return Object.assign(
      new DtoClaimNumsGetRes(),
      await CallAPI.getRequest(
        "/claimnums/" + dto.projectid + "?" + Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async post(
    dto: DtoClaimNumsPostReq
  ): Promise<DtoClaimNumsGetRes> {
    return Object.assign(
      new DtoClaimNumsGetRes(),
      await CallAPI.postRequest("/claimnums", dto)
    );
  }

  // public static async put(dto: DtoClaimsPutReq): Promise<DtoClaimsPutRes> {
  //   return Object.assign(
  //     new DtoClaimsPutRes(),
  //     await CallAPI.putRequest("/claimnums/" + dto.c_claim_id, dto)
  //   );
  // }

  // public static async del(
  //   dto: DtoClaimsDeleteReq
  // ): Promise<DtoClaimsDeleteRes> {
  //   return Object.assign(
  //     new DtoClaimsDeleteRes(),
  //     await CallAPI.deleteRequest("/claims?" + Cl_common.cnvObj2GetParam(dto))
  //   );
  // }
}
