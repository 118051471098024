import { DtoApiPager } from "./abst/DtoApiPager";

export class DtoMastersgetReq {
  /**
   * 社員データ取得用 1:取得 0:取得しない
   */
  public getStaff: number | null = null;
  /**
   * 削除フラグを考慮して取得（社員マスタ用）　1:削除されてないデータだけ取得　0:全データ取得
   */
  public delFlag4staff: number | null = null;
  //---------------------
  /**
   * 社員データ取得用(勤怠管理用) 1:取得 0:取得しない
   */
  public getStaff4Attendance: number | null = null;
  //---------------------
  /**
   * 社員データ取得用(社員番号入力済み＋社員番号ソート) 1:取得 0:取得しない
   */
  public getStaff4sortNum: number | null = null;
  /**
   * 削除フラグを考慮して取得（getStaff4sortNum用）　1:削除されてないデータだけ取得　0:全データ取得
   */
  public delFlag4Staff4sortNum: number | null = null;
  //---------------------
  /**
   * 売上先データ取得用　1:取得 0:取得しない
   */
  public getClients: number | null = null;
  /**
   * 削除フラグを考慮して取得（売上先マスタ用）1:削除されてないデータだけ取得　0:全データ取得
   */
  public delFlag4clients: number | null = null;
  //---------------------
  /**
   * 仕入れ先データ取得用　1:取得 0:取得しない
   */
  public getSuppliers: number | null = null;
  /**
   * 削除フラグを考慮して取得（仕入れ先マスタ用）1:削除されてないデータだけ取得　0:全データ取得
   */
  public delFlag4suppliers: number | null = null;
  //---------------------
  /**
   * 口座取得用　1:取得 0:取得しない
   */
  public getBank: number | null = null;
  /**
   * 削除フラグを考慮して取得（仕入れ先マスタ用）1:削除されてないデータだけ取得　0:全データ取得
   */
  public delFlag4Bank: number | null = null;
  //---------------------
  /**
   * 保険申請担当社員データ取得用 1:取得 0:取得しない
   */
  public getStaff4insurance: number | null = null;
  //---------------------
  /**
   * 拠点データ取得用 1:取得 0:取得しない
   */
  public getBranch: number | null = null;
  /**
   * 削除フラグを考慮して取得（拠点マスタ用）　1:削除されてないデータだけ取得　0:全データ取得
   */
  public delFlag4Branch: number | null = null;

  //---------------------
  /**
   * 広告主用　1:取得 0:取得しない
   */
  public getSponsor: number | null = null;
  /**
   * 削除フラグを考慮して取得（広告主マスタ用）1:削除されてないデータだけ取得　0:全データ取得
   */
  public delFlag4Sponsor: number | null = null;
  //---------------------
  /**
   * 休日管理取得用　1:取得 0:取得しない
   */
  public getHoliday: number | null = null;
  //---------------------
  public IsValidImple(chkPager: boolean): string[] {
    let res: string[] = new Array();

    return res;
  }
}
