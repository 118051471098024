import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoRequestsGetReqImple } from "furukawa-common/src/common/dto/DtoRequestsGetReqImple";
import { DtoRequestsGetResImple } from "furukawa-common/src/common/dto/DtoRequestsGetResImple";
import { DtoRequestsPostReq } from "furukawa-common/src/common/dto/abst/DtoRequestsPostReq";
import { DtoRequestsPostRes } from "furukawa-common/src/common/dto/abst/DtoRequestsPostRes";
import { DtoRequestsPutReq } from "furukawa-common/src/common/dto/abst/DtoRequestsPutReq";
import { DtoRequestsPutRes } from "furukawa-common/src/common/dto/abst/DtoRequestsPutRes";
import { DtoRequestsDeleteReq } from "furukawa-common/src/common/dto/abst/DtoRequestsDeleteReq";
import { DtoRequestsDeleteRes } from "furukawa-common/src/common/dto/abst/DtoRequestsDeleteRes";

export class Cl_request {
  public static async getList(
    dto: DtoRequestsGetReqImple
  ): Promise<DtoRequestsGetResImple> {
    return Object.assign(
      new DtoRequestsGetResImple(),
      await CallAPI.getRequest(
        "/requests?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoRequestsGetReqImple
  ): Promise<DtoRequestsGetResImple> {
    return Object.assign(
      new DtoRequestsGetResImple(),
      await CallAPI.getRequest("/requests/" + dto.c_request_id)
    );
  }

  public static async post(
    dto: DtoRequestsPostReq
  ): Promise<DtoRequestsPostRes> {
    return Object.assign(
      new DtoRequestsPostRes(),
      await CallAPI.postRequest("/requests", dto)
    );
  }

  public static async put(dto: DtoRequestsPutReq): Promise<DtoRequestsPutRes> {
    return Object.assign(
      new DtoRequestsPutRes(),
      await CallAPI.putRequest("/requests/" + dto.c_request_id, dto)
    );
  }

  public static async del(
    dto: DtoRequestsDeleteReq
  ): Promise<DtoRequestsDeleteRes> {
    return Object.assign(
      new DtoRequestsDeleteRes(),
      await CallAPI.deleteRequest("/requests?" + Cl_common.cnvObj2GetParam(dto))
    );
  }
}
