import { EntityStaff4attendance } from "./EntityStaff4attendance";
import { EntityRequestHoliday } from "./EntityRequestHoliday";
import { EntityRequestOvertime } from "./EntityRequestOvertime";
import { EntityRequestBiztrip } from "./EntityRequestBiztrip";

export class EntityStaff4attendanceImple extends EntityStaff4attendance {
  /**
   * 当日の所属する休暇申請
   */
  public item4requestHoliday: EntityRequestHoliday[] = [];
  /**
   * 当日の所属する時間外申請
   */
  public item4requestOvertime: EntityRequestOvertime[] = [];
  /**
   * 当日の所属する出張申請
   */
  public item4requestBiztrip: EntityRequestBiztrip[] = [];
}
