
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import { DtoSponsorsGetReqImple } from "furukawa-common/src/common/dto/DtoSponsorsGetReqImple";
import { Cl_common } from "@/functions/Cl_common";
import { DtoSponsorsGetResImple } from "furukawa-common/src/common/dto/DtoSponsorsGetResImple";
import {
  cnvSponsorGenreId2Name,
  cnvSponsorTypeId2Name,
} from "furukawa-common/src/common/constant/Constants";
import { Cl_sponsor } from "@/functions/Cl_sponsor";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_modal_alert,
  },
})
export default class SponsorList extends Vue {
  /**
   * パフォーマンス問題の解決案としてテスト的に実装中
   * 残課題：グローバルなメソッドとして宣言して、どこからでも呼び出せるようにしたい
   * 　　　　色々なパターンでも問題ないかの検証
   * @param url
   * @param event
   */
  goTo(url: string, event?: MouseEvent) {
    if (event && (event.ctrlKey || event.metaKey)) {
      window.open(url);
    } else {
      this.$router.push(url);
    }
  }
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoSponsorsGetResImple();
  //ボタン制御用
  btnDisable = false;

  //検索条件dom
  domIdStart = "";
  domIdEnd = "";
  domClientName = "";
  domKeyword = "";

  columns = [
    {
      label: "詳細",
      field: "btnDet",
      width: "10%",
    },
    {
      label: "広告主コード",
      field: "c_sponsor_id",
    },
    {
      label: "広告主名",
      field: "c_sponsor_name",
    },
    {
      label: "業種",
      field: "c_sponsor_genre",
    },
    {
      label: "種別",
      field: "c_sponsor_type",
    },
  ];

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";

  //----------------------
  //基本操作用メソッド
  //----------------------
  async mounted() {
    await this.getlist(1);
  }

  /**
   * 一覧データを読み込む
   */
  async getlist(currentPage: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoSponsorsGetReqImple();

      //検索条件設定
      dto.pager.count = 9999;
      dto.pager.cursor = currentPage;

      dto.keyword = this.domKeyword;

      //API実行
      //-------
      this.delegate = await Cl_sponsor.getList(dto);
      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      //エラー発生で一覧を初期化
      this.delegate = new DtoSponsorsGetResImple();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      // let dto = new DtoFileMastersGetReqImple();
      // dto.type = "ShiiresakiList";
      // dto.fromCode = Number(this.domIdStart);
      // dto.toCode = Number(this.domIdEnd);
      // dto.name = this.domClientName;

      //API実行
      //-------
      // const res = await Cl_filemaster.get(dto);
      // console.log(res);
      // console.log(res.url);

      //レスポンス処理
      //-------
      // location.href = res.url;
      //window.open(res.url, "_blank");
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  async moveDet(id: string) {
    //詳細ページに移動
    this.$router.push("/sponsorEdit?id=" + id);
  }
  /**
   * ページ更新時の処理
   * @param params
   */
  async onPageChange(params: any) {
    //テーブル更新処理
    await this.getlist(params.currentPage);
  }
  /**
   * ソート変更時の処理
   * @param params
   */
  async onSortChange(params: any) {
    //テーブル更新処理
  }

  /**
   * 業種の番号を受け取り、名称にして返す
   */
  cnvSponsorGenre2Name(type: number | null): string {
    if (type == null) {
      return "";
    }
    return cnvSponsorGenreId2Name(type);
  }
  /**
   * 種別の番号を受け取り、名称にして返す
   */
  cnvSponsorType2Name(type: number | null): string {
    if (type == null) {
      return "";
    }
    return cnvSponsorTypeId2Name(type);
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
