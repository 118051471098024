
import { Options, Vue } from "vue-class-component";
import { Cl_common } from "@/functions/Cl_common";
import { CONST_LIST_DEFO_COUNT } from "@/constants/constant";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import { DtoBranchsGetReqImple } from "furukawa-common/src/common/dto/DtoBranchsGetReqImple";
import { DtoBranchGoalsGetRes } from "furukawa-common/src/common/dto/DtoBranchGoalsGetRes";
import { Cl_branchGoal } from "@/functions/Cl_branchGoal";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_modal_alert,
  },
})
export default class BaseGoalList extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoBranchGoalsGetRes();
  domYear = { label: "" };
  domDepart = "";
  domBranchId = "";
  domStaffId = "";

  //ボタン制御用
  btnDisable = false;

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";

  columns = [
    {
      label: "期",
      field: "c_goal_year",
      thClass: "text-center",
      width: "10%",
    },
    {
      label: "拠点",
      field: "c_branch_name",
      thClass: "text-center",
    },
    {
      label: "売上目標",
      field: "c_goal_earnings_sum",
      thClass: "text-center",
      tdClass: "text-right",
    },
    {
      label: "粗利目標",
      field: "c_goal_gross_sum",
      thClass: "text-center",
      tdClass: "text-right",
    },
  ];

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getlist(1);
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * 一覧データを読み込む
   */
  async getlist(currentPage: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoBranchsGetReqImple();

      //検索条件設定
      // dto.pager.count = CONST_LIST_DEFO_COUNT;
      dto.pager.count = 9999;
      dto.pager.cursor = currentPage;

      // if (
      //   this.domYear != null &&
      //   this.domYear.label != null &&
      //   this.domYear.label != ""
      // ) {
      //   dto.year = Number(this.domYear.label);
      // }

      // if (this.domStaffId != "" && this.domStaffId != null)
      //   dto.staffId = this.domStaffId;

      // if (this.domBranchId != "" && this.domBranchId != null)
      //   dto.branchId = this.domBranchId;

      // if (this.domDepart != "" && this.domDepart != null)
      //   dto.depertId = this.domDepart;

      //API実行
      //-------
      this.delegate = await Cl_branchGoal.getList(dto);

      //レスポンス処理
      //-------
      Cl_common.logger("結果");
      Cl_common.logger(this.delegate);
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      //エラー発生で一覧を初期化
      this.delegate = new DtoBranchGoalsGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      // let dto = new DtoFileMastersGetReqImple();
      // dto.type = "SalesTargetMaster";
      // if (
      //   this.domYear != null &&
      //   this.domYear.label != null &&
      //   this.domYear.label != ""
      // ) {
      //   dto.year = Number(this.domYear.label);
      // }

      //API実行
      //-------
      // const res = await Cl_filemaster.get(dto);

      //レスポンス処理
      //-------
      // location.href = res.url;
      //window.open(res.url, "_blank");
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * ページ更新時の処理
   * @param params
   */
  async onPageChange(params: any) {
    //テーブル更新処理
    await this.getlist(params.currentPage);
  }
  /**
   * ソート変更時の処理
   * @param params
   */
  async onSortChange(params: any) {
    //テーブル更新処理
  }

  /**
   * 銀行名を返す
   */
  getBankName(bankNumber: number) {
    // return CONST_BANK_NAME[bankNumber];
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
