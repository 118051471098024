import { EntityStaff } from "../entity/EntityStaff";
import { DtoApiPager } from "./abst/DtoApiPager";
import { DtoStaffsGetRes } from "./abst/DtoStaffsGetRes";

export class DtoStaffsGetResImple extends DtoStaffsGetRes {
  public pager = new DtoApiPager();
  public exitems: Exitem[] = [];

  //メソッド
  //-----------------------
  public async setEximtes(Branchmap: Map<number, string>) {
    let tmp: Exitem;
    for (let index = 0; index < this.items.length; index++) {
      tmp = new Exitem();

      tmp.c_staff_id = this.items[index].c_staff_id;
      tmp.c_staff_number = this.items[index].c_staff_number;
      tmp.c_staff_name = this.items[index].c_staff_name;
      tmp.c_staff_name_pt = this.items[index].c_staff_name_pt;
      tmp.c_branch_id = this.items[index].c_branch_id;
      tmp.c_branch_name = Branchmap.get(this.items[index].c_branch_id!)!;     

      this.exitems.push(tmp);
    }
  }


}

export class Exitem extends EntityStaff {
  public c_branch_name: string | null = null;
}