
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_modal_alert,
  },
})
export default class DailyReportSearch extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  btnDisable = false;

  optionsOp = [
    { label: "社員１", code: 1 },
    { label: "社員２", code: 2 },
    { label: "社員３", code: 3 },
    { label: "社員４", code: 4 },
  ];
  opReportStatus = [
    { label: "決定", code: 1 },
    { label: "提案", code: 2 },
    { label: "調査", code: 3 },
  ];
  columns = [
    {
      label: "詳細",
      field: "btnDet",
      thClass: "text-center",
      tdClass: "text-center",
      width: "10%",
    },
    {
      label: "種別",
      field: "report_status",
      thClass: "text-center",
    },
    {
      label: "記載者",
      field: "staff_nm",
      thClass: "text-center",
    },
    {
      label: "日付",
      field: "report_day",
      thClass: "text-center",
    },
    {
      label: "案件名",
      field: "project_nm",
      thClass: "text-center",
    },
    {
      label: "商談先",
      field: "report_prospect",
      thClass: "text-center",
    },
    {
      label: "場所",
      field: "report_place",
      thClass: "text-center",
    },
    {
      label: "内容",
      field: "report_content",
      thClass: "text-center",
    },
    {
      label: "売上",
      field: "report_claim_sales",
      thClass: "text-center",
      tdClass: "text-right",
    },
    {
      label: "仕入",
      field: "report_claim_supplier",
      thClass: "text-center",
      tdClass: "text-right",
    },
  ];
  rows = [
    {
      id: 1,
      report_status: "提案",
      staff_nm: "中前 忠久",
      report_day: "2020-10-02",
      project_nm: "サイエンス",
      report_prospect: "プレジール",
      report_place: "豊中市庄本",
      report_content: "広告物申請書引取",
      report_claim_sales: "150,000",
      report_claim_supplier: "80,000",
    },
    {
      id: 2,
      report_status: "提案",
      staff_nm: "中前 忠久",
      report_day: "2020-10-02",
      project_nm: "サイエンス",
      report_prospect: "プレジール",
      report_place: "豊中市庄本",
      report_content: "広告物申請書引取",
      report_claim_sales: "150,000",
      report_claim_supplier: "80,000",
    },
    {
      id: 3,
      report_status: "提案",
      staff_nm: "中前 忠久",
      report_day: "2020-10-02",
      project_nm: "サイエンス",
      report_prospect: "プレジール",
      report_place: "豊中市庄本",
      report_content: "広告物申請書引取",
      report_claim_sales: "150,000",
      report_claim_supplier: "80,000",
    },
  ];
}
