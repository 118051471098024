
import { Prop, Options, Vue, Watch } from "vue-property-decorator";
import { EntitySimulationImple } from "furukawa-common/src/common/entity/EntitySimulationImple";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import {
  CONST_MIGRATION_SETTING_KAKUTEI_A,
  CONST_MIGRATION_SETTING_KAKUTEI_B,
  CONST_MIGRATION_SETTING_MIKOMI_C,
  CONST_MIGRATION_SETTING_SAKUJYO,
  CONST_MIGRATION_SETTING_SEIKYUUZUMI,
  CONST_MIGRATION_SETTING_TEKKYO,
  CONST_OP_MIGRATION_SETTING,
  CONST_MIGRATION_SETTING_SYUDOUIKOUZUMI,
  CONST_OP_TAXRATE,
  cnvSettingNum2Name,
} from "furukawa-common/src/common/constant/Constants";
import { DtoMastersGetRes } from "furukawa-common/src/common/dto/DtoMastersGetRes";
import { Cl_common } from "@/functions/Cl_common";
import { DtoMigrationsPostReq } from "furukawa-common/src/common/dto/abst/DtoMigrationsPostReq";
import { Cl_migration } from "@/functions/Cl_migration";

@Options({
  components: {
    vSelect,
  },
})
export default class Mo_simulationlist_row extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  @Prop({ required: true })
  item: EntitySimulationImple;
  @Prop({ required: true })
  mastarslist: DtoMastersGetRes;

  domComment = "";
  domPrice_sales = "";
  domPrice_supplys = "";

  //CSSクラス関係
  classSetter = {
    KAKUTEI_A: false,
    KAKUTEI_B: false,
    MIKOMI_C: false,
    TEKKYO: false,
    SAKUJYO: false,
    SEIKYUUZUMI: false,
    SYUDOUIKOUZUMI: false,
    ISREADONLY: false,
  };

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  opSetting: {
    label: string;
    code: number;
  }[] = [];
  //----------------------
  //モーダル用プロパティ
  //----------------------

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    if (this.item.c_migration_comment != null) {
      this.domComment = this.item.c_migration_comment;
    }

    //CSS クラス設定
    this.setCssClass();

    //種別の選択肢を設定:削除を最後に移動
    let opSettingTmp = JSON.parse(JSON.stringify(CONST_OP_MIGRATION_SETTING));
    const indexDelet = 4;
    const item = opSettingTmp.splice(indexDelet, 1)[0]; // 配列から要素を削除
    opSettingTmp.push(item); // 削除した要素を最後に追加
    this.opSetting = opSettingTmp;

    //売上金額
    if (this.item.v_simulation_salessum != null) {
      this.domPrice_sales = this.item.v_simulation_salessum.toString();
      while (
        this.domPrice_sales !=
        (this.domPrice_sales = this.domPrice_sales.replace(
          /^(-?\d+)(\d{3})/,
          "$1,$2"
        ))
      );
    }
    //仕入金額
    if (this.item.v_simulation_supplyssum != null) {
      this.domPrice_supplys = this.item.v_simulation_supplyssum.toString();
      while (
        this.domPrice_supplys !=
        (this.domPrice_supplys = this.domPrice_supplys.replace(
          /^(-?\d+)(\d{3})/,
          "$1,$2"
        ))
      );
    }
  }
  /**
   * パフォーマンス問題の解決案としてテスト的に実装中
   * 残課題：グローバルなメソッドとして宣言して、どこからでも呼び出せるようにしたい
   * 　　　　色々なパターンでも問題ないかの検証
   * @param url
   * @param event
   */
  goTo(url: string, event?: MouseEvent) {
    if (event && (event.ctrlKey || event.metaKey)) {
      window.open(url);
    } else {
      this.$router.push(url);
    }
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * 更新　更新イベント発生させる
   */
  async upd() {
    //コメント
    this.item.c_migration_comment = this.domComment;

    //売上
    if (this.domPrice_sales != null && this.domPrice_sales != "") {
      this.item.c_migration_price_sales = Number(
        this.domPrice_sales.replaceAll(",", "")
      );
    }
    //仕入
    if (this.domPrice_supplys != null && this.domPrice_supplys != "") {
      this.item.c_migration_price_supplys = Number(
        this.domPrice_supplys.replaceAll(",", "")
      );
    }

    //CSS クラス設定
    if (
      this.item.c_migration_setting != null &&
      this.item.c_migration_price_sales != null &&
      this.item.c_migration_price_supplys != null
    ) {
      this.setCssClass();
    }

    //イベントトリガ
    this.$emit("upd", this.item);
  }

  /**
   * 削除　削除用イベントを発生させる
   */
  async del() {
    //イベントトリガ
    this.$emit("del", this.item);
  }

  cnvSetting2Name(): string {
    if (this.item.c_migration_setting == null) {
      return "";
    }

    return cnvSettingNum2Name(this.item.c_migration_setting);
  }
  cnvStaffId2Name(): string {
    if (this.item.c_migration_staff_id4sales == null) {
      return "";
    }

    let staffName = this.mastarslist.items4staffs.find(
      (e) => e.c_staff_id == this.item.c_migration_staff_id4sales
    )?.c_staff_name;

    if (staffName == null || staffName == undefined) {
      return "";
    }

    return staffName;
  }

  setCssClass() {
    this.classSetter.KAKUTEI_A =
      this.item.c_migration_setting == CONST_MIGRATION_SETTING_KAKUTEI_A;
    this.classSetter.KAKUTEI_B =
      this.item.c_migration_setting == CONST_MIGRATION_SETTING_KAKUTEI_B;
    this.classSetter.MIKOMI_C =
      this.item.c_migration_setting == CONST_MIGRATION_SETTING_MIKOMI_C;
    this.classSetter.TEKKYO =
      this.item.c_migration_setting == CONST_MIGRATION_SETTING_TEKKYO;
    this.classSetter.SAKUJYO =
      this.item.c_migration_setting == CONST_MIGRATION_SETTING_SAKUJYO;
    this.classSetter.SEIKYUUZUMI =
      this.item.c_migration_setting == CONST_MIGRATION_SETTING_SEIKYUUZUMI;
    this.classSetter.SYUDOUIKOUZUMI =
      this.item.c_migration_setting == CONST_MIGRATION_SETTING_SYUDOUIKOUZUMI;
    this.classSetter.ISREADONLY = this.item.c_migration_id != null;
  }

  formatDateToYYYYMM(isoString: Date | null): string {
    if (isoString == null) {
      return "未設定";
    }

    // 文字列をDateオブジェクトに変換
    const date = new Date(isoString);

    // 年と月を取得
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1; // getUTCMonth()は0から11までの値を返すため、1を加算して1から12にする

    // yyyy/MM形式にフォーマット
    return `${year}/${month.toString().padStart(2, "0")}`;
  }
  /**
   * 金額セル入力時の処理
   * カンマを消す
   * @param obj
   */
  kanmaDel(obj: any) {
    // //カンマを消す
    obj.target.value = obj.target.value.replace(/,/g, "");
    if (obj.target.value == "0") obj.target.value = "";
  }
  /**
   * 金額セル入力時の処理
   * カンマを付ける
   * @param obj
   */
  kanmaIns(obj: any) {
    let val: string = obj.target.value;

    //うっかり入力しているかもしれないカンマを消す
    val = val.replace(/,/g, "");
    //整数に変換したのち文字列に戻す
    //この時点で数字とマイナス記号だけが残る
    let num = "" + parseInt(val);
    //正規表現で桁区切りするための良く見かける関数的な何か
    //変数 num の中身が、桁区切りされる
    while (num != (num = num.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
    //numに入っている値が数値じゃないときは0とする
    if (isNaN(parseInt(num))) num = "";
    //桁区切りした結果（変数 num）でテキストボックスの中身を書き換える
    obj.target.value = num;
  }
  /**
   * 金額セル入力時の処理
   * カンマを付ける
   * @param obj
   */
  kanmaIns4static(obj: string | null): string {
    if (obj == null) {
      return "";
    }
    let val: string = obj.toString();

    //うっかり入力しているかもしれないカンマを消す
    val = val.replace(/,/g, "");
    //整数に変換したのち文字列に戻す
    //この時点で数字とマイナス記号だけが残る
    let num = "" + parseInt(val);
    //正規表現で桁区切りするための良く見かける関数的な何か
    //変数 num の中身が、桁区切りされる
    while (num != (num = num.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
    //numに入っている値が数値じゃないときは空白とする
    if (isNaN(parseInt(num))) num = "";
    //桁区切りした結果（変数 num）でテキストボックスの中身を書き換える
    return num;
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
}
