import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoSponsorsGetReqImple } from "furukawa-common/src/common/dto/DtoSponsorsGetReqImple";
import { DtoSponsorsGetResImple } from "furukawa-common/src/common/dto/DtoSponsorsGetResImple";
import { DtoSponsorsPostReq } from "furukawa-common/src/common/dto/abst/DtoSponsorsPostReq";
import { DtoSponsorsPostRes } from "furukawa-common/src/common/dto/abst/DtoSponsorsPostRes";
import { DtoSponsorsPutReq } from "furukawa-common/src/common/dto/abst/DtoSponsorsPutReq";
import { DtoSponsorsPutRes } from "furukawa-common/src/common/dto/abst/DtoSponsorsPutRes";
import { DtoSponsorsDeleteReq } from "furukawa-common/src/common/dto/abst/DtoSponsorsDeleteReq";
import { DtoSponsorsDeleteRes } from "furukawa-common/src/common/dto/abst/DtoSponsorsDeleteRes";

export class Cl_sponsor {
  public static async getList(
    dto: DtoSponsorsGetReqImple
  ): Promise<DtoSponsorsGetResImple> {
    return Object.assign(
      new DtoSponsorsGetResImple(),
      await CallAPI.getRequest(
        "/sponsors?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoSponsorsGetReqImple
  ): Promise<DtoSponsorsGetResImple> {
    return Object.assign(
      new DtoSponsorsGetResImple(),
      await CallAPI.getRequest("/sponsors/" + dto.c_sponsor_id)
    );
  }

  public static async post(
    dto: DtoSponsorsPostReq
  ): Promise<DtoSponsorsPostRes> {
    return Object.assign(
      new DtoSponsorsPostRes(),
      await CallAPI.postRequest("/sponsors", dto)
    );
  }

  public static async put(dto: DtoSponsorsPutReq): Promise<DtoSponsorsPutRes> {
    return Object.assign(
      new DtoSponsorsPutRes(),
      await CallAPI.putRequest("/sponsors/" + dto.c_sponsor_id, dto)
    );
  }

  public static async del(
    dto: DtoSponsorsDeleteReq
  ): Promise<DtoSponsorsDeleteRes> {
    return Object.assign(
      new DtoSponsorsDeleteRes(),
      await CallAPI.deleteRequest("/sponsors?" + Cl_common.cnvObj2GetParam(dto))
    );
  }
}
