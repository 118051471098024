
import { Prop, Options, Vue, Watch } from "vue-property-decorator";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { DtoMdDtp } from "furukawa-common/src/common/dto/DtoMdDtp";
import { DtoMastersGetRes } from "furukawa-common/src/common/dto/DtoMastersGetRes";
import { ref } from "vue";
import { CommonDate4FU } from "furukawa-common/src/common/function/CommonDate4FU";

@Options({
  components: { VueDatePicker },
})
export default class Mt_dtp extends Vue {
  mode = ref<boolean | string>("center");
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  @Prop({ required: true })
  contdate: DtoMdDtp;
  @Prop({ required: false })
  holidays: DtoMastersGetRes;

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------

  //----------------------
  //モーダル用プロパティ
  //----------------------

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    //
  }

  getDayClass(date: any) {
    const weekDay = new Date(date).getDay();
    const holiDay = this.holidays;
    const date2 = CommonDate4FU.cnvUTC2JST(date);
    //社内祝日処理
    //オブジェクト配列の日付の入った特定のフィールドを照会して、一致した要素を取得する
    if (holiDay != null) {
      for (let index = 0; index < holiDay.items4Holiday.length; index++) {
        //Date型の中身は1970/1/1～指定日時までの経過時間なので、比較できる
        if (
          new Date(holiDay.items4Holiday[index].c_holiday_date!).getTime() ==
          date2.getTime()
        ) {
          return "sunday";
        }
      }
    }
    //休日管理データがなければ、通常の曜日処理
    if (weekDay == 6) {
      // 土曜日の場合、classに"saturday"を追加
      return "saturday";
    }
    if (weekDay == 0) {
      // 日曜日の場合、classに"sunday"を追加
      return "sunday";
    }
    return "";
  }

  //----------------------
  //基本操作用メソッド
  //----------------------

  //----------------------
  //モーダル用メソッド
  //----------------------
}
