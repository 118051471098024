import { CONST_OP_YEAR } from "../constant/Constants";
import { EntityGoal } from "../entity/EntityGoal";
import { EntitySearchGoal } from "../entity/EntitySearchGoal";
import { DtoApiPager } from "./abst/DtoApiPager";
import { DtoGoalsGetRes } from "./abst/DtoGoalsGetRes";

export class DtoGoalsGetResImple extends DtoGoalsGetRes {
  public pager = new DtoApiPager();
  public itemsImple: EntitySearchGoal[] = [];
  public exitems: Exitem[] = [];

  //メソッド
  //-----------------------
  public async setEximtes() {
    let tmp: Exitem;
    for (let index = 0; index < this.itemsImple.length; index++) {
      tmp = new Exitem();

      tmp.c_goal_id = this.itemsImple[index].c_goal_id;
      tmp.c_staff_id = this.itemsImple[index].c_staff_id;
      tmp.c_goal_year = this.itemsImple[index].c_goal_year;
      tmp.c_goal_earnings = this.itemsImple[index].c_goal_earnings;
      tmp.c_goal_gross = this.itemsImple[index].c_goal_gross;
      tmp.c_branch_name = this.itemsImple[index].c_branch_name;
      tmp.c_branch_id = this.itemsImple[index].c_branch_id;
      tmp.c_staff_number = this.itemsImple[index].c_staff_number;
      tmp.c_staff_name = this.itemsImple[index].c_staff_name;
      tmp.c_staff_depart = this.itemsImple[index].c_staff_depart;

      // if (tmp.c_goal_year != null) {
      //   tmp.c_goal_year_show = CONST_OP_YEAR_SHOW[tmp.c_goal_year].label;
      // } else {
      //   tmp.c_goal_year_show = "";
      // }

      this.exitems.push(tmp);
    }
  }
}

export class Exitem extends EntitySearchGoal {
  public c_goal_year_show: string | null = null;
}
