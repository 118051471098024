import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntityProject } from "../../entity/EntityProject";
import { EntitySalesImple } from "../../entity/EntitySalesImple";
import { EntitySupplyImple } from "../../entity/EntitySupplyImple";

export class DtoProjectsPostReq extends EntityProject {
  //詳細用
  //-----------------------
  public businessNum: number | null = null;
  /**
   * 売上用
   */
  public sales: EntitySalesImple[] = [];
  /**
   * 仕入用
   */
  public supplys: EntitySupplyImple[] = [];

  //案件コピー用
  //-----------------------
  /**
   * 1で案件コピーモード
   */
  public isProjectCopy: number | null = null;
  /**
   * コピー対象ProjectID
   */
  public projectid4copy: number | null = null;
  /**
   * コピー対象案件番号
   */
  public projectcopyNum: number | null = null;

  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------
    if (this.businessNum == null) {
      res.push("不正な値");
    }

    return res;
  }
}
