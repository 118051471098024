
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

@Options({
  components: {
    vSelect,
  },
})
export default class Or_tableSupply extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  optionsOp4 = [{ label: "新規" }, { label: "継続" }];
  optionsOp5 = [
    { label: "長期看板" },
    { label: "短期看板" },
    { label: "リムジンバス" },
    { label: "都営交通" },
    { label: "交通広告" },
    { label: "ビジョン" },
    { label: "経費" },
    { label: "その他" },
  ];
  optionsOp6 = [
    { label: "媒体料" },
    { label: "プリント" },
    { label: "施工" },
    { label: "製作施工" },
    { label: "工事(資産)" },
    { label: "保険・申請" },
    { label: "撤去前受" },
    { label: "その他" },
  ];
  optionsOp7 = [
    { label: "テスト" },
    { label: "アイエイチ・ラブ" },
    { label: "アイビーエス" },
    { label: "アクスル" },
    { label: "アクティス" },
    { label: "ACOZ" },
    { label: "アサツーディ・ケイ" },
    { label: "朝日電装" },
    { label: "アテンポライフ" },
    { label: "アドベル" },
    { label: "アド・トゥモロー" },
    { label: "アートシステム" },
    { label: "五十嵐商事" },
    { label: "伊勢屋テック" },
    { label: "茨城ロボッツ・スポーツエンターテイメント" },
    { label: "インフィニティ・コミュニケーションズ" },
    { label: "INFORICH" },
    { label: "エイディ・アンド・エム" },
    { label: "エスピーエスエス" },
    { label: "ＮＫＢ" },
    { label: " エヌ・ティ・ティ・アド  " },
    { label: "エムズネットワーク" },
    { label: "エムティエム" },
    { label: "ADK マーケティング・ソリューションズ" },
    { label: "エー・ティ・エー" },
    { label: "大阪屋通商" },
    { label: "那覇市役所 経済観光部 なはまちなか振興課" },
    { label: "オフィス・ミツキ" },
    { label: "オリエンタルダイニング" },
    { label: "特定非営利活動法人オンデン・商店会支援機構" },
    { label: "オーティス" },
    { label: "OPA 金沢フォーラス" },
    { label: "金子 亜矢子" },
    { label: "カンナム" },
    { label: "近宣" },
    { label: "クボタ" },
    { label: "熊沢印刷工芸" },
    { label: "光伸プランニング" },
    { label: "神戸新聞会館" },
    { label: "コンセルジェ" },
    { label: "さいたま市" },
    { label: "サッポロ不動産開発" },
    { label: "三和美研" },
    { label: "サーチ" },
    { label: "サードカルチャー" },
    { label: "シティボード" },
    { label: "渋谷区　土木管理課" },
    { label: "心商" },
    { label: "シンボルタワー開発" },
    { label: "JR東日本リテールネット" },
    { label: "JVIS" },
    { label: "創研プランニング" },
    { label: "創窓堂" },
    { label: "造産" },
    { label: "タイム･エージェント" },
    { label: "タカショーデジテック" },
    { label: "タンデムクロス" },
    { label: "大広メディアックス 名古屋支社　" },
    { label: "中國新聞広告社" },
    { label: "東急" },
    { label: "東急不動産" },
    { label: "東京Ｂａｉｔａｉ" },
    { label: "斗南電装" },
    { label: "TOMOE" },
    { label: "トレードワークス" },
    { label: "内外事業社" },
    { label: "長野朝日放送株式会社　東京支社" },
    { label: "新潟日報社" },
    { label: "日東工業" },
    { label: "ニューステクノロジー" },
    { label: "人参社" },
    { label: "Nomad Tokyo 合同会社" },
    { label: "ノーラッド" },
    { label: "阪急阪神マーケティングソリューションズ" },
    { label: "ビープラスト" },
    { label: "ピーエーアール" },
    { label: "ファイナンシャルニュースジャパン" },
    { label: "ファンクス" },
    { label: "風光社" },
    { label: "福井新聞社　東京支社" },
    { label: "富士フイルムイメージングシステムズ" },
    { label: "フリークアウト" },
    { label: "古川広告社　札幌営業所" },
    { label: "フレッシュタウン" },
    { label: "株式会社ベストクルーズ" },
    { label: "三菱リテールマネジメント" },
    { label: "未来図" },
    { label: "明信社" },
    { label: "メディアサービス" },
    { label: "森ビル" },
    { label: "ヤマダ電機" },
    { label: "横浜市　都市整備局" },
    { label: "吉村建設工業" },
    { label: "ラジカンマネジメント" },
    { label: "リップルリンク" },
    { label: "リビオ五反田プラグマ．Gタワー管理組合" },
    { label: "リード" },
    { label: "ルミネアソシエーツ" },
    { label: "アイシフト" },
    { label: "アイマッチング" },
    { label: "赤坂警察署" },
    { label: "旭自動車ボデー" },
    { label: "アドアーズ" },
    { label: "アドクルーズ" },
    { label: "アドビジョン大阪メディアセンター" },
    { label: "アドマックス" },
    { label: "アンデザイン" },
    { label: "生柄景子" },
    { label: "伊勢佐木警察署" },
    { label: "一般財団法人東京都営交通協力会" },
    { label: "一般社団法人 茶道文化振興会 茶道会館" },
    { label: "一般社団法人日本ランニング協会" },
    { label: "イディアクロス" },
    { label: "イムズ" },
    { label: "インターコネクト" },
    { label: "インターコネクト関西オフィス" },
    { label: "ウイル" },
    { label: "うちわダイレクト販促" },
    { label: "エコ・エイト" },
    { label: "エスエイエム" },
    { label: "NTTビジネスソリューションズ" },
    { label: "NPO法人復興支援ネットワーク・フェニックス" },
    { label: "ゑびすや" },
    { label: "エフグラムプロダクト" },
    { label: "Mエステート" },
    { label: "エン・ジャパン" },
    { label: "エージェンシー・ルクス" },
    { label: "A.T.L" },
    { label: "エーディーエス" },
    { label: "エーピー・ジャパン" },
    { label: "大阪市保健所" },
    { label: "オカ工芸" },
    { label: "オカダヤ" },
    { label: "小田急エージェンシー" },
    { label: "オリエンタルメディア" },
    { label: "オリエンタル・エージェンシー" },
    { label: "オリコム" },
    { label: "オンリーワン" },
    { label: "オーヴァルジュニア" },
    { label: "OOHメディア・ソリューションズ" },
    { label: "オーディーエー" },
    { label: "OPA" },
    { label: "会音社" },
    { label: "カズファクトリー" },
    { label: "加茂商事" },
    { label: "川口商工会議所" },
    { label: "関西電力" },
    { label: "カーサグランデ" },
    { label: "学校法人 町田学園 品川エトワール女子高等学校" },
    { label: "キッズプロモーション" },
    { label: "キャトル" },
    { label: "キョウエイアドインターナショナル" },
    { label: "京王エージェンシー" },
    { label: "キョウシンアド" },
    { label: "京西広告" },
    { label: "共同、人参、五木、古川" },
    { label: "共同、人参、五木、古川、メディクリ" },
    { label: "共同エージェンシー" },
    { label: "協立広告" },
    { label: "空港施設" },
    { label: "熊本日日新聞社" },
    { label: "クラウドポイント" },
    { label: "グリーンルーム" },
    { label: "グリーンワン" },
    { label: "ケイエムアドシステム" },
    { label: "京急アドエンタープライズ" },
    { label: "経費精算" },
    { label: "ケイ・アイ・システム" },
    { label: "ケシオン" },
    { label: "弘亜社" },
    { label: "公益社団法人 東京屋外広告協会" },
    { label: "公益社団法人北九州市観光協会" },
    { label: "公共シィー・アール・イー" },
    { label: "広告通信社" },
    { label: "神戸新聞社" },
    { label: "神戸ビエンナーレ組織委員会" },
    { label: "廣和" },
    { label: "古宮こうき" },
    { label: "コミュニケーションアド" },
    { label: "コラボレーション" },
    { label: "五木エージェンシー" },
    { label: "斉藤　美智子" },
    { label: "侍" },
    { label: "サンノア" },
    { label: "サンプラン" },
    { label: "山陽新聞社" },
    { label: "シネブリッジ" },
    { label: "シブヤテレビジョン" },
    { label: "渋谷ブラザービル" },
    { label: "島根不動産" },
    { label: "春光社" },
    { label: "商店街振興組合原宿表参道欅会" },
    { label: "昭和印刷" },
    { label: "昭和ネオン" },
    { label: "所管庁" },
    { label: "信越放送" },
    { label: "シンクロ" },
    { label: "新日本創業" },
    { label: "JR九州エージェンシー" },
    { label: "ジェイアール四国企画" },
    { label: "ジェイアール東日本企画" },
    { label: "JSPプロダクツ" },
    { label: "ジェスト" },
    { label: "ジュンアンドロペ・エンターテイメント" },
    { label: "ジョイエンタープライズ" },
    { label: "G-Production" },
    { label: "スタジオアルタ" },
    { label: "ストレート" },
    { label: "スパーダ" },
    { label: "スポーツソウルジャパン" },
    { label: "すみれスタジオ" },
    { label: "スリーエム" },
    { label: "スリーエープロダクト" },
    { label: "誠志堂ワールド" },
    { label: "瀬戸内企画" },
    { label: "セイストン" },
    { label: "セーレン" },
    { label: "ゼルプロモーション" },
    { label: "相鉄エージェンシー" },
    { label: "ソフマップ" },
    { label: "タカギ" },
    { label: "タップ" },
    { label: "多摩中央商業開発" },
    { label: "タワーレコード" },
    { label: "第一弘報社" },
    { label: "大同クリーニング" },
    { label: "ダイワロイヤル" },
    { label: "ダスキンくりはら" },
    { label: "千葉県バス協会" },
    { label: "CHINTAI" },
    { label: "TMサインシステム" },
    { label: "テックソリューション" },
    { label: "電通" },
    { label: "東海インタービジョン" },
    { label: "東急エージェンシー" },
    { label: "東京オフラインセンター" },
    { label: "東京空港交通" },
    { label: "東京交通会館" },
    { label: "東京タカラ商会" },
    { label: "東京都交通局" },
    { label: "東京ミッドタウンマネジメント" },
    { label: "東京メディアコミュニケーションズ" },
    { label: "東京リスマチック" },
    { label: "東京レインボープライド運営委員会" },
    { label: "東通" },
    { label: "遠鉄コミュニケーションズ" },
    { label: "遠山アド" },
    { label: "トピー工業" },
    { label: "トピー実業" },
    { label: "豊島区立　目白庭園" },
    { label: "トヨタ保険サービス" },
    { label: "トライゴン・グラフィックス・サービス" },
    { label: "トライニクス" },
    { label: "トレイダーズ" },
    { label: "ナイキジャパン" },
    { label: "中村工社" },
    { label: "長野県諏訪建設事務所" },
    { label: "新潟日報社" },
    { label: "二期会21" },
    { label: "日本ドライブシール" },
    { label: "ノムラデュオ" },
    { label: "博報堂DYアウトドア" },
    { label: "博報堂DYメディアパートナーズ" },
    { label: "函館バス商会" },
    { label: "ハリガイ電気管理事務所" },
    { label: "阪急クオリティーサポート" },
    { label: "阪急阪神ビルマネジメント" },
    { label: "パクラル" },
    { label: "パシフィックメディア" },
    { label: "パス・コミュニケーションズ" },
    { label: "パナソニックES産機システム" },
    { label: "ヒット" },
    { label: "ヒビノ" },
    { label: "平賀スクエア" },
    { label: "ビスメディア" },
    { label: "BILLY DURANT WOT’S" },
    { label: "ビルケンシュトック" },
    { label: "ビーコン" },
    { label: "ピュア" },
    { label: "ピー・プランニング" },
    { label: "フォレスト流通" },
    { label: "FORIE" },
    { label: "福井新聞PRセンター" },
    { label: "フクダ運輸倉庫" },
    { label: "藤原農機" },
    { label: "古川広告社" },
    { label: "古川広告社(東京・大阪)" },
    { label: "古川広告社（東阪名）" },
    { label: "古川広告社　大阪本社" },
    { label: "古川広告社　名古屋営業所" },
    { label: "古川広告社　東京、大阪、名古屋" },
    { label: "古川広告社　福岡営業所" },
    { label: "古川広告社　東京支社" },
    { label: "古川 東京、大阪" },
    { label: "フロンティアインターナショナル" },
    { label: "ブル－" },
    { label: "プラド" },
    { label: "プラネット" },
    { label: "プランニング大分" },
    { label: "プリンシプル自動車" },
    { label: "北陸スタッフ" },
    { label: "ホシザキ東京" },
    { label: "豊靖社" },
    { label: "保高　幸子" },
    { label: "ホットスタッフ・プロモーション" },
    { label: "ホンダウォーク" },
    { label: "ホンマ装美" },
    { label: "ポイントラグ" },
    { label: "毎日広告社" },
    { label: "マエノ" },
    { label: "松本泉" },
    { label: "三菱地所プロパティマネジメント" },
    { label: "港区みなと保健所" },
    { label: "ミューカ" },
    { label: "みるぼーど" },
    { label: "三輪 昌稔" },
    { label: "ムサシノ広告社" },
    { label: "中村工社" },
    { label: "メガ・コーポレーション" },
    { label: "MEDIAEDGE" },
    { label: "メディアサプライ" },
    { label: "メディアサーフコミュニケーションズ" },
    { label: "メディアムーブメント" },
    { label: "メトロ アド エージェンシー" },
    { label: "望月龍平シアターカンパニー" },
    { label: "森ビル流通システム" },
    { label: "モールオブファイブ" },
    { label: "ヤマトホームコンビニエンス" },
    { label: "ヤマノクリエイツ" },
    { label: "ゆう" },
    { label: "ユニカ" },
    { label: "ユニリビング" },
    { label: "横浜国際平和会議場" },
    { label: "横浜市中区食品衛生協会" },
    { label: "横浜メディアアド" },
    { label: "吉田　健一" },
    { label: "ラフォーレ原宿" },
    { label: "ラ・ウィル" },
    { label: "LIXILビバ　スパービバホーム豊洲店" },
    { label: "リバーフィールド社" },
    { label: "流通アシストネットワーク" },
    { label: "ロカヒクリエーションズ" },
    { label: "和気星都" },
    { label: "和田（新見）佳子" },
    { label: "ワールド運輸" },
  ];

  //----------------------
  //モーダル用プロパティ
  //----------------------
  //----------------------
  //制御用メソッド
  //----------------------
  //----------------------
  //基本操作用メソッド
  //----------------------
  //----------------------
  //モーダル用メソッド
  //----------------------
}
