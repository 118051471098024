
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import Mo_simulationlist_row from "@/components/molecules/Mo_simulationlist_row.vue";

import { DtoSimulationsGetReqImple } from "furukawa-common/src/common/dto/DtoSimulationsGetReqImple";
import { DtoSimulationsGetResImple } from "furukawa-common/src/common/dto/DtoSimulationsGetResImple";
import { DtoMastersGetRes } from "furukawa-common/src/common/dto/DtoMastersGetRes";
import { Cl_common } from "@/functions/Cl_common";
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersgetReq } from "furukawa-common/src/common/dto/DtoMastersGetReq";
import { Cl_simulation } from "@/functions/Cl_simulation";
import {
  CONST_MIGRATION_SETTING_KAKUTEI_A,
  CONST_MIGRATION_SETTING_KAKUTEI_B,
  CONST_MIGRATION_SETTING_MIKOMI_C,
  CONST_MIGRATION_SETTING_SAKUJYO,
  CONST_MIGRATION_SETTING_SEIKYUUZUMI,
  CONST_MIGRATION_SETTING_SYUDOUIKOUZUMI,
  CONST_MIGRATION_SETTING_TEKKYO,
  CONST_OP_MIGRATION_SETTING,
} from "furukawa-common/src/common/constant/Constants";
import { EntitySimulation } from "furukawa-common/src/common/entity/EntitySimulation";
import { DtoMigrationsPostReq } from "furukawa-common/src/common/dto/abst/DtoMigrationsPostReq";
import { DtoMigrationsDeleteReq } from "furukawa-common/src/common/dto/abst/DtoMigrationsDeleteReq";
import { DtoMigrationsDeleteRes } from "furukawa-common/src/common/dto/abst/DtoMigrationsDeleteRes";
import { Cl_migration } from "@/functions/Cl_migration";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_modal_alert,
    Mo_simulationlist_row,
  },
})
export default class ProjectMigration extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoSimulationsGetResImple();
  delegate4list = new DtoMastersGetRes();

  //ボタン制御用
  btnDisable = false;

  //検索条件dom
  domMigrationSetting = [];
  domStaffs = [];
  domBranchs = [];

  //一括更新中かの判断
  isAll = false;

  /**
   * 削除行一次保管用
   */
  delData: EntitySimulation | null = null;

  //summary関係
  summary4count_A = "--";
  summary4count_B = "--";
  summary4count_C = "--";
  summary4count_Tekkyo = "--";
  summary4count_Seikyuzumi = "--";
  summary4count_Syudouikouzumi = "--";
  summary4count_sum = "--";
  summary4sales_A = "--";
  summary4sales_B = "--";
  summary4sales_C = "--";
  summary4sales_Tekkyo = "--";
  summary4sales_Seikyuzumi = "--";
  summary4sales_Syudouikouzumi = "--";
  summary4sales_sum = "--";
  summary4supplys_A = "--";
  summary4supplys_B = "--";
  summary4supplys_C = "--";
  summary4supplys_Tekkyo = "--";
  summary4supplys_Seikyuzumi = "--";
  summary4supplys_Syudouikouzumi = "--";
  summary4supplys_sum = "--";
  summary4profit_A = "--";
  summary4profit_B = "--";
  summary4profit_C = "--";
  summary4profit_Tekkyo = "--";
  summary4profit_Seikyuzumi = "--";
  summary4profit_Syudouikouzumi = "--";
  summary4profit_sum = "--";
  summary4profitratio_A = "--";
  summary4profitratio_B = "--";
  summary4profitratio_C = "--";
  summary4profitratio_Tekkyo = "--";
  summary4profitratio_Seikyuzumi = "--";
  summary4profitratio_Syudouikouzumi = "--";
  summary4profitratio_sum = "--";
  progberSize = 0;

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  op4MigrationSetting = CONST_OP_MIGRATION_SETTING;

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalErrorConf = "";
  message4ModalDelConf = "";
  message4ModalUpd4all = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    this.op4MigrationSetting = JSON.parse(
      JSON.stringify(this.op4MigrationSetting)
    );
    this.op4MigrationSetting.unshift({ label: "未入力", code: 0 });

    await this.getMastersList();
    await this.getlist();
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      //検索選択肢用
      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4Staff4sortNum = 1;
      //表示用(消すとフロントテーブルの営業担当が消える)
      dtoMaster.getStaff = 1;
      dtoMaster.delFlag4staff = 1;

      dtoMaster.getBranch = 1;
      dtoMaster.delFlag4Branch = 1;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);
      this.delegate4list.cnvBranchName();
      console.log("this.delegate4list");
      console.log(this.delegate4list);

      //レスポンス処理
      //-------
      //初回だけ自身を指定
      this.$store.commit("revStore");
      this.domStaffs = [this.$store.state.id as never];
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました"; //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * 一覧データを読み込む
   */
  async getlist() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoSimulationsGetReqImple();

      //検索条件設定
      dto.pager.count = 999999;
      dto.pager.cursor = 1;

      if (0 < this.domMigrationSetting.length) {
        let settingsTmp = JSON.parse(JSON.stringify(this.domMigrationSetting));
        //0は未入力の為、排除
        for (let index = 0; index < settingsTmp.length; index++) {
          if (settingsTmp[index] == 0) {
            settingsTmp.splice(index, 1);
            dto.setting4IsNotInput = 1;
            break;
          }
        }

        dto.migrationsettings = settingsTmp.join(",");
      }
      if (0 < this.domStaffs.length) {
        dto.staffs = this.domStaffs.join(",");
      }
      if (0 < this.domBranchs.length) {
        dto.branchs = this.domBranchs.join(",");
      }

      Cl_common.logger("dto");
      Cl_common.logger(dto);

      //API実行
      //-------
      this.delegate = await Cl_simulation.getList(dto);

      //レスポンス処理
      //-------
      this.calcProfit();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      //エラー発生で一覧を初期化
      this.delegate = new DtoSimulationsGetResImple();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * データを更新する
   */
  async upd(ev: EntitySimulation) {
    // Cl_common.logger(ev);
    //ボタン無効＋ローダー表示
    //-----------------

    const panel = document.getElementsByClassName("panel-container");
    if (!this.isAll) {
      Array.from(panel).map((element: Element) => {
        element.classList.add("enable-loader");
      });
    }
    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //バリデーション
      //-------
      if (ev.c_migration_setting == null) {
        this.message4ModalErrorConf = "種別を選択してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }

      if (ev.c_migration_price_sales == null) {
        this.message4ModalErrorConf = "売上合計額を入力してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }

      if (ev.c_migration_price_supplys == null) {
        this.message4ModalErrorConf = "仕入合計額を入力してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }

      //準備
      //-------
      let dto = new DtoMigrationsPostReq();

      dto.c_claim_id = ev.c_claim_id;
      dto.c_project_id = ev.c_project_id;
      dto.c_migration_setting = ev.c_migration_setting;
      dto.c_migration_comment = ev.c_migration_comment;
      dto.c_migration_project_name = ev.c_project_name;
      dto.c_migration_staff_id4sales = ev.c_staff_id4sales;

      dto.c_migration_price_sales = ev.c_migration_price_sales;
      dto.c_migration_price_supplys = ev.c_migration_price_supplys;

      Cl_common.logger("dto");
      Cl_common.logger(dto);

      //API実行
      //-------
      await Cl_migration.post(dto);
      //レスポンス処理
      //-------
      ev.c_migration_id = 999;

      if (!this.isAll) {
        //再読み込み
        await this.getlist();
      }
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
      //エラー発生で一覧を初期化
      this.delegate = new DtoSimulationsGetResImple();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      if (!this.isAll) {
        this.btnDisable = false;
        Array.from(panel).map((element: Element) => {
          element.classList.remove("enable-loader");
        });
      }
    }
  }

  /**
   * データ一括処理用のモーダルを表示
   */
  async upd4AllConf() {
    this.delData = null;
    //モーダルのメッセージを設定
    this.message4ModalUpd4all =
      "入力済みで確定していないデータを一括で確定させます";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_upd4all;
    modal.showModal();
  }

  /**
   * 更新処理　一括
   */
  async upd4all() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });
    this.closeModal4Upd4all();
    try {
      if (this.delegate.itemsImple.length == 0) {
        return;
      }

      this.isAll = true;
      let countUpd = 0;
      let simRowsTmp = this.$refs.simRows as Mo_simulationlist_row[];

      for (let index = 0; index < simRowsTmp.length; index++) {
        //バリデーション
        //-------
        if (
          (simRowsTmp[index] as Mo_simulationlist_row).item.c_migration_id !=
            null ||
          (simRowsTmp[index] as Mo_simulationlist_row).item
            .c_migration_setting == null ||
          (simRowsTmp[index] as Mo_simulationlist_row).domPrice_sales == "" ||
          (simRowsTmp[index] as Mo_simulationlist_row).domPrice_supplys == ""
        ) {
          Cl_common.logger("upd4all continue");
          continue;
        }

        // const modal: any = this.delegate.itemsImple[index];
        // modal.showModal();

        // this.upd(this.delegate.itemsImple[index], true);

        Cl_common.logger(
          (simRowsTmp[index] as Mo_simulationlist_row).item.c_project_name
        );
        Cl_common.logger(
          (simRowsTmp[index] as Mo_simulationlist_row).item.c_migration_setting
        );
        await (simRowsTmp[index] as Mo_simulationlist_row).upd();
        countUpd++;
        //再読み込み
        //1行処理につき、1秒待機
        await this.sleep(1);
      }

      if (countUpd != 0) {
        this.delegate = new DtoSimulationsGetResImple();
        await this.getlist();
      }
    } catch (error) {
      //---------------
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //一括処理フラグ解放
      this.isAll = false;
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * 指定秒待機
   * @param seconds
   * @param callback
   */
  sleep(seconds: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
  }
  /**
   * データ削除用のモーダルを表示
   */
  async delConf(ev: EntitySimulation) {
    this.delData = null;
    //モーダルのメッセージを設定
    this.message4ModalDelConf =
      // ev.c_migration_id +
      "【" +
      ev.c_claim_number +
      "】" +
      ev.c_project_name +
      "を未入力状態に戻します。";
    this.delData = ev;
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.showModal();
  }
  /**
   * データを削除する
   */
  async del() {
    this.closeModal4deleteConf();

    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");
      if (this.delData == null || this.delData.c_migration_id == null) {
        return;
      }

      //準備
      //-------
      let dto = new DtoMigrationsDeleteReq();
      dto.c_migration_id = this.delData.c_migration_id;

      //API実行
      //-------
      await Cl_migration.del(dto);
      //レスポンスの処理
      //-------

      //再読み込み
      this.delegate = new DtoSimulationsGetResImple();
      await this.getlist();
    } catch (error) {
      //---------------
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * 集計処理
   */
  async calcProfit() {
    if (this.delegate.itemsImple == null) {
      this.summary4count_A = "--";
      this.summary4count_B = "--";
      this.summary4count_C = "--";
      this.summary4count_Tekkyo = "--";
      this.summary4count_Seikyuzumi = "--";
      this.summary4count_Syudouikouzumi = "--";
      this.summary4count_sum = "--";
      this.summary4sales_A = "--";
      this.summary4sales_B = "--";
      this.summary4sales_C = "--";
      this.summary4sales_Tekkyo = "--";
      this.summary4sales_Seikyuzumi = "--";
      this.summary4sales_Syudouikouzumi = "--";
      this.summary4sales_sum = "--";
      this.summary4supplys_A = "--";
      this.summary4supplys_B = "--";
      this.summary4supplys_C = "--";
      this.summary4supplys_Tekkyo = "--";
      this.summary4supplys_Seikyuzumi = "--";
      this.summary4supplys_Syudouikouzumi = "--";
      this.summary4supplys_sum = "--";
      this.summary4profit_A = "--";
      this.summary4profit_B = "--";
      this.summary4profit_C = "--";
      this.summary4profit_Tekkyo = "--";
      this.summary4profit_Seikyuzumi = "--";
      this.summary4profit_Syudouikouzumi = "--";
      this.summary4profit_sum = "--";
      this.summary4profitratio_A = "--";
      this.summary4profitratio_B = "--";
      this.summary4profitratio_C = "--";
      this.summary4profitratio_Tekkyo = "--";
      this.summary4profitratio_Seikyuzumi = "--";
      this.summary4profitratio_Syudouikouzumi = "--";
      this.summary4profitratio_sum = "--";
      this.progberSize = 0;
    }

    // 集計
    //-------------------
    let count_sakujyo = 0;

    let count_A = 0;
    let count_B = 0;
    let count_C = 0;
    let count_Tekkyo = 0;
    let count_Seikyuzumi = 0;
    let count_Syudouikouzumi = 0;
    let count_sum = 0;
    let sales_A = 0;
    let sales_B = 0;
    let sales_C = 0;
    let sales_Tekkyo = 0;
    let sales_Seikyuzumi = 0;
    let sales_Syudouikouzumi = 0;
    let sales_sum = 0;
    let supplys_A = 0;
    let supplys_B = 0;
    let supplys_C = 0;
    let supplys_Tekkyo = 0;
    let supplys_Seikyuzumi = 0;
    let supplys_Syudouikouzumi = 0;
    let supplys_sum = 0;
    // let profit_A = 0;
    // let profit_B = 0;
    // let profit_C = 0;
    // let profit_Tekkyo = 0;
    // let profit_Seikyuzumi = 0;
    // let profit_Syudouikouzumi = 0;
    // let profit_sum = 0;
    let profitratio_A = 0.0;
    let profitratio_B = 0.0;
    let profitratio_C = 0.0;
    let profitratio_Tekkyo = 0.0;
    let profitratio_Seikyuzumi = 0.0;
    let profitratio_Syudouikouzumi = 0.0;
    let profitratio_sum = 0.0;

    for (let index = 0; index < this.delegate.itemsImple.length; index++) {
      //-------------------
      //確定A
      if (
        this.delegate.itemsImple[index].c_migration_id != null &&
        this.delegate.itemsImple[index].c_migration_setting ==
          CONST_MIGRATION_SETTING_KAKUTEI_A
      ) {
        //入力状況
        //-------
        count_A++;
        //売上
        //-------
        if (this.delegate.itemsImple[index].c_migration_price_sales != null) {
          sales_A += Number(
            this.delegate.itemsImple[index].c_migration_price_sales
          );
        }
        //仕入
        //-------
        if (this.delegate.itemsImple[index].c_migration_price_supplys != null) {
          supplys_A += Number(
            this.delegate.itemsImple[index].c_migration_price_supplys
          );
        }
      }
      //-------------------
      // 確定B
      if (
        this.delegate.itemsImple[index].c_migration_id != null &&
        this.delegate.itemsImple[index].c_migration_setting ==
          CONST_MIGRATION_SETTING_KAKUTEI_B
      ) {
        //入力状況
        //-------
        count_B++;
        //売上
        //-------
        if (this.delegate.itemsImple[index].c_migration_price_sales != null) {
          sales_B += Number(
            this.delegate.itemsImple[index].c_migration_price_sales
          );
        }
        //仕入
        //-------
        if (this.delegate.itemsImple[index].c_migration_price_supplys != null) {
          supplys_B += Number(
            this.delegate.itemsImple[index].c_migration_price_supplys
          );
        }
      }
      //-------------------
      // 見込C
      if (
        this.delegate.itemsImple[index].c_migration_id != null &&
        this.delegate.itemsImple[index].c_migration_setting ==
          CONST_MIGRATION_SETTING_MIKOMI_C
      ) {
        //入力状況
        //-------
        count_C++;
        //売上
        //-------
        if (this.delegate.itemsImple[index].c_migration_price_sales != null) {
          sales_C += Number(
            this.delegate.itemsImple[index].c_migration_price_sales
          );
        }
        //仕入
        //-------
        if (this.delegate.itemsImple[index].c_migration_price_supplys != null) {
          supplys_C += Number(
            this.delegate.itemsImple[index].c_migration_price_supplys
          );
        }
      }
      //-------------------
      // 撤去
      if (
        this.delegate.itemsImple[index].c_migration_id != null &&
        this.delegate.itemsImple[index].c_migration_setting ==
          CONST_MIGRATION_SETTING_TEKKYO
      ) {
        //入力状況
        //-------
        count_Tekkyo++;
        //売上
        //-------
        if (this.delegate.itemsImple[index].c_migration_price_sales != null) {
          sales_Tekkyo += Number(
            this.delegate.itemsImple[index].c_migration_price_sales
          );
        }
        //仕入
        //-------
        if (this.delegate.itemsImple[index].c_migration_price_supplys != null) {
          supplys_Tekkyo += Number(
            this.delegate.itemsImple[index].c_migration_price_supplys
          );
        }
      }
      //-------------------
      //削除
      if (
        this.delegate.itemsImple[index].c_migration_id != null &&
        this.delegate.itemsImple[index].c_migration_setting ==
          CONST_MIGRATION_SETTING_SAKUJYO
      ) {
        //入力状況
        //-------
        count_sakujyo++;
        //売上
        //-------
        // if (this.delegate.itemsImple[index].c_migration_price_sales != null) {
        //   sales_A += Number(
        //     this.delegate.itemsImple[index].c_migration_price_sales
        //   );
        // }
        //仕入
        //-------
        // if (this.delegate.itemsImple[index].c_migration_price_supplys != null) {
        //   supplys_A += Number(
        //     this.delegate.itemsImple[index].c_migration_price_supplys
        //   );
        // }
      }
      //-------------------
      // 請求済み
      if (
        this.delegate.itemsImple[index].c_migration_id != null &&
        this.delegate.itemsImple[index].c_migration_setting ==
          CONST_MIGRATION_SETTING_SEIKYUUZUMI
      ) {
        //入力状況
        //-------
        count_Seikyuzumi++;
        //売上
        //-------
        if (this.delegate.itemsImple[index].c_migration_price_sales != null) {
          sales_Seikyuzumi += Number(
            this.delegate.itemsImple[index].c_migration_price_sales
          );
        }
        //仕入
        //-------
        if (this.delegate.itemsImple[index].c_migration_price_supplys != null) {
          supplys_Seikyuzumi += Number(
            this.delegate.itemsImple[index].c_migration_price_supplys
          );
        }
      }
      //-------------------
      //　手動移行済み
      if (
        this.delegate.itemsImple[index].c_migration_id != null &&
        this.delegate.itemsImple[index].c_migration_setting ==
          CONST_MIGRATION_SETTING_SYUDOUIKOUZUMI
      ) {
        //入力状況
        //-------
        count_Syudouikouzumi++;
        //売上
        //-------
        if (this.delegate.itemsImple[index].c_migration_price_sales != null) {
          sales_Syudouikouzumi += Number(
            this.delegate.itemsImple[index].c_migration_price_sales
          );
        }
        //仕入
        //-------
        if (this.delegate.itemsImple[index].c_migration_price_supplys != null) {
          supplys_Syudouikouzumi += Number(
            this.delegate.itemsImple[index].c_migration_price_supplys
          );
        }
      }
      //-------------------
    }

    //入力
    //-------------------
    //入力状況
    //-------
    this.summary4count_A = count_A.toString();
    this.summary4count_B = count_B.toString();
    this.summary4count_C = count_C.toString();
    this.summary4count_Tekkyo = count_Tekkyo.toString();
    this.summary4count_Seikyuzumi = count_Seikyuzumi.toString();
    this.summary4count_Syudouikouzumi = count_Syudouikouzumi.toString();
    count_sum =
      count_A +
      count_B +
      count_C +
      count_Tekkyo +
      count_Seikyuzumi +
      count_Syudouikouzumi +
      count_sakujyo;
    this.summary4count_sum =
      count_sum.toString() +
      "(内削除" +
      count_sakujyo.toString() +
      ")/" +
      this.delegate.itemsImple.length;

    let progberSizeTmp = Number(
      ((count_sum / this.delegate.itemsImple.length) * 100).toFixed(0)
    );

    if (!isNaN(progberSizeTmp) && progberSizeTmp != -Infinity) {
      this.progberSize = progberSizeTmp;
    } else {
      this.progberSize = 0;
    }

    //売上
    //-------
    this.summary4sales_A = "￥" + sales_A.toLocaleString("ja-JP") + " -";
    this.summary4sales_B = "￥" + sales_B.toLocaleString("ja-JP") + " -";
    this.summary4sales_C = "￥" + sales_C.toLocaleString("ja-JP") + " -";
    this.summary4sales_Tekkyo =
      "￥" + sales_Tekkyo.toLocaleString("ja-JP") + " -";
    this.summary4sales_Seikyuzumi =
      "￥" + sales_Seikyuzumi.toLocaleString("ja-JP") + " -";
    this.summary4sales_Syudouikouzumi =
      "￥" + sales_Syudouikouzumi.toLocaleString("ja-JP") + " -";
    sales_sum =
      sales_A +
      sales_B +
      sales_C +
      sales_Tekkyo +
      sales_Seikyuzumi +
      sales_Syudouikouzumi;
    this.summary4sales_sum = "￥" + sales_sum.toLocaleString("ja-JP") + " -";

    //仕入
    //-------
    this.summary4supplys_A = "￥" + supplys_A.toLocaleString("ja-JP") + " -";
    this.summary4supplys_B = "￥" + supplys_B.toLocaleString("ja-JP") + " -";
    this.summary4supplys_C = "￥" + supplys_C.toLocaleString("ja-JP") + " -";
    this.summary4supplys_Tekkyo =
      "￥" + supplys_Tekkyo.toLocaleString("ja-JP") + " -";
    this.summary4supplys_Seikyuzumi =
      "￥" + supplys_Seikyuzumi.toLocaleString("ja-JP") + " -";
    this.summary4supplys_Syudouikouzumi =
      "￥" + supplys_Syudouikouzumi.toLocaleString("ja-JP") + " -";
    supplys_sum =
      supplys_A +
      supplys_B +
      supplys_C +
      supplys_Tekkyo +
      supplys_Seikyuzumi +
      supplys_Syudouikouzumi;
    this.summary4supplys_sum =
      "￥" + supplys_sum.toLocaleString("ja-JP") + " -";

    // 利益
    //-------
    this.summary4profit_A =
      "￥" + (sales_A - supplys_A).toLocaleString("ja-JP") + " -";
    this.summary4profit_B =
      "￥" + (sales_B - supplys_B).toLocaleString("ja-JP") + " -";
    this.summary4profit_C =
      "￥" + (sales_C - supplys_C).toLocaleString("ja-JP") + " -";
    this.summary4profit_Tekkyo =
      "￥" + (sales_Tekkyo - supplys_Tekkyo).toLocaleString("ja-JP") + " -";
    this.summary4profit_Seikyuzumi =
      "￥" +
      (sales_Seikyuzumi - supplys_Seikyuzumi).toLocaleString("ja-JP") +
      " -";
    this.summary4profit_Syudouikouzumi =
      "￥" +
      (sales_Syudouikouzumi - supplys_Syudouikouzumi).toLocaleString("ja-JP") +
      " -";
    this.summary4profit_sum =
      "￥" + (sales_sum - supplys_sum).toLocaleString("ja-JP") + " -";

    //利益率
    //-------
    profitratio_A = Number(
      (((sales_A - supplys_A) / sales_A) * 100).toFixed(1)
    );
    profitratio_B = Number(
      (((sales_B - supplys_B) / sales_B) * 100).toFixed(1)
    );
    profitratio_C = Number(
      (((sales_C - supplys_C) / sales_C) * 100).toFixed(1)
    );
    profitratio_Tekkyo = Number(
      (((sales_Tekkyo - supplys_Tekkyo) / sales_Tekkyo) * 100).toFixed(1)
    );
    profitratio_Seikyuzumi = Number(
      (
        ((sales_Seikyuzumi - supplys_Seikyuzumi) / sales_Seikyuzumi) *
        100
      ).toFixed(1)
    );
    profitratio_Syudouikouzumi = Number(
      (
        ((sales_Syudouikouzumi - supplys_Syudouikouzumi) /
          sales_Syudouikouzumi) *
        100
      ).toFixed(1)
    );
    profitratio_sum = Number(
      (((sales_sum - supplys_sum) / sales_sum) * 100).toFixed(1)
    );
    //-------
    if (!isNaN(profitratio_A) && profitratio_A != -Infinity) {
      this.summary4profitratio_A = profitratio_A.toString() + "%";
    } else {
      this.summary4profitratio_A = "--";
    }
    if (!isNaN(profitratio_B) && profitratio_B != -Infinity) {
      this.summary4profitratio_B = profitratio_B.toString() + "%";
    } else {
      this.summary4profitratio_B = "--";
    }
    if (!isNaN(profitratio_C) && profitratio_C != -Infinity) {
      this.summary4profitratio_C = profitratio_C.toString() + "%";
    } else {
      this.summary4profitratio_C = "--";
    }
    if (!isNaN(profitratio_Tekkyo) && profitratio_Tekkyo != -Infinity) {
      this.summary4profitratio_Tekkyo = profitratio_Tekkyo.toString() + "%";
    } else {
      this.summary4profitratio_Tekkyo = "--";
    }
    if (!isNaN(profitratio_Seikyuzumi) && profitratio_Seikyuzumi != -Infinity) {
      this.summary4profitratio_Seikyuzumi =
        profitratio_Seikyuzumi.toString() + "%";
    } else {
      this.summary4profitratio_Seikyuzumi = "--";
    }
    if (
      !isNaN(profitratio_Syudouikouzumi) &&
      profitratio_Syudouikouzumi != -Infinity
    ) {
      this.summary4profitratio_Syudouikouzumi =
        profitratio_Syudouikouzumi.toString() + "%";
    } else {
      this.summary4profitratio_Syudouikouzumi = "--";
    }
    if (!isNaN(profitratio_sum) && profitratio_sum != -Infinity) {
      this.summary4profitratio_sum = profitratio_sum.toString() + "%";
    } else {
      this.summary4profitratio_sum = "--";
    }
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
  async closeModal4deleteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.closeModal();
  }
  async closeModal4Upd4all(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_upd4all;
    modal.closeModal();
  }
}
