export class EntityFile {
public c_file_id:number | null = null;
public c_project_id:number | null = null;
public c_file_order:number | null = null;
public c_file_name:string | null = null;
public c_file_path:string | null = null;
public c_file_reg_user:string | null = null;
public c_file_reg_at:Date | null = null;
public c_file_upd_user:string | null = null;
public c_file_upd_at:Date | null = null;
}