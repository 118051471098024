
import { Prop, Options, Vue } from "vue-property-decorator";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { EntityClaimDet } from "furukawa-common/src/common/entity/EntityClaimDet";

import Mo_dailyReport_row from "../molecules/Mo_dailyReport_row.vue";
import { EntityReportDet } from "furukawa-common/src/common/entity/EntityReportDet";

@Options({
  components: {
    vSelect,
    Mo_dailyReport_row,
  },
})
export default class Or_tableDailyReport extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  @Prop({ required: true })
  items: EntityReportDet[];

  sum = "0";
  sumTax = "0";

  btnDisable = false;

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  //----------------------
  //モーダル用プロパティ
  //----------------------
  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    //
  }

  /**
   * buttonの有効無効を制御
   */
  eventAdjButton(btnDisableTmp: boolean) {
    this.btnDisable = btnDisableTmp;
  }
  //----------------------
  //基本操作用メソッド
  //----------------------

  delRow() {
    this.items.pop();
  }
  addRow() {
    // if (15 <= this.items.length) {
    //   return;
    // }

    let tmp = new EntityReportDet();

    tmp.c_report_det_id = -1;
    tmp.c_report_det_rownum = this.items.length + 1;
    // tmp.c_claim_det_taxrate = 3;

    this.items.push(tmp);
  }

  getSum(): string[] {
    let res: string[] = [];
    res.push(this.sum);
    res.push(this.sumTax);
    return res;
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
}
