import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoRequestOvertimesGetReqImple } from "furukawa-common/src/common/dto/DtoRequestOvertimesGetReqImple";
import { DtoRequestOvertimesGetResImple } from "furukawa-common/src/common/dto/DtoRequestOvertimesGetResImple";
import { DtoRequestOvertimesPostReq } from "furukawa-common/src/common/dto/abst/DtoRequestOvertimesPostReq";
import { DtoRequestOvertimesPostRes } from "furukawa-common/src/common/dto/abst/DtoRequestOvertimesPostRes";
import { DtoRequestOvertimesPutReq } from "furukawa-common/src/common/dto/abst/DtoRequestOvertimesPutReq";
import { DtoRequestOvertimesPutRes } from "furukawa-common/src/common/dto/abst/DtoRequestOvertimesPutRes";
import { DtoRequestOvertimesDeleteReq } from "furukawa-common/src/common/dto/abst/DtoRequestOvertimesDeleteReq";
import { DtoRequestOvertimesDeleteRes } from "furukawa-common/src/common/dto/abst/DtoRequestOvertimesDeleteRes";

export class Cl_requestovertime {
  public static async getList(
    dto: DtoRequestOvertimesGetReqImple
  ): Promise<DtoRequestOvertimesGetResImple> {
    return Object.assign(
      new DtoRequestOvertimesGetResImple(),
      await CallAPI.getRequest(
        "/requestOvertimes?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoRequestOvertimesGetReqImple
  ): Promise<DtoRequestOvertimesGetResImple> {
    return Object.assign(
      new DtoRequestOvertimesGetResImple(),
      await CallAPI.getRequest("/requestOvertimes/" + dto.c_request_overtime_id)
    );
  }

  public static async post(
    dto: DtoRequestOvertimesPostReq
  ): Promise<DtoRequestOvertimesPostRes> {
    return Object.assign(
      new DtoRequestOvertimesPostRes(),
      await CallAPI.postRequest("/requestOvertimes", dto)
    );
  }

  public static async put(
    dto: DtoRequestOvertimesPutReq
  ): Promise<DtoRequestOvertimesPutRes> {
    return Object.assign(
      new DtoRequestOvertimesPutRes(),
      await CallAPI.putRequest(
        "/requestOvertimes/" + dto.c_request_overtime_id,
        dto
      )
    );
  }

  public static async del(
    dto: DtoRequestOvertimesDeleteReq
  ): Promise<DtoRequestOvertimesDeleteRes> {
    return Object.assign(
      new DtoRequestOvertimesDeleteRes(),
      await CallAPI.deleteRequest(
        "/requestOvertimes?" + Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
