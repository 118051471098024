
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import { DtoSponsorsGetResImple } from "furukawa-common/src/common/dto/DtoSponsorsGetResImple";
import {
  CONST_OP_SPONSOR_GENRE,
  CONST_OP_SPONSOR_TYPE,
  CONST_REPLACEMENT_MAP,
} from "furukawa-common/src/common/constant/Constants";
import { Cl_common } from "@/functions/Cl_common";
import { Cl_sponsor } from "@/functions/Cl_sponsor";
import { DtoSponsorsPutReq } from "furukawa-common/src/common/dto/abst/DtoSponsorsPutReq";
import { DtoSponsorsGetReqImple } from "furukawa-common/src/common/dto/DtoSponsorsGetReqImple";
import { DtoMastersGetRes } from "furukawa-common/src/common/dto/DtoMastersGetRes";
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersgetReq } from "furukawa-common/src/common/dto/DtoMastersGetReq";

// バリデーション
import * as Yup from "yup";

@Options({
  components: {
    vSelect,
    Mo_modal_alert,
  },
})
export default class SponsorEdit extends Vue {
  //----------------------
  //バリデーション
  //----------------------
  //スキーマ宣言
  schema = Yup.object().shape({
    // sponsorType: Yup.string().required("種別を選択してください"),
    sponsorGenre: Yup.string().required("業種を選択してください"),
    sponsorName: Yup.string().required("広告主名を入力してください"),
  });

  //エラー格納用
  errors: Yup.ValidationError | null = null;

  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoSponsorsGetResImple();
  delegate4list = new DtoMastersGetRes();
  btnDisable = false;
  isEditMode = true;

  domRemarks = "";
  domcomment = "";

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  opSponsorGenre = CONST_OP_SPONSOR_GENRE;
  opSponsorType = CONST_OP_SPONSOR_TYPE;

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalDelConf = "";
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.get();
  }
  //----------------------
  //基本操作用メソッド
  //----------------------

  /**
   * データを読み込む
   */
  async get() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //パラメータにIDが指定されていなければ、新規追加モードでデータを読み込まない
      //-------
      let id = Cl_common.getParam("id");
      if (id == "") {
        //新規追加
        this.isEditMode = false;
        //初期値設定
        this.delegate.c_sponsor_name = "名称未設定広告主";
        return;
      } else {
        //編集
        this.isEditMode = true;
      }

      //準備
      //-------
      let dto = new DtoSponsorsGetReqImple();

      dto.c_sponsor_id = Number(id);

      //API実行
      //-------
      this.delegate = await Cl_sponsor.get(dto);

      //レスポンス処理
      //-------

      //IDがnullなら一覧へ遷移
      if (this.delegate.c_sponsor_id == null) {
        this.$router.push("/sponsorList");
      }
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * データを更新する
   */
  async upd(isDel: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoSponsorsPutReq();
      if (isDel) {
        //削除処理：削除フラグを立てる。
        dto.c_sponsor_id = this.delegate.c_sponsor_id;
        dto.c_sponsor_flag_del = 1;
      } else {
        //バリデーション
        //-----------------
        this.errors = null;
        this.schema.validateSync({
          sponsorName: this.delegate.c_sponsor_name,
          // sponsorType: this.delegate.c_sponsor_type,
          sponsorGenre: this.delegate.c_sponsor_genre,
        });

        //更新処理：delegateからdtoにデータ投入。
        dto = Object.assign(new DtoSponsorsPutReq(), this.delegate);

        //広告主名に変換すべき全角文字があれば半角に直す
        if (this.delegate.c_sponsor_name != null) {
          dto.c_sponsor_name = this.replaceCharacters(
            this.delegate.c_sponsor_name
          );
        }
      }

      //API実行
      //-------
      await Cl_sponsor.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      if (isDel) {
        //削除処理：
        this.message4ModalSuccess = "正常に削除されました";
      } else {
        //更新処理：
        this.message4ModalSuccess = "正常に変更されました";
      }

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * データを新たに登録する
   */
  async add() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //バリデーション
      //-----------------
      this.errors = null;
      this.schema.validateSync({
        sponsorName: this.delegate.c_sponsor_name,
        // sponsorType: this.delegate.c_sponsor_type,
        sponsorGenre: this.delegate.c_sponsor_genre,
      });

      //準備
      //-------
      //仕入先名に変換すべき全角文字があれば半角に直す
      if (this.delegate.c_sponsor_name != null) {
        this.delegate.c_sponsor_name = this.replaceCharacters(
          this.delegate.c_sponsor_name
        );
      }

      let dto = Object.assign(new DtoSponsorsPutReq(), this.delegate);
      dto.c_sponsor_flag_del = 0;

      //API実行
      //-------
      const res = await Cl_sponsor.post(dto);

      //レスポンスの処理
      //-------
      //編集画面のURL作成
      this.$router.push({
        path: "/sponsorEdit",
        query: { id: res.c_sponsor_id },
      });

      //モーダルのメッセージを設定
      this.message4ModalSuccess = "正常に追加されました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * データ削除用のモーダルを表示
   */
  async delConf() {
    //モーダルのメッセージを設定
    this.message4ModalDelConf =
      "【" +
      this.delegate.c_sponsor_id +
      "】" +
      this.delegate.c_sponsor_name +
      "を削除します。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.showModal();
  }
  /**
   * データを削除する
   */
  async del() {
    //削除フラグのあるテーブルなので更新処理。
    this.closeModal4deleteConf();
    this.upd(true);
  }

  /**
   * チェックボックスの値を数値に変換する
   * @param event
   */
  cnvCb(event: any): number {
    if (event.target.checked) {
      return 1;
    } else {
      return 0;
    }
  }

  /**
   * 全角アルファベット、スペース、@、&、を半角に置き換える
   * @param input
   */
  replaceCharacters(input: string): string {
    return input
      .split("")
      .map((char) => CONST_REPLACEMENT_MAP[char] || char)
      .join("");
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4deleteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.closeModal();
  }
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
