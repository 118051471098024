import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoMigrationsGetReqImple } from "furukawa-common/src/common/dto/DtoMigrationsGetReqImple";
import { DtoMigrationsGetResImple } from "furukawa-common/src/common/dto/DtoMigrationsGetResImple";
import { DtoMigrationsPostReq } from "furukawa-common/src/common/dto/abst/DtoMigrationsPostReq";
import { DtoMigrationsPostRes } from "furukawa-common/src/common/dto/abst/DtoMigrationsPostRes";
import { DtoMigrationsPutReq } from "furukawa-common/src/common/dto/abst/DtoMigrationsPutReq";
import { DtoMigrationsPutRes } from "furukawa-common/src/common/dto/abst/DtoMigrationsPutRes";
import { DtoMigrationsDeleteReq } from "furukawa-common/src/common/dto/abst/DtoMigrationsDeleteReq";
import { DtoMigrationsDeleteRes } from "furukawa-common/src/common/dto/abst/DtoMigrationsDeleteRes";

export class Cl_migration {
  public static async getList(
    dto: DtoMigrationsGetReqImple
  ): Promise<DtoMigrationsGetResImple> {
    return Object.assign(
      new DtoMigrationsGetResImple(),
      await CallAPI.getRequest(
        "/migrations?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoMigrationsGetReqImple
  ): Promise<DtoMigrationsGetResImple> {
    return Object.assign(
      new DtoMigrationsGetResImple(),
      await CallAPI.getRequest("/migrations/" + dto.c_migration_id)
    );
  }

  public static async post(
    dto: DtoMigrationsPostReq
  ): Promise<DtoMigrationsPostRes> {
    return Object.assign(
      new DtoMigrationsPostRes(),
      await CallAPI.postRequest("/migrations", dto)
    );
  }

  public static async put(
    dto: DtoMigrationsPutReq
  ): Promise<DtoMigrationsPutRes> {
    return Object.assign(
      new DtoMigrationsPutRes(),
      await CallAPI.putRequest("/migrations/" + dto.c_migration_id, dto)
    );
  }

  public static async del(
    dto: DtoMigrationsDeleteReq
  ): Promise<DtoMigrationsDeleteRes> {
    return Object.assign(
      new DtoMigrationsDeleteRes(),
      await CallAPI.deleteRequest("/migrations/" + dto.c_migration_id)
    );
  }
}
