import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8bfa9b1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "koumoku keihiCont cwK_1" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { class: "suuryou keihiCont cwK_2 selectBorderNone disableWhite" }
const _hoisted_4 = { class: "tanka keihiCont cwK_3" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "kingaku keihiCont cwK_4" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "tanka keihiCont cwK_5" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = { class: "kingaku keihiCont cwK_6" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = { class: "invoice keihiCont cwK_7 selectBorderNone disableWhite" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vSelect = _resolveComponent("vSelect")!

  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        type: "date",
        class: "form-control form-control-sm",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.item.cnvExpensesDate) = $event)),
        disabled: _ctx.isDisable
      }, null, 8, _hoisted_2), [
        [_vModelText, _ctx.item.cnvExpensesDate]
      ])
    ]),
    _createElementVNode("td", _hoisted_3, [
      _createVNode(_component_vSelect, {
        options: _ctx.opType,
        modelValue: _ctx.item.c_expenses_type,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.item.c_expenses_type) = $event)),
        class: "ks_select",
        reduce: (label) => label.code,
        disabled: _ctx.isDisable
      }, null, 8, ["options", "modelValue", "reduce", "disabled"])
    ]),
    _createElementVNode("td", _hoisted_4, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control form-control-sm",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.item.c_expenses_payment_destination) = $event)),
        disabled: _ctx.isDisable
      }, null, 8, _hoisted_5), [
        [_vModelText, _ctx.item.c_expenses_payment_destination]
      ])
    ]),
    _createElementVNode("td", _hoisted_6, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control form-control-sm",
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.item.c_expenses_purpose) = $event)),
        disabled: _ctx.isDisable
      }, null, 8, _hoisted_7), [
        [_vModelText, _ctx.item.c_expenses_purpose]
      ])
    ]),
    _createElementVNode("td", _hoisted_8, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control form-control-sm",
        style: {"text-align":"right"},
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.item.c_expenses_count) = $event)),
        disabled: _ctx.isDisable
      }, null, 8, _hoisted_9), [
        [_vModelText, _ctx.item.c_expenses_count]
      ])
    ]),
    _createElementVNode("td", _hoisted_10, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control form-control-sm",
        onFocus: _cache[5] || (_cache[5] = ($event: any) => (_ctx.kanmaDel($event))),
        onBlur: _cache[6] || (_cache[6] = ($event: any) => (_ctx.kanmaIns($event))),
        style: {"text-align":"right"},
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.item.cnvPrice) = $event)),
        disabled: _ctx.isDisable
      }, null, 40, _hoisted_11), [
        [_vModelText, _ctx.item.cnvPrice]
      ])
    ]),
    _createElementVNode("td", _hoisted_12, [
      _createVNode(_component_vSelect, {
        selectable: _ctx.kanmaIns4select(),
        options: _ctx.opInvoice,
        modelValue: _ctx.item.c_expenses_invoice,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.item.c_expenses_invoice) = $event)),
        reduce: (label) => label.code,
        disabled: _ctx.isDisable
      }, null, 8, ["selectable", "options", "modelValue", "reduce", "disabled"])
    ])
  ]))
}