import { EntityAttendance } from "../entity/EntityAttendance";
import { EntityRequestBiztrip } from "../entity/EntityRequestBiztrip";
import { EntityRequestHoliday } from "../entity/EntityRequestHoliday";
import { EntityRequestOvertime } from "../entity/EntityRequestOvertime";
import { EntityHoliday } from "../entity/EntityHoliday";
import { DtoApiPager } from "./abst/DtoApiPager";
import { DtoAttendancesGetRes } from "./abst/DtoAttendancesGetRes";

export class DtoAttendancesGetResImple extends DtoAttendancesGetRes {
  public pager = new DtoApiPager();
  public items4Attendance: attendanceItem[] = [];
}

export class attendanceItem extends EntityAttendance {
  //将来的に必要なデータを追加しやすいように別クラスを定義
  public cnvRemarks = "";

  /**
   * 当日の所属する休暇申請
   */
  public item4requestHoliday: EntityRequestHoliday[] = [];
  /**
   * 当日の所属する時間外申請
   */
  public item4requestOvertime: EntityRequestOvertime[] = [];
  /**
   * 当日の所属する出張申請
   */
  public item4requestBiztrip: EntityRequestBiztrip[] = [];
  /**
     * 当日の所属する指定休日
     * ※指定休日は同じ日付の、重複登録不可なので配列にしない。
     */
  public item4holiday: EntityHoliday | null = null;

  // /**
  //  * データからDOM連携用の変数にデータを入力する
  //  */
  // public async cnvData2dom() {
  //   //備考
  //   //-------------
  //   if (this.c_attendance_remarks != null) {
  //     this.cnvRemarks = this.c_attendance_remarks;
  //   }
  // }
  // /**
  //  * DOM連携用の変数からデータ用の変数に入力する
  //  */
  // public async cnvDom2data() {
  //   //備考
  //   //-------------
  //   this.c_attendance_remarks = this.cnvRemarks;
  // }
}
