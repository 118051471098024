import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoAuthenticationsGetReq } from "furukawa-common/src/common/dto/DtoAuthenticationsGetReq";
import { DtoAuthenticationsGetRes } from "furukawa-common/src/common/dto/DtoAuthenticationsGetRes";

export class Cl_authentication {
  public static async get(
    dto: DtoAuthenticationsGetReq
  ): Promise<DtoAuthenticationsGetRes> {
    return Object.assign(
      new DtoAuthenticationsGetRes(),
      await CallAPI.getRequest(
        "/authentications?" + Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
