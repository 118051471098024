import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoReportsGetReqImple } from "furukawa-common/src/common/dto/DtoReportsGetReqImple";
import { DtoReportsGetResImple } from "furukawa-common/src/common/dto/DtoReportsGetResImple";
import { DtoReportsPostReq } from "furukawa-common/src/common/dto/abst/DtoReportsPostReq";
import { DtoReportsPostRes } from "furukawa-common/src/common/dto/abst/DtoReportsPostRes";
import { DtoReportsPutReq } from "furukawa-common/src/common/dto/abst/DtoReportsPutReq";
import { DtoReportsPutRes } from "furukawa-common/src/common/dto/abst/DtoReportsPutRes";
import { DtoReportsDeleteReq } from "furukawa-common/src/common/dto/abst/DtoReportsDeleteReq";
import { DtoReportsDeleteRes } from "furukawa-common/src/common/dto/abst/DtoReportsDeleteRes";

export class Cl_report {
  public static async getList(
    dto: DtoReportsGetReqImple
  ): Promise<DtoReportsGetResImple> {
    return Object.assign(
      new DtoReportsGetResImple(),
      await CallAPI.getRequest(
        "/reports?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoReportsGetReqImple
  ): Promise<DtoReportsGetResImple> {
    return Object.assign(
      new DtoReportsGetResImple(),
      await CallAPI.getRequest("/reports/" + dto.c_report_id)
    );
  }

  public static async post(dto: DtoReportsPostReq): Promise<DtoReportsPostRes> {
    return Object.assign(
      new DtoReportsPostRes(),
      await CallAPI.postRequest("/reports", dto)
    );
  }

  public static async put(dto: DtoReportsPutReq): Promise<DtoReportsPutRes> {
    return Object.assign(
      new DtoReportsPutRes(),
      await CallAPI.putRequest("/reports/" + dto.c_report_id, dto)
    );
  }

  public static async del(
    dto: DtoReportsDeleteReq
  ): Promise<DtoReportsDeleteRes> {
    return Object.assign(
      new DtoReportsDeleteRes(),
      await CallAPI.deleteRequest("/reports?" + Cl_common.cnvObj2GetParam(dto))
    );
  }
}
