import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f889c9e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "colBtnSet simCont sim_1" }
const _hoisted_2 = { class: "colType simCont sim_2" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "colComment simCont sim_3" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "colSalesMonth simCont sim_4" }
const _hoisted_8 = { class: "colInvoiceNo simCont sim_5" }
const _hoisted_9 = { class: "colProjectNum simCont sim_6" }
const _hoisted_10 = { class: "colProjectName simCont sim_7" }
const _hoisted_11 = { class: "colSponsorName simCont sim_8" }
const _hoisted_12 = { class: "colClient simCont sim_9" }
const _hoisted_13 = { class: "colSupplier simCont sim_10" }
const _hoisted_14 = { class: "colStaff simCont sim_11 selectBorderNone" }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { class: "colSalesPrice simCont sim_12" }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "colSupplyPrice simCont sim_13" }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { class: "colBtnGoProject simCont sim_14" }
const _hoisted_21 = { class: "colBtnGoInvoice simCont sim_15" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vSelect = _resolveComponent("vSelect")!

  return (_openBlock(), _createElementBlock("tr", {
    class: _normalizeClass(_ctx.classSetter)
  }, [
    _createElementVNode("td", _hoisted_1, [
      (_ctx.item.c_migration_id == null)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: "btn btn-xs btn-primary waves-effect waves-themed",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.upd()))
          }, " 確定 "))
        : (_openBlock(), _createElementBlock("button", {
            key: 1,
            type: "button",
            class: "btn btn-xs btn-danger waves-effect waves-themed",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.del()))
          }, " 取消 "))
    ]),
    _createElementVNode("td", _hoisted_2, [
      (_ctx.item.c_migration_project_name == null)
        ? _withDirectives((_openBlock(), _createElementBlock("select", {
            key: 0,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.item.c_migration_setting) = $event))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.opSetting, (option) => {
              return (_openBlock(), _createElementBlock("option", {
                value: option.code,
                key: option.code!
              }, _toDisplayString(option.label), 9, _hoisted_3))
            }), 128))
          ], 512)), [
            [_vModelSelect, _ctx.item.c_migration_setting]
          ])
        : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.cnvSetting2Name()), 1))
    ]),
    _createElementVNode("td", _hoisted_5, [
      _withDirectives(_createElementVNode("textarea", {
        name: "",
        id: "",
        disabled: _ctx.item.c_migration_id != null,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.domComment) = $event))
      }, null, 8, _hoisted_6), [
        [_vModelText, _ctx.domComment]
      ])
    ]),
    _createElementVNode("td", _hoisted_7, _toDisplayString(_ctx.formatDateToYYYYMM(_ctx.item.c_sales_month)), 1),
    _createElementVNode("td", _hoisted_8, _toDisplayString(_ctx.item.c_claim_number), 1),
    _createElementVNode("td", _hoisted_9, _toDisplayString(_ctx.item.c_project_number), 1),
    _createElementVNode("td", _hoisted_10, [
      (_ctx.item.c_migration_project_name == null)
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 0,
            type: "text",
            class: "",
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.item.c_project_name) = $event))
          }, null, 512)), [
            [_vModelText, _ctx.item.c_project_name]
          ])
        : _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 1,
            type: "text",
            class: "",
            disabled: "",
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.item.c_migration_project_name) = $event))
          }, null, 512)), [
            [_vModelText, _ctx.item.c_migration_project_name]
          ])
    ]),
    _createElementVNode("td", _hoisted_11, _toDisplayString(_ctx.item.c_sponsor_name), 1),
    _createElementVNode("td", _hoisted_12, _toDisplayString(_ctx.item.c_client_name), 1),
    _createElementVNode("td", _hoisted_13, _toDisplayString(_ctx.item.c_supplier_name), 1),
    _createElementVNode("td", _hoisted_14, [
      (_ctx.item.c_migration_id == null)
        ? (_openBlock(), _createBlock(_component_vSelect, {
            key: 0,
            options: _ctx.mastarslist.items4staffs,
            modelValue: _ctx.item.c_staff_id4sales,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.item.c_staff_id4sales) = $event)),
            reduce: (label) => label.c_staff_id,
            label: "c_staff_name"
          }, null, 8, ["options", "modelValue", "reduce"]))
        : (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.cnvStaffId2Name()), 1))
    ]),
    _createElementVNode("td", _hoisted_16, [
      (_ctx.item.c_migration_id == null)
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 0,
            type: "text",
            class: "",
            onFocus: _cache[7] || (_cache[7] = ($event: any) => (_ctx.kanmaDel($event))),
            onBlur: _cache[8] || (_cache[8] = ($event: any) => (_ctx.kanmaIns($event))),
            style: {"text-align":"right"},
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.domPrice_sales) = $event))
          }, null, 544)), [
            [_vModelText, _ctx.domPrice_sales]
          ])
        : (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.kanmaIns4static(_ctx.item.c_migration_price_sales!.toString())), 1))
    ]),
    _createElementVNode("td", _hoisted_18, [
      (_ctx.item.c_migration_id == null)
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 0,
            type: "text",
            class: "",
            onFocus: _cache[10] || (_cache[10] = ($event: any) => (_ctx.kanmaDel($event))),
            onBlur: _cache[11] || (_cache[11] = ($event: any) => (_ctx.kanmaIns($event))),
            style: {"text-align":"right"},
            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.domPrice_supplys) = $event))
          }, null, 544)), [
            [_vModelText, _ctx.domPrice_supplys]
          ])
        : (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_ctx.kanmaIns4static(_ctx.item.c_migration_price_supplys!.toString())), 1))
    ]),
    _createElementVNode("td", _hoisted_20, [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-xs btn-success waves-effect waves-themed",
        onClick: _cache[13] || (_cache[13] = ($event: any) => (
          _ctx.goTo('/projectInput?record_id=' + _ctx.item.c_project_number, $event)
        ))
      }, " 案件 ")
    ]),
    _createElementVNode("td", _hoisted_21, [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-xs btn-success waves-effect waves-themed",
        onClick: _cache[14] || (_cache[14] = ($event: any) => (
          _ctx.goTo('/invoiceCreationForm?claimnum=' + _ctx.item.c_claim_number, $event)
        ))
      }, " 請求 ")
    ])
  ], 2))
}