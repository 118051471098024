
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";

// バリデーション
import * as Yup from "yup";
import { DtoStaffsGetResImple } from "furukawa-common/src/common/dto/DtoStaffsGetResImple";
import { DtoMastersGetRes } from "furukawa-common/src/common/dto/DtoMastersGetRes";
import { DtoMastersgetReq } from "furukawa-common/src/common/dto/DtoMastersGetReq";
import { Cl_common } from "@/functions/Cl_common";
import { Cl_staff } from "@/functions/Cl_staff";
import { Cl_master } from "@/functions/Cl_master";
import { DtoStaffsPostReq } from "furukawa-common/src/common/dto/abst/DtoStaffsPostReq";
import { DtoStaffsPutReq } from "furukawa-common/src/common/dto/abst/DtoStaffsPutReq";
import { DtoStaffsGetReqImple } from "furukawa-common/src/common/dto/DtoStaffsGetReqImple";
import {
  CONST_OP_AUTHTYPE,
  CONST_OP_SHOWORDER,
  CONST_OP_STAFFDEPART,
} from "furukawa-common/src/common/constant/Constants";
import { EntityStaff } from "../../../furukawa-common/src/common/entity/EntityStaff";

@Options({
  components: {
    Mo_modal_alert,
    vSelect,
  },
})
export default class EmployeeEdit extends Vue {
  //----------------------
  //バリデーション
  //----------------------
  //スキーマ宣言
  schemaAdd = Yup.object().shape({
    staffName: Yup.string().required("社員名を入力してください"),
    staffLoginid: Yup.string()
      .required("ログインIDを入力してください")
      .matches(
        /^[0-9a-zA-Z.]{4,}$/,
        "ログインIDは半角英数およびドット（.）4桁以上で入力してください。"
      ),
    staffPassNew: Yup.string()
      .required("パスワードを入力してください")
      .matches(
        /^[0-9a-zA-Z]{6,}$/,
        "パスワードは半角英数6桁以上で入力してください。"
      ),
    email: Yup.string()
      .email("有効なメールアドレスを入力してください")
      .required("メールアドレスを入力してください"),
  });
  schemaUpd = Yup.object().shape({
    staffName: Yup.string().required("社員名を入力してください"),
    staffLoginid: Yup.string()
      .required("ログインIDを入力してください")
      .matches(
        /^[0-9a-zA-Z.]{4,}$/,
        "ログインIDは半角英数およびドット（.）4桁以上で入力してください。"
      ),
    staffPassUpd: Yup.string()
      .nullable()
      .matches(
        /^([0-9a-zA-Z]{6,}|)$/,
        "パスワードは半角英数6桁以上で入力してください。"
      ),
    email: Yup.string()
      .email("有効なメールアドレスを入力してください")
      .required("メールアドレスを入力してください"),
  });

  //エラー格納用
  errors: Yup.ValidationError | null = null;

  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoStaffsGetResImple();
  delegate4list = new DtoMastersGetRes();
  btnDisable = false;
  isEditMode = true;
  domStaffBillingPerson: number[] = [];

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  op4StaffDepart = CONST_OP_STAFFDEPART;
  op4AuthType = CONST_OP_AUTHTYPE;
  op4ShowOrder = CONST_OP_SHOWORDER;
  op4ShowOrderAttendance = CONST_OP_SHOWORDER;
  /**
   * 社員の選択肢を構築する用
   */
  getOpStaffs(No: number): EntityStaff[] | undefined {
    switch (No) {
      case 1:
        return this.delegate4list.getStaffSortNum4NoneDelete([
          this.delegate.c_staff_elder_user_id_1!,
        ]);
      case 2:
        return this.delegate4list.getStaffSortNum4NoneDelete([
          this.delegate.c_staff_elder_user_id_2!,
        ]);
      case 3:
        return this.delegate4list.getStaffSortNum4NoneDelete([
          this.delegate.c_staff_elder_user_id_3!,
        ]);
      case 4:
        return this.delegate4list.getStaffSortNum4NoneDelete([
          this.delegate.c_staff_elder_user_id_4!,
        ]);
      case 5:
        return this.delegate4list.getStaffSortNum4NoneDelete([
          this.delegate.c_staff_elder_user_id_5!,
        ]);
      case 6:
        return this.delegate4list.getStaffSortNum4NoneDelete([
          this.delegate.c_staff_elder_user_id_6!,
        ]);
      case 7:
        return this.delegate4list.getStaffSortNum4NoneDelete([
          this.delegate.c_staff_elder_user_id_7!,
        ]);
      case 8:
        return this.delegate4list.getStaffSortNum4NoneDelete([
          this.delegate.c_staff_elder_user_id_8!,
        ]);
      case 9:
        return this.delegate4list.getStaffSortNum4NoneDelete([
          this.delegate.c_staff_elder_user_id_9!,
        ]);
      case 10:
        return this.delegate4list.getStaffSortNum4NoneDelete([
          this.delegate.c_staff_elder_user_id_10!,
        ]);
      case 11:
        return this.delegate4list.getStaffSortNum4NoneDelete([
          this.delegate.c_staff_application_staff_id_1!,
        ]);
      case 12:
        return this.delegate4list.getStaffSortNum4NoneDelete([
          this.delegate.c_staff_application_staff_id_2!,
        ]);
      case 13:
        return this.delegate4list.getStaffSortNum4NoneDelete([
          this.delegate.c_staff_application_staff_id_3!,
        ]);
      case 14:
        return this.delegate4list.getStaffSortNum4NoneDelete([
          this.delegate.c_staff_individual_staff_id_1!,
        ]);
      case 15:
        return this.delegate4list.getStaffSortNum4NoneDelete([
          this.delegate.c_staff_individual_staff_id_2!,
        ]);
      case 16:
        return this.delegate4list.getStaffSortNum4NoneDelete([
          this.delegate.c_staff_individual_staff_id_3!,
        ]);
    }
  }
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalDelConf = "";
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getMastersList();
    await this.get();
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4Staff4sortNum = 0;

      // dtoMaster.getStaff = 1;
      // dtoMaster.delFlag4staff = 0;

      dtoMaster.getBranch = 1;
      dtoMaster.delFlag4Branch = 0;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);
      console.log("this.delegate4list");
      console.log(this.delegate4list);

      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました"; //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * チェックボックスの値を数値に変換する
   * @param event
   */
  cnvCb(event: any): number {
    if (event.target.checked) {
      return 1;
    } else {
      return 0;
    }
  }

  /**
   * データを読み込む
   */
  async get() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //パラメータにIDが指定されていなければ、新規追加モードでデータを読み込まない
      //-------
      let id = Cl_common.getParam("id");
      if (id == "") {
        //新規追加
        this.isEditMode = false;
        //初期値設定
        this.delegate.c_staff_depart = 1;
        this.delegate.c_branch_id = 1;
        this.delegate.c_staff_show_order = 1;
        this.delegate.c_staff_auth_type = 1;
        // this.delegate.c_staff_flag_auth_claim = 1;
        this.delegate.c_staff_flag_del = 0;
        return;
      } else {
        //編集
        this.isEditMode = true;
      }

      //準備
      //-------
      let dto = new DtoStaffsGetReqImple();

      dto.c_staff_id = Number(id);

      //API実行
      //-------
      this.delegate = await Cl_staff.get(dto);
      console.log(this.delegate);
      //レスポンス処理
      //-------
      //IDがnullなら一覧へ遷移
      if (this.delegate.c_staff_id == null) {
        this.$router.push("/employeeList");
      }
      //請求書印刷担当拠点用変換
      if (
        null != this.delegate.c_staff_billing_person &&
        "" != this.delegate.c_staff_billing_person
      ) {
        this.domStaffBillingPerson = await this.cnvStrArray2NumArray(
          this.delegate.c_staff_billing_person!.split(",")
        );
      }
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを更新する
   */
  async upd(isDel: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoStaffsPutReq();
      if (isDel) {
        //削除処理：削除フラグを立てる。
        dto.c_staff_id = this.delegate.c_staff_id;
        dto.c_staff_flag_del = 1;
      } else {
        //バリデーション
        //-----------------
        this.errors = null;
        this.schemaUpd.validateSync({
          staffName: this.delegate.c_staff_name,
          email: this.delegate.c_staff_mail,
          staffLoginid: this.delegate.c_staff_login_id,
          staffPassUpd: this.delegate.c_staff_login_pass,
        });

        //請求書印刷担当拠点用変換
        if (undefined != this.domStaffBillingPerson) {
          this.delegate.c_staff_billing_person =
            this.domStaffBillingPerson.join(",");
        }
        //更新処理：delegateからdtoにデータ投入。
        dto = Object.assign(new DtoStaffsPutReq(), this.delegate);
      }

      //API実行
      //-------
      await Cl_staff.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      if (isDel) {
        //削除処理：
        this.message4ModalSuccess = "データを削除しました。";
      } else {
        //更新処理：
        this.message4ModalSuccess = "データを更新しました。";
      }

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを新たに登録する
   */
  async add() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //バリデーション
      //-----------------
      this.errors = null;
      this.schemaAdd.validateSync({
        staffName: this.delegate.c_staff_name,
        email: this.delegate.c_staff_mail,
        staffLoginid: this.delegate.c_staff_login_id,
        staffPassNew: this.delegate.c_staff_login_pass,
      });

      //準備
      //-------
      //請求書印刷担当拠点用変換
      if (undefined != this.domStaffBillingPerson) {
        this.delegate.c_staff_billing_person =
          this.domStaffBillingPerson.join(",");
      }
      let dto = Object.assign(new DtoStaffsPostReq(), this.delegate);
      dto.c_staff_flag_del = 0;

      //API実行
      //-------
      const res = await Cl_staff.post(dto);

      //レスポンスの処理
      //-------
      //編集画面のURL作成
      this.$router.push({
        path: "/employeeEdit",
        query: { id: res.c_staff_id },
      });

      //this.start = res.c_message_start?.toString().split("T")[0];
      //this.end = res.c_message_end?.toString().split("T")[0];

      //モーダルのメッセージを設定
      this.message4ModalSuccess = "正常に追加されました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを削除する
   */
  async del() {
    //削除フラグのあるテーブルなので更新処理。
    this.closeModal4deleteConf();
    this.upd(true);
  }

  async cnvStrArray2NumArray(strArray: string[]): Promise<number[]> {
    let res: number[] = [];

    for (let i = 0; i < strArray.length; ++i) {
      res.push(Number(strArray[i]));
    }

    return res;
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  /**
   * データを削除する
   */
  async delConf() {
    //モーダルのメッセージを設定
    this.message4ModalDelConf = "削除してもよろしいでしょうか。";

    //モーダルを表示
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.showModal();
  }

  async closeModal4deleteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.closeModal();
  }

  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
