
import { Prop, Options, Vue, Watch } from "vue-property-decorator";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import {
  CONST_AUTHORITY_STAFF_HENSYUSYA,
  CONST_AUTHORITY_STAFF_KANRISYA,
  CONST_AUTHORITY_STAFF_KENGENSYA,
  CONST_AUTHORITY_STAFF_NYURYOKUSYA,
  CONST_AUTHORITY_STAFF_SYUKEISYA,
  CONST_CLAIMSTATUS_SAKUSEIZUMI,
  CONST_CLAIMSTATUS_SASIMODOSI,
  CONST_CLAIMSTATUS_SYOUNINZUMI,
  CONST_CLAIMSTATUS_TOUROKUZUMI,
} from "furukawa-common/src/common/constant/Constants";
import Or_tableClaim from "@/components/organisms/Or_tableClaim.vue";
import { EntityClaimDet } from "furukawa-common/src/common/entity/EntityClaimDet";
import { Cl_common } from "@/functions/Cl_common";
import { Cl_filemaster } from "@/functions/Cl_filemaster";

import { Cl_claim } from "@/functions/Cl_claim";
import { DtoClaimsGetReqImple } from "furukawa-common/src/common/dto/DtoClaimsGetReqImple";
import { DtoClaimsGetResImple } from "furukawa-common/src/common/dto/DtoClaimsGetResImple";
import { DtoClaimsPostReqImple } from "furukawa-common/src/common/dto/DtoClaimsPostReqImple";
import { DtoClaimsPostResImple } from "furukawa-common/src/common/dto/DtoClaimsPostResImple";
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersgetReq } from "furukawa-common/src/common/dto/DtoMastersGetReq";
import { DtoMastersGetRes } from "furukawa-common/src/common/dto/DtoMastersGetRes";
import { DtoClaimsPutReq } from "furukawa-common/src/common/dto/abst/DtoClaimsPutReq";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import { DtoFileMastersGetReqImple } from "furukawa-common/src/common/dto/DtoFileMastersGetReqImple";

//DTP
import Mt_dtp from "@/components/atoms/Mt_dtp.vue";
import { DtoMdDtp } from "furukawa-common/src/common/dto/DtoMdDtp";
import "@vuepic/vue-datepicker/dist/main.css";

// バリデーション
import * as Yup from "yup";
import { EntityStaff } from "../../../furukawa-common/src/common/entity/EntityStaff";

@Options({
  components: {
    vSelect,
    Or_tableClaim,
    Mo_modal_alert,
    Mt_dtp,
  },
})
export default class InvoiceCreationFormNs extends Vue {
  //----------------------
  //バリデーション
  //----------------------
  //スキーマ宣言
  schema = Yup.object().shape({
    c_client_id: Yup.string().required("請求先を選択してください"),
    claimDate: Yup.string().required("請求日を設定してください"),
    c_staff_id: Yup.string().required("担当者を選択してください"),
    payLimit: Yup.string().required("入金予定日を設定してください"),
  });
  //エラー格納用
  errors: Yup.ValidationError | null = null;
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoClaimsGetResImple();
  delegate4list = new DtoMastersGetRes();
  claimDate: DtoMdDtp = new DtoMdDtp();
  payLimit: DtoMdDtp = new DtoMdDtp();
  btnDisable = false;
  claimNumber = "";
  paySite = "支払サイト：未設定";
  isEditMode = true;

  //NULL判定のCSSバインド用。
  isNull = false;
  //入力欄の有効無効フラグ
  inputDisable4conf = false;
  //各ボタン個別の有効無効フラグ
  btnDisable4conf = false;
  btnDisable4delete = false;
  btnDisable4save = false;
  btnDisable4create = false;
  btnDisable4Rewrite = false;
  //各ボタンの個別の表示非表示フラグ
  btnShow4conf = true;
  btnShow4delete = true;
  btnShow4save = true;
  btnShow4create = true;
  btnShow4Rewrite = true;
  //承認フォームの表示非表示フラグ
  fmShowConf = false;
  fmShowConf4self = false;
  fmShowConfEx = false;
  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  /**
   * 社員の選択肢を構築する用
   */
  getOpStaffs(): EntityStaff[] {
    return this.delegate4list.getStaffSortNum4NoneDelete([
      this.delegate.c_staff_id!,
    ]);
  }
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalDelConf = "";
  message4ModalErrorConf = "";
  message4ModalRewriteConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getMastersList();
    this.get();
  }
  /**
   * ボタンの表示制御
   */
  async adjButton() {
    //storeの値とLSの値を同期
    this.$store.commit("revStore");
    const detTable: any = this.$refs.Or_tableClaim;

    // ※読み込み失敗は全て無効化
    //-------------------------
    if (this.delegate.c_claim_id == null) {
      this.btnDisable4conf = true;
      this.btnDisable4delete = true;
      this.btnDisable4save = true;
      this.btnDisable4create = true;
      this.btnDisable4Rewrite = true;
      detTable.eventAdjButton(true);
      this.fmShowConf = false;
      this.fmShowConf4self = false;
      this.fmShowConfEx = false;
      this.fmShowConf4self = false;
      this.claimNumber = "";
      this.isNull = true;

      //新規登録モードなら明細有効化。
      if (!this.isEditMode) {
        detTable.eventAdjButton(false);
      }

      return;
    } else {
      this.btnDisable4conf = false;
      this.btnDisable4delete = false;
      this.btnDisable4save = false;
      this.btnDisable4create = false;
      this.btnDisable4Rewrite = false;
      detTable.eventAdjButton(false);
      this.isNull = false;
    }

    // 請求データ登録						ステータス 登録済み0 or 差戻済み3で表示
    //-------------------------
    if (
      this.delegate.c_claim_status == CONST_CLAIMSTATUS_TOUROKUZUMI ||
      this.delegate.c_claim_status == CONST_CLAIMSTATUS_SASIMODOSI
    ) {
      this.btnShow4save = true;
      this.btnDisable4save = false;
    } else {
      this.btnShow4save = false;
      this.btnDisable4save = true;
    }

    // 承認依頼						自身が請求承認必要＋ステータス0 or 3で表示、それ以外は非表示
    //-------------------------
    if (
      (this.$store.state.authType == CONST_AUTHORITY_STAFF_NYURYOKUSYA ||
        this.$store.state.authType == CONST_AUTHORITY_STAFF_SYUKEISYA ||
        this.$store.state.authType == CONST_AUTHORITY_STAFF_HENSYUSYA) &&
      (this.delegate.c_claim_status == 0 || this.delegate.c_claim_status == 3)
    ) {
      this.btnShow4conf = true;
      this.btnDisable4conf = false;
    } else {
      this.btnShow4conf = false;
      this.btnDisable4conf = true;
    }
    // 作成						ステータスが10 or 20で表示、それ以外は非表示
    //-------------------------
    if (
      this.delegate.c_claim_status == 10 ||
      this.delegate.c_claim_status == 20
    ) {
      this.btnShow4create = true;
      this.btnDisable4create = false;
    } else {
      this.btnShow4create = false;
      this.btnDisable4create = true;
    }

    // 再編集						ステータスが承認済・出力待ち(10) or 請求書作成済(20)、それ以外は非表示
    //-------------------------
    if (
      // this.$store.state.flagAuthClaim == 2 ||
      this.delegate.c_claim_status == CONST_CLAIMSTATUS_SYOUNINZUMI ||
      this.delegate.c_claim_status == CONST_CLAIMSTATUS_SAKUSEIZUMI
    ) {
      this.btnShow4Rewrite = true;
      this.btnDisable4Rewrite = false;
    } else {
      this.btnShow4Rewrite = false;
      this.btnDisable4Rewrite = true;
    }

    // 自己承認						ステータス請求データ登録済(0)もしくは差し戻し(3)、かつ自身が権限者or管理者の場合表示、それ以外は非表示
    //-------------------------
    if (
      (this.delegate.c_claim_status == CONST_CLAIMSTATUS_TOUROKUZUMI ||
        this.delegate.c_claim_status == CONST_CLAIMSTATUS_SASIMODOSI) &&
      (this.$store.state.authType == CONST_AUTHORITY_STAFF_KENGENSYA ||
        this.$store.state.authType == CONST_AUTHORITY_STAFF_KANRISYA)
    ) {
      this.fmShowConf4self = true;
    } else {
      this.fmShowConf4self = false;
    }

    // 承認					ステータスが1、かつ自身が承認者の場合表示、それ以外は非表示
    // 差戻					ステータスが1、かつ自身が承認者の場合表示、それ以外は非表示
    //-------------------------
    if (this.delegate.c_claim_status == 1) {
      if (this.delegate.elderStaffIds.indexOf(this.$store.state.id) != -1) {
        //承認依頼中で自身が承認者
        this.fmShowConf = true;
        this.btnDisable4conf = true;
        this.btnDisable4delete = true;
        this.btnDisable4save = true;
        this.btnDisable4create = true;
        detTable.eventAdjButton(true);
      } else {
        //承認依頼中で自身が承認者でない
        this.fmShowConfEx = true;
        this.btnDisable4conf = true;
        this.btnDisable4delete = true;
        this.btnDisable4save = true;
        this.btnDisable4create = true;
        detTable.eventAdjButton(true);
      }
    } else {
      this.fmShowConf = false;
      this.fmShowConfEx = false;
    }

    //入力用UI　　　　　　　ステータスが承認済・出力待ち(10) or 請求書作成済(20)で編集不可能、それ以外は編集可能。
    //-------------------------
    if (
      this.delegate.c_claim_status == CONST_CLAIMSTATUS_SYOUNINZUMI ||
      this.delegate.c_claim_status == CONST_CLAIMSTATUS_SAKUSEIZUMI
    ) {
      this.inputDisable4conf = true;
      detTable.eventAdjButton(true);
    } else {
      this.inputDisable4conf = false;
      detTable.eventAdjButton(false);
    }
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });
    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");
      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();
      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4Staff4sortNum = 0;
      // dtoMaster.getStaff = 1;
      // dtoMaster.delFlag4staff = 1;
      dtoMaster.getClients = 1;
      dtoMaster.delFlag4clients = 1;
      // dtoMaster.getBank = 1;
      // dtoMaster.delFlag4Bank = 1;

      //カレンダー休日反映用
      dtoMaster.getHoliday = 1;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);
      //レスポンス処理
      //-------
      Cl_common.logger(this.delegate4list);
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * データを読み込む
   */
  async get() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });
    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");
      //パラメータにIDが指定されていなければ、新規追加モードでデータを読み込まない
      //-------
      let id = Cl_common.getParam("claimnum");
      if (id == "") {
        //新規追加
        this.isEditMode = false;
        // const detTable: any = this.$refs.Or_tableClaim;
        // detTable.eventAdjButton(false);
        //初期値設定

        return;
      } else {
        this.isEditMode = true;
      }
      //準備
      //-------
      let dto = new DtoClaimsGetReqImple();
      dto.c_claim_id = Number(id); //APIのURL構成の関係で、請求書Noを請求書IDに入れているので注意
      dto.flagSales = 0;
      //API実行
      //-------
      this.delegate = await Cl_claim.get(dto);
      Cl_common.logger(this.delegate);
      if (this.delegate.c_claim_id == null) {
        this.$router.push("/invoiceSearch");
      }
      //日付展開
      if (this.delegate.c_claim_datetime != null) {
        this.claimDate.date = this.delegate.c_claim_datetime
          .toString()
          .split("T")[0];
      }
      if (this.delegate.c_claim_date_exp_payment != null) {
        this.payLimit.date = this.delegate.c_claim_date_exp_payment
          .toString()
          .split("T")[0];
      }

      //請求No展開
      this.claimNumber = this.delegate.c_claim_number!.toString();
      //明細の金額にカンマを付ける調整
      await this.cnvDetails();
      this.inputDisable4conf = false;
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
      Cl_common.logger(error);
      this.delegate = new DtoClaimsGetResImple();
      this.inputDisable4conf = true;
    } finally {
      //button調整
      await this.adjButton();
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * 明細内のデータを調整
   */
  async cnvDetails() {
    for (let i = 0; i < this.delegate.details.length; ++i) {
      //金額
      if (
        this.delegate.details[i].c_claim_det_price == null ||
        this.delegate.details[i].c_claim_det_price == ""
      ) {
        // this.delegate.details[i].c_claim_det_price = "0";
      } else {
        //カンマ補完
        while (
          this.delegate.details[i].c_claim_det_price !=
          (this.delegate.details[i].c_claim_det_price = this.delegate.details[
            i
          ].c_claim_det_price!.replace(/^(-?\d+)(\d{3})/, "$1,$2"))
        );
      }
    }

    for (let i = 0; i < this.delegate.details.length; ++i) {
      //金額
      if (
        this.delegate.details[i].c_claim_det_unitprice == null ||
        this.delegate.details[i].c_claim_det_unitprice == ""
      ) {
        // this.delegate.details[i].c_claim_det_price = "0";
      } else {
        //カンマ補完
        while (
          this.delegate.details[i].c_claim_det_unitprice !=
          (this.delegate.details[i].c_claim_det_unitprice =
            this.delegate.details[i].c_claim_det_unitprice!.replace(
              /^(-?\d+)(\d{3})/,
              "$1,$2"
            ))
        );
      }
    }
  }
  /**
   * データを更新する
   */
  async upd(isDel: boolean, isRequest: boolean, isNoModal: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    if (!isNoModal) {
      Array.from(panel).map((element: Element) => {
        element.classList.add("enable-loader");
      });
    }
    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoClaimsPutReq();
      if (isDel) {
        //削除処理：削除フラグを立てる。
        dto.c_claim_id = this.delegate.c_claim_id;
        dto.c_claim_flag_del = 1;
      } else {
        //バリデーション
        //-----------------
        this.errors = null;
        this.schema.validateSync({
          c_client_id: this.delegate.c_client_id,
          claimDate: this.claimDate.date,
          c_staff_id: this.delegate.c_staff_id,
          payLimit: this.payLimit.date,
        });

        //更新処理：delegateからdtoにデータ投入。
        dto = Object.assign(new DtoClaimsPutReq(), this.delegate);
        //日付をDTOに戻す
        if (this.claimDate.date != null && this.claimDate.date != "") {
          // console.log(this.claimDate);
          dto.c_claim_datetime = new Date(this.claimDate.date);
        }
        if (this.payLimit.date != null && this.payLimit.date != "") {
          dto.c_claim_date_exp_payment = new Date(this.payLimit.date);
        }
        //明細の合計を取得
        const tableClaim: any = this.$refs.Or_tableClaim;
        const sum = tableClaim.getSum();
        dto.c_claim_subtotal = sum[0].replaceAll(",", "");
        dto.c_claim_total = sum[1].replaceAll(",", "");
        if (isRequest) {
          //承認申請押下
          dto.c_claim_status = 1;
        }
      }
      //API実行
      //-------
      await Cl_claim.put(dto);
      //レスポンスの処理
      //-------
      //更新処理：
      //モーダルのメッセージを設定
      if (isDel) {
        //削除処理：
        this.message4ModalSuccess = "正常に削除されました";
      } else {
        //更新処理：
        this.message4ModalSuccess = "正常に変更されました";
      }
      //モーダルを表示
      if (!isNoModal) {
        const modal: any = this.$refs.Mo_modal_success;
        modal.showModal();
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      if (!isNoModal) {
        this.btnDisable = false;
        Array.from(panel).map((element: Element) => {
          element.classList.remove("enable-loader");
        });
      }
    }
  }
  /**
   * データを登録する
   */
  async add() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");

    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //バリデーション
      //-----------------
      this.errors = null;
      this.schema.validateSync({
        c_client_id: this.delegate.c_client_id,
        claimDate: this.claimDate.date,
        c_staff_id: this.delegate.c_staff_id,
        payLimit: this.payLimit.date,
      });

      //準備
      //-------
      let dto = new DtoClaimsPostReqImple();
      //登録処理：delegateからdtoにデータ投入。
      dto = Object.assign(new DtoClaimsPostReqImple(), this.delegate);
      dto.isNoSales = 1;
      //日付をDTOに戻す
      if (this.claimDate.date != null && this.claimDate.date != "") {
        // console.log(this.claimDate);
        dto.c_claim_datetime = new Date(this.claimDate.date);
      }
      if (this.payLimit.date != null && this.payLimit.date != "") {
        dto.c_claim_date_exp_payment = new Date(this.payLimit.date);
      }
      //明細の合計を取得
      const tableClaim: any = this.$refs.Or_tableClaim;
      const sum = tableClaim.getSum();
      dto.c_claim_subtotal = sum[0].replaceAll(",", "");
      dto.c_claim_total = sum[1].replaceAll(",", "");

      //API実行
      //-------
      const res = await Cl_claim.post(dto);
      //レスポンスの処理
      //-------
      //編集画面のURL作成
      this.$router.push({
        path: "/invoiceCreationFormNs",
        query: { claimnum: res.c_claim_number },
      });

      //モーダルのメッセージを設定

      //更新処理：
      this.message4ModalSuccess = "正常に追加されました";

      //モーダルを表示

      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------

      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを出力する
   */
  async exp(isSetStmp: boolean, isFormat_2025: boolean) {
    if (this.btnDisable) {
      return;
    }
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });
    try {
      //バリデーション
      //-----------------
      this.errors = null;
      this.schema.validateSync({
        c_client_id: this.delegate.c_client_id,
        claimDate: this.claimDate.date,
        c_staff_id: this.delegate.c_staff_id,
        payLimit: this.payLimit.date,
      });
      //まず保存
      //-----------------
      await this.upd(false, false, true);
      //処理
      //-----------------
      Cl_common.logger("処理実行");
      //準備
      //-------
      let dto = new DtoFileMastersGetReqImple();
      dto.type = "claim";
      dto.claimNumber = this.delegate.c_claim_number;
      dto.claimId = this.delegate.c_claim_id;
      if (isSetStmp) {
        dto.isSetStmp = 1;
      }
      if (isFormat_2025) {
        dto.isFormat_2025 = 1;
      } else {
        dto.isFormat_2025 = 0;
      }

      // dto.deliverySlip = this.delegate.c_claim_flag_delivery;
      //API実行
      //-------
      const res = await Cl_filemaster.get(dto);
      Cl_common.logger(res);
      Cl_common.logger(res.url);
      //レスポンス処理
      //-------
      location.href = res.url;
      //レスポンスの処理
      //-------
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * データを更新する
   * 承認・差戻用
   */
  async upd4Approval(isApproval: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });
    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //バリデーション
      //-----------------
      this.errors = null;
      this.schema.validateSync({
        c_client_id: this.delegate.c_client_id,
        claimDate: this.claimDate.date,
        c_staff_id: this.delegate.c_staff_id,
        payLimit: this.payLimit.date,
      });

      //一度保存
      await this.upd(false, false, true);

      //準備
      //-------
      let dto = new DtoClaimsPutReq();
      dto.c_claim_id = this.delegate.c_claim_id;
      dto.c_claim_flag_sales = this.delegate.c_claim_flag_sales;
      dto.c_claim_number = this.delegate.c_claim_number;
      dto.c_claim_title_1 = this.delegate.c_claim_title_1;
      dto.c_staff_id = this.delegate.c_staff_id;
      if (isApproval) {
        //承認
        dto.isApproval = 1;
      } else {
        //差戻
        dto.isApproval = 0;
      }
      //API実行
      //-------
      await Cl_claim.put(dto);
      //レスポンスの処理
      //-------
      //更新処理：
      //モーダルのメッセージを設定
      if (isApproval) {
        //削除処理：
        this.message4ModalSuccess = "正常に承認されました";
      } else {
        //更新処理：
        this.message4ModalSuccess = "正常に差戻されました";
      }
      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * データ削除用のモーダルを表示
   */
  async delConf() {
    //モーダルのメッセージを設定
    this.message4ModalDelConf =
      "請求No:" +
      this.delegate.c_claim_number +
      "　" +
      "を削除します。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.showModal();
  }
  /**
   * データを削除する
   */
  async del() {
    //削除フラグのあるテーブルなので更新処理。
    this.closeModal4deleteConf();
    this.upd(true, false, false);
  }

  //-------------------
  /**
   * 再編集確認用のモーダルを表示
   */
  async reriteConf() {
    //モーダルのメッセージを設定
    this.message4ModalRewriteConf =
      "請求No:" +
      this.delegate.c_claim_number +
      "　" +
      "を再編集するために未承認の状態に戻します。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_rewiteConf;
    modal.showModal();
  }
  /**
   * 請求書を未承認状態に戻す
   */
  async rewrite() {
    this.closeModal4RewriteConf();

    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoClaimsPutReq();

      //削除処理：削除フラグを立てる。
      dto.c_claim_id = this.delegate.c_claim_id;
      dto.isRewite = 1;

      //API実行
      //-------
      await Cl_claim.put(dto);
      //レスポンスの処理
      //-------
      //モーダルのメッセージ
      this.message4ModalSuccess = "正常に変更されました";
      const modal: any = this.$refs.Mo_modal_success;

      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------

      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  //-------------------

  /**
   * プロジェクトに遷移
   */
  async goProject() {
    if (this.delegate.project.c_project_number == null) {
      return;
    }
    await this.$router.push(
      "/projectInput:URL?record_id=" + this.delegate.project.c_project_number
    );
  }

  /**
   * 支払サイト表示更新
   */
  @Watch("delegate.c_client_id")
  getPaySite() {
    if (this.delegate.c_client_id == null) {
      this.paySite = "支払サイト：未設定";
    }

    let obj = this.delegate4list.items4Client.find(
      (item) => item.c_client_id === this.delegate.c_client_id
    );
    if (obj) {
      if (obj.c_client_paysite != null) {
        this.paySite = "支払サイト：" + obj.c_client_paysite;
      } else {
        this.paySite = "支払サイト：未設定";
      }
    } else {
      this.paySite = "支払サイト：未設定";
    }
  }

  // /**
  //  * 指定の請求Noへ遷移
  //  */
  // async goEnt() {
  //   await this.$router.push("/seikyusyocreate?claimnum=" + this.claimNumber);
  //   await this.get();
  // }
  // /**
  //  * 前の請求書に遷移
  //  */
  // async goPrev() {
  //   await this.$router.push(
  //     "/seikyusyocreate?claimnum=" + this.delegate.claimNumberPrev
  //   );
  //   await this.get();
  // }
  // /**
  //  * 次の請求書に遷移
  //  */
  // async goNext() {
  //   await this.$router.push(
  //     "/seikyusyocreate?claimnum=" + this.delegate.claimNumberNext
  //   );
  //   await this.get();
  // }
  /**
   * チェックボックスの値を数値に変換する
   * @param event
   */
  cnvCb(event: any): number {
    if (event.target.checked) {
      return 1;
    } else {
      return 0;
    }
  }
  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4RewriteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_rewiteConf;
    modal.closeModal();
  }
  async closeModal4deleteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.closeModal();
  }
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
  //モック時のコード
  //---------------------------
}
