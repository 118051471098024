
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import { DtoMdDtp } from "furukawa-common/src/common/dto/DtoMdDtp";
import Mt_dtp from "@/components/atoms/Mt_dtp.vue";
import { DtoMastersgetReq } from "furukawa-common/src/common/dto/DtoMastersGetReq";
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersGetRes } from "furukawa-common/src/common/dto/DtoMastersGetRes";
import { Cl_common } from "@/functions/Cl_common";
import { DtoHistorysGetResImple } from "furukawa-common/src/common/dto/DtoHistorysGetResImple";
import { DtoHistorysGetReqImple } from "furukawa-common/src/common/dto/DtoHistorysGetReqImple";
import { CONST_LIST_DEFO_COUNT } from "@/constants/constant";
import { Cl_history } from "@/functions/Cl_history";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_modal_alert,
    Mt_dtp,
  },
})
export default class OperationHistoryList extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoHistorysGetResImple();
  delegate4list = new DtoMastersGetRes();

  //ボタン制御用
  btnDisable = false;
  //検索条件dom
  domStuff = "";
  domProjectNo = "";
  domClaimNo = "";
  domOperationDay_start = new DtoMdDtp();
  domOperationDay_end = new DtoMdDtp();

  optionsOp = [
    {
      field: "c_staff_name",
    },
  ];
  columns = [
    {
      label: "操作時刻",
      field: "c_history_datetime",
      // type: "date",
      // dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
      // dateOutputFormat: "yyyy/MM/dd",
      // thClass: "text-center",
      // tdClass: "text-center",
      width: "20%",
    },
    {
      label: "操作者",
      field: "staffName",
      width: "10%",
    },
    {
      label: "画面名",
      field: "c_history_title",
    },
    {
      label: "操作内容",
      field: "c_history_det",
    },
  ];
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getMastersList();
    await this.getlist(1);
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * 一覧データを読み込む
   */
  async getlist(currentPage: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoHistorysGetReqImple();

      //検索条件設定
      dto.pager.count = CONST_LIST_DEFO_COUNT;
      dto.pager.cursor = currentPage;

      if (this.domOperationDay_start.date != null) {
        dto.OperationDay_start = new Date(this.domOperationDay_start.date);
      }
      // dto.salesIssueDate_end = this.domSalesIssueDate_end;
      if (this.domOperationDay_end.date != null) {
        dto.OperationDay_end = new Date(this.domOperationDay_end.date);
      }

      if (this.domStuff != "" && this.domStuff != null)
        dto.HistorysStuff = this.domStuff;

      //詳細の値から「案件番号」検索
      if (this.domProjectNo != "") dto.ProjectNo = Number(this.domProjectNo);
      //詳細の値から「請求No」検索
      if (this.domClaimNo != "") dto.ClaimNo = Number(this.domClaimNo);

      //API実行
      //-------
      this.delegate = await Cl_history.getList(dto);
      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      //エラー発生で一覧を初期化
      this.delegate = new DtoHistorysGetResImple();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getStaff = 1;
      dtoMaster.delFlag4staff = 0;

      //カレンダー休日反映用
      dtoMaster.getHoliday = 1;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);

      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました"; //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * ページ更新時の処理
   * @param params
   */
  async onPageChange(params: any) {
    //テーブル更新処理
    await this.getlist(params.currentPage);
  }
  /**
   * ソート変更時の処理
   * @param params
   */
  async onSortChange(params: any) {
    //テーブル更新処理
  }
  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
  //----------------------
  //その他のメソッド（画面独自のもの等）
  //----------------------
  cnvDate(date: Date | null) {
    if (date == null) {
      return "-";
    }

    return date
      .toString()
      .replaceAll("-", "/")
      .replace("T", " ")
      .replace(".000Z", "");
  }
}
