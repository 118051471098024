import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoSalesInfosGetReq } from "furukawa-common/src/common/dto/abst/DtoSalesInfosGetReq";
import { DtoSalesInfosGetRes } from "furukawa-common/src/common/dto/abst/DtoSalesInfosGetRes";

export class Cl_salesinfo {
  public static async get(
    dto: DtoSalesInfosGetReq
  ): Promise<DtoSalesInfosGetRes> {
    return Object.assign(
      new DtoSalesInfosGetRes(),
      await CallAPI.getRequest("/salesinfos?" + Cl_common.cnvObj2GetParam(dto))
    );
  }
}
