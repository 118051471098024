import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntityMigration } from "../../entity/EntityMigration";

export class DtoMigrationsPostReq extends EntityMigration {
  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------

    return res;
  }
}
