import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { DtoFileReportsGetReq } from "./abst/DtoFileReportsGetReq";
import { DtoApiPager } from "./abst/DtoApiPager";
import { DtoAuthorityRes } from "./DtoAuthorityRes";

export class DtoFileReportsGetReqImple extends DtoFileReportsGetReq {
  public authData: DtoAuthorityRes | null = null;
  //----------------------------

  public cover: boolean | null = null;
  public type: string | null = null;
  public type2: string | null = null;
  public month: number | null = null;
  public year: number | null = null;
  public outputqueue_code: string | null = null;

  // 受注情報検索

  //public staffId: number | null = null;
  public salesPrice_start: number | null = null;
  public salesPrice_end: number | null = null;
  public supplyPrice_start: number | null = null;
  public supplyPrice_end: number | null = null;
  public proRemarks: string | null = null;
  public proFreeWord: string | null = null;

  // 売上検索
  public projectNo_start: number | null = null;
  public projectNo_end: number | null = null;
  public projectName: string | null = null;
  public proClientid: number | null = null;
  public projectSponsorName: string | null = null;
  public staffId: string | null = null;
  public claimNo_start: number | null = null;
  public claimNo_end: number | null = null;
  public salesIssueDate_start: Date | null = null;
  public salesIssueDate_end: Date | null = null;
  public salesMonth_start: Date | null = null;
  public salesMonth_end: Date | null = null;
  public salesItem: number | null = null;
  public salesSubject: number | null = null;
  public salesBreakdown: number | null = null;
  public salesClientid: number | null = null;
  public SalesRemarks: string | null = null;
  public VariationType: number | null = null;
  public ClientGenre: number | null = null;
  public ItemType: number | null = null;
  public salesDateExpPayment_start: Date | null = null;
  public salesDateExpPayment_end: Date | null = null;
  public branchId: number | null = null;

  // 仕入検索
  // public projectNo_start: number | null = null;
  // public projectNo_end: number | null = null;
  // public projectName: string | null = null;
  // public proClientid: number | null = null;
  // public projectSponsorName: string | null = null;
  // public staffId: string | null = null;
  // public claimNo_start: number | null = null;
  // public claimNo_end: number | null = null;
  public supplyMonth_start: Date | null = null;
  public supplyMonth_end: Date | null = null;
  public supplyItem: number | null = null;
  public supplySubject: number | null = null;
  public supplyBreakdown: number | null = null;
  public supplierid: number | null = null;
  public projectType: number | null = null;
  public supplyRemarks: string | null = null;
  public supplyDateExpPayment_start: Date | null = null;
  public supplyDateExpPayment_end: Date | null = null;
  // public VariationType: number | null = null;
  // public ClientGenre: number | null = null;
  // public ItemType: number | null = null;
  public chkClaim: number | null = null;

  // 未承認一覧
  // public claimNo_start: number | null = null;
  // public claimNo_end: number | null = null;
  public ClaimDate_start: Date | null = null;
  public ClaimDate_end: Date | null = null;
  public Clientid: number | null = null;
  public ClaimTitle: string | null = null;
  public ClaimSponsorName: string | null = null;
  public ClaimPayLimit_start: Date | null = null;
  public ClaimPayLimit_end: Date | null = null;
  public StaffId: number | null = null;
  public ClaimStatus: number | null = null;
  //public flagSales: number | null = null;

  // 入金確定表
  // public claimNo_start: number | null = null;
  // public claimNo_end: number | null = null;
  // public ClaimDate_start: Date | null = null;
  // public ClaimDate_end: Date | null = null;
  // public Clientid: number | null = null;
  // public ClaimTitle: string | null = null;
  // public ClaimSponsorName: string | null = null;
  // public ClaimPayLimit_start: Date | null = null;
  // public ClaimPayLimit_end: Date | null = null;
  // public StaffId: number | null = null;
  public PaymentStatus: number | null = null;

  // 請求No検索
  public ClaimNo: number | null = null;

  //勤怠一覧
  //-------------------------
  public staffNo_start: string | null = null;
  public staffNo_end: string | null = null;
  public staffName: string | null = null;
  /**
   * 対象月をyyyy-MMで指定
   */
  public attendancemo: string | null = null;

  //出勤表個別用
  /**
   * 社員IDを指定
   */
  public attendanceid: number | null = null;

  //請求一覧用
  //-------------------------
  //検索条件
  public claimlist_claimNo_start: number | null = null;
  public claimlist_claimNo_end: number | null = null;
  public claimlist_claimDate_start: string | null = null;
  public claimlist_claimDate_end: string | null = null;
  public claimlist_clientid: number | null = null;
  public claimlist_claimTitle: string | null = null;
  public claimlist_claimSponsorName: string | null = null;
  public claimlist_claimPayLimit_start: string | null = null;
  public claimlist_claimPayLimit_end: string | null = null;
  public claimlist_StaffId: number | null = null;
  public claimlist_paymentStatus: number | null = null;
  public claimlist_saleStatus: number | null = null;
  public claimlist_claimStatus: number | null = null;
  public claimlist_branchId: string | null = null;

  // 日報出力用
  //-------------------------
  //検索条件
  public date_start: Date | null = null;
  public date_end: Date | null = null;
  public reportType: string | null = null;
  public freeword: string | null = null;

  //順
  public orderStaff: number | null = null;
  public orderDate: number | null = null;

  // 請求書のPDFを文書に登録
  //-------------------------
  public claimid: number | null = null;

  public IsValidImple(chkPager: boolean): string[] {
    let res: string[] = new Array();

    return res;
  }
}
