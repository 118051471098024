
import { Cl_common } from "@/functions/Cl_common";
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersgetReq } from "furukawa-common/src/common/dto/DtoMastersGetReq";
import { DtoMastersGetRes } from "furukawa-common/src/common/dto/DtoMastersGetRes";
import { DtoReport4detsGetRes } from "furukawa-common/src/common/dto/abst/DtoReport4detsGetRes";
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { VueGoodTable } from "vue-good-table-next";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import { DtoMdDtp } from "furukawa-common/src/common/dto/DtoMdDtp";
import Mt_dtp4month from "@/components/atoms/Mt_dtp4month.vue";
import Mt_dtp from "@/components/atoms/Mt_dtp.vue";
import { CONST_OP_DAILYREPORT_TYPE } from "furukawa-common/src/common/constant/Constants";
import { DtoReport4detsGetReq } from "furukawa-common/src/common/dto/abst/DtoReport4detsGetReq";
import { Cl_report4det } from "@/functions/Cl_report4det";
import { DtoReport4detsGetResImple } from "furukawa-common/src/common/dto/DtoReport4detsGetResImple";
import { DtoReport4detsGetReqImple } from "furukawa-common/src/common/dto/DtoReport4detsGetReqImple";
import { CONST_LIST_DEFO_COUNT } from "@/constants/constant";
import Or_tableReportList from "@/components/organisms/Or_tableReportList.vue";

//store
import store from "@/store";
import { Axios, AxiosError } from "axios";
import { DtoFileReportsGetReqImple } from "furukawa-common/src/common/dto/DtoFileReportsGetReqImple";
import { Cl_filereport } from "@/functions/Cl_filereport";
import { DtoOutputqueuesGetReqImple } from "furukawa-common/src/common/dto/DtoOutputqueuesGetReqImple";
import { Cl_outputqueue } from "@/functions/Cl_outputqueue";
import { DtoOutputqueuesGetResImple } from "furukawa-common/src/common/dto/DtoOutputqueuesGetResImple";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_modal_alert,
    Mt_dtp,
    Mt_dtp4month,
    Or_tableReportList,
  },
})
export default class DailyReportSearchIdeas extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoReport4detsGetResImple();
  delegate4list = new DtoMastersGetRes();
  btnDisable = false;

  //検索条件dom
  domStaffId: number | null = null;
  domDate_start = new DtoMdDtp();
  domDate_end = new DtoMdDtp();
  domReportType = "";
  domFreeWord = "";
  isOrderDate = true;

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  opReportStatus = CONST_OP_DAILYREPORT_TYPE;

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";

  async mounted() {
    //選択肢を初期化
    //-------
    await this.getMastersList();
    //ログインしているユーザーを検索条件に設定。
    //-------
    this.$store.commit("revStore");
    this.domStaffId = this.$store.state.id;

    //前月の初日と末日を設定。
    //-------
    this.getlist_premonth();

    //-------
    await this.getlist(1, false);
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      //検索選択肢用
      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4Staff4sortNum = 1;
      // dtoMaster.getStaff = 1;
      // dtoMaster.delFlag4staff = 1;

      // dtoMaster.getBranch = 1;
      // dtoMaster.delFlag4Branch = 0;

      // dtoMaster.getClients = 1;
      // dtoMaster.delFlag4clients = 0;

      //カレンダー休日反映用
      dtoMaster.getHoliday = 1;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);

      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました"; //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * 一覧データを読み込む
   */
  async getlist(currentPage: number, toggleOrder: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoReport4detsGetReqImple();

      if (toggleOrder) {
        this.isOrderDate = !this.isOrderDate;
      }

      //検索条件設定
      dto.pager.count = 9999;
      dto.pager.cursor = currentPage;

      if (this.domStaffId != null)
        //dto.staffId = Number(this.domProStaffId);
        dto.staffId = this.domStaffId.toString();

      if (this.domDate_start.date != null) {
        dto.date_start = new Date(this.domDate_start.date);
      }

      if (this.domDate_end.date != null) {
        dto.date_end = new Date(this.domDate_end.date);
      }

      if (this.domReportType != "" && this.domReportType != null) {
        dto.reportType = this.domReportType;
      }

      if (this.domFreeWord != "" && this.domFreeWord != null) {
        dto.freeword = this.domFreeWord;
      }

      if (this.isOrderDate) {
        dto.orderDate = 1;
        dto.orderStaff = 0;
      } else {
        dto.orderDate = 0;
        dto.orderStaff = 1;
      }

      //API実行
      //-------
      this.delegate = await Cl_report4det.getList(dto);

      for (let index = 0; index < this.delegate.exitems.length; index++) {
        this.delegate.exitems[index].cnvRemarks =
          this.delegate.exitems[index].c_report_remarks!;
      }

      //レスポンス処理
      //-------
      Cl_common.logger("結果");
      Cl_common.logger(this.delegate);
    } catch (error) {
      Cl_common.logger(error);

      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      //エラー発生で一覧を初期化
      this.delegate = new DtoReport4detsGetResImple();
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoFileReportsGetReqImple();
      dto.type = "ReportList";

      if (this.domStaffId != null)
        //dto.staffId = Number(this.domProStaffId);
        dto.staffId = this.domStaffId.toString();

      if (this.domDate_start.date != null) {
        dto.date_start = new Date(this.domDate_start.date);
      }

      if (this.domDate_end.date != null) {
        dto.date_end = new Date(this.domDate_end.date);
      }

      if (this.domReportType != "" && this.domReportType != null) {
        dto.reportType = this.domReportType;
      }

      if (this.domFreeWord != "" && this.domFreeWord != null) {
        dto.freeword = this.domFreeWord;
      }

      if (this.isOrderDate) {
        dto.orderDate = 1;
        dto.orderStaff = 0;
      } else {
        dto.orderDate = 0;
        dto.orderStaff = 1;
      }

      //API実行
      //-------
      const res = await Cl_filereport.get(dto);

      if (0 == res.outputqueue_code.length) {
        throw new Error("出力失敗");
      }

      Cl_common.logger("res.outputqueue_code");
      Cl_common.logger(res.outputqueue_code);

      let timeLimit = Date.now() + 15 * 60 * 1000; // 15分
      let now = Date.now();
      let dtoOutputReq = new DtoOutputqueuesGetReqImple();
      dtoOutputReq.c_outputqueue_code = res.outputqueue_code;
      let resOutputReq = new DtoOutputqueuesGetResImple();
      resOutputReq = await Cl_outputqueue.get(dtoOutputReq);
      while (now < timeLimit) {
        Cl_common.logger("wait request");
        await new Promise((resolve) => setTimeout(resolve, 10000)); // 10秒待つ

        now = Date.now();

        resOutputReq = await Cl_outputqueue.get(dtoOutputReq);
        //↓社領修正行
        if (resOutputReq.c_outputqueue_flag_end == 1) {
          break;
        }
      }

      //レスポンス処理
      //-------
      Cl_common.logger("resOutputReq");
      Cl_common.logger(resOutputReq);

      //↓社領修正行
      if (resOutputReq.c_outputqueue_flag_success == 1) {
        location.href = resOutputReq.url;
      } else {
        throw new Error("出力失敗");
      }
      //window.open(res.url, "_blank");
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  async getlist_premonth() {
    const today = new Date();
    const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const firstDayOfMonth = new Date(
      lastMonth.getFullYear(),
      lastMonth.getMonth(),
      1
    );
    firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1); // 1日を次の日に設定する
    const lastDayOfLastMonth = new Date(
      today.getFullYear(),
      today.getMonth(),
      0
    );
    lastDayOfLastMonth.setDate(lastDayOfLastMonth.getDate() + 1); // 前月の末日を次の日に設定する

    this.domDate_start.date = firstDayOfMonth.toISOString().split("T")[0];
    // this.domClaimDate_end.date = lastDayOfLastMonth
    //   .toISOString()
    //   .split("T")[0];

    Cl_common.logger("1日");
    Cl_common.logger(firstDayOfMonth.toISOString().split("T")[0]);
    Cl_common.logger("末日");
    Cl_common.logger(lastDayOfLastMonth.toISOString().split("T")[0]);
  }
  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
