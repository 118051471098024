import { EntityReport } from "../entity/EntityReport";
import { EntityReportDet } from "../entity/EntityReportDet";
import { EntityReportImple } from "../entity/EntityReportImple";
import { DtoApiPager } from "./abst/DtoApiPager";
import { DtoReport4detsGetRes } from "./abst/DtoReport4detsGetRes";
import { commonDateFurukawa } from "../function/commonDateFurukawa";
import { CONST_OP_DAILYREPORT_TYPE } from "../constant/Constants";
import { EntityReportDetImple } from "../entity/EntityReportDetImple";

export class DtoReport4detsGetResImple extends DtoReport4detsGetRes {
  public pager = new DtoApiPager();

  public exitems: EntityReportImple[] = [];

  //-----------------------
  //メソッド
  //-----------------------
  public async setEximtes(staffmap: Map<number, string>) {
    let tmp: EntityReportImple;
    let tmpDet: EntityReportDetImple;
    let hdIDtmp: number | null = null;
    let indexDet = 0;

    tmp = new EntityReportImple();
    tmpDet = new EntityReportDetImple;

    for (let index = 0; index < this.items.length; index++) {


      if (hdIDtmp != this.items[index].c_report_id || index == 0) {

        if (index != 0) {
          this.exitems.push(tmp);
        }

        hdIDtmp = this.items[index].c_report_id;

        tmp = new EntityReportImple();

        tmp.c_report_id = this.items[index].c_report_id;
        tmp.c_staff_id = this.items[index].c_staff_id;
        tmp.c_report_date = this.items[index].c_report_date;
        tmp.c_report_remarks = this.items[index].c_report_remarks;
        tmp.c_report_date_str = commonDateFurukawa.formatDateString(this.items[index].c_report_date!);
        tmp.c_staff_name = staffmap.get(this.items[index].c_staff_id!)!;

        indexDet = 0;
      }

      if (hdIDtmp == this.items[index].c_report_id) {

        tmpDet = new EntityReportDetImple;

        tmpDet.c_report_det_id = this.items[index].c_report_det_id;
        tmpDet.c_report_det_rownum = this.items[index].c_report_det_rownum;
        tmpDet.c_report_det_type = this.items[index].c_report_det_type;
        tmpDet.c_report_det_project_name = this.items[index].c_report_det_project_name;
        tmpDet.c_report_det_visited_name = this.items[index].c_report_det_visited_name;
        tmpDet.c_report_det_location = this.items[index].c_report_det_location;
        tmpDet.c_report_det_work_contents = this.items[index].c_report_det_work_contents;
        tmpDet.c_report_det_sales = this.items[index].c_report_det_sales;
        tmpDet.c_report_det_supply = this.items[index].c_report_det_supply;
        tmpDet.c_report_det_alert_date_start = this.items[index].c_report_det_alert_date_start;
        tmpDet.c_report_det_alert_date_end = this.items[index].c_report_det_alert_date_end;

        if (tmpDet.c_report_det_type != null) {
          // tmp.c_status_name = CONST_CLAIMSTATUS[tmp.c_claim_status].label;

          var found = CONST_OP_DAILYREPORT_TYPE.find(
            (e) => e.code === tmpDet.c_report_det_type
          );
          tmpDet.c_report_det_type_name = found?.label!;
        } else {
          tmpDet.c_report_det_type_name = "";
        }

        tmp.c_report_det_row[indexDet] = tmpDet;
        indexDet++;
      }

      if (index == this.items.length - 1) {
        this.exitems.push(tmp);
      }


    }
  }
}

// export class Exitem extends EntityReport {
//   public c_report_det_row: EntityReportDet[] = [];
// }