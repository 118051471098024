
import { Options, Vue } from "vue-class-component";
import { Cl_common } from "@/functions/Cl_common";
import { Cl_master } from "@/functions/Cl_master";
import { Cl_holiday } from "@/functions/Cl_holiday";
import { DtoMastersGetRes } from "furukawa-common/src/common/dto/DtoMastersGetRes";
import { DtoMastersgetReq } from "furukawa-common/src/common/dto/DtoMastersGetReq";
import { DtoHolidaysGetReqImple } from "furukawa-common/src/common/dto/DtoHolidaysGetReqImple";
import { DtoHolidaysGetResImple } from "furukawa-common/src/common/dto/DtoHolidaysGetResImple";
import { DtoHolidaysPutReq } from "furukawa-common/src/common/dto/abst/DtoHolidaysPutReq";
import { DtoHolidaysPostReq } from "furukawa-common/src/common/dto/abst/DtoHolidaysPostReq";
import { DtoHolidaysDeleteReq } from "furukawa-common/src/common/dto/abst/DtoHolidaysDeleteReq";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import { DtoMdDtp } from "furukawa-common/src/common/dto/DtoMdDtp";
import Mt_dtp from "@/components/atoms/Mt_dtp.vue";

// バリデーション
import * as Yup from "yup";

@Options({
  components: {
    Mo_modal_alert,
    Mt_dtp,
  },
})
export default class HolidayEdit extends Vue {
  //----------------------
  //バリデーション
  //----------------------
  //スキーマ宣言
  schemaAdd = Yup.object().shape({
    holidayDate: Yup.string().required("日付を選択してください。"),
    holidayName: Yup.string().required("休日名を入力してください。"),
  });
  schemaUpd = Yup.object().shape({
    holidayDate: Yup.string().required("日付を選択してください。"),
    holidayName: Yup.string().required("休日名を入力してください。"),
  });

  //エラー格納用
  errors: Yup.ValidationError | null = null;

  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoHolidaysGetResImple();
  delegate4list = new DtoMastersGetRes();
  domDate = new DtoMdDtp();
  btnDisable = false;
  isEditMode = true;

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalDelConf = "";
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.get();
    await this.getMastersList();
  }

  //----------------------
  //基本操作用メソッド
  //----------------------}
  /**
   * データを読み込む
   */
  async get() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //パラメータにIDが指定されていなければ、新規追加モードでデータを読み込まない
      //-------
      let id = Cl_common.getParam("id");
      if (id == "") {
        //新規追加
        this.isEditMode = false;
        //初期値設定
        //this.delegate.c_client_company_type = 1;
        //this.delegate.c_client_flag_company_signage_posi = 1;
        return;
      } else {
        //編集
        this.isEditMode = true;
      }

      //準備
      //-------
      let dto = new DtoHolidaysGetReqImple();

      dto.c_holiday_id = Number(id);

      //API実行
      //-------
      this.delegate = await Cl_holiday.get(dto);
      //レスポンス処理
      //-------
      //IDがnullなら一覧へ遷移
      if (this.delegate.c_holiday_id == null) {
        this.$router.push("/holidayList");
      }
      //日付変換
      if (this.delegate.c_holiday_date != null) {
        this.domDate.date = this.delegate
          .c_holiday_date!.toString()
          .split("T")[0];
      }
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを更新する
   */
  async upd(isDel: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoHolidaysPutReq();
      if (isDel) {
        //削除処理
        dto.c_holiday_id = this.delegate.c_holiday_id;
      } else {
        //バリデーション
        //-----------------
        this.errors = null;

        this.schemaUpd.validateSync({
          holidayDate: this.domDate.date,
          holidayName: this.delegate.c_holiday_name,
        });

        //日付
        if (this.domDate != null && this.domDate.date != null) {
          this.delegate.c_holiday_date = new Date(this.domDate.date!);
        } else {
          this.delegate.c_holiday_date = null;
        }
      }

      //更新処理：delegateからdtoにデータ投入。
      dto = Object.assign(new DtoHolidaysPutReq(), this.delegate);

      //API実行
      //-------
      await Cl_holiday.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      if (isDel) {
        //削除処理：
        this.message4ModalSuccess = "データを削除しました。";
      } else {
        //更新処理：
        this.message4ModalSuccess = "データを更新しました。";
      }

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * デ ータを新たに登録する
   */
  async add() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //バリデーション
      //-----------------
      this.errors = null;
      this.schemaAdd.validateSync({
        holidayDate: this.domDate.date,
        holidayName: this.delegate.c_holiday_name,
      });

      //準備
      //-------
      //日付をdelegateに戻す
      if (this.domDate != null && this.domDate.date != null) {
        this.delegate.c_holiday_date = new Date(this.domDate.date);
      } else {
        this.delegate.c_holiday_date = null;
      }
      let dto = Object.assign(new DtoHolidaysPostReq(), this.delegate);

      //API実行
      //-------
      const res = await Cl_holiday.post(dto);

      //レスポンスの処理
      //-------
      //編集画面のURL作成
      this.$router.push({
        path: "/holidayEdit",
        query: { id: res.c_holiday_id },
      });

      //モーダルのメッセージを設定
      this.message4ModalSuccess = "データを更新しました。";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行休日マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      //カレンダー休日反映用
      dtoMaster.getHoliday = 1;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);

      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * データを削除する
   */
  async del() {
    //削除確認モーダル。
    this.closeModal4deleteConf();
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoHolidaysDeleteReq();
      //削除処理
      dto.c_holiday_id = this.delegate.c_holiday_id;

      //API実行
      //-------
      await Cl_holiday.del(dto);
      //レスポンスの処理
      //-------

      //モーダルのメッセージを設定
      //削除処理：
      this.message4ModalSuccess = "データを削除しました。";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      //---------------
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを削除する
   */
  async delConf() {
    //モーダルのメッセージを設定
    this.message4ModalDelConf = "削除してもよろしいでしょうか。";

    //モーダルを表示
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.showModal();
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4deleteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.closeModal();
  }
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
