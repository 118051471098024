
import { PropType, defineComponent, onMounted, reactive, ref } from "vue";
import { VuePdf, createLoadingTask } from "vue3-pdfjs/esm";
import { VuePdfPropsType } from "vue3-pdfjs/components/vue-pdf/vue-pdf-props"; // Prop type definitions can also be imported
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api";
import { EntityDocfileImple } from "furukawa-common/src/common/entity/EntityDocfileImple";

// propsの型を定義
interface Props {
  item: EntityDocfileImple;
  isOne: boolean;
}

export default defineComponent({
  name: "Or_pdfviewer",
  components: { VuePdf },

  props: {
    item: {
      type: Object as PropType<EntityDocfileImple>,
      required: true,
    },
    isOne: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },

  setup(props: Props) {
    const pdfSrc = ref<VuePdfPropsType["src"]>(props.item.url4Show!);
    const numOfPages = ref(0);

    onMounted(() => {
      const loadingTask = createLoadingTask(pdfSrc.value);
      // const loadingTask = createLoadingTask({
      //   url: pdfSrc.value,
      //   cMapUrl: "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.10.377/cmaps/",
      //   cMapPacked: true,
      //   useWorkerFetch: true,
      // });
      loadingTask.promise.then((pdf: PDFDocumentProxy) => {
        if (props.isOne) {
          //1ページ目のみ表示
          numOfPages.value = 1;
        } else {
          //全ページ表示
          numOfPages.value = pdf.numPages;
        }
      });
    });
    return {
      pdfSrc,
      numOfPages,
    };
  },
});
