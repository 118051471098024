import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoFileMastersGetReqImple } from "furukawa-common/src/common/dto/DtoFileMastersGetReqImple";
import { DtoFileMastersGetResImple } from "furukawa-common/src/common/dto/DtoFileMastersGetResImple";

export class Cl_filemaster {
  public static async get(
    dto: DtoFileMastersGetReqImple
  ): Promise<DtoFileMastersGetResImple> {
    return Object.assign(
      new DtoFileMastersGetResImple(),
      await CallAPI.getRequest(
        "/filemasters/?" + Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
