export class EntityMessage {
public c_message_id:number | null = null;
public c_staff_id:number | null = null;
public c_message_start:Date | null = null;
public c_message_end:Date | null = null;
public c_message_cont:string | undefined;
public c_message_flag_del:number | null = null;
public c_message_reg_user:string | null = null;
public c_message_reg_at:Date | null = null;
public c_message_upd_user:string | null = null;
public c_message_upd_at:Date | null = null;
}