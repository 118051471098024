import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { DtoHistorysGetReq } from "./abst/DtoHistorysGetReq";
import { DtoApiPager } from "./abst/DtoApiPager";

export class DtoHistorysGetReqImple extends DtoHistorysGetReq {
  //操作履歴一覧の検索条件
  public OperationDay_start: Date | null = null;
  public OperationDay_end: Date | null = null;
  public HistorysStuff: string | null = null;
  public ProjectNo: number | null = null;
  public ClaimNo: number | null = null;

  public pager = new DtoApiPager();

  public IsValidImple(chkPager: boolean): string[] {
    let res: string[] = new Array();
    //ページャー用入力値チェック
    if (chkPager) {
      this.pager.IsValid4Pager(res);
    } else {
      res = res.concat(super.IsValid());
    }

    return res;
  }
}
