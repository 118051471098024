import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntityFile } from "../../entity/EntityFile";

export class DtoFilesGetReq extends EntityFile {
  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------
    if (!commonRegx.isMatch(this.c_file_id!.toString(), "^\\d+$", commonRegx._stdOption)) {
      res.push("type error");
    }

    return res;
  }
}
