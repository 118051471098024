import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoClientsGetReqImple } from "furukawa-common/src/common/dto/DtoClientsGetReqImple";
import { DtoClientsGetResImple } from "furukawa-common/src/common/dto/DtoClientsGetResImple";
import { DtoClientsPostReq } from "furukawa-common/src/common/dto/abst/DtoClientsPostReq";
import { DtoClientsPostRes } from "furukawa-common/src/common/dto/abst/DtoClientsPostRes";
import { DtoClientsPutReq } from "furukawa-common/src/common/dto/abst/DtoClientsPutReq";
import { DtoClientsPutRes } from "furukawa-common/src/common/dto/abst/DtoClientsPutRes";
import { DtoClientsDeleteReq } from "furukawa-common/src/common/dto/abst/DtoClientsDeleteReq";
import { DtoClientsDeleteRes } from "furukawa-common/src/common/dto/abst/DtoClientsDeleteRes";

export class Cl_client {
  public static async getList(
    dto: DtoClientsGetReqImple
  ): Promise<DtoClientsGetResImple> {
    return Object.assign(
      new DtoClientsGetResImple(),
      await CallAPI.getRequest(
        "/clients?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoClientsGetReqImple
  ): Promise<DtoClientsGetResImple> {
    return Object.assign(
      new DtoClientsGetResImple(),
      await CallAPI.getRequest("/clients/" + dto.c_client_id)
    );
  }

  public static async post(dto: DtoClientsPostReq): Promise<DtoClientsPostRes> {
    return Object.assign(
      new DtoClientsPostRes(),
      await CallAPI.postRequest("/clients", dto)
    );
  }

  public static async put(dto: DtoClientsPutReq): Promise<DtoClientsPutRes> {
    return Object.assign(
      new DtoClientsPutRes(),
      await CallAPI.putRequest("/clients/" + dto.c_client_id, dto)
    );
  }

  public static async del(
    dto: DtoClientsDeleteReq
  ): Promise<DtoClientsDeleteRes> {
    return Object.assign(
      new DtoClientsDeleteRes(),
      await CallAPI.deleteRequest("/clients?" + Cl_common.cnvObj2GetParam(dto))
    );
  }
}
