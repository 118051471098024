import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoRequestHolidaysGetReqImple } from "furukawa-common/src/common/dto/DtoRequestHolidaysGetReqImple";
import { DtoRequestHolidaysGetResImple } from "furukawa-common/src/common/dto/DtoRequestHolidaysGetResImple";
import { DtoRequestHolidaysPostReq } from "furukawa-common/src/common/dto/abst/DtoRequestHolidaysPostReq";
import { DtoRequestHolidaysPostRes } from "furukawa-common/src/common/dto/abst/DtoRequestHolidaysPostRes";
import { DtoRequestHolidaysPutReq } from "furukawa-common/src/common/dto/abst/DtoRequestHolidaysPutReq";
import { DtoRequestHolidaysPutRes } from "furukawa-common/src/common/dto/abst/DtoRequestHolidaysPutRes";
import { DtoRequestHolidaysDeleteReq } from "furukawa-common/src/common/dto/abst/DtoRequestHolidaysDeleteReq";
import { DtoRequestHolidaysDeleteRes } from "furukawa-common/src/common/dto/abst/DtoRequestHolidaysDeleteRes";

export class Cl_requestholiday {
  public static async getList(
    dto: DtoRequestHolidaysGetReqImple
  ): Promise<DtoRequestHolidaysGetResImple> {
    return Object.assign(
      new DtoRequestHolidaysGetResImple(),
      await CallAPI.getRequest(
        "/requestHolidays?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoRequestHolidaysGetReqImple
  ): Promise<DtoRequestHolidaysGetResImple> {
    return Object.assign(
      new DtoRequestHolidaysGetResImple(),
      await CallAPI.getRequest("/requestHolidays/" + dto.c_request_holiday_id)
    );
  }

  public static async post(
    dto: DtoRequestHolidaysPostReq
  ): Promise<DtoRequestHolidaysPostRes> {
    return Object.assign(
      new DtoRequestHolidaysPostRes(),
      await CallAPI.postRequest("/requestHolidays", dto)
    );
  }

  public static async put(
    dto: DtoRequestHolidaysPutReq
  ): Promise<DtoRequestHolidaysPutRes> {
    return Object.assign(
      new DtoRequestHolidaysPutRes(),
      await CallAPI.putRequest(
        "/requestHolidays/" + dto.c_request_holiday_id,
        dto
      )
    );
  }

  public static async del(
    dto: DtoRequestHolidaysDeleteReq
  ): Promise<DtoRequestHolidaysDeleteRes> {
    return Object.assign(
      new DtoRequestHolidaysDeleteRes(),
      await CallAPI.deleteRequest(
        "/requestHolidays?" + Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
