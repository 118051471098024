import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoRequestCostsacctsGetReqImple } from "furukawa-common/src/common/dto/DtoRequestCostsacctsGetReqImple";
import { DtoRequestCostsacctsGetResImple } from "furukawa-common/src/common/dto/DtoRequestCostsacctsGetResImple";
import { DtoRequestCostsacctsPostReq } from "furukawa-common/src/common/dto/abst/DtoRequestCostsacctsPostReq";
import { DtoRequestCostsacctsPostRes } from "furukawa-common/src/common/dto/abst/DtoRequestCostsacctsPostRes";
import { DtoRequestCostsacctsPutReq } from "furukawa-common/src/common/dto/abst/DtoRequestCostsacctsPutReq";
import { DtoRequestCostsacctsPutRes } from "furukawa-common/src/common/dto/abst/DtoRequestCostsacctsPutRes";
import { DtoRequestCostsacctsDeleteReq } from "furukawa-common/src/common/dto/abst/DtoRequestCostsacctsDeleteReq";
import { DtoRequestCostsacctsDeleteRes } from "furukawa-common/src/common/dto/abst/DtoRequestCostsacctsDeleteRes";

export class Cl_requestcostsacct {
  public static async getList(
    dto: DtoRequestCostsacctsGetReqImple
  ): Promise<DtoRequestCostsacctsGetResImple> {
    return Object.assign(
      new DtoRequestCostsacctsGetResImple(),
      await CallAPI.getRequest(
        "/requestCostsaccts?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoRequestCostsacctsGetReqImple
  ): Promise<DtoRequestCostsacctsGetResImple> {
    return Object.assign(
      new DtoRequestCostsacctsGetResImple(),
      await CallAPI.getRequest(
        "/requestCostsaccts/" + dto.c_request_costsacct_id
      )
    );
  }

  public static async post(
    dto: DtoRequestCostsacctsPostReq
  ): Promise<DtoRequestCostsacctsPostRes> {
    return Object.assign(
      new DtoRequestCostsacctsPostRes(),
      await CallAPI.postRequest("/requestCostsaccts", dto)
    );
  }

  public static async put(
    dto: DtoRequestCostsacctsPutReq
  ): Promise<DtoRequestCostsacctsPutRes> {
    return Object.assign(
      new DtoRequestCostsacctsPutRes(),
      await CallAPI.putRequest(
        "/requestCostsaccts/" + dto.c_request_costsacct_id,
        dto
      )
    );
  }

  public static async del(
    dto: DtoRequestCostsacctsDeleteReq
  ): Promise<DtoRequestCostsacctsDeleteRes> {
    return Object.assign(
      new DtoRequestCostsacctsDeleteRes(),
      await CallAPI.deleteRequest(
        "/requestCostsaccts?" + Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
