import { DtoApiPager } from "./abst/DtoApiPager";
import { DtoReportsGetRes } from "./abst/DtoReportsGetRes";
import { EntityReportDet } from "../entity/EntityReportDet";
import { EntityProject } from "../entity/EntityProject";

export class DtoReportsGetResImple extends DtoReportsGetRes {
  public pager = new DtoApiPager();

  //詳細用
  //-----------------------
  public details: EntityReportDet[] = [];
  public project = new EntityProject();
  public reportNumberPrev: number | null = null;
  public reportNumberNext: number | null = null;

}
