import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoReport4detsGetReqImple } from "furukawa-common/src/common/dto/DtoReport4detsGetReqImple";
import { DtoReport4detsGetResImple } from "furukawa-common/src/common/dto/DtoReport4detsGetResImple";
import { DtoReport4detsPostReq } from "furukawa-common/src/common/dto/abst/DtoReport4detsPostReq";
import { DtoReport4detsPostRes } from "furukawa-common/src/common/dto/abst/DtoReport4detsPostRes";
import { DtoReport4detsPutReq } from "furukawa-common/src/common/dto/abst/DtoReport4detsPutReq";
import { DtoReport4detsPutRes } from "furukawa-common/src/common/dto/abst/DtoReport4detsPutRes";
import { DtoReport4detsDeleteReq } from "furukawa-common/src/common/dto/abst/DtoReport4detsDeleteReq";
import { DtoReport4detsDeleteRes } from "furukawa-common/src/common/dto/abst/DtoReport4detsDeleteRes";

export class Cl_report4det {
  public static async getList(
    dto: DtoReport4detsGetReqImple
  ): Promise<DtoReport4detsGetResImple> {
    return Object.assign(
      new DtoReport4detsGetResImple(),
      await CallAPI.getRequest(
        "/report4dets?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoReport4detsGetReqImple
  ): Promise<DtoReport4detsGetResImple> {
    return Object.assign(
      new DtoReport4detsGetResImple(),
      await CallAPI.getRequest("/report4dets/" + dto.c_report_id)
    );
  }

  public static async post(
    dto: DtoReport4detsPostReq
  ): Promise<DtoReport4detsPostRes> {
    return Object.assign(
      new DtoReport4detsPostRes(),
      await CallAPI.postRequest("/report4dets", dto)
    );
  }

  public static async put(
    dto: DtoReport4detsPutReq
  ): Promise<DtoReport4detsPutRes> {
    return Object.assign(
      new DtoReport4detsPutRes(),
      await CallAPI.putRequest("/report4dets/" + dto.c_report_id, dto)
    );
  }

  public static async del(
    dto: DtoReport4detsDeleteReq
  ): Promise<DtoReport4detsDeleteRes> {
    return Object.assign(
      new DtoReport4detsDeleteRes(),
      await CallAPI.deleteRequest(
        "/report4dets?" + Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
