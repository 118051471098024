import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cc0ea544"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  style: {"text-align":"center"},
  class: "GYONO cwS_1"
}
const _hoisted_2 = {
  style: {"text-align":"center"},
  class: "CLAIMNO cwS_2"
}
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "SALESMONTH cwS_3" }
const _hoisted_5 = {
  style: {"text-align":"left"},
  class: "COURSE cwS_4"
}
const _hoisted_6 = ["value"]
const _hoisted_7 = {
  style: {"text-align":"left"},
  class: "COURSE cwS_5"
}
const _hoisted_8 = ["value"]
const _hoisted_9 = {
  style: {"text-align":"center"},
  class: "BREAK_DOWN cwS_6"
}
const _hoisted_10 = ["value"]
const _hoisted_11 = { class: "SUPPLIER_CD cwS_7 selectBorderNone" }
const _hoisted_12 = {
  style: {"text-align":"left"},
  class: "SUM cwS_8"
}
const _hoisted_13 = {
  style: {"text-align":"center"},
  class: "SYOHIZEI_RITU cwS_9 selectBorderNone"
}
const _hoisted_14 = ["value"]
const _hoisted_15 = {
  style: {"text-align":"center","position":"relative"},
  class: "PAYMENT_FLG cwS_9-2 tdDisables"
}
const _hoisted_16 = ["checked"]
const _hoisted_17 = { class: "PAYMENT_YOTEI_DATE cwS_10" }
const _hoisted_18 = {
  style: {"text-align":"center"},
  class: "PAYMENT_FLG cwS_11"
}
const _hoisted_19 = ["checked", "disabled"]
const _hoisted_20 = { class: "BIKO cwS_12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Mt_dtp4month = _resolveComponent("Mt_dtp4month")!
  const _component_vSelect = _resolveComponent("vSelect")!
  const _component_Mt_dtp = _resolveComponent("Mt_dtp")!

  return (_openBlock(), _createElementBlock("tr", {
    class: _normalizeClass(["supplierrow", { selectedRow: _ctx.item.isSelect }]),
    onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.selectRow())),
    onFocusout: _cache[18] || (_cache[18] = ($event: any) => (_ctx.calcProfit()))
  }, [
    _createElementVNode("td", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        readonly: "true",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.item.c_supply_rownum) = $event))
      }, null, 512), [
        [_vModelText, _ctx.item.c_supply_rownum]
      ])
    ]),
    _createElementVNode("td", _hoisted_2, [
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.item.cnvClaimnumTmp) = $event))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.claims, (option) => {
          return (_openBlock(), _createElementBlock("option", {
            value: option.c_claim_number,
            key: option.c_claim_number!
          }, _toDisplayString(option.c_claim_number), 9, _hoisted_3))
        }), 128))
      ], 512), [
        [_vModelSelect, _ctx.item.cnvClaimnumTmp]
      ])
    ]),
    _createElementVNode("td", _hoisted_4, [
      _createVNode(_component_Mt_dtp4month, {
        contdate: _ctx.item.cnvDateMonth,
        "onUpdate:contdate": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.item.cnvDateMonth) = $event)),
        class: "dtpJyutyuBulk jyutyuTable"
      }, null, 8, ["contdate"])
    ]),
    _createElementVNode("td", _hoisted_5, [
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.item.c_supply_item) = $event)),
        onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.cngItem()))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.opItemtypeItem, (option) => {
          return (_openBlock(), _createElementBlock("option", {
            value: option.code,
            key: option.code
          }, _toDisplayString(option.label), 9, _hoisted_6))
        }), 128))
      ], 544), [
        [_vModelSelect, _ctx.item.c_supply_item]
      ])
    ]),
    _createElementVNode("td", _hoisted_7, [
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.item.c_supply_subject) = $event))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getOpItemtypeSubjects(), (option) => {
          return (_openBlock(), _createElementBlock("option", {
            value: option.code,
            key: option.code
          }, _toDisplayString(option.label), 9, _hoisted_8))
        }), 128))
      ], 512), [
        [_vModelSelect, _ctx.item.c_supply_subject]
      ])
    ]),
    _createElementVNode("td", _hoisted_9, [
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.item.c_supply_breakdown) = $event))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getOpItemtypeBreakdown(), (option) => {
          return (_openBlock(), _createElementBlock("option", {
            value: option.code,
            key: option.code
          }, _toDisplayString(option.label), 9, _hoisted_10))
        }), 128))
      ], 512), [
        [_vModelSelect, _ctx.item.c_supply_breakdown]
      ])
    ]),
    _createElementVNode("td", _hoisted_11, [
      _createVNode(_component_vSelect, {
        options: _ctx.opSuppliers,
        modelValue: _ctx.item.c_supplier_id,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.item.c_supplier_id) = $event)),
        label: "c_supplier_name",
        reduce: (label) => label.c_supplier_id
      }, null, 8, ["options", "modelValue", "reduce"])
    ]),
    _createElementVNode("td", _hoisted_12, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        maxlength: "10",
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.item.cnvPrice) = $event)),
        onFocus: _cache[9] || (_cache[9] = ($event: any) => (_ctx.kanmaDel($event))),
        onBlur: _cache[10] || (_cache[10] = ($event: any) => (_ctx.kanmaIns($event)))
      }, null, 544), [
        [_vModelText, _ctx.item.cnvPrice]
      ])
    ]),
    _createElementVNode("td", _hoisted_13, [
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.item.c_supply_taxrate) = $event))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.opTaxtate, (option) => {
          return (_openBlock(), _createElementBlock("option", {
            value: option.code,
            key: option.code
          }, _toDisplayString(option.label), 9, _hoisted_14))
        }), 128))
      ], 512), [
        [_vModelSelect, _ctx.item.c_supply_taxrate]
      ])
    ]),
    _createElementVNode("td", _hoisted_15, [
      _createElementVNode("input", {
        type: "checkbox",
        style: {"height":"auto","width":"auto"},
        checked: (_ctx.item.c_supply_flag_chkclaim as any),
        onChange: _cache[12] || (_cache[12] = ($event: any) => (_ctx.item.c_supply_flag_chkclaim = _ctx.cnvCb($event)))
      }, null, 40, _hoisted_16)
    ]),
    _createElementVNode("td", _hoisted_17, [
      _createVNode(_component_Mt_dtp, {
        contdate: _ctx.item.cnvDateExpPayment,
        "onUpdate:contdate": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.item.cnvDateExpPayment) = $event)),
        holidays: _ctx.mastersData,
        "onUpdate:holidays": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.mastersData) = $event)),
        class: "dtpJyutyuBulk jyutyuTable"
      }, null, 8, ["contdate", "holidays"])
    ]),
    _createElementVNode("td", _hoisted_18, [
      _createElementVNode("input", {
        type: "checkbox",
        style: {"height":"auto","width":"auto"},
        checked: (_ctx.item.c_supply_flag_paid as any),
        disabled: !_ctx.authPayCheck.includes(_ctx.$store.state.id),
        onChange: _cache[15] || (_cache[15] = ($event: any) => (_ctx.item.c_supply_flag_paid = _ctx.cnvCb($event)))
      }, null, 40, _hoisted_19)
    ]),
    _createElementVNode("td", _hoisted_20, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.item.c_supply_remarks) = $event))
      }, null, 512), [
        [_vModelText, _ctx.item.c_supply_remarks]
      ])
    ])
  ], 34))
}