import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { DtoClaimsGetReq } from "./abst/DtoClaimsGetReq";
import { DtoApiPager } from "./abst/DtoApiPager";

export class DtoClaimNumsGetReq {
  //案件ID
  public projectid: number | null = null;

  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------

    return res;
  }
}
