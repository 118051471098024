import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoSimulationsGetReqImple } from "furukawa-common/src/common/dto/DtoSimulationsGetReqImple";
import { DtoSimulationsGetResImple } from "furukawa-common/src/common/dto/DtoSimulationsGetResImple";
import { DtoSimulationsPostReq } from "furukawa-common/src/common/dto/abst/DtoSimulationsPostReq";
import { DtoSimulationsPostRes } from "furukawa-common/src/common/dto/abst/DtoSimulationsPostRes";
import { DtoSimulationsPutReq } from "furukawa-common/src/common/dto/abst/DtoSimulationsPutReq";
import { DtoSimulationsPutRes } from "furukawa-common/src/common/dto/abst/DtoSimulationsPutRes";
import { DtoSimulationsDeleteReq } from "furukawa-common/src/common/dto/abst/DtoSimulationsDeleteReq";
import { DtoSimulationsDeleteRes } from "furukawa-common/src/common/dto/abst/DtoSimulationsDeleteRes";

export class Cl_simulation {
  public static async getList(
    dto: DtoSimulationsGetReqImple
  ): Promise<DtoSimulationsGetResImple> {
    return Object.assign(
      new DtoSimulationsGetResImple(),
      await CallAPI.getRequest(
        "/simulations?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoSimulationsGetReqImple
  ): Promise<DtoSimulationsGetResImple> {
    return Object.assign(
      new DtoSimulationsGetResImple(),
      await CallAPI.getRequest("/simulations/" + dto.c_claim_id)
    );
  }

  public static async post(
    dto: DtoSimulationsPostReq
  ): Promise<DtoSimulationsPostRes> {
    return Object.assign(
      new DtoSimulationsPostRes(),
      await CallAPI.postRequest("/simulations", dto)
    );
  }

  public static async put(
    dto: DtoSimulationsPutReq
  ): Promise<DtoSimulationsPutRes> {
    return Object.assign(
      new DtoSimulationsPutRes(),
      await CallAPI.putRequest("/simulations/" + dto.c_claim_id, dto)
    );
  }

  public static async del(
    dto: DtoSimulationsDeleteReq
  ): Promise<DtoSimulationsDeleteRes> {
    return Object.assign(
      new DtoSimulationsDeleteRes(),
      await CallAPI.deleteRequest(
        "/simulations?" + Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
