import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntityClaim } from "../../entity/EntityClaim";
import { EntityClaimDet } from "../../entity/EntityClaimDet";

export class DtoClaimsPutReq extends EntityClaim {
  //承認済みの請求書を、未承認状態に戻す際に使用する。
  //1を入力すると、通常のPUTとは別処理になり、ステータスの操作のみを行うので注意。
  //-----------------------
  public isRewite: number | null = null;

  //承認・差戻用
  //-----------------------
  //1で承認、0で差戻。nullで通常のput処理。
  public isApproval: number | null = null;

  //詳細用
  //-----------------------
  public details: EntityClaimDet[] = [];

  /**
   * 対象案件の案件番号
   */
  public projectNum: number | null = null;

  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------
    if (
      !commonRegx.isMatch(
        this.c_claim_id!.toString(),
        "^\\d+$",
        commonRegx._stdOption
      )
    ) {
      res.push("type error");
    }

    return res;
  }
}
