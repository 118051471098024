import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import { State } from "vue";
import Store from "@/store/index";

export default createStore({
  state: {
    id: -99, //id
    name: "", //氏名
    authType: -99, //権限
    flagAuthListexprt: -99, //請求NO変更権限フラグ
    flagAuthReport: -99, //一覧画面エクセル出力フラグ
    username: "", //Cognitoのユーザー名
    token_id: "",
    exp_token: -99,
    token_upd: "",
  },
  getters: {},
  mutations: {
    /**
     * 初期化用
     * @param state
     */
    clearStore(state) {
      state.id = -99;
      state.name = "";
      state.authType = -99;
      state.flagAuthListexprt = -99;
      state.flagAuthReport = -99;
      state.username = "";
      state.token_id = "";
      state.exp_token = -99;
      state.token_upd = "";
    },
    /**
     * ローカルストレージからStoreへのデータ取得用(ページ遷移時に呼び出されている)
     * @param state
     */
    revStore(state) {
      let res = localStorage.getItem("furukawa");

      if (res === null) {
        //データなし
        res = "{}";
        Store.commit("clearStore");
      } else {
        const data: State = JSON.parse(res);
        state = data;
      }
    },
    //--------------------------------
    //個別更新用
    //--------------------------------
    updName(state, payload) {
      state.name = payload;
    },
    updAuthType(state, payload) {
      state.authType = payload;
    },
    updFlagAuthListexprt(state, payload) {
      state.flagAuthListexprt = payload;
    },
    updFlagAuthReport(state, payload) {
      state.flagAuthReport = payload;
    },
    updId(state, payload) {
      state.id = payload;
    },
    updUsername(state, payload) {
      state.username = payload;
    },
    updToken_id(state, payload) {
      state.token_id = payload;
    },
    updExp_token(state, payload) {
      state.exp_token = payload;
    },
    updToken_upd(state, payload) {
      state.token_upd = payload;
    },
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedState({
      key: "furukawa",
      storage: window.localStorage,
    }),
  ],
});
