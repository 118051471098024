import { EntityBank } from "../entity/EntityBank";
import { DtoApiPager } from "./abst/DtoApiPager";
import { DtoBanksGetRes } from "./abst/DtoBanksGetRes";

export class DtoBanksGetResImple extends DtoBanksGetRes {
  public pager = new DtoApiPager();

  public exitems: Exitem[] = [];

  //メソッド
  //-----------------------
  public async setEximtes(Branchmap: Map<number, string>) {
    let tmp: Exitem;
    for (let index = 0; index < this.items.length; index++) {
      tmp = new Exitem();

      tmp.c_bank_id = this.items[index].c_bank_id;
      tmp.c_bank_name = this.items[index].c_bank_name;
      tmp.c_bank_branch_name = this.items[index].c_bank_branch_name;
      tmp.c_bank_type = this.items[index].c_bank_type;
      tmp.c_bank_number = this.items[index].c_bank_number;
      tmp.c_branch_name = Branchmap.get(this.items[index].c_branch_id!)!;     

      this.exitems.push(tmp);
    }
  }
}

export class Exitem extends EntityBank {
  public c_branch_name: string | null = null;
}
