import { DtoMdDtp } from "../dto/DtoMdDtp";

export class EntityClaimImple {
  public c_claim_id: number | null = null;
  public c_claim_number: number | null = null;
  public c_claim_status: number | null = null;
  public c_project_number: number | null = null;
  public c_branch_name: string | null = null;
  public c_claim_datetime: Date | null = null;
  public c_client_name: string | null = null;
  public c_claim_subtotal: number | null = null;
  public c_staff_name: string | null = null;
  public c_claim_paylimit: Date | null = null;
  public c_claim_depo_datetime: DtoMdDtp = new DtoMdDtp();
  public c_claim_flag_sales: number | null = null;
  public c_claim_date_exp_payment: Date | null = null;
  public c_claim_total: number | null = null;
  public c_claim_title_1: string | null = null;
}
