import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Store from "@/store/index";
//-----------------管理用
import Login from "../views/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import Error from "../views/Error.vue";
//-----------------売上管理
import ProjectInput from "../views/ProjectInput.vue";
import ProjectSearch from "../views/ProjectSearch.vue";
import SalesSearch from "../views/SalesSearch.vue";
import SupplierSearch from "../views/SupplierSearch.vue";
import ProjectMigration from "../views/ProjectMigration.vue";
import InvoiceSearch from "../views/InvoiceSearch.vue";
import InvoiceNumberAssignment from "../views/InvoiceNumberAssignment.vue";
import DataLock from "../views/DataLock.vue";
//-----------------帳票作成
import InvoiceCreationForm from "../views/InvoiceCreationForm.vue";
import InvoiceCreationFormNs from "../views/InvoiceCreationFormNs.vue";
import FinalABCList from "../views/FinalABCList.vue";
import SalesForecastNonConsolidated from "../views/SalesForecastNonConsolidated.vue";
import SalesByCustomer from "../views/SalesByCustomer.vue";
//-----------------営業管理
import DailyReportList from "../views/DailyReportList.vue";
import DailyReportEdit from "../views/DailyReportEdit.vue";
import DailyReportSearch from "../views/DailyReportSearch.vue";
import DailyReportSearchIdeas from "../views/DailyReportSearchIdeas.vue";
//-----------------マスタ情報管理
import SalesDestinationList from "../views/SalesDestinationList.vue";
import SupplierList from "../views/SupplierList.vue";
import EmployeeList from "../views/EmployeeList.vue";
import BankAccountList from "../views/BankAccountList.vue";
import BulletinBoardInfomationList from "../views/BulletinBoardInfomationList.vue";
import BaseGoalList from "../views/BaseGoalList.vue";
import PersonalGoalList from "../views/PersonalGoalList.vue";
import SponsorList from "../views/SponsorList.vue";
import HolidayList from "../views/HolidayList.vue";
//-----------------マスタ情報編集
import SalesDestinationEdit from "../views/SalesDestinationEdit.vue";
import SupplierEdit from "../views/SupplierEdit.vue";
import EmployeeEdit from "../views/EmployeeEdit.vue";
import BankAccountEdit from "../views/BankAccountEdit.vue";
import BulletinBoardInfomationEdit from "../views/BulletinBoardInfomationEdit.vue";
import PersonalGoalEdit from "../views/PersonalGoalEdit.vue";
import SponsorEdit from "../views/SponsorEdit.vue";
import HolidayEdit from "../views/HolidayEdit.vue";
//-----------------申請書管理
import Shinseisyolist from "../views/Shinseisyolist.vue";
import shinsei_Karibaraiedit from "../views/shinsei_Karibaraiedit.vue";
import shinsei_Shutchoedit from "../views/shinsei_Shutchoedit.vue";
import shinsei_Settaiedit from "../views/shinsei_Settaiedit.vue";
import shinsei_Kyushokunegaiedit from "../views/shinsei_Kyushokunegaiedit.vue";
import shinsei_Shayoshashiyoedit from "../views/shinsei_Shayoshashiyoedit.vue";
import shinsei_Keihiseisanedit from "../views/shinsei_Keihiseisanedit.vue";
import shinsei_Kyuyoshoyokozatorokuedit from "../views/shinsei_Kyuyoshoyokozatorokuedit.vue";
import shinsei_Jushohenkotsukinteateedit from "../views/shinsei_Jushohenkotsukinteateedit.vue";
import shinsei_Keihikozatorokuedit from "../views/shinsei_Keihikozatorokuedit.vue";
import shinsei_Ringishoedit from "../views/shinsei_Ringishoedit.vue";
import shinsei_Zangyoedit from "../views/shinsei_Zangyoedit.vue";
import shinsei_Kyukatodokeedit from "../views/shinsei_Kyukatodokeedit.vue";
import shinsei_Remoteworkedit from "../views/shinsei_Remoteworkedit.vue";
//-----------------勤怠管理
import AttendanceList from "../views/AttendanceList.vue";
import AttendanceEdit from "../views/AttendanceEdit.vue";
//-----------------文書管理
import Doclist from "../views/Doclist.vue";
import DocEdit from "../views/DocEdit.vue";
//-----------------特殊操作
import OperationHistoryList from "../views/OperationHistoryList.vue";
import MakeNextYearData from "../views/MakeNextYearData.vue";
//-----------------情報分析
// import Dataportal from "../views/Dataportal.vue";
// import Analyprofit from "../views/Analyprofit.vue";
//-----------------その他
import Empty from "../views/Empty.vue";
import Salert from "../views/Salert.vue";
import Toaster from "../views/Toaster.vue";
import Loader from "../views/Loader.vue";
import Alerts from "../views/Alerts.vue";
import Modal from "../views/Modal.vue";
import Analytics_dashboard from "../views/Analytics_dashboard.vue";
//-----------------新機能用モック
// import profit_Shiiresakiedit from "../views/profit_Shiiresakiedit.vue";
// import profit_Uriagesakiedit from "../views/profit_Uriagesakiedit.vue";
// import profit_itemlist from "../views/profit_itemlist.vue";
// import Itemedit4billbord from "../views/Itemedit4billbord.vue";
// import Itemedit4posterjack from "../views/Itemedit4posterjack.vue";
// import Itemedit4commercialfacility from "../views/Itemedit4commercialfacility.vue";
// import Itemedit4digitalbillbord from "../views/Itemedit4digitalbillbord.vue";
// import Itemedit4koutu from "../views/Itemedit4koutu.vue";
// import Itemedit4exmedia from "../views/Itemedit4exmedia.vue";
// import Itemedit4poster from "../views/Itemedit4poster.vue";
// import Itemedit4sonota from "../views/Itemedit4sonota.vue";
import { Cl_common } from "@/functions/Cl_common";

const routes: Array<RouteRecordRaw> = [
  //-----------------管理用

  {
    path: "/",
    name: "Login",
    component: Login,
    meta: { title: "MEDIA DEPT | ログイン", desc: "ログイン", isPublic: true },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/:catchAll(.*)",
    name: "Error",
    component: Error,
  },

  //-----------------売上管理
  {
    path: "/projectInput",
    name: "ProjectInput",
    component: ProjectInput,
  },
  {
    path: "/projectSearch",
    name: "ProjectSearch",
    component: ProjectSearch,
  },
  {
    path: "/salesSearch",
    name: "SalesSearch",
    component: SalesSearch,
  },
  {
    path: "/supplierSearch",
    name: "SupplierSearch",
    component: SupplierSearch,
  },
  {
    path: "/projectMigration",
    name: "ProjectMigration",
    component: ProjectMigration,
  },
  {
    path: "/invoiceSearch",
    name: "InvoiceSearch",
    component: InvoiceSearch,
  },
  {
    path: "/invoiceNumberAssignment",
    name: "InvoiceNumberAssignment",
    component: InvoiceNumberAssignment,
  },
  {
    path: "/dataLock",
    name: "DataLock",
    component: DataLock,
  },

  //-----------------帳票作成
  {
    path: "/invoiceCreationForm",
    name: "InvoiceCreationForm",
    component: InvoiceCreationForm,
  },
  {
    path: "/invoiceCreationFormNs",
    name: "InvoiceCreationFormNs",
    component: InvoiceCreationFormNs,
  },
  {
    path: "/finalABCList",
    name: "FinalABCList",
    component: FinalABCList,
  },
  {
    path: "/salesForecastNonConsolidated",
    name: "SalesForecastNonConsolidated",
    component: SalesForecastNonConsolidated,
  },
  {
    path: "/salesByCustomer",
    name: "SalesByCustomer",
    component: SalesByCustomer,
  },
  //-----------------営業管理
  {
    path: "/dailyReportList",
    name: "DailyReportList",
    component: DailyReportList,
  },
  {
    path: "/dailyReportEdit",
    name: "DailyReportEdit",
    component: DailyReportEdit,
  },
  {
    path: "/dailyReportSearch",
    name: "DailyReportSearch",
    component: DailyReportSearch,
  },
  {
    path: "/dailyReportSearchIdeas",
    name: "DailyReportSearchIdeas",
    component: DailyReportSearchIdeas,
  },

  //-----------------マスタ情報管理
  {
    path: "/salesDestinationList",
    name: "SalesDestinationList",
    component: SalesDestinationList,
  },
  {
    path: "/supplierList",
    name: "SupplierList",
    component: SupplierList,
  },
  {
    path: "/employeeList",
    name: "EmployeeList",
    component: EmployeeList,
  },
  {
    path: "/bankAccountList",
    name: "BankAccountList",
    component: BankAccountList,
  },
  {
    path: "/bulletinBoardInfomationList",
    name: "BulletinBoardInfomationList",
    component: BulletinBoardInfomationList,
  },
  {
    path: "/baseGoalList",
    name: "BaseGoalList",
    component: BaseGoalList,
  },
  {
    path: "/personalGoalList",
    name: "PersonalGoalList",
    component: PersonalGoalList,
  },
  {
    path: "/sponsorList",
    name: "SponsorList",
    component: SponsorList,
  },
  {
    path: "/holidayList",
    name: "HolidayList",
    component: HolidayList,
  },
  //-----------------マスタ編集
  {
    path: "/salesDestinationEdit",
    name: "SalesDestinationEdit",
    component: SalesDestinationEdit,
  },
  {
    path: "/supplierEdit",
    name: "SupplierEdit",
    component: SupplierEdit,
  },
  {
    path: "/employeeEdit",
    name: "EmployeeEdit",
    component: EmployeeEdit,
  },
  {
    path: "/bankAccountEdit",
    name: "BankAccountEdit",
    component: BankAccountEdit,
  },
  {
    path: "/bulletinBoardInfomationEdit",
    name: "BulletinBoardInfomationEdit",
    component: BulletinBoardInfomationEdit,
  },
  {
    path: "/personalGoalEdit",
    name: "PersonalGoalEdit",
    component: PersonalGoalEdit,
  },
  {
    path: "/sponsorEdit",
    name: "SponsorEdit",
    component: SponsorEdit,
  },
  {
    path: "/holidayEdit",
    name: "HolidayEdit",
    component: HolidayEdit,
  },
  //-----------------申請書管理
  {
    path: "/shinseisyolist",
    name: "Shinseisyolist",
    component: Shinseisyolist,
  },
  {
    path: "/shinsei_karibaraiedit",
    name: "shinsei_Karibaraiedit",
    component: shinsei_Karibaraiedit,
  },
  {
    path: "/shinsei_Shutchoedit",
    name: "shinsei_Shutchoedit",
    component: shinsei_Shutchoedit,
  },
  {
    path: "/shinsei_Settaiedit",
    name: "shinsei_Settaiedit",
    component: shinsei_Settaiedit,
  },
  {
    path: "/shinsei_Kyushokunegaiedit",
    name: "shinsei_Kyushokunegaiedit",
    component: shinsei_Kyushokunegaiedit,
  },
  {
    path: "/shinsei_Shayoshashiyoedit",
    name: "shinsei_Shayoshashiyoedit",
    component: shinsei_Shayoshashiyoedit,
  },
  {
    path: "/shinsei_Keihiseisanedit",
    name: "shinsei_Keihiseisanedit",
    component: shinsei_Keihiseisanedit,
  },
  {
    path: "/shinsei_Kyuyoshoyokozatorokuedit",
    name: "shinsei_Kyuyoshoyokozatorokuedit",
    component: shinsei_Kyuyoshoyokozatorokuedit,
  },
  {
    path: "/shinsei_Jushohenkotsukinteateedit",
    name: "shinsei_Jushohenkotsukinteateedit",
    component: shinsei_Jushohenkotsukinteateedit,
  },
  {
    path: "/shinsei_Keihikozatorokuedit",
    name: "shinsei_Keihikozatorokuedit",
    component: shinsei_Keihikozatorokuedit,
  },
  {
    path: "/shinsei_Ringishoedit",
    name: "shinsei_Ringishoedit",
    component: shinsei_Ringishoedit,
  },
  {
    path: "/shinsei_Zangyoedit",
    name: "shinsei_Zangyoedit",
    component: shinsei_Zangyoedit,
  },
  {
    path: "/shinsei_Kyukatodokeedit",
    name: "shinsei_Kyukatodokeedit",
    component: shinsei_Kyukatodokeedit,
  },
  {
    path: "/shinsei_Remoteworkedit",
    name: "shinsei_Remoteworkedit",
    component: shinsei_Remoteworkedit,
  },
  //-----------------勤怠管理
  {
    path: "/attendanceList",
    name: "AttendanceList",
    component: AttendanceList,
  },
  {
    path: "/attendanceEdit",
    name: "AttendanceEdit",
    component: AttendanceEdit,
  },
  //-----------------文書管理
  {
    path: "/doclist",
    name: "Doclist",
    component: Doclist,
  },
  {
    path: "/docedit",
    name: "DocEdit",
    component: DocEdit,
  },
  //-----------------特殊操作
  {
    path: "/operationHistoryList",
    name: "OperationHistoryList",
    component: OperationHistoryList,
  },
  {
    path: "/makeNextYearData",
    name: "MakeNextYearData",
    component: MakeNextYearData,
  },

  //-----------------その他
  {
    path: "/empty",
    name: "Empty",
    component: Empty,
  },
  {
    path: "/toaster",
    name: "Toaster",
    component: Toaster,
  },
  {
    path: "/salert",
    name: "Salert",
    component: Salert,
  },
  {
    path: "/loader",
    name: "Loader",
    component: Loader,
  },
  {
    path: "/alerts",
    name: "Alerts",
    component: Alerts,
  },
  {
    path: "/modal",
    name: "Modal",
    component: Modal,
  },
  {
    path: "/analytics_dashboard",
    name: "Analytics_dashboard",
    component: Analytics_dashboard,
  },
  //-----------------新機能用モック
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  Store.commit("revStore");
  if (to.matched.some((page) => page.meta.isPublic) || Store.state.token_id) {
    //activeクラスの付け替え
    //-------------
    if (document != null) {
      try {
        const parent = document.getElementById("js-nav-menu");
        if (parent != null) {
          const elements = parent.getElementsByTagName("li");
          Array.prototype.forEach.call(elements, function (element) {
            if (!element.classList.contains("open")) {
              element.classList.remove("active");
            }
          });

          // console.log(parent.querySelector("a[href='" + to.path + "']"));
          //2.自身のactivを付ける
          if (
            to.path != "/dashboard" &&
            parent.querySelector("a[href='" + to.path + "']") != null
          ) {
            parent
              .querySelector("a[href='" + to.path + "']")!
              .closest("li")!
              .classList.add("active");
          }
        }
      } catch (error) {
        Cl_common.logger(error);
        //画面遷移に問題が出るので、エラーは無視する
      }
    }

    //-------------
    next();
  } else {
    next("/");
  }
});

export default router;
