export class DtoClaimNumsPostReq {
  /**
   * 発行する請求書Noのタイプ
   * 1:売上あり;2:売上なし
   */
  public isSales: number | null = null;

  /**
   * 発行する請求書の案件id。
   * 売上ありの場合は必須。
   */
  public projectid: number | null = null;
  public IsValid(): string[] {
    let res: string[] = new Array();

    return res;
  }
}
