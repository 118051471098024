/**
 * 古川広告社プロジェクト内に関する日付の処理を担当する
 */
export class CommonDate4FU {
  /**
   * 開始日（年月日まで）からバリエーションで指定されてい期間分、オフセットした日付を返す。
   * 期間が入力されていない場合、nullを返す。
   * @param entityClaim
   * @param authData
   * @returns
   */
  // public static async offsetDate4VariationTerm(
  //   startDate: Date,
  //   variation: EntityVariationImple
  // ): Promise<Date | null> {
  //   //入力チェック
  //   //---------------
  //   if (
  //     variation.c_variation_term == null ||
  //     variation.c_variation_term_type == null ||
  //     !this.isInteger(variation.c_variation_term) ||
  //     !this.isInteger(variation.c_variation_term_type) ||
  //     variation.c_variation_term <= 0 ||
  //     variation.c_variation_term_type <= 0
  //   ) {
  //     return null;
  //   }
  //   //オフセット
  //   //---------------
  //   let res = startDate;
  //   switch (variation.c_variation_term_type) {
  //     case 1:
  //       //1:日間
  //       res.setDate(res.getDate() + variation.c_variation_term!);
  //       break;
  //     case 2:
  //       //2:週間
  //       res.setDate(res.getDate() + 7 * variation.c_variation_term!);
  //       break;
  //     case 3:
  //       //3:ヵ月
  //       res.setMonth(res.getMonth() + variation.c_variation_term!);
  //       break;
  //     case 4:
  //       //4:年間
  //       res.setFullYear(res.getFullYear() + variation.c_variation_term!);
  //       break;
  //     default:
  //       break;
  //   }

  //   return res;
  // }

  /**
   * 基準日から売上先、支払先マスタで指定される支払いサイト分をオフセットした日付を返す。
   * paysiteが未設定か都度確認の場合はnullを返す
   * @param baseDate
   * @param paysite
   */
  public static async offsetDate4PaySite(
    baseDate: Date,
    paysite: number | null
  ): Promise<Date | null> {
    if (paysite == null || paysite == 1) {
      return null;
    }

    let res = baseDate;

    switch (paysite) {
      case 2:
        //2:当月末;
        res = await this.getLastDayOfMonth(baseDate);
        break;
      case 3:
        //3:翌末;
        res = await this.getLastDayOfNextMonth(baseDate);
        break;
      case 4:
        //4:翌翌末;
        res = await this.getLastDayOfNextNextMonth(baseDate);
        break;
      case 5:
        //5:翌々10日;
        res = await this.getNextNextMonth10th(baseDate);
        break;
      default:
        break;
    }

    return res;
  }

  /**
   * 整数かどうかをbool型で返す。
   * @param value
   * @returns
   */
  public static isInteger(value: any): boolean {
    return typeof value === "number" && value % 1 === 0;
  }

  /**
   * 入力した日付の当月末日を返す
   * @param date
   * @returns
   */
  public static async getLastDayOfMonth(date: Date): Promise<Date> {
    // Get the year and month from the input date
    const year = date.getFullYear();
    const month = date.getMonth();

    // Create a new date object for the first day of the next month
    const firstDayOfNextMonth = new Date(year, month + 1, 1);

    // Subtract one day from the first day of the next month to get the last day of the current month
    const lastDayOfMonth = new Date(firstDayOfNextMonth.getTime() - 1);

    return lastDayOfMonth;
  }

  /**
   * 入力した日付の翌月末日を返す
   * @param date
   * @returns
   */
  public static async getLastDayOfNextMonth(date: Date): Promise<Date> {
    // Get the year and month from the input date
    const year = date.getFullYear();
    const month = date.getMonth();

    // Create a new date object for the first day of the month after next
    const firstDayOfNextMonth = new Date(year, month + 2, 1);

    // Subtract one day from the first day of the month after next to get the last day of next month
    const lastDayOfNextMonth = new Date(firstDayOfNextMonth.getTime() - 1);

    return lastDayOfNextMonth;
  }

  /**
   * 入力した日付の翌々月末日を返す
   * @param date
   * @returns
   */
  public static async getLastDayOfNextNextMonth(date: Date): Promise<Date> {
    // Get the year and month from the input date
    const year = date.getFullYear();
    const month = date.getMonth();

    // Create a new date object for the first day of the month after next next
    const firstDayOfNextNextMonth = new Date(year, month + 3, 1);

    // Subtract one day from the first day of the month after next next to get the last day of next next month
    const lastDayOfNextNextMonth = new Date(
      firstDayOfNextNextMonth.getTime() - 1
    );

    return lastDayOfNextNextMonth;
  }

  /**
   * 入力した日付の翌々月10日を返す
   * @param date
   * @returns
   */
  public static async getNextNextMonth10th(date: Date): Promise<Date> {
    // Get the year and month from the input date
    const year = date.getFullYear();
    const month = date.getMonth();

    // Create a new date object for the 10th day of the month after next next
    const nextNextMonth10th = new Date(year, month + 3, 10);

    return nextNextMonth10th;
  }

  /**
   * 2つのHH:mm形式の文字列を受け取り、時間としての単純検証と、
   * 開始 < 終了である事の複合検証を行う。
   * 時間は、MD様の基準で考え、00:00～04:59までは、翌日として取り扱う。
   * 開始=終了はfalseなので注意。
   */
  public static async validateTimes(
    startTimeTmp: string | null,
    endTimeTmp: string | null
  ): Promise<boolean> {
    if (startTimeTmp == null || endTimeTmp == null) {
      return false;
    }

    const toDate = (s: string) => new Date(`1970-01-01T${s}Z`);
    let startTime = toDate(startTimeTmp).getTime();
    let endTime = toDate(endTimeTmp).getTime();

    //00:00:00から4:59:59の間であれば、次の日補正
    if (startTime < 5 * 60 * 60 * 1000) {
      startTime += 24 * 60 * 60 * 1000; // add 24 hours
    }
    if (endTime < 5 * 60 * 60 * 1000) {
      endTime += 24 * 60 * 60 * 1000; // add 24 hours
    }

    let workTime = endTime - startTime;

    if (workTime <= 0) {
      return false;
    }

    return true;
  }

  /**
   * UTCのDateを受け取り、JSTにして返す。
   * Date型はTimeZoneを認識しないので、単純に時差を調整するだけ。
   * @param date
   * @returns
   */
  public static cnvUTC2JST(date: Date): Date {
    let res = date;
    res.setHours(res.getHours() + 9);
    return res;
  }

  /**
   * 日付を受け取り、期を返す。
   * @param date
   */
  public static cnvDate2Businessperiod(date: Date): number {
    // 基準となる開始日時を設定
    const startDate = new Date("2023-10-01T00:00:00");
    // 基準の数値を設定
    const baseValue = 63;

    // 基準日からの経過年数を計算する
    const yearDiff = date.getFullYear() - startDate.getFullYear();
    // 月や日による影響も考慮して実際の年差を調整
    const monthDiff = date.getMonth() - startDate.getMonth();
    const dayDiff = date.getDate() - startDate.getDate();

    // 月または日が基準日未満であれば、1年分減算
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      return baseValue + yearDiff - 1;
    } else {
      return baseValue + yearDiff;
    }
  }
  /**
   * 期を受け取り、期の開始日時と終了日時を返す。
   * @param date
   */
  public static cnvBusinessperiod2Date(businessperiod: number): Date[] {
    // 基準となる開始日時（JSTで直接指定）
    const baseDateJST = "2023-10-01T00:00:00+09:00";
    // 基準の数値
    const baseValue = 63;

    // 入力された数値から年数を計算
    const yearOffset = businessperiod - baseValue;
    // 基準日（JSTで計算）
    const startDate = new Date(
      new Date(baseDateJST).setFullYear(
        new Date(baseDateJST).getFullYear() + yearOffset
      )
    );
    // 終了日（JSTで計算）
    const endDate = new Date(
      new Date(startDate).setFullYear(startDate.getFullYear() + 1)
    );
    endDate.setSeconds(endDate.getSeconds() - 1);

    // JSTのオフセットを加えて返す
    const jstOffset = 9 * 60 * 60 * 1000; // 9時間をミリ秒で計算
    const adjustedStartDate = new Date(startDate.getTime() + jstOffset);
    const adjustedEndDate = new Date(endDate.getTime() + jstOffset);

    return [adjustedStartDate, adjustedEndDate];
  }
}
