import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoBranchsGetReqImple } from "furukawa-common/src/common/dto/DtoBranchsGetReqImple";
import { DtoBranchGoalsGetRes } from "furukawa-common/src/common/dto/DtoBranchGoalsGetRes";
import { DtoGoalsPostReq } from "furukawa-common/src/common/dto/abst/DtoGoalsPostReq";
import { DtoGoalsPostRes } from "furukawa-common/src/common/dto/abst/DtoGoalsPostRes";
import { DtoGoalsPutReq } from "furukawa-common/src/common/dto/abst/DtoGoalsPutReq";
import { DtoGoalsPutRes } from "furukawa-common/src/common/dto/abst/DtoGoalsPutRes";
import { DtoGoalsDeleteReq } from "furukawa-common/src/common/dto/abst/DtoGoalsDeleteReq";
import { DtoGoalsDeleteRes } from "furukawa-common/src/common/dto/abst/DtoGoalsDeleteRes";

export class Cl_branchGoal {
  public static async getList(
    dto: DtoBranchsGetReqImple
  ): Promise<DtoBranchGoalsGetRes> {
    return Object.assign(
      new DtoBranchGoalsGetRes(),
      await CallAPI.getRequest(
        "/branchGoals?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
