
import { Options, Vue } from "vue-class-component";
import { isProdEnv } from "furukawa-common/src/common/constant/Constants";

@Options({})
export default class Tm_footer extends Vue {
  isProd(): boolean {
    return isProdEnv();
  }
}
