
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_modal_alert,
  },
})
export default class DailyReportList extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  btnDisable = false;

  items4staffs = [
    { label: "社員１", code: 1 },
    { label: "社員２", code: 2 },
    { label: "社員３", code: 3 },
    { label: "社員４", code: 4 },
  ];
  columns = [
    {
      label: "対象日",
      field: "report_date",
    },
    {
      label: "記載者",
      field: "report_staff",
    },
  ];
}
