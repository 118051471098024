import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoRequestExpensessGetReqImple } from "furukawa-common/src/common/dto/DtoRequestExpensessGetReqImple";
import { DtoRequestExpensessGetResImple } from "furukawa-common/src/common/dto/DtoRequestExpensessGetResImple";
import { DtoRequestExpensessPostReq } from "furukawa-common/src/common/dto/abst/DtoRequestExpensessPostReq";
import { DtoRequestExpensessPostRes } from "furukawa-common/src/common/dto/abst/DtoRequestExpensessPostRes";
import { DtoRequestExpensessPutReq } from "furukawa-common/src/common/dto/abst/DtoRequestExpensessPutReq";
import { DtoRequestExpensessPutRes } from "furukawa-common/src/common/dto/abst/DtoRequestExpensessPutRes";
import { DtoRequestExpensessDeleteReq } from "furukawa-common/src/common/dto/abst/DtoRequestExpensessDeleteReq";
import { DtoRequestExpensessDeleteRes } from "furukawa-common/src/common/dto/abst/DtoRequestExpensessDeleteRes";

export class Cl_requestexpenses {
  public static async getList(
    dto: DtoRequestExpensessGetReqImple
  ): Promise<DtoRequestExpensessGetResImple> {
    return Object.assign(
      new DtoRequestExpensessGetResImple(),
      await CallAPI.getRequest(
        "/requestExpensess?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoRequestExpensessGetReqImple
  ): Promise<DtoRequestExpensessGetResImple> {
    return Object.assign(
      new DtoRequestExpensessGetResImple(),
      await CallAPI.getRequest("/requestExpensess/" + dto.c_request_expenses_id)
    );
  }

  public static async post(
    dto: DtoRequestExpensessPostReq
  ): Promise<DtoRequestExpensessPostRes> {
    return Object.assign(
      new DtoRequestExpensessPostRes(),
      await CallAPI.postRequest("/requestExpensess", dto)
    );
  }

  public static async put(
    dto: DtoRequestExpensessPutReq
  ): Promise<DtoRequestExpensessPutRes> {
    return Object.assign(
      new DtoRequestExpensessPutRes(),
      await CallAPI.putRequest(
        "/requestExpensess/" + dto.c_request_expenses_id,
        dto
      )
    );
  }

  public static async del(
    dto: DtoRequestExpensessDeleteReq
  ): Promise<DtoRequestExpensessDeleteRes> {
    return Object.assign(
      new DtoRequestExpensessDeleteRes(),
      await CallAPI.deleteRequest(
        "/requestExpensess?" + Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
