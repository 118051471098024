
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import { DtoSuppliersGetResImple } from "furukawa-common/src/common/dto/DtoSuppliersGetResImple";
import {
  CONST_OP_4SIGNAGE_POSI,
  CONST_OP_COMPANY,
  CONST_OP_SUPPLIERTYPE,
  CONST_REPLACEMENT_MAP,
  // CONST_OP_PAYMENTSITE,
} from "furukawa-common/src/common/constant/Constants";
import { Cl_common } from "@/functions/Cl_common";
import { Cl_supplier } from "@/functions/Cl_supplier";
import { DtoSuppliersPutReq } from "furukawa-common/src/common/dto/abst/DtoSuppliersPutReq";
import { DtoSuppliersGetReqImple } from "furukawa-common/src/common/dto/DtoSuppliersGetReqImple";
import { DtoMastersGetRes } from "furukawa-common/src/common/dto/DtoMastersGetRes";
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersgetReq } from "furukawa-common/src/common/dto/DtoMastersGetReq";
import { Cl_Authorization_menu } from "@/constants/Cl_Authorization_menu";

// バリデーション
import * as Yup from "yup";

@Options({
  components: {
    vSelect,
    Mo_modal_alert,
  },
})
export default class SupplierEdit extends Vue {
  //----------------------
  //バリデーション
  //----------------------
  //スキーマ宣言
  schema = Yup.object().shape({
    supplierName: Yup.string().required("仕入先名を入力してください"),
    supplierNamePt: Yup.string().required("ふりがなを入力してください"),
    branchId: Yup.string().required("管轄拠点を選択してください"),
  });

  //エラー格納用
  errors: Yup.ValidationError | null = null;
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoSuppliersGetResImple();
  delegate4list = new DtoMastersGetRes();
  showFlags = new Cl_Authorization_menu();
  btnDisable = false;
  isEditMode = true;

  domRemarks = "";
  domcomment = "";

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  op4CompanyType = CONST_OP_COMPANY;
  op4SignagePosi = CONST_OP_4SIGNAGE_POSI;
  op4SupplierType = CONST_OP_SUPPLIERTYPE;
  // op4PaymentSite = CONST_OP_PAYMENTSITE;

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalDelConf = "";
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.get();
    await this.getMastersList();
    //設定された権限からメニューの表示非表示を制御
    this.showFlags.getAuthorization(this.$store);
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      // dtoMaster.getStaff = 1;
      // dtoMaster.delFlag4staff = 0;

      dtoMaster.getBranch = 1;
      dtoMaster.delFlag4Branch = 0;

      // dtoMaster.getClients = 1;
      // dtoMaster.delFlag4clients = 0;

      // dtoMaster.getSuppliers = 1;
      // dtoMaster.delFlag4suppliers = 0;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);
      console.log("this.delegate4list");
      console.log(this.delegate4list);

      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました"; //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * データを読み込む
   */
  async get() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //パラメータにIDが指定されていなければ、新規追加モードでデータを読み込まない
      //-------
      let id = Cl_common.getParam("id");
      if (id == "") {
        //新規追加
        this.isEditMode = false;
        //初期値設定
        this.delegate.c_supplier_company_type = 1;
        this.delegate.c_supplier_flag_company_signage_posi = 1;
        return;
      } else {
        //編集
        this.isEditMode = true;
      }

      //準備
      //-------
      let dto = new DtoSuppliersGetReqImple();

      dto.c_supplier_id = Number(id);

      //API実行
      //-------
      this.delegate = await Cl_supplier.get(dto);
      //レスポンス処理
      //-------
      this.domRemarks = this.delegate.c_supplier_remarks!;

      this.domcomment = this.delegate.c_supplier_comment!;

      //IDがnullなら一覧へ遷移
      if (this.delegate.c_supplier_id == null) {
        this.$router.push("/supplierList");
      }
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * データを更新する
   */
  async upd(isDel: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoSuppliersPutReq();
      if (isDel) {
        //削除処理：削除フラグを立てる。
        dto.c_supplier_id = this.delegate.c_supplier_id;
        dto.c_supplier_flag_del = 1;
      } else {
        //バリデーション
        //-----------------
        this.errors = null;
        this.schema.validateSync({
          supplierName: this.delegate.c_supplier_name,
          supplierNamePt: this.delegate.c_supplier_name_pt,
          branchId: this.delegate.c_branch_id,
        });

        //更新処理：delegateからdtoにデータ投入。
        dto = Object.assign(new DtoSuppliersPutReq(), this.delegate);
        //備考欄を戻す
        if (this.domRemarks != null) {
          dto.c_supplier_remarks = this.domRemarks;
        }
        //コメントを戻す
        if (this.domcomment != null) {
          dto.c_supplier_comment = this.domcomment;
        }
        //仕入れ先名に変換すべき全角文字があれば半角に直す
        if (this.delegate.c_supplier_name != null) {
          dto.c_supplier_name = this.replaceCharacters(
            this.delegate.c_supplier_name
          );
        }
      }

      //API実行
      //-------
      await Cl_supplier.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      if (isDel) {
        //削除処理：
        this.message4ModalSuccess = "正常に削除されました";
      } else {
        //更新処理：
        this.message4ModalSuccess = "正常に変更されました";
      }

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * データを新たに登録する
   */
  async add() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //バリデーション
      //-----------------
      this.errors = null;
      this.schema.validateSync({
        supplierName: this.delegate.c_supplier_name,
        supplierNamePt: this.delegate.c_supplier_name_pt,
        branchId: this.delegate.c_branch_id,
      });

      //準備
      //-------
      //備考欄を戻す
      if (this.domRemarks != null) {
        this.delegate.c_supplier_remarks = this.domRemarks;
      }
      //コメントを戻す
      if (this.domcomment != null) {
        this.delegate.c_supplier_comment = this.domcomment;
      }

      //仕入先名に変換すべき全角文字があれば半角に直す
      if (this.delegate.c_supplier_name != null) {
        this.delegate.c_supplier_name = this.replaceCharacters(
          this.delegate.c_supplier_name
        );
      }

      let dto = Object.assign(new DtoSuppliersPutReq(), this.delegate);
      dto.c_supplier_flag_del = 0;

      //API実行
      //-------
      const res = await Cl_supplier.post(dto);

      //レスポンスの処理
      //-------
      //編集画面のURL作成
      this.$router.push({
        path: "/supplierEdit",
        query: { id: res.c_supplier_id },
      });

      //モーダルのメッセージを設定
      this.message4ModalSuccess = "正常に追加されました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * データ削除用のモーダルを表示
   */
  async delConf() {
    //モーダルのメッセージを設定
    this.message4ModalDelConf =
      "【" +
      this.delegate.c_supplier_id +
      "】" +
      this.delegate.c_supplier_name +
      "を削除します。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.showModal();
  }
  /**
   * データを削除する
   */
  async del() {
    //削除フラグのあるテーブルなので更新処理。
    this.closeModal4deleteConf();
    this.upd(true);
  }

  /**
   * チェックボックスの値を数値に変換する
   * @param event
   */
  cnvCb(event: any): number {
    if (event.target.checked) {
      return 1;
    } else {
      return 0;
    }
  }

  /**
   * 全角アルファベット、スペース、@、&、を半角に置き換える
   * @param input
   */
  replaceCharacters(input: string): string {
    return input
      .split("")
      .map((char) => CONST_REPLACEMENT_MAP[char] || char)
      .join("");
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4deleteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.closeModal();
  }
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
