import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36a7a1b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "reportCont cwC_1" }
const _hoisted_2 = { class: "reportCont cwC_2" }
const _hoisted_3 = { class: "reportCont cwC_3" }
const _hoisted_4 = { class: "reportCont cwC_4" }
const _hoisted_5 = { class: "reportCont cwC_5" }
const _hoisted_6 = { class: "reportCont cwC_6" }
const _hoisted_7 = { class: "reportCont cwC_7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", _hoisted_1, _toDisplayString(_ctx.item.c_report_det_type_name), 1),
    _createElementVNode("td", _hoisted_2, _toDisplayString(_ctx.item.c_report_det_project_name), 1),
    _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx.item.c_report_det_visited_name), 1),
    _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.item.c_report_det_location), 1),
    _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx.item.c_report_det_work_contents), 1),
    _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.item.c_report_det_sales), 1),
    _createElementVNode("td", _hoisted_7, _toDisplayString(_ctx.item.c_report_det_supply), 1)
  ]))
}