
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import "vue-select/dist/vue-select.css";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import {
  CONST_OP_REQUEST_TYPE,
  CONST_OP_REQUEST_STATUS,
  cnvRequestStatusNum2Name,
  cnvRequestTypeNum2Name,
  CONST_REQUEST_TYPE_KARIBARAI,
  CONST_REQUEST_TYPE_JUSHOHENKOTSUKINTEATE,
  CONST_REQUEST_TYPE_KEIHIKOZATOROKU,
  CONST_REQUEST_TYPE_KEIHISEISAN,
  CONST_REQUEST_TYPE_KYUKATODOKE,
  CONST_REQUEST_TYPE_KYUSHOKUNEGAI,
  CONST_REQUEST_TYPE_KYUYOSHOYOKOZATOROKU,
  CONST_REQUEST_TYPE_REMOTEWORK,
  CONST_REQUEST_TYPE_RINGISHO,
  CONST_REQUEST_TYPE_SETTAI,
  CONST_REQUEST_TYPE_SHAYOSHASHIYO,
  CONST_REQUEST_TYPE_SHUTCHO,
  CONST_REQUEST_TYPE_ZANGYO,
  CONST_REQUEST_STATUS_MISINSEI,
  CONST_REQUEST_STATUS_SINSEITYU,
  CONST_REQUEST_STATUS_SASIMODOSI,
  CONST_REQUEST_STATUS_SYOUNINZUMI,
  CONST_REQUEST_STATUS_TORISAGE,
} from "furukawa-common/src/common/constant/Constants";
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersgetReq } from "furukawa-common/src/common/dto/DtoMastersGetReq";
import { DtoMastersGetRes } from "furukawa-common/src/common/dto/DtoMastersGetRes";
import { CommonStaff } from "furukawa-common/src/common/service/CommonStaff";
import Mo_modal_l from "@/components/molecules/Mo_modal_n.vue";
import { Cl_common } from "@/functions/Cl_common";
import { CONST_LIST_DEFO_COUNT } from "@/constants/constant";
import { Cl_request } from "@/functions/Cl_request";
import { DtoRequestsGetReqImple } from "furukawa-common/src/common/dto/DtoRequestsGetReqImple";
import { DtoRequestsGetResImple } from "furukawa-common/src/common/dto/DtoRequestsGetResImple";
import { Cl_filemaster } from "@/functions/Cl_filemaster";
import { DtoFileMastersGetReqImple } from "furukawa-common/src/common/dto/DtoFileMastersGetReqImple";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_modal_l,
    Mo_modal_alert,
  },
})
export default class Shinseisyolist extends Vue {
  /**
   * パフォーマンス問題の解決案としてテスト的に実装中
   * 残課題：グローバルなメソッドとして宣言して、どこからでも呼び出せるようにしたい
   * 　　　　色々なパターンでも問題ないかの検証
   * @param url
   * @param event
   */
  goTo(url: string, event?: MouseEvent) {
    if (event && (event.ctrlKey || event.metaKey)) {
      window.open(url);
    } else {
      this.$router.push(url);
    }
  }
  //----------------------
  //バリデーション
  //----------------------
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoRequestsGetResImple();
  delegate4list = new DtoMastersGetRes();
  btnDisable = false;

  //検索条件dom
  domIdStart = "";
  domIdEnd = "";
  domReqType = "";
  domReqState = "";
  domAuthorizerId = "";
  domApplicantId = "";

  columns = [
    {
      label: "詳細",
      field: "btnDet",
    },
    {
      label: "申請番号",
      field: "c_request_id",
    },
    {
      label: "管理名",
      field: "c_request_name",
    },
    {
      label: "申請書類",
      field: "c_request_type",
    },
    {
      label: "申請者",
      field: "c_staff_id",
    },
    {
      label: "申請日時",
      field: "c_request_datetime",
      // type: "date",
      // dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
      // dateOutputFormat: "yyyy/MM/dd HH:mm:ss",
      // thClass: "text-left",
      // tdClass: "text-left",
    },
    {
      label: "ステータス",
      field: "c_request_status",
    },
    {
      label: "第一承認者",
      field: "c_request_1_approval",
    },
    {
      label: "第一承認日時",
      field: "c_request_1_approval_datetime",
    },
    {
      label: "第二承認者",
      field: "c_request_2_approval",
    },
    {
      label: "第二承認日時",
      field: "c_request_2_approval_datetime",
    },
    {
      label: "最終承認者",
      field: "c_request_3_approval",
    },
    {
      label: "最終承認日時",
      field: "c_request_3_approval_datetime",
    },
  ];

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  opRequestType = CONST_OP_REQUEST_TYPE;
  opRequsetStatus = CONST_OP_REQUEST_STATUS;

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";
  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getMastersList();
    await this.getlist(1);
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      //検索選択肢用
      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4Staff4sortNum = 1;
      //表示用(消すとフロントテーブルの申請者が消える)
      dtoMaster.getStaff = 1;
      dtoMaster.delFlag4staff = 0;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);

      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * 一覧データを読み込む
   */
  async getlist(currentPage: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoRequestsGetReqImple();

      // //検索条件設定
      dto.pager.count = CONST_LIST_DEFO_COUNT;
      dto.pager.cursor = currentPage;

      if (this.domIdStart != "") dto.requestidstart = this.domIdStart;
      if (this.domIdEnd != "") dto.requestidend = this.domIdEnd;
      if (this.domReqType != null && this.domReqType != "")
        dto.requesttype = Number(this.domReqType);
      if (this.domReqState != null && this.domReqState != "")
        dto.requeststatsu = Number(this.domReqState);
      if (this.domAuthorizerId != null && this.domAuthorizerId != "")
        dto.authorizerid = Number(this.domAuthorizerId);
      if (this.domApplicantId != null && this.domApplicantId != "")
        dto.applicantid = Number(this.domApplicantId);

      Cl_common.logger(dto);

      //API実行
      //-------
      this.delegate = await Cl_request.getList(dto);
      //レスポンス処理
      //-------
      Cl_common.logger(this.delegate);
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      //エラー発生で一覧を初期化
      this.delegate = new DtoRequestsGetResImple();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /*
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      // let dto = new DtoFileMastersGetReqImple();
      // dto.type = "Shinseisyolist";
      // if (this.domIdStart != "") dto.requestidstart = this.domIdStart;
      // if (this.domIdEnd != "") dto.requestidend = this.domIdEnd;
      // if (this.domReqType != null && this.domReqType != "")
      //   dto.requesttype = Number(this.domReqType);
      // if (this.domReqState != null && this.domReqState != "")
      //   dto.requeststatsu = Number(this.domReqState);
      // if (this.domAuthorizerId != null && this.domAuthorizerId != "")
      //   dto.authorizerid = Number(this.domAuthorizerId);
      // if (this.domApplicantId != null && this.domApplicantId != "")
      //   dto.applicantid = Number(this.domApplicantId);

      //API実行
      //-------
      // const res = await Cl_filemaster.get(dto);

      //レスポンス処理
      //-------
      // location.href = res.url;
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  async moveDet(requestType: number, id: string) {
    //詳細ページに移動

    switch (requestType) {
      case CONST_REQUEST_TYPE_KARIBARAI:
        this.$router.push("/shinsei_karibaraiedit?id=" + id);
        break;
      case CONST_REQUEST_TYPE_ZANGYO:
        this.$router.push("/shinsei_Zangyoedit?id=" + id);
        break;
      case CONST_REQUEST_TYPE_REMOTEWORK:
        this.$router.push("/shinsei_Remoteworkedit?id=" + id);
        break;
      case CONST_REQUEST_TYPE_SHUTCHO:
        this.$router.push("/shinsei_Shutchoedit?id=" + id);
        break;
      case CONST_REQUEST_TYPE_SETTAI:
        this.$router.push("/shinsei_Settaiedit?id=" + id);
        break;
      case CONST_REQUEST_TYPE_SHAYOSHASHIYO:
        this.$router.push("/shinsei_Shayoshashiyoedit?id=" + id);
        break;
      case CONST_REQUEST_TYPE_KEIHISEISAN:
        this.$router.push("/shinsei_Keihiseisanedit?id=" + id);
        break;
      case CONST_REQUEST_TYPE_KYUKATODOKE:
        this.$router.push("/shinsei_Kyukatodokeedit?id=" + id);
        break;
      case CONST_REQUEST_TYPE_RINGISHO:
        this.$router.push("/shinsei_Ringishoedit?id=" + id);
        break;
      case CONST_REQUEST_TYPE_KYUSHOKUNEGAI:
        this.$router.push("/shinsei_Kyushokunegaiedit?id=" + id);
        break;
      case CONST_REQUEST_TYPE_KYUYOSHOYOKOZATOROKU:
        this.$router.push("/shinsei_Kyuyoshoyokozatorokuedit?id=" + id);
        break;
      case CONST_REQUEST_TYPE_JUSHOHENKOTSUKINTEATE:
        this.$router.push("/shinsei_Jushohenkotsukinteateedit?id=" + id);
        break;
      case CONST_REQUEST_TYPE_KEIHIKOZATOROKU:
        this.$router.push("/shinsei_Keihikozatorokuedit?id=" + id);
        break;
      default:
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
        //モーダル表示
        (this.$refs.Mo_modal_errorConf as any).showModal();
        break;
    }
  }
  /**
   * ページ更新時の処理
   * @param params
   */
  async onPageChange(params: any) {
    //テーブル更新処理
    await this.getlist(params.currentPage);
  }
  /**
   * ソート変更時の処理
   * @param params
   */
  async onSortChange(params: any) {
    //テーブル更新処理
  }
  /**
   * 申請書種別の番号を受け取り、名称にして返す
   */
  cnvReqType2Name(type: number): string {
    return cnvRequestTypeNum2Name(type);
  }
  /**
   * 申請書種別の番号を受け取り、名称にして返す
   */
  cnvStaffId2Name(id: number | null): string {
    if (id == null) {
      return "-";
    }

    return CommonStaff.cnvStaffNum2Name(this.delegate4list.items4staffs, id);
  }
  /**
   * 申請書ステータスの番号を受け取り、名称にして返す
   */
  cnvStatusNum2Name(statusNum: number): string {
    switch (statusNum) {
      case CONST_REQUEST_STATUS_MISINSEI:
        return '<span class="badge badge-warning badge-pill" style="font-size: 1em;">未申請</span>';
      case CONST_REQUEST_STATUS_SINSEITYU:
        return '<span class="badge badge-info badge-pill" style="font-size: 1em;">申請中</span>';
      case CONST_REQUEST_STATUS_SASIMODOSI:
        return '<span class="badge badge-danger badge-pill" style="font-size: 1em;">差戻</span>';
      case CONST_REQUEST_STATUS_SYOUNINZUMI:
        return '<span class="badge badge-success badge-pill" style="font-size: 1em;">承認済</span>';
      case CONST_REQUEST_STATUS_TORISAGE:
        return '<span  class="badge badge-dark badge-pill" style="font-size: 1em;">取下</span>';
      default:
        return "";
    }

    // return cnvRequestStatusNum2Name(statusNum);
  }
  /**
   * 承認日時の記載事項を返す
   */
  getApprovalDate(appDate: Date | null, appStaffId: number | null): string {
    if (appStaffId == null) {
      return "-";
    }

    if (appDate == null) {
      return "未承認";
    }

    return appDate
      .toString()
      .replaceAll("-", "/")
      .replace("T", " ")
      .replace(".000Z", "");
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  //----------------------
  //その他のメソッド（画面独自のもの等）
  //----------------------
  cnvDate(date: Date | null) {
    if (date == null) {
      return "-";
    }

    return date
      .toString()
      .replaceAll("-", "/")
      .replace("T", " ")
      .replace(".000Z", "");
  }
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
  /**
   * 申請書選択用モーダル表示
   */
  async showModal(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_selectType;
    modalComp.showModal();
  }
  /**
   * 申請書選択用モーダル閉じる
   */
  async closeModal(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_selectType;
    modalComp.closeModal();
  }
}
