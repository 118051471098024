import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoDocfilesGetReqImple } from "furukawa-common/src/common/dto/DtoDocfilesGetReqImple";
import { DtoDocfilesGetResImple } from "furukawa-common/src/common/dto/DtoDocfilesGetResImple";
import { DtoDocfilesPostReq } from "furukawa-common/src/common/dto/abst/DtoDocfilesPostReq";
import { DtoDocfilesPostRes } from "furukawa-common/src/common/dto/abst/DtoDocfilesPostRes";
import { DtoDocfilesPutReq } from "furukawa-common/src/common/dto/abst/DtoDocfilesPutReq";
import { DtoDocfilesPutRes } from "furukawa-common/src/common/dto/abst/DtoDocfilesPutRes";
import { DtoDocfilesDeleteReq } from "furukawa-common/src/common/dto/abst/DtoDocfilesDeleteReq";
import { DtoDocfilesDeleteRes } from "furukawa-common/src/common/dto/abst/DtoDocfilesDeleteRes";

export class Cl_docfile {
  public static async getList(
    dto: DtoDocfilesGetReqImple
  ): Promise<DtoDocfilesGetResImple> {
    return Object.assign(
      new DtoDocfilesGetResImple(),
      await CallAPI.getRequest(
        "/docfiles?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoDocfilesGetReqImple
  ): Promise<DtoDocfilesGetResImple> {
    return Object.assign(
      new DtoDocfilesGetResImple(),
      await CallAPI.getRequest("/docfiles/" + dto.c_docfile_id)
    );
  }

  public static async post(
    dto: DtoDocfilesPostReq
  ): Promise<DtoDocfilesPostRes> {
    return Object.assign(
      new DtoDocfilesPostRes(),
      await CallAPI.postRequest("/docfiles", dto)
    );
  }

  public static async put(dto: DtoDocfilesPutReq): Promise<DtoDocfilesPutRes> {
    return Object.assign(
      new DtoDocfilesPutRes(),
      await CallAPI.putRequest("/docfiles/" + dto.c_docfile_id, dto)
    );
  }

  public static async del(
    dto: DtoDocfilesDeleteReq
  ): Promise<DtoDocfilesDeleteRes> {
    return Object.assign(
      new DtoDocfilesDeleteRes(),
      await CallAPI.deleteRequest("/docfiles/" + dto.c_docfile_id)
    );
  }
}
