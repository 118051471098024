import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoFilesGetReqImple } from "furukawa-common/src/common/dto/DtoFilesGetReqImple";
import { DtoFilesGetResImple } from "furukawa-common/src/common/dto/DtoFilesGetResImple";
import { DtoFilesPostReqImple } from "furukawa-common/src/common/dto/DtoFilesPostReqImple";
import { DtoFilesPostResImple } from "furukawa-common/src/common/dto/DtoFilesPostResImple";
import { DtoFilesPutReq } from "furukawa-common/src/common/dto/abst/DtoFilesPutReq";
import { DtoFilesPutRes } from "furukawa-common/src/common/dto/abst/DtoFilesPutRes";
import { DtoFilesDeleteReqImple } from "furukawa-common/src/common/dto/DtoFilesDeleteReqImple";
import { DtoFilesDeleteResImple } from "furukawa-common/src/common/dto/DtoFilesDeleteResImple";

export class Cl_file {
  public static async getList(
    dto: DtoFilesGetReqImple
  ): Promise<DtoFilesGetResImple> {
    return Object.assign(
      new DtoFilesGetResImple(),
      await CallAPI.getRequest(
        "/files?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoFilesGetReqImple
  ): Promise<DtoFilesGetResImple> {
    return Object.assign(
      new DtoFilesGetResImple(),
      await CallAPI.getRequest("/files/" + dto.c_file_id)
    );
  }

  public static async post(
    dto: DtoFilesPostReqImple
  ): Promise<DtoFilesPostResImple> {
    return Object.assign(
      new DtoFilesPostResImple(),
      await CallAPI.postRequest("/files", dto)
    );
  }

  public static async put(dto: DtoFilesPutReq): Promise<DtoFilesPutRes> {
    return Object.assign(
      new DtoFilesPutRes(),
      await CallAPI.putRequest("/files/" + dto.c_file_id, dto)
    );
  }

  public static async del(
    dto: DtoFilesDeleteReqImple
  ): Promise<DtoFilesDeleteResImple> {
    return Object.assign(
      new DtoFilesDeleteResImple(),
      await CallAPI.deleteRequest("/files?" + Cl_common.cnvObj2GetParam(dto))
    );
  }
}
