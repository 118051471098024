export class EntitySimulation {
public c_claim_id:number | null = null;
public c_project_id:number | null = null;
public c_claim_flag_enable:number | null = null;
public c_claim_flag_sales:number | null = null;
public c_claim_flag_del:number | null = null;
public c_claim_number:number | null = null;
public c_claim_upd_at:Date | null = null;
public c_claim_upd_user:string | null = null;
public c_claim_reg_at:Date | null = null;
public c_claim_reg_user:string | null = null;
public c_staff_name:string | null = null;
public c_client_id:number | null = null;
public c_supplier_id:number | null = null;
public c_staff_id4sales:number | null = null;
public c_staff_id4supply:number | null = null;
public c_sponsor_id:number | null = null;
public c_branch_id:number | null = null;
public c_project_number:number | null = null;
public c_project_name:string | null = null;
public c_project_salesdate_start:Date | null = null;
public c_project_salesdate_end:Date | null = null;
public c_project_supplydate_start:Date | null = null;
public c_project_supplydate_end:Date | null = null;
public c_project_type:number | null = null;
public c_sponsor_name:string | null = null;
public c_project_flag_del:number | null = null;
public v_simulation_salessum:string | null = null;
public c_sales_climnum_tmp:number | null = null;
public c_supply_climnum_tmp:number | null = null;
public v_simulation_supplyssum:string | null = null;
public c_sales_id:number | null = null;
public c_supply_rownum:number | null = null;
public c_supply_claim_date:Date | null = null;
public c_supply_month:Date | null = null;
public c_supply_item:number | null = null;
public c_supply_subject:number | null = null;
public c_supply_breakdown:number | null = null;
public c_supply_id:number | null = null;
public c_sales_breakdown:number | null = null;
public c_sales_subject:number | null = null;
public c_sales_item:number | null = null;
public c_sales_month:Date | null = null;
public c_sales_issue:Date | null = null;
public c_sales_rownum:number | null = null;
public c_supplier_name:string | null = null;
public c_client_name:string | null = null;
public c_migration_id:number | null = null;
public c_project_id_new:number | null = null;
public c_migration_eval:number | null = null;
public c_migration_setting:number | null = null;
public c_migration_comment:string | null = null;
public c_migration_project_name:string | null = null;
public c_migration_staff_id4sales:number | null = null;
public c_migration_price_sales:number | null = null;
public c_migration_price_supplys:number | null = null;
}