import { EntityStaff } from "../entity/EntityStaff";
import { EntityClient } from "../entity/EntityClient";
import { EntitySupplier } from "../entity/EntitySupplier";
import { EntityBankImple } from "../entity/EntityBankImple";
import { EntityBranch } from "../entity/EntityBranch";
import { EntitySponsor } from "../entity/EntitySponsor";
import { EntityHoliday } from "../entity/EntityHoliday";
import { commonRegx } from "xcommon-node/src/common/commonRegx";

export class DtoMastersGetRes {
  /**
   * 社員マスタ用
   */
  public items4staffs: EntityStaff[] = [];
  /**
   * 社員マスタ表示順入力済み＋削除フラグOFF＋表示順ソート用
   * 受注情報入力、請求書、請求書（売上なし）用
   */
  public items4staffsSortNum: EntityStaff[] = [];
  /**
   * 社員マスタ　勤怠管理用
   */
  public items4AttendanceStaffs: EntityStaff[] = [];
  /**
   * 社員マスタ保険申請担当者用
   */
  public items4staffInsurance: EntityStaff[] = [];
  /**
   * 売上先マスタ用
   */
  public items4Client: EntityClient[] = [];
  /**
   * 仕入れ先マスタ用
   */
  public items4Suplier: EntitySupplier[] = [];
  /**
   * 口座マスタ用
   */
  public items4Bank: EntityBankImple[] = [];

  /**
   * 拠点マスタ用
   */
  public items4Branch: EntityBranch[] = [];
  /**
   * 広告主マスタ用
   */
  public items4Sponsor: EntitySponsor[] = [];
  /**
   * 休日管理用
   */
  public items4Holiday: EntityHoliday[] = [];

  //---------------------------------
  /**
   * 拠点名を表示用に調整する。items4Branchが対象。
   */
  cnvBranchName() {
    this.items4Branch.forEach((item) => {
      item.c_branch_name = commonRegx.replace(
        item.c_branch_name!,
        " ",
        "",
        commonRegx._stdOptionWithG
      );
      item.c_branch_name = item.c_branch_name!.replace("古川広告社", "");
      if (item.c_branch_name == "") {
        item.c_branch_name = "大阪本社";
      }
    });
  }

  //---------------------------------
  /**
   * 削除フラグが立っていない仕入先と、渡されたIDの仕入先で別配列を作成し返す。
   * 削除された選択肢を含む可能性のあるMasterを処理する用。
   */
  getSupplier4NoneDelete(supplierIds: number[] | null): EntitySupplier[] {
    let res: EntitySupplier[] = [];

    //削除フラグを除外。
    //-----------------
    for (let index = 0; index < this.items4Suplier.length; index++) {
      if (this.items4Suplier[index].c_supplier_flag_del == 0) {
        res.push(this.items4Suplier[index]);
      }
    }
    //指定IDのデータを追加。
    //-----------------
    if (supplierIds != null) {
      for (let index = 0; index < supplierIds.length; index++) {
        //検索
        const result = res.find(
          (item) => item.c_supplier_id === supplierIds[index]
        );
        if (!result) {
          //存在しないので入力
          const exactMatches = this.items4Suplier.filter(
            (supplier) => supplier.c_supplier_id === supplierIds[index]
          );
          res = exactMatches.concat(res);
        }
      }
    }

    return res;
  }
  /**
   * 削除フラグが立っていない顧客と、渡されたIDの顧客で別配列を作成し返す。
   * 削除された選択肢を含む可能性のあるMasterを処理する用。
   */
  getClient4NoneDelete(clientIds: number[] | null): EntityClient[] {
    let res: EntityClient[] = [];

    //削除フラグを除外。
    //-----------------
    for (let index = 0; index < this.items4Client.length; index++) {
      if (this.items4Client[index].c_client_flag_del == 0) {
        res.push(this.items4Client[index]);
      }
    }
    //指定IDのデータを追加。
    //-----------------
    if (clientIds != null) {
      for (let index = 0; index < clientIds.length; index++) {
        //検索
        const result = res.find(
          (item) => item.c_client_id === clientIds[index]
        );
        if (!result) {
          //存在しないので入力
          const exactMatches = this.items4Client.filter(
            (supplier) => supplier.c_client_id === clientIds[index]
          );
          res = exactMatches.concat(res);
        }
      }
    }

    return res;
  }

  /**
   * 削除フラグが立っていない社員と、渡されたIDの社員で別配列を作成し返す。
   * 削除された選択肢を含む可能性のあるMasterを処理する用。
   * items4staffsSortNum用。
   */
  getStaffSortNum4NoneDelete(staffIds: number[] | null): EntityStaff[] {
    let res: EntityStaff[] = [];

    //削除フラグを除外。
    //-----------------
    for (let index = 0; index < this.items4staffsSortNum.length; index++) {
      if (this.items4staffsSortNum[index].c_staff_flag_del == 0) {
        res.push(this.items4staffsSortNum[index]);
      }
    }
    //指定IDのデータを追加。
    //-----------------
    if (staffIds != null) {
      for (let index = 0; index < staffIds.length; index++) {
        //検索
        const result = res.find(
          (item) => item.c_staff_id === staffIds[index]
        );
        if (!result) {
          //存在しないので入力
          const exactMatches = this.items4staffsSortNum.filter(
            (staff) => staff.c_staff_id === staffIds[index]
          );
          res = exactMatches.concat(res);
        }
      }
    }

    return res;
  }
}
