
import { Options, Vue } from "vue-class-component";
import { Cl_common } from "@/functions/Cl_common";
import { CONST_LIST_DEFO_COUNT } from "@/constants/constant";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import { DtoGoalsGetReqImple } from "furukawa-common/src/common/dto/DtoGoalsGetReqImple";
import { DtoGoalsGetResImple } from "furukawa-common/src/common/dto/DtoGoalsGetResImple";
import { Cl_goal } from "@/functions/Cl_goal";
import { DtoMastersgetReq } from "furukawa-common/src/common/dto/DtoMastersGetReq";
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersGetRes } from "furukawa-common/src/common/dto/DtoMastersGetRes";
import {
  CONST_OP_STAFFDEPART,
  CONST_OP_YEAR,
} from "furukawa-common/src/common/constant/Constants";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_modal_alert,
  },
})
export default class PersonalGoalList extends Vue {
  /**
   * パフォーマンス問題の解決案としてテスト的に実装中
   * 残課題：グローバルなメソッドとして宣言して、どこからでも呼び出せるようにしたい
   * 　　　　色々なパターンでも問題ないかの検証
   * @param url
   * @param event
   */
  goTo(url: string, event?: MouseEvent) {
    if (event && (event.ctrlKey || event.metaKey)) {
      window.open(url);
    } else {
      this.$router.push(url);
    }
  }
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoGoalsGetResImple();
  delegate4list = new DtoMastersGetRes();
  // domYear = { label: "" };
  domYear = "";
  domDepart = "";
  domBranchId = "";
  domStaffId = "";
  //ボタン制御用
  btnDisable = false;

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  opGoal = CONST_OP_YEAR;
  op4StaffDepart = CONST_OP_STAFFDEPART;

  columns = [
    {
      label: "詳細",
      field: "btnDet",
      thClass: "text-center",
      tdClass: "text-center",
      width: "10%",
    },
    {
      label: "期",
      field: "c_goal_year",
      thClass: "text-center",
      width: "10%",
    },
    {
      label: "社員番号",
      field: "c_staff_id",
      thClass: "text-center",
    },
    {
      label: "社員名",
      field: "c_staff_name",
      thClass: "text-center",
    },
    {
      label: "部署",
      field: "c_staff_depart",
      thClass: "text-center",
    },
    {
      label: "拠点",
      field: "c_branch_name",
      thClass: "text-center",
    },
    {
      label: "売上目標",
      field: "c_goal_earnings",
      thClass: "text-center",
      tdClass: "text-right",
    },
    {
      label: "粗利目標",
      field: "c_goal_gross",
      thClass: "text-center",
      tdClass: "text-right",
    },
  ];

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getlist(1);
    await this.getMastersList();
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      //検索選択肢用
      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4Staff4sortNum = 1;
      // dtoMaster.getStaff = 1;
      // dtoMaster.delFlag4staff = 0;

      dtoMaster.getBranch = 1;
      dtoMaster.delFlag4Branch = 0;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);

      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました"; //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * 一覧データを読み込む
   */
  async getlist(currentPage: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoGoalsGetReqImple();

      //検索条件設定
      // dto.pager.count = CONST_LIST_DEFO_COUNT;
      dto.pager.count = 9999;
      dto.pager.cursor = currentPage;

      // if (
      //   this.domYear != null &&
      //   this.domYear.label != null &&
      //   this.domYear.label != ""
      // ) {
      //   dto.year = Number(this.domYear.label);
      // }

      if (this.domYear != "" && this.domYear != null)
        dto.year = Number(this.domYear);

      if (this.domStaffId != "" && this.domStaffId != null)
        dto.staffId = this.domStaffId;

      if (this.domBranchId != "" && this.domBranchId != null)
        dto.branchId = this.domBranchId;

      if (this.domDepart != "" && this.domDepart != null)
        dto.depertId = this.domDepart;

      //API実行
      //-------
      this.delegate = await Cl_goal.getList(dto);

      //レスポンス処理
      //-------
      Cl_common.logger("結果");
      Cl_common.logger(this.delegate);
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      //エラー発生で一覧を初期化
      this.delegate = new DtoGoalsGetResImple();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      // let dto = new DtoFileMastersGetReqImple();
      // dto.type = "SalesTargetMaster";
      // if (
      //   this.domYear != null &&
      //   this.domYear.label != null &&
      //   this.domYear.label != ""
      // ) {
      //   dto.year = Number(this.domYear.label);
      // }

      //API実行
      //-------
      // const res = await Cl_filemaster.get(dto);

      //レスポンス処理
      //-------
      // location.href = res.url;
      //window.open(res.url, "_blank");
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  async moveDet(id: string) {
    //詳細ページに移動
    this.$router.push("/personalGoalEdit?id=" + id);
  }

  /**
   * ページ更新時の処理
   * @param params
   */
  async onPageChange(params: any) {
    //テーブル更新処理
    await this.getlist(params.currentPage);
  }

  /**
   * ソート変更時の処理
   * @param params
   */
  async onSortChange(params: any) {
    //テーブル更新処理
  }

  totalEarnings(props: any) {
    return (
      props.row.c_goal_earnings_1 +
      props.row.c_goal_earnings_2 +
      props.row.c_goal_earnings_3 +
      props.row.c_goal_earnings_4 +
      props.row.c_goal_earnings_5 +
      props.row.c_goal_earnings_6 +
      props.row.c_goal_earnings_7 +
      props.row.c_goal_earnings_8 +
      props.row.c_goal_earnings_9 +
      props.row.c_goal_earnings_10 +
      props.row.c_goal_earnings_11 +
      props.row.c_goal_earnings_12
    ).toLocaleString();
  }

  totalGross(props: any) {
    return (
      props.row.c_goal_gross_1 +
      props.row.c_goal_gross_2 +
      props.row.c_goal_gross_3 +
      props.row.c_goal_gross_4 +
      props.row.c_goal_gross_5 +
      props.row.c_goal_gross_6 +
      props.row.c_goal_gross_7 +
      props.row.c_goal_gross_8 +
      props.row.c_goal_gross_9 +
      props.row.c_goal_gross_10 +
      props.row.c_goal_gross_11 +
      props.row.c_goal_gross_12
    ).toLocaleString();
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
