import { CONST_CLAIMSTATUS } from "../constant/Constants";
import { EntityClaim } from "../entity/EntityClaim";
import { EntityClaimImple } from "../entity/EntityClaimImple";
import { EntityProject } from "../entity/EntityProject";
import { EntityClaimDet } from "../entity/EntityClaimDet";
import { DtoApiPager } from "./abst/DtoApiPager";
import { DtoClaimsGetRes } from "./abst/DtoClaimsGetRes";

export class DtoClaimsGetResImple extends DtoClaimsGetRes {
  //一覧用
  //-----------------------
  public pager = new DtoApiPager();
  public itemsImple: EntityClaimImple[] = [];
  public exitems: Exitem[] = [];

  //詳細用
  //-----------------------
  public details: EntityClaimDet[] = [];
  public project = new EntityProject();
  public sumSales: number | null = null;
  public claimNumberPrev: number | null = null;
  public claimNumberNext: number | null = null;

  //承認者のstaffidリスト。c_claim_statusが1の時だけ入力される
  public elderStaffIds: number[] = [];

  //メソッド
  //-----------------------
  public async setEximtes() {
    let tmp: Exitem;
    for (let index = 0; index < this.itemsImple.length; index++) {
      tmp = new Exitem();

      tmp.c_claim_id = this.itemsImple[index].c_claim_id;
      tmp.c_claim_number = this.itemsImple[index].c_claim_number;
      tmp.c_claim_status = this.itemsImple[index].c_claim_status;
      tmp.c_project_number = this.itemsImple[index].c_project_number;
      tmp.c_branch_name = this.itemsImple[index].c_branch_name;
      tmp.c_claim_datetime = this.itemsImple[index].c_claim_datetime;
      tmp.c_client_name = this.itemsImple[index].c_client_name;
      tmp.c_claim_subtotal = this.itemsImple[index].c_claim_subtotal;
      tmp.c_staff_name = this.itemsImple[index].c_staff_name;

      tmp.c_claim_paylimit = this.itemsImple[index].c_claim_paylimit;
      tmp.c_claim_depo_datetime = this.itemsImple[index].c_claim_depo_datetime;
      tmp.c_claim_flag_sales = this.itemsImple[index].c_claim_flag_sales;
      tmp.c_claim_date_exp_payment =
        this.itemsImple[index].c_claim_date_exp_payment;
      tmp.c_claim_total = this.itemsImple[index].c_claim_total;
      tmp.c_claim_title_1 = this.itemsImple[index].c_claim_title_1;

      if (tmp.c_claim_status != null) {
        // tmp.c_status_name = CONST_CLAIMSTATUS[tmp.c_claim_status].label;

        var found = CONST_CLAIMSTATUS.find(
          (e) => e.code === tmp.c_claim_status
        );
        tmp.c_status_name = found?.label!;
      } else {
        tmp.c_status_name = "";
      }

      this.exitems.push(tmp);
    }
  }
}

export class Exitem extends EntityClaimImple {
  public c_status_name: string | null = null;
  // public c_staff_name: string | null = null;
  // public c_claim_datetime_short: string | null = null;
}
