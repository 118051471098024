
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import Or_tableDailyReport from "@/components/organisms/Or_tableDailyReport.vue";
import { Cl_common } from "@/functions/Cl_common";
import { DtoMastersGetRes } from "furukawa-common/src/common/dto/DtoMastersGetRes";
import { DtoReportsGetResImple } from "furukawa-common/src/common/dto/DtoReportsGetResImple";
import { DtoMastersgetReq } from "furukawa-common/src/common/dto/DtoMastersGetReq";
import { Cl_master } from "@/functions/Cl_master";
import { DtoMdDtp } from "furukawa-common/src/common/dto/DtoMdDtp";
import Mt_dtp4month from "@/components/atoms/Mt_dtp4month.vue";
import Mt_dtp from "@/components/atoms/Mt_dtp.vue";
import { DtoReportsGetReqImple } from "furukawa-common/src/common/dto/DtoReportsGetReqImple";
import { Cl_report } from "@/functions/Cl_report";
import { DtoReportsPutReq } from "furukawa-common/src/common/dto/abst/DtoReportsPutReq";
import { DtoReportsPostReq } from "furukawa-common/src/common/dto/abst/DtoReportsPostReq";
import axios, { AxiosError } from "axios";
// バリデーション
import * as Yup from "yup";

//store
import store from "@/store";
import { EntityStaff } from "../../../furukawa-common/src/common/entity/EntityStaff";

@Options({
  components: {
    vSelect,
    Mo_modal_alert,
    Or_tableDailyReport,
    Mt_dtp,
    Mt_dtp4month,
  },
})
export default class DailyReportEdit extends Vue {
  // カスタムバリデーション関数を作成
  validateDtoMdDtp = () => {
    return Yup.mixed().test({
      name: "isDtoMdDtp",
      message: "対象日を入力してください",
      test: function (value) {
        // ここで必要なバリデーションロジックを実装する
        // 例：date プロパティが null ではない場合に true を返す
        return (value as DtoMdDtp).date !== null;
      },
    });
  };
  //----------------------
  //バリデーション
  //----------------------
  //スキーマ宣言
  schemaAdd = Yup.object().shape({
    staffs: Yup.string().required("記入者を入力してください"),
  });
  schemaAdd_date = Yup.object().shape({
    dates: this.validateDtoMdDtp().required("対象日を入力してください"),
  });

  //エラー格納用
  errors: Yup.ValidationError | null = null;

  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoReportsGetResImple();
  delegate4list = new DtoMastersGetRes();

  btnDisable = false;

  domStaffId: number;
  domReportDate = new DtoMdDtp();
  reportNumber = "";
  domRemarks = "";

  isEditMode = true;

  //NULL判定のCSSバインド用。
  isNull = false;

  //入力欄の有効無効フラグ
  inputDisable4conf = false;
  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  /**
   * 社員の選択肢を構築する用
   */
  getOpStaffs(): EntityStaff[] {
    return this.delegate4list.getStaffSortNum4NoneDelete([
      this.delegate.c_staff_id!,
    ]);
  }
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalDelConf = "";
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getMastersList();
    await this.get();
  }

  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4Staff4sortNum = 0;
      // dtoMaster.getStaff = 1;
      // dtoMaster.delFlag4staff = 1;
      // dtoMaster.getClients = 1;
      // dtoMaster.delFlag4clients = 1;
      // dtoMaster.getBank = 1;
      // dtoMaster.delFlag4Bank = 1;

      //カレンダー休日反映用
      dtoMaster.getHoliday = 1;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);

      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを読み込む
   */
  async get() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //パラメータにIDが指定されていなければ、新規追加モードでデータを読み込まない
      //-------
      let id = Cl_common.getParam("id");

      //準備
      //-------
      let dto = new DtoReportsGetReqImple();

      dto.c_report_id = Number(id); //APIのURL構成の関係で、請求書Noを請求書IDに入れているので注意
      // dto.flagSales = 1;

      //API実行
      //-------
      this.delegate = await Cl_report.get(dto);
      Cl_common.logger(this.delegate);

      if (this.delegate.c_report_id == null) {
        store.commit("revStore");

        const delegateTmp = this.delegate;
        //新しいdelegateを作成し
        this.delegate = new DtoReportsGetResImple();
        this.delegate.reportNumberPrev = delegateTmp.reportNumberPrev;
        this.delegate.reportNumberNext = delegateTmp.reportNumberNext;
        this.delegate.c_staff_id = store.state.id;
        this.isEditMode = false;
        this.reportNumber = "";
        this.domRemarks = "";
        this.$router.replace("/dailyReportEdit");
        return;
      } else {
        this.isEditMode = true;
      }

      //日付展開
      this.domReportDate.date = this.delegate
        .c_report_date!.toString()
        .split("T")[0];
      // this.payLimit = this.delegate.c_claim_paylimit?.toString().split("T")[0];

      //No展開
      this.reportNumber = this.delegate.c_report_id!.toString();

      this.domRemarks = this.delegate.c_report_remarks!;

      this.inputDisable4conf = false;
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
      this.delegate = new DtoReportsGetResImple();
      this.inputDisable4conf = true;
    } finally {
      //button調整
      // await this.adjButton();
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを追加する
   */
  async add() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      Cl_common.logger("this.domReportDate");
      Cl_common.logger(this.domReportDate);
      Cl_common.logger("this.delegate.c_staff_id");
      Cl_common.logger(this.delegate.c_staff_id);

      //バリデーション
      //-----------------
      this.errors = null;
      this.schemaAdd.validateSync({
        staffs: this.delegate.c_staff_id,
      });
      this.schemaAdd_date.validateSync({
        dates: this.domReportDate,
      });

      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoReportsPostReq();

      //日付をdelegateに戻す
      // this.cnvDetails4Upd();
      dto.c_report_id = 0;
      dto.c_report_flag_del = 0;

      if (this.domReportDate.date != null) {
        this.delegate.c_report_date = new Date(this.domReportDate!.date!);
      } else {
        this.delegate.c_report_date = null;
      }

      //備考欄を戻す
      if (this.domRemarks != null) {
        this.delegate.c_report_remarks = this.domRemarks;
      }

      Cl_common.logger("this.delegate.c_report_remarks");
      Cl_common.logger(this.delegate.c_report_remarks);

      //スタッフ
      // if (this.domStaffId != null) {
      //   this.delegate.c_staff_id = this.domStaffId;
      // }

      //delegateからdtoにデータ投入。
      dto = Object.assign(new DtoReportsPostReq(), this.delegate);

      Cl_common.logger("dto");
      Cl_common.logger(dto);

      //API実行
      //-------
      const res = await Cl_report.post(dto);
      Cl_common.logger("res");
      Cl_common.logger(res);
      //レスポンスの処理
      //-------
      //編集画面のURL作成
      this.$router.push({
        path: "/dailyReportEdit",
        query: { id: res.c_report_id },
      });

      //モーダルのメッセージを設定
      this.message4ModalSuccess = "正常に登録されました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      Cl_common.logger("error");
      if (axios.isAxiosError(error)) {
        if (error.response) {
          if (error.response.data.message === "duplication") {
            //モーダルのメッセージを設定
            this.message4ModalErrorConf =
              "同一の記入者、対象日の日報が既に登録されています。";
          } else {
            //モーダルのメッセージを設定
            this.message4ModalErrorConf = "エラーが発生しました";
          }
          // console.log("エラーレスポンス:", error.response.data.message);
          // console.log("ステータスコード:", error.response.status);
        } else {
          // console.error("通信エラー:", error.message);
          //モーダルのメッセージを設定
          this.message4ModalErrorConf = "エラーが発生しました";
        }
      } else if (error instanceof Yup.ValidationError) {
        Cl_common.logger("errorバリデーション");
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        // その他のエラーの場合
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }

      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger("error");
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを更新する
   */
  async upd(isDel: boolean, isRequest: boolean, isNoModal: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");

    if (!isNoModal) {
      Array.from(panel).map((element: Element) => {
        element.classList.add("enable-loader");
      });
    }

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoReportsPutReq();
      if (isDel) {
        // //削除処理：削除フラグを立てる。
        dto.c_report_id = this.delegate.c_report_id;
        dto.c_report_flag_del = 1;
      } else {
        // //更新処理：delegateからdtoにデータ投入。
        dto = Object.assign(new DtoReportsPutReq(), this.delegate);
        // //日付をDTOに戻す
        if (this.domReportDate != undefined) {
          // console.log(this.claimDate);
          dto.c_report_date = new Date(this.domReportDate.date!);
        }

        //備考欄を戻す
        if (this.domRemarks != null) {
          dto.c_report_remarks = this.domRemarks;
        }

        //スタッフ
        // if (this.domStaffId != null) {
        //   dto.c_staff_id = this.domStaffId;
        // }
      }

      //API実行
      //-------
      await Cl_report.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      if (isDel) {
        this.domReportDate.date = "";
        //削除処理：
        this.message4ModalSuccess = "正常に削除されました";
      } else {
        //更新処理：
        this.message4ModalSuccess = "正常に変更されました";
      }

      //モーダルを表示
      if (!isNoModal) {
        const modal: any = this.$refs.Mo_modal_success;
        modal.showModal();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          if (error.response.data.message === "duplication") {
            //モーダルのメッセージを設定
            this.message4ModalErrorConf =
              "同一の記入者、対象日の日報が既に登録されています。";
          } else {
            //モーダルのメッセージを設定
            this.message4ModalErrorConf = "エラーが発生しました";
          }
          // console.log("エラーレスポンス:", error.response.data.message);
          // console.log("ステータスコード:", error.response.status);
        } else {
          // console.error("通信エラー:", error.message);
          //モーダルのメッセージを設定
          this.message4ModalErrorConf = "エラーが発生しました";
        }
      } else {
        // その他のエラーの場合
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }

      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger("error");
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      if (!isNoModal) {
        this.btnDisable = false;
        Array.from(panel).map((element: Element) => {
          element.classList.remove("enable-loader");
        });
      }
    }
  }

  /**
   * データ削除用のモーダルを表示
   */
  async delConf() {
    //モーダルのメッセージを設定
    this.message4ModalDelConf =
      "日報ID:" +
      this.delegate.c_report_id +
      "　" +
      "を削除します。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.showModal();
  }

  /**
   * データを削除する
   */
  async del() {
    //削除フラグのあるテーブルなので更新処理。
    this.closeModal4deleteConf();
    this.upd(true, false, false);
  }

  /**
   * 指定の日報Noへ遷移
   */
  async goEnt() {
    await this.$router.push("/dailyReportEdit?id=" + this.reportNumber);
    await this.get();
  }

  /**
   * 前の日報に遷移
   */
  async goPrev() {
    if (this.delegate.reportNumberPrev != null) {
      await this.$router.push(
        "/dailyReportEdit?id=" + this.delegate.reportNumberPrev
      );
      await this.get();
    }
  }
  /**
   * 次の日報に遷移
   */
  async goNext() {
    if (this.delegate.reportNumberNext != null) {
      await this.$router.push(
        "/dailyReportEdit?id=" + this.delegate.reportNumberNext
      );
      await this.get();
    }
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4deleteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.closeModal();
  }
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
