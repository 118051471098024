export class EntityReportDet {
public c_report_det_id:number | null = null;
public c_report_id:number | null = null;
public c_report_det_rownum:number | null = null;
public c_report_det_type:number | null = null;
public c_report_det_project_name:string | null = null;
public c_report_det_visited_name:string | null = null;
public c_report_det_location:string | null = null;
public c_report_det_work_contents:string | null = null;
public c_report_det_sales:string | null = null;
public c_report_det_supply:string | null = null;
public c_report_det_alert_date_start:Date | null = null;
public c_report_det_alert_date_end:Date | null = null;
public c_report_det_reg_user:string | null = null;
public c_report_det_reg_at:Date | null = null;
public c_report_det_upd_user:string | null = null;
public c_report_det_upd_at:Date | null = null;
}