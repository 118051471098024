export class EntityMigration {
public c_migration_id:number | null = null;
public c_claim_id:number | null = null;
public c_project_id:number | null = null;
public c_project_id_new:number | null = null;
public c_migration_setting:number | null = null;
public c_migration_eval:number | null = null;
public c_migration_comment:string | null = null;
public c_migration_project_name:string | null = null;
public c_migration_staff_id4sales:number | null = null;
public c_migration_price_sales:number | null = null;
public c_migration_price_supplys:number | null = null;
public c_migration_reg_user:string | null = null;
public c_migration_reg_at:Date | null = null;
public c_migration_upd_user:string | null = null;
public c_migration_upd_at:Date | null = null;
}