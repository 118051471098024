import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoMessagesGetReqImple } from "furukawa-common/src/common/dto/DtoMessagesGetReqImple";
import { DtoMessagesGetResImple } from "furukawa-common/src/common/dto/DtoMessagesGetResImple";
import { DtoMessagesPostReq } from "furukawa-common/src/common/dto/abst/DtoMessagesPostReq";
import { DtoMessagesPostRes } from "furukawa-common/src/common/dto/abst/DtoMessagesPostRes";
import { DtoMessagesPutReq } from "furukawa-common/src/common/dto/abst/DtoMessagesPutReq";
import { DtoMessagesPutRes } from "furukawa-common/src/common/dto/abst/DtoMessagesPutRes";
import { DtoMessagesDeleteReq } from "furukawa-common/src/common/dto/abst/DtoMessagesDeleteReq";
import { DtoMessagesDeleteRes } from "furukawa-common/src/common/dto/abst/DtoMessagesDeleteRes";

export class Cl_message {
  public static async getList(
    dto: DtoMessagesGetReqImple
  ): Promise<DtoMessagesGetResImple> {
    return Object.assign(
      new DtoMessagesGetResImple(),
      await CallAPI.getRequest(
        "/messages?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoMessagesGetReqImple
  ): Promise<DtoMessagesGetResImple> {
    return Object.assign(
      new DtoMessagesGetResImple(),
      await CallAPI.getRequest("/messages/" + dto.c_message_id)
    );
  }

  public static async post(
    dto: DtoMessagesPostReq
  ): Promise<DtoMessagesPostRes> {
    return Object.assign(
      new DtoMessagesPostRes(),
      await CallAPI.postRequest("/messages", dto)
    );
  }

  public static async put(dto: DtoMessagesPutReq): Promise<DtoMessagesPutRes> {
    return Object.assign(
      new DtoMessagesPutRes(),
      await CallAPI.putRequest("/messages/" + dto.c_message_id, dto)
    );
  }

  public static async del(
    dto: DtoMessagesDeleteReq
  ): Promise<DtoMessagesDeleteRes> {
    return Object.assign(
      new DtoMessagesDeleteRes(),
      await CallAPI.deleteRequest("/messages?" + Cl_common.cnvObj2GetParam(dto))
    );
  }
}
