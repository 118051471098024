import { Store } from "vuex";
import { State } from "vue";

/**
 * メニューの表示・非表示を管理するクラス。
 * 管理用のエクセルファイルがあるので、個別に編集するのは禁止。
 */
export class Cl_Authorization_menu {
  //メニュー
  //-------------------
  public showDashboard_1 = false; //メニュー表示フラグダッシュボード
  public showSales_2 = false; //メニュー表示フラグ売上管理
  public showReport_3 = false; //メニュー表示フラグ帳票作成
  public showDaily_4 = false; //メニュー表示フラグ営業管理
  public showMaster_5 = false; //メニュー表示フラグマスタ情報管理
  public showRequest_6 = false; //メニュー表示フラグ申請書管理
  public showAttendance_7 = false; //メニュー表示フラグ勤怠管理
  public showSpecial_8 = false; //メニュー表示フラグ特殊操作
  public showProjectinput_100 = false; //メニュー表示フラグ売上管理（案件情報入力）
  public showProjectsearch_101 = false; //メニュー表示フラグ売上管理（案件情報検索）
  public showSalessearch_102 = false; //メニュー表示フラグ売上管理（売上情報検索）
  public showSuppliersearch_103 = false; //メニュー表示フラグ売上管理（仕入情報検索）
  public showLock_104 = false; //メニュー表示フラグ売上管理（データロック）
  public showSimulation_105 = false; //メニュー表示フラグ売上管理（シミュレーション）
  public showInvsearch_200 = false; //メニュー表示フラグ帳票作成（請求管理）
  public showAnnIndivperfsheet_201 = false; //メニュー表示フラグ帳票作成（年次成績表(個人)）
  public showFinalABClist_202 = false; //メニュー表示フラグ帳票作成（確定ＡＢＣリスト）
  public showSalesforesolo_203 = false; //メニュー表示フラグ帳票作成（売上予想表(単独)）
  public showSalescust_204 = false; //メニュー表示フラグ帳票作成（取引先売上高）
  public showDoclist_205 = false; //メニュー表示フラグ帳票作成（文書管理）
  public showInvcreform_210 = false; //メニュー表示フラグ請求書作成（売上あり）
  public showInvcreformns_211 = false; //メニュー表示フラグ請求書作成（売上なし）
  public showDailyrepinput_300 = false; //メニュー表示フラグ営業管理（日報入力）
  public showDailyrepsearch_301 = false; //メニュー表示フラグ営業管理（日報検索）
  public showSalesdestination_400 = false; //メニュー表示フラグマスタ情報管理（売上先マスタ管理）
  public showSupplier_401 = false; //メニュー表示フラグマスタ情報管理（仕入先マスタ管理）
  public showEmployee_402 = false; //メニュー表示フラグマスタ情報管理（社員マスタ管理）
  public showBankaccount_403 = false; //メニュー表示フラグマスタ情報管理（口座マスタ管理）
  public showBBInfo_404 = false; //メニュー表示フラグマスタ情報管理（掲示板マスタ管理）
  public showBasegoal_405 = false; //メニュー表示フラグマスタ情報管理（拠点目標マスタ管理）
  public showPersonalgoal_406 = false; //メニュー表示フラグマスタ情報管理（個人目標マスタ管理）
  public showSponsor_407 = false; //メニュー表示フラグマスタ情報管理（広告主マスタ管理）
  public showHoliday_408 = false; //メニュー表示フラグマスタ情報管理（休日マスタ管理）
  public showRequestlist_500 = false; //メニュー表示フラグ申請書管理（申請書一覧）
  public showAttendancelist_600 = false; //メニュー表示フラグ勤怠管理（勤怠管理）
  public showAttendanceedit_601 = false; //メニュー表示フラグ勤怠管理（出勤表）
  public showHistory_700 = false; //メニュー表示フラグ特殊操作（操作履歴）
  public showMknxtyrdata_701 = false; //メニュー表示フラグ特殊操作（来期見通しデータ作成）

  //ボタン
  //-------------------
  public btn_creInvoiceNs_10000 = false; //ボタン表示フラグ請求書作成（売上なし）
  public btn_salesdestinationCtrl_10001 = false; //ボタン表示フラグマスタ情報管理（売上先マスタ管理）
  public btn_salesdestinationEditCtrl_10002 = false; //ボタン表示フラグマスタ情報管理（売上先マスタ編集）
  public btn_supplierCtrl_10003 = false; //ボタン表示フラグマスタ情報管理（仕入先マスタ管理）
  public btn_supplierEditCtrl_10004 = false; //ボタン表示フラグマスタ情報管理（仕入先マスタ編集）

  async getAuthorization(store: Store<State>) {
    //ローカルストレージから権限を取得
    //-------------------------
    store.commit("revStore");
    const authType = store.state.authType;

    //フラグを立てる
    //-------------------------
    //メニュー
    //--------------------
    // 1:入力者;2:集計者;3:権限者;4:管理者;5:勤怠者;6:編集者;
    switch (authType) {
      case 1:
        //入力者
        this.showDashboard_1 = true;
        this.showSales_2 = true;
        this.showReport_3 = true;
        this.showDaily_4 = true;
        this.showMaster_5 = true;
        this.showRequest_6 = true;
        this.showAttendance_7 = true;
        this.showProjectinput_100 = true;
        this.showProjectsearch_101 = true;
        this.showSalessearch_102 = true;
        this.showSuppliersearch_103 = true;
        this.showSimulation_105 = true;
        this.showInvsearch_200 = true;
        this.showDoclist_205 = true;
        this.showInvcreform_210 = true;
        this.showDailyrepinput_300 = true;
        this.showDailyrepsearch_301 = true;
        this.showSalesdestination_400 = true;
        this.showSupplier_401 = true;
        this.showSponsor_407 = true;
        this.showRequestlist_500 = true;
        this.showAttendanceedit_601 = true;
        break;

      case 2:
        //集計者
        this.showDashboard_1 = true;
        this.showSales_2 = true;
        this.showReport_3 = true;
        this.showDaily_4 = true;
        this.showMaster_5 = true;
        this.showRequest_6 = true;
        this.showAttendance_7 = true;
        this.showProjectinput_100 = true;
        this.showProjectsearch_101 = true;
        this.showSalessearch_102 = true;
        this.showSuppliersearch_103 = true;
        this.showLock_104 = true;
        this.showSimulation_105 = true;
        this.showInvsearch_200 = true;
        this.showAnnIndivperfsheet_201 = true;
        this.showFinalABClist_202 = true;
        this.showSalesforesolo_203 = true;
        this.showSalescust_204 = true;
        this.showDoclist_205 = true;
        this.showInvcreform_210 = true;
        this.showDailyrepinput_300 = true;
        this.showDailyrepsearch_301 = true;
        this.showSalesdestination_400 = true;
        this.showSupplier_401 = true;
        this.showBasegoal_405 = true;
        this.showPersonalgoal_406 = true;
        this.showSponsor_407 = true;
        this.showRequestlist_500 = true;
        this.showAttendanceedit_601 = true;

        //集計者------
        this.btn_salesdestinationCtrl_10001 = true;
        this.btn_salesdestinationEditCtrl_10002 = true;
        this.btn_supplierCtrl_10003 = true;
        this.btn_supplierEditCtrl_10004 = true;

        break;

      case 3:
        //権限者
        this.showDashboard_1 = true;
        this.showSales_2 = true;
        this.showReport_3 = true;
        this.showDaily_4 = true;
        this.showMaster_5 = true;
        this.showRequest_6 = true;
        this.showAttendance_7 = true;
        this.showProjectinput_100 = true;
        this.showProjectsearch_101 = true;
        this.showSalessearch_102 = true;
        this.showSuppliersearch_103 = true;
        this.showLock_104 = true;
        this.showSimulation_105 = true;
        this.showInvsearch_200 = true;
        this.showAnnIndivperfsheet_201 = true;
        this.showFinalABClist_202 = true;
        this.showSalesforesolo_203 = true;
        this.showSalescust_204 = true;
        this.showDoclist_205 = true;
        this.showInvcreform_210 = true;
        this.showInvcreformns_211 = true;
        this.showDailyrepinput_300 = true;
        this.showDailyrepsearch_301 = true;
        this.showSalesdestination_400 = true;
        this.showSupplier_401 = true;
        this.showEmployee_402 = true;
        this.showBankaccount_403 = true;
        this.showBBInfo_404 = true;
        this.showBasegoal_405 = true;
        this.showPersonalgoal_406 = true;
        this.showSponsor_407 = true;
        this.showRequestlist_500 = true;
        this.showAttendancelist_600 = true;
        this.showAttendanceedit_601 = true;

        //権限者-------
        this.btn_creInvoiceNs_10000 = true;
        this.btn_salesdestinationCtrl_10001 = true;
        this.btn_salesdestinationEditCtrl_10002 = true;
        this.btn_supplierCtrl_10003 = true;
        this.btn_supplierEditCtrl_10004 = true;

        break;

      case 4:
        //管理者
        this.showDashboard_1 = true;
        this.showSales_2 = true;
        this.showReport_3 = true;
        this.showDaily_4 = true;
        this.showMaster_5 = true;
        this.showRequest_6 = true;
        this.showAttendance_7 = true;
        this.showSpecial_8 = true;
        this.showProjectinput_100 = true;
        this.showProjectsearch_101 = true;
        this.showSalessearch_102 = true;
        this.showSuppliersearch_103 = true;
        this.showLock_104 = true;
        this.showSimulation_105 = true;
        this.showInvsearch_200 = true;
        this.showAnnIndivperfsheet_201 = true;
        this.showFinalABClist_202 = true;
        this.showSalesforesolo_203 = true;
        this.showSalescust_204 = true;
        this.showDoclist_205 = true;
        this.showInvcreform_210 = true;
        this.showInvcreformns_211 = true;
        this.showDailyrepinput_300 = true;
        this.showDailyrepsearch_301 = true;
        this.showSalesdestination_400 = true;
        this.showSupplier_401 = true;
        this.showEmployee_402 = true;
        this.showBankaccount_403 = true;
        this.showBBInfo_404 = true;
        this.showBasegoal_405 = true;
        this.showPersonalgoal_406 = true;
        this.showSponsor_407 = true;
        this.showHoliday_408 = true;
        this.showRequestlist_500 = true;
        this.showAttendancelist_600 = true;
        this.showAttendanceedit_601 = true;
        this.showHistory_700 = true;
        this.showMknxtyrdata_701 = true;

        //管理者-------
        this.btn_creInvoiceNs_10000 = true;
        this.btn_salesdestinationCtrl_10001 = true;
        this.btn_salesdestinationEditCtrl_10002 = true;
        this.btn_supplierCtrl_10003 = true;
        this.btn_supplierEditCtrl_10004 = true;

        break;

      case 5:
        //勤怠者
        this.showDashboard_1 = true;
        this.showRequest_6 = true;
        this.showAttendance_7 = true;
        this.showRequestlist_500 = true;
        this.showAttendanceedit_601 = true;
        break;

      case 6:
        //編集者
        this.showDashboard_1 = true;
        this.showSales_2 = true;
        this.showReport_3 = true;
        this.showDaily_4 = true;
        this.showMaster_5 = true;
        this.showRequest_6 = true;
        this.showAttendance_7 = true;
        this.showProjectinput_100 = true;
        this.showProjectsearch_101 = true;
        this.showSalessearch_102 = true;
        this.showSuppliersearch_103 = true;
        this.showLock_104 = true;
        this.showSimulation_105 = true;
        this.showInvsearch_200 = true;
        this.showAnnIndivperfsheet_201 = true;
        this.showFinalABClist_202 = true;
        this.showSalesforesolo_203 = true;
        this.showSalescust_204 = true;
        this.showDoclist_205 = true;
        this.showInvcreform_210 = true;
        this.showInvcreformns_211 = true;
        this.showDailyrepinput_300 = true;
        this.showDailyrepsearch_301 = true;
        this.showSalesdestination_400 = true;
        this.showSupplier_401 = true;
        this.showEmployee_402 = true;
        this.showBankaccount_403 = true;
        this.showBBInfo_404 = true;
        this.showBasegoal_405 = true;
        this.showPersonalgoal_406 = true;
        this.showSponsor_407 = true;
        this.showRequestlist_500 = true;
        this.showAttendancelist_600 = true;
        this.showAttendanceedit_601 = true;

        //編集者-------
        this.btn_creInvoiceNs_10000 = true;
        this.btn_salesdestinationCtrl_10001 = true;
        this.btn_salesdestinationEditCtrl_10002 = true;
        this.btn_supplierCtrl_10003 = true;
        this.btn_supplierEditCtrl_10004 = true;

        break;

      default:
        break;
    }
  }
}
