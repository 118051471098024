
import { DtoApiPager } from "./abst/DtoApiPager";
import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntitySearchSales } from "../entity/EntitySearchSales";

export class DtoSearchSalesGetReq extends EntitySearchSales{

    //パラメーター
    //検索条件
    public projectNo_start: number | null = null;
    public projectNo_end: number | null = null;
    public projectName: string | null = null;
    public proClientid: number | null = null;
    public projectSponsorName: string | null = null;
    //public staffId: number | null = null;
    public staffId: string | null = null;
    public claimNo_start: number | null = null;
    public claimNo_end: number | null = null;
    public salesIssueDate_start: Date | null = null;
    public salesIssueDate_end: Date | null = null;
    public salesMonth_start: Date | null = null;
    public salesMonth_end: Date | null = null;
    public salesItem: number | null = null;
    public salesSubject: number | null = null;
    public salesBreakdown: number | null = null;
    public salesClientid: number | null = null;
    public SalesRemarks: string | null = null;
    public projectType: number | null = null;
    public salesDateExpPayment_start: Date | null = null;
    public salesDateExpPayment_end: Date | null = null;
    public branchId: string | null = null;

    public VariationType: number | null = null;
    public ClientGenre: number | null = null;
    public ItemType: number | null = null;
  
    public pager = new DtoApiPager();

    public IsValidImple(chkPager: boolean): string[] {
        let res: string[] = new Array();
        //ページャー用入力値チェック
        if (chkPager) {
          this.pager.IsValid4Pager(res);
        } else {
          res = res.concat(this.IsValid());
        }
    
        return res;
      }

      public IsValid(): string[] {
        let res: string[] = new Array();
        //単項目チェック
        //--------------
        if (!commonRegx.isMatch(this.c_sales_id!.toString(), "^\\d+$", commonRegx._stdOption)) {
          res.push("type error");
        }
    
        return res;
      }
}