import { Cl_staff } from "./Cl_staff";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import {
  getCognitoUserPoolId4Front,
  getCognitoAppClientId4Front,
} from "furukawa-common/src/common/constant/Constants";
import { Store } from "vuex";
import { State } from "vue";
import { DtoAuthenticationsGetReq } from "furukawa-common/src/common/dto/DtoAuthenticationsGetReq";
import { DtoAuthenticationsGetRes } from "furukawa-common/src/common/dto/DtoAuthenticationsGetRes";
import { Cl_authentication } from "../functions/Cl_authentication";
import { Cl_common } from "./Cl_common";

export class Cl_staffImple extends Cl_staff {
  static async signIn(
    userId: string,
    password: string,
    store: Store<State>
  ): Promise<string[]> {
    //Cognitoへのサインイン。
    //----------------------------
    const authenticationData = {
      Username: userId,
      Password: password,
    };

    const authenticationDetails =
      new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

    const poolData = {
      UserPoolId: getCognitoUserPoolId4Front(),
      ClientId: getCognitoAppClientId4Front(),
      Storage: sessionStorage,
    };

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    const userData = {
      Username: userId,
      Pool: userPool,
      Storage: sessionStorage,
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    const authenticateUser = async (): Promise<string[]> => {
      return new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
          newPasswordRequired: (userAttributes, requiredAttributes) => {
            const err = new Error("newPasswordRequired");
            reject(err);
          },
          onFailure: (err) => {
            reject(err);
          },
          onSuccess: (result) => {
            const accessToken = result.getAccessToken().getJwtToken();
            const idToken = result.getIdToken().getJwtToken();
            const refreshToken = result.getRefreshToken().getToken();
            const idTokenExp = result.getIdToken().getExpiration();

            const tokens: string[] = [
              accessToken,
              idToken,
              refreshToken,
              idTokenExp.toString(),
            ];
            resolve(tokens);
          },
        });
      });
    };

    const accessTokens: string[] = await authenticateUser();

    store.commit("updToken_id", accessTokens[1]);
    store.commit("updToken_upd", accessTokens[2]);
    store.commit("updExp_token", Number(accessTokens[3]));

    try {
      //APIを実行して動作確認。
      //----------------------------
      const dto = new DtoAuthenticationsGetReq();
      const data = await Cl_authentication.get(dto);

      //データ入力
      //----------------------------
      store.commit("updName", data.c_staff_name);
      store.commit("updId", data.c_staff_id);
      store.commit("updAuthType", data.c_staff_auth_type);
      // store.commit("updFlagAuthClaim", data.c_staff_flag_auth_claim);
      // store.commit("updFlagAuthListexprt", data.c_staff_flag_auth_listexprt);
      // store.commit("updFlagAuthReport", data.c_staff_flag_auth_report);
    } catch (error) {
      //ローカルストレージをクリアした後に呼び出し元に例外をスロー
      Cl_common.logger(error);
      store.commit("clearStore");
      throw error;
    }

    return accessTokens;
  }
  /**
   * リフレッシュトークンを使用してIDトークンを更新する
   */
  static async tokenRefresh(store: Store<State>): Promise<boolean> {
    const poolData = {
      UserPoolId: getCognitoUserPoolId4Front(),
      ClientId: getCognitoAppClientId4Front(),
    };

    // ログイン時に保管していたアクセストークンの有効期限
    store.commit("revStore");
    const exp = store.state.exp_token * 1000;
    const now = new Date().getTime();

    // アクセストークの有効期限が切れていた場合

    if (now > exp) {
      const username = store.state.username;
      const token = store.state.token_upd;

      // ユーザープールの生成
      const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
      const userData = { Username: username, Pool: userPool };
      const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

      // 更新トークンの設定
      const refreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({
        RefreshToken: token,
      });

      // トークン情報更新
      const authenticateUser = async (): Promise<boolean> => {
        return new Promise((resolve, reject) => {
          cognitoUser.refreshSession(refreshToken, function (err, session) {
            if (err == null) {
              store.commit("updToken_id", session.idToken.jwtToken);
              store.commit("updToken_upd", session.refreshToken.token);
              store.commit("updExp_token", session.idToken.payload.exp);

              // console.log(session);
              resolve(true);
            } else {
              //更新できなかったらログアウト
              // console.log((err as Error).message);
              if ((err as Error).message == "Refresh Token has expired") {
                Cl_staffImple.signOut(store);
              }

              resolve(false);
            }
          });
        });
      };

      return await authenticateUser();
    }

    return false;
  }

  /**
   * サインアウトする
   */
  static async signOut(store: Store<State>) {
    const poolData = {
      UserPoolId: getCognitoUserPoolId4Front(),
      ClientId: getCognitoAppClientId4Front(),
    };

    const username = store.state.username;

    // ユーザープールの生成
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const userData = { Username: username, Pool: userPool };
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    if (cognitoUser) {
      // ログアウト
      cognitoUser.signOut();
    }
    //ローカルストレージ削除
    store.commit("clearStore");
    //画面遷移
    location.href = "/";
  }
}
