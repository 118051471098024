
import { Options, Vue, Watch } from "vue-property-decorator";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { Cl_common } from "@/functions/Cl_common";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import Mo_modal_max_force from "@/components/molecules/Mo_modal_max_force.vue";
import Mo_modal_l from "@/components/molecules/Mo_modal_l.vue";
import {
  CONST_APPROVAL_STATUS_SYOUNIN,
  CONST_OP_DOC_ISSUER,
  CONST_OP_DOC_TYPE,
  CONST_OP_DOC_MANAGETYPE,
  CONST_REQUEST_STATUS_SASIMODOSI,
  CONST_REQUEST_STATUS_SINSEITYU,
  CONST_REQUEST_STATUS_SYOUNINZUMI,
  CONST_REQUEST_STATUS_TORISAGE,
} from "furukawa-common/src/common/constant/Constants";
import { DtoMdDtp } from "furukawa-common/src/common/dto/DtoMdDtp";
import Mt_dtp from "@/components/atoms/Mt_dtp.vue";

//doc
import { Cl_doc4det } from "@/functions/Cl_doc4det";
import { DtoDocsGetReqImple } from "furukawa-common/src/common/dto/DtoDocsGetReqImple";
import { DtoDocsGetResImple } from "furukawa-common/src/common/dto/DtoDocsGetResImple";
import { DtoDocsPostReqImple } from "furukawa-common/src/common/dto/DtoDocsPostReqImple";
import { DtoDocsPostResImple } from "furukawa-common/src/common/dto/DtoDocsPostResImple";
import { DtoDocsPutReqImple } from "furukawa-common/src/common/dto/DtoDocsPutReqImple";
import { DtoDocsPutResImple } from "furukawa-common/src/common/dto/DtoDocsPutResImple";
import { DtoDocfilesGetReqImple } from "furukawa-common/src/common/dto/DtoDocfilesGetReqImple";
import { DtoDocfilesGetResImple } from "furukawa-common/src/common/dto/DtoDocfilesGetResImple";

// masters
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersgetReq } from "furukawa-common/src/common/dto/DtoMastersGetReq";
import { DtoMastersGetRes } from "furukawa-common/src/common/dto/DtoMastersGetRes";

//store
import store from "@/store";
import { CommonStaff } from "furukawa-common/src/common/service/CommonStaff";
// バリデーション
import * as Yup from "yup";

//file
import { Cl_filemaster } from "@/functions/Cl_filemaster";
import { DtoFileMastersGetReqImple } from "furukawa-common/src/common/dto/DtoFileMastersGetReqImple";
import { Cl_doc } from "@/functions/Cl_doc";
import dropzone from "@/components/organisms/Or_fileupload.vue";
import Or_pdfviewer from "@/components/organisms/Or_pdfviewer.vue";
import axios from "axios";
import { EntityDocfileImple } from "../../../furukawa-common/src/common/entity/EntityDocfileImple";
import { Cl_docfile } from "@/functions/Cl_docfile";
import { EntityStaff } from "furukawa-common/src/common/entity/EntityStaff";
import { EntityHistoryImple } from "furukawa-common/src/common/entity/EntityHistoryImple";

@Options({
  components: {
    vSelect,
    Mo_modal_alert,
    Mt_dtp,
    dropzone,
    Mo_modal_max_force,
    Or_pdfviewer,
    Mo_modal_l,
  },
})
export default class DocEdit extends Vue {
  //---------------------------------

  //----------------------
  //バリデーション
  //----------------------
  //スキーマ宣言

  //エラー格納用
  errors: Yup.ValidationError | null = null;
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoDocsGetResImple();
  delegate4list = new DtoMastersGetRes();
  domPrice = "0";
  domRemarks = "";

  //新規時のファイル用
  //-------
  fileInfo: {
    path: string;
    name: string;
    size: number;
    type: string;
    lastMidified: number;
    data: any;
    isExcute: boolean;
  } = {
    path: "",
    name: "",
    size: -1,
    type: "",
    lastMidified: 0,
    data: null,
    isExcute: false,
  };
  /**
   * ファイルアップロードモーダルで処理されたデータの受け渡しに使用される。
   *
   * @param newFileInfo
   */
  updateFileInfo(newFileInfo: {
    path: string;
    name: string;
    size: number;
    type: string;
    lastMidified: number;
    data: any;
    isExcute: boolean;
  }) {
    this.fileInfo = newFileInfo;
  }
  //更新時のファイル用
  //-------
  fileInfo4Upd: {
    path: string;
    name: string;
    size: number;
    type: string;
    lastMidified: number;
    data: any;
    isExcute: boolean;
  } = {
    path: "",
    name: "",
    size: -1,
    type: "",
    lastMidified: 0,
    data: null,
    isExcute: false,
  };
  /**
   * ファイルアップロードモーダルで処理されたデータの受け渡しに使用される。
   *
   * @param newFileInfo
   */
  updateFileInfo4Upd(newFileInfo: {
    path: string;
    name: string;
    size: number;
    type: string;
    lastMidified: number;
    data: any;
    isExcute: boolean;
  }) {
    this.fileInfo4Upd = newFileInfo;
  }
  //-------

  btnDisable = false;
  isEditMode = true;
  domStaffName = "-";

  //日付関係
  domConsDate: DtoMdDtp = new DtoMdDtp();

  //画僧関係
  item4main = new EntityDocfileImple();
  showItem4main = true;

  //----------------------
  //DOM連携用メソッド
  //----------------------
  convDateTime(val: Date | null): string {
    let res = "";

    if (val == null) {
      res = "";
    } else {
      res = val
        .toString()
        .replaceAll("-", "/")
        .replace("T", " ")
        .replace(".000Z", "");
    }

    return res;
  }

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  op4DocType = CONST_OP_DOC_TYPE;
  op4DocManageType = CONST_OP_DOC_MANAGETYPE;
  op4DocIssuer = CONST_OP_DOC_ISSUER;
  opEnable = [
    { label: "無効", code: 0 },
    { label: "有効", code: 1 },
  ];
  /**
   * 社員の選択肢を構築する用
   */
  getOpStaffs(): EntityStaff[] {
    return this.delegate4list.getStaffSortNum4NoneDelete([
      this.delegate.c_staff_id4project!,
    ]);
  }

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getMastersList();
    await this.get();
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4Staff4sortNum = 0;
      // dtoMaster.getStaff = 1;
      // dtoMaster.delFlag4staff = 0;

      //カレンダー休日反映用
      dtoMaster.getHoliday = 1;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);

      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  async get() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //パラメータにIDが指定されていなければ、新規追加モードでデータを読み込まない
      //-------
      let id = Cl_common.getParam("id");

      if (id == "") {
        //新規追加
        this.isEditMode = false;

        //初期値設定
        this.delegate = new DtoDocsGetResImple();
        this.delegate.c_doc_id = null;
        this.delegate.c_doc_name = "名称未設定文書";
        this.delegate.c_staff_id = store.state.id;
        this.delegate.c_doc_customer = "";
        this.delegate.c_doc_customerstaff = "";
        this.delegate.c_doc_date = new Date();
        this.delegate.c_doc_flag_issuer = 0;
        this.delegate.c_doc_flag_reserve = 0;
        this.delegate.c_doc_flag_enab = 1;
        this.delegate.c_doc_flag_del = 0;

        this.showModal4FileUp();

        return;
      } else {
        //編集
        this.isEditMode = true;
      }

      //準備
      //-------
      let dto = new DtoDocsGetReqImple();

      dto.c_doc_id = Number(id);

      //API実行
      //-------
      this.delegate = await Cl_doc.get(dto);
      //レスポンス処理
      //-------
      //IDがnullなら一覧へ遷移
      if (this.delegate.c_doc_id == null) {
        this.$router.push("/doclist");
      }

      //UI連携用変数に入力
      //---
      await this.cnvData4Get();

      //画像の表示
      //---
      let res = "";
      if (
        this.delegate.items4docFile == null ||
        this.delegate.items4docFile[0] == null ||
        this.delegate.items4docFile[0].url4Show == null
      ) {
        // this.domSrcImg4Main = "/img/invoicedemo.png";
      } else {
        this.item4main =
          this.delegate.items4docFile[this.delegate.items4docFile.length - 1];
      }
    } catch (error) {
      Cl_common.logger(error);

      if (axios.isAxiosError(error)) {
        if (error.response) {
          if (error.response.data.message === "no auth") {
            //モーダルのメッセージを設定
            this.message4ModalErrorConf = "表示権限がありません";
            this.$router.push("/doclist");
          } else {
            //モーダルのメッセージを設定
            this.message4ModalErrorConf = "エラーが発生しました";
          }
        } else {
          this.message4ModalErrorConf = "エラーが発生しました";
        }
      } else {
        // その他のエラーの場合
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }

      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * DOM連携用の変換処理　GET用。
   */
  async cnvData4Get() {
    //作成者
    if (this.delegate.c_staff_id == null) {
      this.domStaffName = "-";
    } else {
      this.domStaffName = CommonStaff.cnvStaffNum2Name(
        this.delegate4list.items4staffsSortNum,
        this.delegate.c_staff_id
      );
    }

    //発行年月日
    let tmp = new DtoMdDtp();
    tmp = new DtoMdDtp();
    if (this.delegate.c_doc_date != null) {
      this.domConsDate.date = this.delegate
        .c_doc_date!.toString()
        .split("T")[0];
    } else {
      this.domConsDate.date = null;
    }

    //金額
    if (this.delegate.c_doc_price != null) {
      this.domPrice = this.delegate.c_doc_price.toString();
      while (
        this.domPrice !=
        (this.domPrice = this.domPrice.replace(/^(-?\d+)(\d{3})/, "$1,$2"))
      );
    }

    //備考
    if (this.delegate.c_doc_remarks != null) {
      this.domRemarks = this.delegate.c_doc_remarks;
    }
  }
  /**
   * DOM連携用の変換処理　UPD用。
   */
  async cnvDetails4Upd() {
    //発行年月日
    if (this.domConsDate != null && this.domConsDate.date != null) {
      this.delegate.c_doc_date = new Date(this.domConsDate.date);
    } else {
      this.delegate.c_doc_date = null;
    }

    //金額を戻す
    if (this.domPrice != null) {
      this.delegate.c_doc_price = Number(this.domPrice);
    }

    //備考欄を戻す
    if (this.domRemarks != null) {
      this.delegate.c_doc_remarks = this.domRemarks;
    }
  }

  /**
   * メインの画像を変更する。
   * @param item
   */
  async chgMainImg(item: EntityDocfileImple) {
    if (this.btnDisable || this.item4main == item) {
      return;
    }

    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      Cl_common.logger(item);
      //画像入れ替え。
      this.item4main = item;

      //リアクティブに変更されないので、一旦falseにして、1秒後にtrueにする。
      this.showItem4main = !this.showItem4main;
      await this.delay(1000);
      this.showItem4main = !this.showItem4main;
    } catch (error) {
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  async delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  /**
   * データを更新する
   */
  @Watch("fileInfo4Upd")
  async updByImg() {
    await this.upd(true);
  }

  async upd(isImgUpd: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //バリデーション
      //-------

      //準備
      //-------
      let dto = new DtoDocsPutReqImple();

      //domからdelegateに戻す
      this.cnvDetails4Upd();

      //更新処理：delegateからdtoにデータ投入。
      dto = Object.assign(new DtoDocsPutReqImple(), this.delegate);

      //ファイル
      if (isImgUpd) {
        //ファイルのアップロード処理も行う。
        dto.filename = this.fileInfo4Upd.name;
        dto.filetype = this.fileInfo4Upd.type;
        dto.filesize = this.fileInfo4Upd.size;
      }

      //ID設定
      let id = Cl_common.getParam("id");
      dto.c_doc_id = Number(id);

      Cl_common.logger(dto);

      //API実行
      //-------
      const res = await Cl_doc.put(dto);
      //レスポンスの処理
      //-------

      Cl_common.logger(res);

      //ファイル
      if (isImgUpd) {
        //アップロード用のURLが返されたら、アップロード処理を行う。
        if (res.url == null || res.url === "") {
          this.message4ModalErrorConf = "ファイルのアップロードに失敗しました";
          //モーダル表示
          const modal: any = this.$refs.Mo_modal_errorConf;
          modal.showModal();
          return;
        } else {
          await axios.put(res.url!, this.fileInfo4Upd.data);
        }
      }

      //更新処理：
      //モーダルのメッセージを設定
      this.message4ModalSuccess = "正常に変更されました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを新たに登録する
   * Or_fileuploadにファイルが投入されると、このメソッドが呼ばれる。
   */
  @Watch("fileInfo")
  async add() {
    Cl_common.logger(this.fileInfo);
    if (this.fileInfo.size <= 0) {
      return;
    }

    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理が始まったら、アップロード用モーダルは閉じる。
      this.closeModal4FileUp();

      //バリデーション
      //-----------------

      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      //UI連携用変数からdelegateに戻す
      //---

      let dto = Object.assign(new DtoDocsPostReqImple(), this.delegate);

      dto.filename = this.fileInfo.name;
      dto.filetype = this.fileInfo.type;
      dto.filesize = this.fileInfo.size;

      Cl_common.logger("API実行");
      //API実行
      //-------
      const res = await Cl_doc.post(dto);

      Cl_common.logger(res);
      //レスポンスの処理
      //-------
      //アップロード用のURLが返されたら、アップロード処理を行う。
      if (res.url == null || res.url === "") {
        this.message4ModalErrorConf = "ファイルのアップロードに失敗しました";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      } else {
        await axios.put(res.url!, this.fileInfo.data);
      }

      //-------
      //アップロードしたファイルからデータを読み取る処理を呼び出す。
      //-------

      //-------
      //編集画面のURL作成
      this.$router.push({
        path: "/docedit",
        query: { id: res.c_doc_id },
      });

      //モーダルのメッセージを設定
      this.message4ModalSuccess = "正常に追加されました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * ファイルをダウンロードする
   */
  async dlFile() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });
    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");
      //準備
      //-------
      let dto = new DtoDocfilesGetReqImple();
      dto = Object.assign(new DtoDocfilesGetReqImple(), this.item4main);

      //API実行
      //-------
      const res = await Cl_docfile.get(dto);
      Cl_common.logger(res);
      Cl_common.logger(res.url);
      //レスポンス処理
      //-------
      location.href = res.url!;

      //レスポンスの処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  //-------------------
  //画像操作用メソッド
  //-------------------
  /**
   * 画像を拡大する
   */
  async zoomIn() {
    // 要素をIDで取得
    const element = document.getElementById("frame-img");

    // 要素が存在し、幅が設定されているか確認
    if (element && element.style.width) {
      // 現在の幅を取得（パーセントとして）
      const currentWidthStr = element.style.width;
      const currentWidth = parseFloat(currentWidthStr);

      if (currentWidth >= 80) {
        return;
      }

      // 幅を10%減少
      const newWidth = currentWidth + 10;

      // 新しい幅を設定
      element.style.width = `${newWidth}%`;

      this.showItem4main = !this.showItem4main;
      await this.delay(1000);
      this.showItem4main = !this.showItem4main;
    } else {
      console.log(
        "指定されたIDの要素が見つからないか、幅が設定されていません。"
      );
    }
  }
  /**
   * 画像を縮小する
   */
  async zoomOut() {
    // 要素をIDで取得
    const element = document.getElementById("frame-img");

    // 要素が存在し、幅が設定されているか確認
    if (element && element.style.width) {
      // 現在の幅を取得（パーセントとして）
      const currentWidthStr = element.style.width;
      const currentWidth = parseFloat(currentWidthStr);

      if (currentWidth <= 30) {
        return;
      }

      // 幅を10%減少
      const newWidth = currentWidth - 10;

      // 新しい幅を設定
      element.style.width = `${newWidth}%`;

      this.showItem4main = !this.showItem4main;
      await this.delay(1000);
      this.showItem4main = !this.showItem4main;
    } else {
      console.log(
        "指定されたIDの要素が見つからないか、幅が設定されていません。"
      );
    }
  }

  /**
   * 金額セル入力時の処理
   * カンマを消す
   * @param obj
   */
  kanmaDel(obj: any) {
    // //カンマを消す
    obj.target.value = obj.target.value.replace(/,/g, "");
    if (obj.target.value == "0") obj.target.value = "";
  }
  /**
   * 金額セル入力時の処理
   * カンマを付ける
   * @param obj
   */
  kanmaIns(obj: any) {
    let val: string = obj.target.value;

    //うっかり入力しているかもしれないカンマを消す
    val = val.replace(/,/g, "");
    //整数に変換したのち文字列に戻す
    //この時点で数字とマイナス記号だけが残る
    let num = "" + parseInt(val);
    //正規表現で桁区切りするための良く見かける関数的な何か
    //変数 num の中身が、桁区切りされる
    while (num != (num = num.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
    //numに入っている値が数値じゃないときは0とする
    if (isNaN(parseInt(num))) num = "0";
    //桁区切りした結果（変数 num）でテキストボックスの中身を書き換える
    obj.target.value = num;
  }

  goTo(url: string, event?: MouseEvent) {
    if (event && (event.ctrlKey || event.metaKey)) {
      window.open(url);
    } else {
      this.$router.push(url);
    }
  }

  /**
   * EntityHistoryImpleを受け取り、表示用の文字列に変換する。
   * @param item
   */
  cnvLogdata2Str(item: EntityHistoryImple): string {
    const dateStr = item
      .c_history_datetime!.toString()
      .replaceAll("-", "/")
      .replace("T", " ")
      .replace(".000Z", "");

    return dateStr + "　" + item.c_staff_name;
  }

  /**
   *delegate.c_doc_typeの値を、表示用の文字列に変換する。
   */
  cnvDocType2Name(): string {
    let res = "";
    if (this.delegate.c_doc_type == null) {
      res = "-";
    } else {
      res = CONST_OP_DOC_TYPE.find(
        (x) => x.code === this.delegate.c_doc_type
      )!.label;
    }

    return res;
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  /**
   * ファイルアップロードモーダルを表示する。
   * 追加登録時。
   */
  async showModal4FileUpByUpd() {
    //-------
    const modalComp: any = this.$refs.Mo_modal_fileup4upd;
    modalComp.showModal();
  }

  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
  async closeModal4FileUp(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_fileup;
    modal.closeModal();
  }
  async closeModal4FileUpByUpd(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_fileup4upd;
    modal.closeModal();
  }
  async showModal4FileUp(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_fileup;
    modalComp.showModal();
  }
}
