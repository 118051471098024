
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import Mo_modal_l from "@/components/molecules/Mo_modal_l.vue";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_modal_l,
  },
})
export default class Or_tableDouble extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  optionsOp = [
    { label: "ADK" },
    { label: "ATA" },
    { label: "エフグラム プロダクト" },
    { label: "hit" },
    { label: "JALPAK" },
    { label: "JALUX" },
    { label: "JIC" },
    { label: "NKB" },
    { label: "no problem LLC." },
    { label: "OMS" },
    { label: "OOHメディア・ソリューション" },
    { label: "Rooftop" },
    { label: "スパーダ" },
    { label: "TOW" },
    { label: "TVC" },
    { label: "アーチェリー" },
    { label: "アイ・アンドキュ－・アドバタイジング" },
    { label: "アウトドア・メディア・サービス" },
    { label: "あかうみ" },
    { label: "アクト計画" },
    { label: "アサツーディ・ケイ" },
    { label: "アシストコガ" },
    { label: "アディダスジャパン" },
    { label: "アド・トゥモロー" },
    { label: "アド電通大阪" },
    { label: "アド・ブレーン" },
    { label: "アドギア" },
    { label: "アドマックス" },
    { label: "アフィックス" },
    { label: "アプレックス" },
    { label: "アルセス" },
    { label: "アルビレックス新潟" },
    { label: "いすゞ自動車首都圏" },
    { label: "インターナショナルクリエイティブ" },
    { label: "エー・ティ・エー" },
    { label: "エムシ－ドゥコ－" },
    { label: "エイベックス・ライブ・クリエイティブ" },
    { label: "オゾンネットワーク" },
    { label: "オリコム" },
    { label: "ガリバーインターナショナル" },
    { label: "キッザニア東京" },
    { label: "キョ－エイ・アド・インタ－ナショナル" },
    { label: "キョウシンアド" },
    { label: "キリンビバレッジ" },
    { label: "クオリケーション" },
    { label: "クオリケーション・他" },
    { label: "クラウドポイント" },
    { label: "クリスマス・カンパニー" },
    { label: "ケイト・スペード　ジャパン" },
    { label: "ケシオン" },
    { label: "コミュニケーションアド" },
    { label: "コンセルジェ" },
    { label: "サマンサタバサジャパンリミテッド" },
    { label: "サンエー/アウトドア" },
    { label: "サンエー・インターナショナル" },
    { label: "シーツープロモーション" },
    { label: "シブヤテレビジョン" },
    { label: "ジャングルジャムプロモーション" },
    { label: "ジュンアンドロペエンターテイメント" },
    { label: "ジンコーポレーション" },
    { label: "ゼ・ファー" },
    { label: "セイストン" },
    { label: "セントラルスポーツ" },
    { label: "ゼンハイザージャパン" },
    { label: "ターキッシュエアラインズ　東京支社" },
    { label: "タワーレコード" },
    { label: "チャイナエアライン" },
    { label: "ディー・エヌ・エー" },
    { label: "ディラン" },
    { label: "テレビ朝日" },
    { label: "テレビ朝日　広報局宣伝部" },
    { label: "テレビ朝日クリエイト" },
    { label: "テレビ朝日ミュージック" },
    { label: "ドゥコ－" },
    { label: "トッパンＴＤＫレーベル" },
    { label: "トピー工業" },
    { label: "ドライブシール" },
    { label: "ドリーミュージック" },
    { label: "ドリル" },
    { label: "ナイキジャパン" },
    { label: "パイオニア企画" },
    { label: "パシフィックメディア" },
    { label: "ハッピーライフ" },
    { label: "ピー・プランニング" },
    { label: "ハブカンパニ－" },
    { label: "フェイス" },
    { label: "ブラスアンドカンパニー" },
    { label: "プラム・ブロッサム・プロモーション" },
    { label: "プランネット" },
    { label: "ブランニューミュージック" },
    { label: "フロンティアインタ－ナショナル" },
    { label: "フロンテッジ" },
    { label: "ベストクルーズ" },
    { label: "ベストコミュニケーションズ" },
    { label: "ベトナム航空　日本支社" },
    { label: "ボウス" },
    { label: "ホワイトナイト" },
    { label: "ホワイトナイト／ビジネスファンタジア" },
    { label: "ホワイトボックス" },
    { label: "ホンマ装美" },
    { label: "マードゥレクス" },
    { label: "マックウィンクリエイト" },
    { label: "ミディアム" },
    { label: "ムサシノ広告社" },
    { label: "メディアポート成田" },
    { label: "メトロアドエージェンシー" },
    { label: "ラ・ポルト青山 テナント会" },
    { label: "ユナイテッドライツ" },
    { label: "ワイデン+ケネディ トウキョウ" },
    { label: "医療法人　有美加" },
    { label: "角川メディアハウス" },
    { label: "ION 東京営業所" },
    { label: "エイシンプランニグ" },
    { label: "CLUB21 JAPAN" },
    { label: "DSC" },
    { label: "アンデザイン" },
    { label: "サドルバック" },
    { label: "ジャビック" },
    { label: "ピコトン" },
    { label: "モスフードサービス" },
    { label: "モデア" },
    { label: "リアライズ" },
    { label: "外為ジャパン" },
    { label: "博報堂" },
    { label: "近宣　東京支社" },
    { label: "山下商会" },
    { label: "電通" },
    { label: "電通(小林工芸社)" },
    { label: "電通（ＯＯＨ）" },
    { label: "電通(一部TOW)" },
    { label: "電通(一部ケシオン)" },
    { label: "電通(電通テック)" },
    { label: "博報堂ＤＹメディアパートナーズ" },
    { label: "博報堂プロダクツ" },
    { label: "リンクアップビューティー" },
    { label: "賃貸不動産ニュース（生和コーポレーション）" },
    { label: "博報堂DYアドステーション" },
    { label: "博報堂マグネット" },
    { label: "毎日広告社" },
    { label: "矢動丸プロジェクト" },
    { label: "エイシンプランニング" },
    { label: "ジュンアンドロペ・エンターテイメント" },
    { label: "岩見沢市観光協会" },
    { label: "京成エージェンシー" },
    { label: "京成建設" },
    { label: "協立広告" },
    { label: "古川 東京" },
    { label: "古川広告社　東京支社" },
    { label: "古川広告社、OOHメディア・ソリューション他" },
    { label: "公和印刷" },
    { label: "弘亜社" },
    { label: "高島屋ＡＴＡ" },
    { label: "済州航空" },
    { label: "三井住友信託銀行" },
    { label: "三井不動産" },
    { label: "首都高速道路" },
    { label: "住友不動産" },
    { label: "春光社" },
    { label: "松林整形外科" },
    { label: "小林工芸社（電通）" },
    { label: "昭通" },
    { label: "城南進学研究社" },
    { label: "生和コーポレーション" },
    { label: "森ビル" },
    { label: "森ビル流通システム" },
    { label: "大広" },
    { label: "大日本インキ" },
    { label: "大日本インキ化学工業" },
    { label: "大和通信社" },
    { label: "中央三井信託銀行" },
    { label: "電子広告社" },
    { label: "電通アドギア" },
    { label: "電通テック" },
    { label: "東急エージェンシー" },
    { label: "東急スペース" },
    { label: "東急スペース開発・トピー工業株式会社" },
    { label: "東京シティ・エアターミナル" },
    { label: "東京空港交通" },
    { label: "東京工芸大学" },
    { label: "東京工芸大学（笠尾先生）" },
    { label: "東京大学" },
    { label: "一般財団法人東京都営交通協力会" },
    { label: "東京都交通局資産運用部事業開発課" },
    { label: "東和産業" },
    { label: "東信企業" },
    { label: "特定非営利法人ｸﾘｴｲﾃｨﾌﾞｽﾏｲﾙ" },
    { label: "読広クロスコム" },
    { label: "日光久指圧院　はり灸院" },
    { label: "日広通信社" },
    { label: "日本コロムビア" },
    { label: "日本スタデオ" },
    { label: "日本経済社" },
    { label: "日本コカ・コーラ" },
    { label: "福田紀彦　事務所" },
    { label: "宝広告社" },
    { label: "宝広告社　東京支社" },
    { label: "北海道広告" },
    { label: "北青山プロパティー" },
    { label: "クー" },
    { label: "藤島興産" },
    { label: "ジャングルジャムプロモーション" },
    { label: "合同会社北青山プロパティー" },
    { label: "合同産業" },
    { label: "阪急阪神ビルマネジメント" },
    { label: "売上先なし" },
    { label: "売上先未定" },
    { label: "警視総監" },
    { label: "共和企画" },
    { label: "東京都総務局長" },
    { label: "三晃" },
    { label: "ディプトリクスリテール" },
    { label: "東京都産業労働局" },
    { label: "共同エージェンシー" },
    { label: "ユニカ" },
    { label: "近宣　東京支店" },
    { label: "JTBコミュニケーションデザイン" },
    { label: "G-Production" },
    { label: "ピュア" },
    { label: "トイボックス" },
    { label: "キングレコード" },
    { label: "日本経済広告社" },
    { label: "中国南方航空" },
    { label: "東京都都市整備局" },
    { label: "日本航空" },
    { label: "古川広告社　大阪本社" },
    { label: "東京タカラ商会" },
    { label: "ミューカ" },
    { label: "古川広告社　名古屋営業所" },
    { label: "LDH JAPAN" },
    { label: "キッズプロモーション" },
    { label: "商店街振興組合原宿表参道欅会" },
    { label: "リバーフィールド社" },
    { label: "ルート" },
    { label: "ハバス　ワールドワイド　ジャパン" },
    { label: "ファンクス" },
    { label: "東京都生活文化局" },
    { label: "JUN" },
    { label: "キャトル" },
    { label: "クオラス" },
    { label: "商店街振興組合原宿表参道欅会" },
    { label: "ナノ・ユニバース" },
    { label: "イナケン建設事務所" },
    { label: "リネン　アンド　カンパニー" },
    { label: "メーカーズシャツ鎌倉" },
    { label: "タップ" },
    { label: "東京都消費生活総合センター" },
    { label: "博報堂DYアウトドア" },
    { label: "コスモ・コミュニケーションズ" },
    { label: "エヌケービー" },
    { label: "I&S BBDO" },
    { label: "コミュニケーションアド" },
    { label: "スタジオマジック・イノベーションズ" },
    { label: "読売広告社" },
    { label: "ネクステージ" },
    { label: "東京都福祉保健局長" },
    { label: "東急ホームズ" },
    { label: "オーイズミフーズ" },
    { label: "森ビル" },
    { label: "大和屋ふとん" },
    { label: "東急不動産" },
    { label: "LOT JAPAN" },
    { label: "SANEI" },
    { label: "ＥＧ八王子" },
    { label: "ヒット" },
    { label: "アドインテ" },
    { label: "ガルーダ・インドネシア航空会社" },
    {
      label:
        "インドネシア共和国観光省　ビジットインドネシアツーリズムオフィス日本地区事務所",
    },
    { label: "パティオ" },
    { label: "ＵＭＡＭＩ　ＢＵＲＧＥＲ　ＪＡＰＡＮ" },
    { label: "古川広告社　福岡営業所" },
    { label: "公益財団法人ユニジャパン" },
    { label: "JR西日本コミュニケーションズ" },
    { label: "スリーエープロダクト" },
    { label: "テレビ朝日" },
    { label: "パス・コミュニケーションズ" },
    { label: "シネブリッジ" },
    { label: "博報堂" },
    { label: "モードメディア・ジャパン" },
    { label: "フジ産業" },
    { label: "グリーンポート・エージェンシー" },
    { label: "エヌ・ティ・ティ・アド" },
    { label: "ジェイ・ウォルター・トンプソンジャパン合同会社" },
    { label: "メディアコンシェルジュ" },
    { label: "ショートショート実行委員会" },
    { label: "合同会社PVH JAPAN" },
    { label: "ショートショート アジア実行委員会" },
    { label: "アルビオン" },
    { label: "ジャガー・ランドローバー・ジャパン" },
    { label: "リップルリンク" },
    { label: "ニューアド社" },
    { label: "パシフィックゴルフマネージメント" },
    { label: "大和通信社" },
    { label: "東京ハーヴェスト実行委員会 オイシックス・ラ・大地" },
    { label: "東京都青少年・治安対策本部長" },
    { label: "クオリタ" },
    { label: "zenplus" },
    { label: "富士フイルムイメージングシステムズ" },
    { label: "プロエスト" },
    { label: "ペルノ・リカール・ジャパン" },
    { label: "アルファトラスト" },
    { label: "ADKマーケティング・ソリューションズ" },
    { label: "AIAD" },
    { label: "Viibar" },
    { label: "ADKエモーションズ" },
    { label: "オリコミサービス" },
    { label: "ルイ・ヴィトン ジャパン" },
    { label: "表示灯" },
    { label: "イーステージ" },
    { label: "MONDO" },
    { label: "アドセブンスリー" },
    { label: "医療法人社団新正会　安藤歯科医院" },
    { label: "プロラボ ホールディングス" },
    { label: "ワーナー ブラザース ジャパン合同会社" },
    { label: "博報堂マグネット" },
    { label: "サードカルチャー" },
    { label: "エルメスジャポン" },
    { label: "アスリン" },
    { label: "エスピーエスエス" },
    { label: "フォルクスワーゲングループジャパン" },
    { label: "ウォルト・ディズニー・ジャパン" },
    { label: "ユニテックシステム" },
    { label: "LIVE　BOARD" },
    { label: "スバル広告" },
    { label: "サイバーエージェント" },
    { label: "プロデュース101ジャパン出演者キムヒチョンくんファン一同" },
    { label: "電通ライブ" },
    { label: "フェイス　営業本部" },
    { label: "トーダン" },
    { label: "AbemaTV" },
    { label: "allfuz" },
    { label: "オフィス・ミツキ" },
    { label: "Mediakeys Japan" },
    { label: "ザイマックス" },
    { label: "スタイレム" },
    { label: "京急アドエンタープライズ" },
    { label: "プーマ ジャパン" },
    { label: "リアル・アド・クリエイティブ" },
    { label: "日昌ディベロップメント" },
    { label: "東京都交通局長" },
    { label: "アドフロンテ" },
    { label: "リアルゲイト" },
    { label: "ジャガー東京" },
    { label: "トラッドインターナショナル" },
    { label: "マッキャンエリクソン" },
    { label: "第一エージェンシー" },
    { label: "第一エージェンシー　東京統括本部" },
    { label: "vivora" },
    { label: "グランボード" },
    { label: "Tier Design&Research" },
    { label: "東京都主税局長" },
    { label: "テスト" },
    { label: "東京都政策企画局長" },
    { label: "町田まちづくり公社" },
    { label: "オリオンビール" },
    { label: "朝日オリコミ" },
    { label: "ビーアット" },
    { label: "一般社団法人　日本パブリックビューイング協会" },
    { label: "モメンタムジャパン" },
    { label: "熊野前医院" },
    { label: "ＭＩＮＴ　ＴＯＫＹＯ" },
    { label: "PalledAd" },
    { label: "コンセルジェ" },
    { label: "MEDIA８" },
    { label: "NPO法人薬害研究センター" },
    { label: "Coupang Japan合同会社" },
    { label: "lululemon athletica JP" },
    { label: "東京都交通局" },
    { label: "SDクリエーション" },
    { label: "ジクマネ" },
    { label: "THIRTEEN" },
    { label: "電通クリエ－ティブＸ" },
    { label: "アイチューザー" },
    { label: "シティボード" },
    { label: "TMC" },
    { label: "KADOKAWA" },
    { label: "松竹" },
    { label: "AMI Paris Japan" },
    { label: "リムジン・パッセンジャーサービス" },
    { label: "Bottega Veneta JAPAN" },
    { label: "Leading communication" },
    { label: "東京都 公営企業管理者 下水道局長" },
    { label: "KOS" },
    { label: "警視庁" },
    { label: "アジリティー" },
    { label: "ソニー" },
    { label: "リポット" },
    { label: "OKULAB" },
    { label: "キャンプ" },
    { label: "キャンプ" },
    { label: "SENKOU SOUND" },
    { label: "社会福祉法人偕恵園　横浜市つたのは学園" },
    { label: "ジクマネ" },
    { label: "SEISHIDO IDEAL" },
    { label: "Obito" },
    { label: "メディアサービス" },
    { label: "Officeroom" },
    { label: "フラッグ" },
    { label: "クラフトワールドワイド" },
    { label: "ジーニー" },
    { label: "東映エージエンシー " },
    { label: "サニーサイドアップ" },
    { label: "日本中央競馬会" },
    { label: "TATRAS INTERNATIONAL" },
    { label: "Nomad Tokyo合同会社" },
    { label: "シグナル" },
    { label: "ゴラク" },
  ];
  optionsOp4 = [{ label: "新規" }, { label: "継続" }];
  optionsOp5 = [
    { label: "長期看板" },
    { label: "短期看板" },
    { label: "リムジンバス" },
    { label: "都営交通" },
    { label: "交通広告" },
    { label: "ビジョン" },
    { label: "経費" },
    { label: "その他" },
  ];
  optionsOp6 = [
    { label: "媒体料" },
    { label: "プリント" },
    { label: "施工" },
    { label: "製作施工" },
    { label: "工事(資産)" },
    { label: "保険・申請" },
    { label: "撤去前受" },
    { label: "その他" },
  ];
  opProjectNumber = [
    { label: "620857：【大阪】丸野商事/小川清秀_62期", code: 620857 },
    {
      label: "620858：【大阪】ブシロード／アニメイト大阪日本橋北面差し替え工事",
      code: 620858,
    },
    {
      label: "620859：【大阪】桜橋深川ビル クマシュー工務店意匠製作取付工事",
      code: 620859,
    },
  ];
  //----------------------
  //モーダル用プロパティ
  //----------------------
  //----------------------
  //制御用メソッド
  //----------------------
  //----------------------
  //基本操作用メソッド
  //----------------------
  //モーダル用メソッド
  //----------------------
  /**
   * 申請書選択用モーダル表示
   */
  async showModal(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_nrGet;
    modalComp.showModal();
  }
  /**
   * 申請書選択用モーダル閉じる
   */
  async closeModal(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_nrGet;
    modalComp.closeModal();
  }
}
