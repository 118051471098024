import { EntityDocfileImple } from "../entity/EntityDocfileImple";
import { EntityHistoryImple } from "../entity/EntityHistoryImple";
import { DtoApiPager } from "./abst/DtoApiPager";
import { DtoDocsGetRes } from "./abst/DtoDocsGetRes";

export class DtoDocsGetResImple extends DtoDocsGetRes {
  public pager = new DtoApiPager();
  public items4docFile: EntityDocfileImple[] = [];
  public items4History: EntityHistoryImple[] = [];

  //連携ページへのリンク
  //-------
  /**
   * 請求書
   */
  public link4Claim: string | null = null;
  /**
   * 経費精算書
   */
  public link4Expensess: string | null = null;
}
