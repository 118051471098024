import { DtoFilesPostReq } from "./abst/DtoFilesPostReq";

export class DtoFilesPostReqImple extends DtoFilesPostReq {
  public projectId: number | null = null;
  public fileName: string | null = null;
  public fileType: string | null = null;

  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------

    return res;
  }
}
