export class EntityClient {
public c_client_id:number | null = null;
public c_client_name:string | null = null;
public c_client_name2:string | null = null;
public c_client_name3:string | null = null;
public c_client_name_pt:string | null = null;
public c_client_company_type:number | null = null;
public c_client_flag_company_signage_posi:number | null = null;
public c_branch_id:number | null = null;
public c_staff_id:number | null = null;
public c_client_tel:string | null = null;
public c_client_fax:string | null = null;
public c_client_zip:string | null = null;
public c_client_address_1:string | null = null;
public c_client_address_2:string | null = null;
public c_client_flag_claim_addon:number | null = null;
public c_client_claim_zip:string | null = null;
public c_client_claim_address_1:string | null = null;
public c_client_claim_address_2:string | null = null;
public c_client_paysite:string | null = null;
public c_client_staff_name:string | null = null;
public c_client_staff_name_pt:string | null = null;
public c_client_depart:string | null = null;
public c_client_post:string | null = null;
public c_client_flag_post_signag_posi:number | null = null;
public c_client_remarks:string | null = null;
public c_client_comment:string | null = null;
public c_client_flag_del:number | null = null;
public c_client_show_order:number | null = null;
public c_client_reg_user:string | null = null;
public c_client_reg_at:Date | null = null;
public c_client_upd_user:string | null = null;
public c_client_upd_at:Date | null = null;
}