
import { Prop, Options, Vue, Watch } from "vue-property-decorator";
import { EntityClaimDet } from "furukawa-common/src/common/entity/EntityClaimDet";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import {
  CONST_OP_DAILYREPORT_TYPE,
  CONST_OP_TAXRATE,
} from "furukawa-common/src/common/constant/Constants";
import { EntityReportDet } from "furukawa-common/src/common/entity/EntityReportDet";

@Options({
  components: {
    vSelect,
  },
})
export default class Mo_dailyReport_row extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  @Prop({ required: true })
  item: EntityReportDet;

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  opType = CONST_OP_DAILYREPORT_TYPE;
  //----------------------
  //モーダル用プロパティ
  //----------------------

  //----------------------
  //制御用メソッド
  //----------------------
  // async mounted() {}

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * 金額セル入力時の処理
   * カンマを消す
   * @param obj
   */
  kanmaDel(obj: any) {
    // //カンマを消す
    obj.target.value = obj.target.value.replace(/,/g, "");
    if (obj.target.value == "0") obj.target.value = "";
  }
  /**
   * 金額セル入力時の処理
   * カンマを付ける
   * @param obj
   */
  kanmaIns(obj: any) {
    let val: string = obj.target.value;

    //うっかり入力しているかもしれないカンマを消す
    val = val.replace(/,/g, "");
    //整数に変換したのち文字列に戻す
    //この時点で数字とマイナス記号だけが残る
    let num = "" + parseInt(val);
    //正規表現で桁区切りするための良く見かける関数的な何か
    //変数 num の中身が、桁区切りされる
    while (num != (num = num.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
    //numに入っている値が数値じゃないときは空白とする
    if (isNaN(parseInt(num))) num = "";
    //桁区切りした結果（変数 num）でテキストボックスの中身を書き換える
    obj.target.value = num;
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
}
