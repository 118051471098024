import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoSearchSupplysGetReq } from "furukawa-common/src/common/dto/DtoSearchSupplysGetReq";
import { DtoSearchSupplysGetRes } from "furukawa-common/src/common/dto/DtoSearchSupplysGetRes";

export class Cl_searchSupplys {
  public static async getList(
    dto: DtoSearchSupplysGetReq
  ): Promise<DtoSearchSupplysGetRes> {
    return Object.assign(
      new DtoSearchSupplysGetRes(),
      await CallAPI.getRequest(
        "/searchsupplys?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
