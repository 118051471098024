
import { Options, Vue } from "vue-class-component";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import { Cl_common } from "@/functions/Cl_common";
import { DtoStaffsGetReqImple } from "furukawa-common/src/common/dto/DtoStaffsGetReqImple";
import { Cl_staff } from "@/functions/Cl_staff";
import { DtoStaffsGetResImple } from "furukawa-common/src/common/dto/DtoStaffsGetResImple";
import { DtoMastersGetRes } from "furukawa-common/src/common/dto/DtoMastersGetRes";
import { DtoMastersgetReq } from "furukawa-common/src/common/dto/DtoMastersGetReq";
import { Cl_master } from "@/functions/Cl_master";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_modal_alert,
  },
})
export default class EmployeeList extends Vue {
  /**
   * パフォーマンス問題の解決案としてテスト的に実装中
   * 残課題：グローバルなメソッドとして宣言して、どこからでも呼び出せるようにしたい
   * 　　　　色々なパターンでも問題ないかの検証
   * @param url
   * @param event
   */
  goTo(url: string, event?: MouseEvent) {
    if (event && (event.ctrlKey || event.metaKey)) {
      window.open(url);
    } else {
      this.$router.push(url);
    }
  }
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoStaffsGetResImple();
  delegateBranch = new DtoMastersGetRes();
  domStaffNo_start = "";
  domStaffNo_end = "";
  domStaffName = "";
  domKeyword = "";
  // domBranch = { code: 0, label: "　" };
  domBranch = "";
  //ボタン制御用
  btnDisable = false;

  columns = [
    {
      label: "詳細",
      field: "btnDet",
      thClass: "text-center",
      tdClass: "text-center",
      width: "10%",
    },
    {
      label: "社員番号",
      field: "c_staff_number",
      thClass: "text-center",
      tdClass: "text-center",
    },
    {
      label: "社員名",
      field: "c_staff_name",
    },
    {
      label: "拠点",
      field: "c_branch_name",
      thClass: "text-center",
      tdClass: "text-center",
    },
  ];

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getlist(1);
    await this.getBranchList();
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * 一覧データを読み込む
   */
  async getlist(currentPage: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoStaffsGetReqImple();

      //検索条件設定
      // dto.pager.count = CONST_LIST_DEFO_COUNT;
      dto.pager.count = 9999;
      dto.pager.cursor = currentPage;

      // dto.staffNo_start = this.domStaffNo_start;
      // dto.staffNo_end = this.domStaffNo_end;
      // dto.staffName = this.domStaffName;
      // if (this.domBranch.code != 0) dto.c_branch_id = this.domBranch.code;
      // if (this.domBranch != "" && this.domBranch != null)
      //   dto.branchId = Number(this.domBranch);

      dto.keyword = this.domKeyword;

      //API実行
      //-------
      this.delegate = await Cl_staff.getList(dto);
      //レスポンス処理
      //-------
      Cl_common.logger("結果");
      Cl_common.logger(this.delegate);
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      //エラー発生で一覧を初期化
      this.delegate = new DtoStaffsGetResImple();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   *拠点プルダウン取得用
   */
  async getBranchList() {
    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行拠点マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getBranch = 1;
      dtoMaster.delFlag4Branch = 0;

      //API実行
      //-------
      this.delegateBranch = await Cl_master.get(dtoMaster);
      // Cl_common.logger("結果");
      // Cl_common.logger(this.delegateStaff);
      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegateBranch = new DtoMastersGetRes();
      Cl_common.logger(error);
    }
  }

  /**
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      // let dto = new DtoFileMastersGetReqImple();
      // dto.type = "SyainList";
      // dto.fromCode = Number(this.domStaffNo_start);
      // dto.toCode = Number(this.domStaffNo_end);
      // dto.name = this.domStaffName;
      // dto.branch = this.domBranch.code;
      //API実行
      //-------
      // const res = await Cl_filemaster.get(dto);
      // console.log(res);
      // console.log(res.url);

      //レスポンス処理
      //-------
      // location.href = res.url;
      //window.open(res.url, "_blank");
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  async moveDet(id: string) {
    //詳細ページに移動
    this.$router.push("/syainedit?id=" + id);
  }
  /**
   * ページ更新時の処理
   * @param params
   */
  async onPageChange(params: any) {
    //テーブル更新処理
    await this.getlist(params.currentPage);
  }
  /**
   * ソート変更時の処理
   * @param params
   */
  async onSortChange(params: any) {
    //テーブル更新処理
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
