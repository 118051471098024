
import { Prop, Options, Vue } from "vue-property-decorator";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { EntityClaimDet } from "furukawa-common/src/common/entity/EntityClaimDet";
import Mo_tableReportList_det from "../molecules/Mo_tableReportList_det.vue";
import { EntityReportImple } from "furukawa-common/src/common/entity/EntityReportImple";
import { commonDateFurukawa } from "furukawa-common/src/common/function/commonDateFurukawa";
import { Cl_common } from "@/functions/Cl_common";

@Options({
  components: {
    vSelect,
    Mo_tableReportList_det,
  },
})
export default class Or_tableReportList extends Vue {
  /**
   * パフォーマンス問題の解決案としてテスト的に実装中
   * 残課題：グローバルなメソッドとして宣言して、どこからでも呼び出せるようにしたい
   * 　　　　色々なパターンでも問題ないかの検証
   * @param url
   * @param event
   */
  goTo(url: string, event?: MouseEvent) {
    if (event && (event.ctrlKey || event.metaKey)) {
      window.open(url);
    } else {
      this.$router.push(url);
    }
  }
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  @Prop({ required: true })
  item: EntityReportImple;

  domDate = "";
  domRemarks = "";

  btnDisable = false;

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  //----------------------
  //モーダル用プロパティ
  //----------------------
  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    // await this.AdjData();
  }

  async AdjData() {
    // this.domRemarks = this.item.c_report_remarks!;
    // this.domDate = commonDateFurukawa.formatDateString(
    //   this.item.c_report_date!
    // );
  }

  /**
   * buttonの有効無効を制御
   */
  eventAdjButton(btnDisableTmp: boolean) {
    this.btnDisable = btnDisableTmp;
  }

  async move() {
    await this.$router.push("/dailyReportEdit?id=" + this.item.c_report_id);
  }
  //----------------------
  //基本操作用メソッド
  //----------------------

  //----------------------
  //モーダル用メソッド
  //----------------------
}
