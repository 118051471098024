import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntityFile } from "../../entity/EntityFile";

export class DtoFilesPostReq extends EntityFile {
  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------

    return res;
  }
}
