
import { Options, Vue } from "vue-class-component";
import Tm_leftnavi from "@/components/template/Tm_leftnavi.vue";
import Tm_header from "@/components/template/Tm_header.vue";
import Tm_footer from "@/components/template/Tm_footer.vue";

@Options({
  components: {
    Tm_leftnavi,
    Tm_header,
    Tm_footer,
  },
  props: {
    msg: String,
  },
})
export default class App extends Vue {
  msg!: string;

  public isMypage(): boolean {
    return this.$route.path !== "/";
  }
}
