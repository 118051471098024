import { DtoClaimsPostReq } from "./abst/DtoClaimsPostReq";
import { EntitySalesImple } from "../entity/EntitySalesImple";

export class DtoClaimsPostReqImple extends DtoClaimsPostReq {
  /**
   * 対象とする売上明細行
   */
  public sales: EntitySalesImple | null = null;
  /**
   * 対象案件の拠点ID
   */
  public branchId: number | null = null;
  /**
   * 対象案件の案件番号
   */
  public projectNum: number | null = null;
  /**
   * 売上無しの請求書を作成する
   */
  public isNoSales: number | null = null;

  public IsValid(): string[] {
    let res: string[] = new Array();

    return res;
  }
}
