
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_modal_alert,
  },
})
export default class InvoiceNumberAssignment extends Vue {
  opProjectNumber = [
    { label: "620857：【大阪】丸野商事/小川清秀_62期", code: 620857 },
    {
      label: "620858：【大阪】ブシロード／アニメイト大阪日本橋北面差し替え工事",
      code: 620858,
    },
    {
      label: "620859：【大阪】桜橋深川ビル クマシュー工務店意匠製作取付工事",
      code: 620859,
    },
  ];
}
