
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import { DtoClaimsGetResImple } from "furukawa-common/src/common/dto/DtoClaimsGetResImple";
import { DtoMastersGetRes } from "furukawa-common/src/common/dto/DtoMastersGetRes";
import { Cl_common } from "@/functions/Cl_common";
import { DtoMastersgetReq } from "furukawa-common/src/common/dto/DtoMastersGetReq";
import { Cl_master } from "@/functions/Cl_master";
import { DtoClaimsGetReqImple } from "furukawa-common/src/common/dto/DtoClaimsGetReqImple";
import { CONST_LIST_DEFO_COUNT } from "@/constants/constant";
import { Cl_claim } from "@/functions/Cl_claim";
import { DtoMdDtp } from "furukawa-common/src/common/dto/DtoMdDtp";
import Mt_dtp from "@/components/atoms/Mt_dtp.vue";
import { DtoClaimsPutReq } from "furukawa-common/src/common/dto/abst/DtoClaimsPutReq";
import { DtoClaimsPutReqImple } from "furukawa-common/src/common/dto/DtoClaimsPutReqImple";
import { DtoClaimsPutResImple } from "furukawa-common/src/common/dto/DtoClaimsPutResImple";
import {
  CONST_CLAIMSTATUS_TOUROKUZUMI,
  CONST_CLAIMSTATUS_SYOUNINMATI,
  CONST_CLAIMSTATUS_SASIMODOSI,
  CONST_CLAIMSTATUS_SYOUNINZUMI,
  CONST_CLAIMSTATUS_SAKUSEIZUMI,
  CONST_CLAIMSTATUS,
} from "furukawa-common/src/common/constant/Constants";
import { Cl_Authorization_menu } from "@/constants/Cl_Authorization_menu";
import { DtoFileReportsGetReqImple } from "furukawa-common/src/common/dto/DtoFileReportsGetReqImple";
import { Cl_filereport } from "@/functions/Cl_filereport";
import { DtoOutputqueuesGetReqImple } from "furukawa-common/src/common/dto/DtoOutputqueuesGetReqImple";
import { DtoOutputqueuesGetResImple } from "furukawa-common/src/common/dto/DtoOutputqueuesGetResImple";
import { Cl_outputqueue } from "@/functions/Cl_outputqueue";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_modal_alert,
    Mt_dtp,
  },
})
export default class InvoiceSearch extends Vue {
  /**
   * パフォーマンス問題の解決案としてテスト的に実装中
   * 残課題：グローバルなメソッドとして宣言して、どこからでも呼び出せるようにしたい
   * 　　　　色々なパターンでも問題ないかの検証
   * @param url
   * @param event
   */
  goTo(url: string, event?: MouseEvent) {
    if (event && (event.ctrlKey || event.metaKey)) {
      window.open(url);
    } else {
      this.$router.push(url);
    }
  }
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoClaimsGetResImple();
  delegate4list = new DtoMastersGetRes();
  showFlags = new Cl_Authorization_menu();

  //ボタン制御用
  btnDisable = false;

  //検索条件dom
  domClaimNo_start = "";
  domClaimNo_end = "";
  // domClaimDate_start: Date | null = null;
  // domClaimDate_end: Date | null = null;
  domClaimDate_start = new DtoMdDtp();
  domClaimDate_end = new DtoMdDtp();
  domClientid = "";
  domClaimTitle = "";
  domClaimSponsorName = "";
  // domClaimPayLimit_start: Date | null = null;
  // domClaimPayLimit_end: Date | null = null;
  domClaimPayLimit_start = new DtoMdDtp();
  domClaimPayLimit_end = new DtoMdDtp();
  domStaffId = "";
  domClaimStatus = ""; //0:未承認未出力;1:承認依頼済み未承認;2:承認済み未出力;3:出力済;9:差戻済み未承認;
  domBranchId = "";
  domPaymentStatus = "";
  domSalesStatus = "";

  opApprovalStatus = CONST_CLAIMSTATUS;

  opPaymentStatus = [
    { label: "未入金のみ", code: 1 },
    { label: "入金済のみ", code: 2 },
  ];
  opSalesStatus = [
    { label: "売上ありのみ", code: 1 },
    { label: "売上なしのみ", code: 2 },
  ];
  columns = [
    {
      label: "詳細",
      field: "btnDet",
      thClass: "text-center",
      tdClass: "text-center",
      width: "80px",
    },

    {
      label: "請求No",
      field: "c_claim_number",
      thClass: "text-center",
      tdClass: "text-right",
    },
    {
      label: "案件番号",
      field: "c_project_number",
      thClass: "text-center",
      tdClass: "text-right",
    },
    {
      label: "ステータス",
      field: "c_claim_status",
      thClass: "text-center",
      tdClass: "text-center",
    },

    {
      label: "拠点",
      field: "c_branch_name",
      thClass: "text-center",
      tdClass: "text-left",
    },
    {
      label: "請求日",
      field: "c_claim_datetime",
      // type: "date",
      // dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
      // dateOutputFormat: "yyyy-MM-dd",
      thClass: "text-center",
      tdClass: "text-center",
    },
    {
      label: "件名",
      field: "c_claim_title_1",
      thClass: "text-center",
      tdClass: "text-left",
    },
    {
      label: "請求先",
      field: "c_client_name",
      thClass: "text-center",
      tdClass: "text-left",
    },
    {
      label: "税抜額",
      field: "c_claim_subtotal",
      thClass: "text-center",
      tdClass: "text-right",
    },
    {
      label: "税込額",
      field: "c_claim_total",
      thClass: "text-center",
      tdClass: "text-right",
    },
    {
      label: "請求書担当",
      field: "c_staff_name",
      thClass: "text-center",
      tdClass: "text-center",
    },
    {
      label: "入金予定日",
      field: "c_claim_date_exp_payment",
      // type: "date",
      // dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
      // dateOutputFormat: "yyyy-MM-dd",
      thClass: "text-center",
      tdClass: "text-center",
    },
    {
      label: "入金完了日",
      field: "c_claim_depo_datetime",
      // type: "date",
      // dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
      // dateOutputFormat: "yyyy-MM-dd",
      thClass: "text-center",
      tdClass: "text-center",
      width: "120px",
    },
  ];

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getlist_premonth();
    await this.getMastersList();
    await this.getlist(1);
    //設定された権限からメニューの表示非表示を制御
    this.showFlags.getAuthorization(this.$store);
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      //検索選択肢用
      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4Staff4sortNum = 1;
      // dtoMaster.getStaff = 1;
      // dtoMaster.delFlag4staff = 0;

      dtoMaster.getBranch = 1;
      dtoMaster.delFlag4Branch = 0;

      dtoMaster.getClients = 1;
      dtoMaster.delFlag4clients = 0;

      //カレンダー休日反映用
      dtoMaster.getHoliday = 1;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);

      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました"; //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * 一覧データを読み込む
   */
  async getlist(currentPage: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoClaimsGetReqImple();

      //検索条件設定
      dto.pager.count = 999999;
      dto.pager.cursor = currentPage;

      if (this.domClaimNo_start != "")
        dto.claimNo_start = Number(this.domClaimNo_start);

      if (this.domClaimNo_end != "")
        dto.claimNo_end = Number(this.domClaimNo_end);

      // dto.ClaimDate_start = this.domClaimDate_start;

      // dto.ClaimDate_end = this.domClaimDate_end;

      if (this.domClaimDate_start.date != null) {
        dto.ClaimDate_start = new Date(
          this.domClaimDate_start.date
        ).toLocaleDateString();
      }

      if (this.domClaimDate_end.date != null) {
        dto.ClaimDate_end = new Date(
          this.domClaimDate_end.date
        ).toLocaleDateString();
      }

      if (this.domClientid != "" && this.domClientid != null)
        dto.Clientid = Number(this.domClientid);

      dto.ClaimTitle = this.domClaimTitle;

      dto.ClaimSponsorName = this.domClaimSponsorName;

      // dto.ClaimPayLimit_start = this.domClaimPayLimit_start;

      // dto.ClaimPayLimit_end = this.domClaimPayLimit_end;

      if (this.domClaimPayLimit_start.date != null) {
        dto.ClaimPayLimit_start = new Date(
          this.domClaimPayLimit_start.date
        ).toLocaleDateString();
      }

      if (this.domClaimPayLimit_end.date != null) {
        dto.ClaimPayLimit_end = new Date(
          this.domClaimPayLimit_end.date
        ).toLocaleDateString();
      }

      if (this.domStaffId != "" && this.domStaffId != null) {
        dto.StaffId = Number(this.domStaffId);
      }

      // console.log("this.domClaimStatus");
      // console.log(typeof this.domClaimStatus);
      if (typeof this.domClaimStatus == "number") {
        dto.ClaimStatus = Number(this.domClaimStatus);
        // console.log(dto.ClaimStatus);
      }

      if (this.domBranchId != "" && this.domBranchId != null)
        dto.branchId = this.domBranchId;

      if (this.domPaymentStatus != "" && this.domPaymentStatus != null)
        dto.paymentStatus = Number(this.domPaymentStatus);

      if (this.domSalesStatus != "" && this.domSalesStatus != null)
        dto.saleStatus = Number(this.domSalesStatus);

      Cl_common.logger("dto");
      Cl_common.logger(dto);

      //API実行
      //-------
      this.delegate = await Cl_claim.getList(dto);

      //レスポンス処理
      //-------
      await this.cnvDetails4Get();
      Cl_common.logger("結果");
      Cl_common.logger(this.delegate);
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      //エラー発生で一覧を初期化
      this.delegate = new DtoClaimsGetResImple();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  async getlist_premonth() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      const today = new Date();
      const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      const firstDayOfMonth = new Date(
        lastMonth.getFullYear(),
        lastMonth.getMonth(),
        1
      );
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1); // 1日を次の日に設定する
      const lastDayOfLastMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      );
      lastDayOfLastMonth.setDate(lastDayOfLastMonth.getDate() + 1); // 前月の末日を次の日に設定する

      this.domClaimDate_start.date = firstDayOfMonth
        .toISOString()
        .split("T")[0];
      // this.domClaimDate_end.date = lastDayOfLastMonth
      //   .toISOString()
      //   .split("T")[0];

      Cl_common.logger("1日");
      Cl_common.logger(firstDayOfMonth.toISOString().split("T")[0]);
      Cl_common.logger("末日");
      Cl_common.logger(lastDayOfLastMonth.toISOString().split("T")[0]);
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      //エラー発生で一覧を初期化
      this.delegate = new DtoClaimsGetResImple();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoFileReportsGetReqImple();
      dto.type = "claimlist";

      //条件設定
      //-----
      if (this.domClaimNo_start != "")
        dto.claimlist_claimNo_start = Number(this.domClaimNo_start);

      if (this.domClaimNo_end != "")
        dto.claimlist_claimNo_end = Number(this.domClaimNo_end);

      if (this.domClaimDate_start.date != null) {
        dto.claimlist_claimDate_start = new Date(
          this.domClaimDate_start.date
        ).toLocaleDateString();
      }

      if (this.domClaimDate_end.date != null) {
        dto.claimlist_claimDate_end = new Date(
          this.domClaimDate_end.date
        ).toLocaleDateString();
      }

      if (this.domClientid != "" && this.domClientid != null)
        dto.claimlist_clientid = Number(this.domClientid);

      dto.claimlist_claimTitle = this.domClaimTitle;

      dto.claimlist_claimSponsorName = this.domClaimSponsorName;

      if (this.domClaimPayLimit_start.date != null) {
        dto.claimlist_claimPayLimit_start = new Date(
          this.domClaimPayLimit_start.date
        ).toLocaleDateString();
      }

      if (this.domClaimPayLimit_end.date != null) {
        dto.claimlist_claimPayLimit_end = new Date(
          this.domClaimPayLimit_end.date
        ).toLocaleDateString();
      }

      if (this.domStaffId != "" && this.domStaffId != null)
        dto.claimlist_StaffId = Number(this.domStaffId);

      if (this.domClaimStatus != "" && this.domClaimStatus != null)
        dto.claimlist_claimStatus = Number(this.domClaimStatus);

      if (this.domBranchId != "" && this.domBranchId != null)
        dto.claimlist_branchId = this.domBranchId;

      if (this.domPaymentStatus != "" && this.domPaymentStatus != null)
        dto.claimlist_paymentStatus = Number(this.domPaymentStatus);

      if (this.domSalesStatus != "" && this.domSalesStatus != null)
        dto.claimlist_saleStatus = Number(this.domSalesStatus);
      //-----

      //API実行
      //-------
      const res = await Cl_filereport.get(dto);

      if (0 == res.outputqueue_code.length) {
        throw new Error("出力失敗");
      }

      Cl_common.logger("res.outputqueue_code");
      Cl_common.logger(res.outputqueue_code);

      let timeLimit = Date.now() + 15 * 60 * 1000; // 15分
      let now = Date.now();
      let dtoOutputReq = new DtoOutputqueuesGetReqImple();
      dtoOutputReq.c_outputqueue_code = res.outputqueue_code;
      let resOutputReq = new DtoOutputqueuesGetResImple();
      resOutputReq = await Cl_outputqueue.get(dtoOutputReq);
      while (now < timeLimit) {
        Cl_common.logger("wait request");
        await new Promise((resolve) => setTimeout(resolve, 10000)); // 10秒待つ

        now = Date.now();

        resOutputReq = await Cl_outputqueue.get(dtoOutputReq);
        //↓社領修正行
        if (resOutputReq.c_outputqueue_flag_end == 1) {
          break;
        }
      }

      //レスポンス処理
      //-------
      Cl_common.logger("resOutputReq");
      Cl_common.logger(resOutputReq);

      //↓社領修正行
      if (resOutputReq.c_outputqueue_flag_success == 1) {
        location.href = resOutputReq.url;
      } else {
        throw new Error("出力失敗");
      }
      //window.open(res.url, "_blank");
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  async upd_date() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    //準備
    //-------
    let dto = new DtoClaimsPutReqImple();

    try {
      const table = document.getElementsByClassName("dateRow_id");

      let tmpDate;
      let tmpSalesId;
      for (var i = 0, len = table.length | 0; i < len; i = (i + 1) | 0) {
        // tmp = <HTMLInputElement>table[i];
        // tmpDate = table[i].getElementsByClassName("dateInput");
        tmpDate = table[i].getElementsByClassName("dp__input");
        tmpSalesId = table[i].getElementsByClassName("salesId");

        Cl_common.logger((tmpDate[0] as HTMLInputElement).value);

        // if (
        //   (tmpDate[0] as HTMLInputElement).value != null &&
        //   (tmpDate[0] as HTMLInputElement).value != ""
        // ) {
        dto.c_claim_id = Number(tmpSalesId[0].innerHTML);

        dto.c_claim_depo_datetime = new Date(
          (tmpDate[0] as HTMLInputElement).value
        );

        Cl_common.logger("-----------");
        Cl_common.logger(dto.c_claim_id);
        Cl_common.logger(dto.c_claim_depo_datetime);
        Cl_common.logger(dto);
        Cl_common.logger("-----------");

        //API実行
        //-------
        dto.isUpdDepoDate = 1;
        await Cl_claim.put(dto);
        // }
      }

      //更新処理：
      this.message4ModalSuccess = "正常に変更されました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  async cnvDetails4Get() {
    let tmp = new DtoMdDtp();

    //入金完了日
    //---------------------
    for (let i = 0; i < this.delegate.exitems.length; ++i) {
      //日付
      tmp = new DtoMdDtp();
      if (this.delegate.exitems[i].c_claim_depo_datetime != null) {
        tmp.date = this.delegate.exitems[i]
          .c_claim_depo_datetime!.toString()
          .split("T")[0];
      }
      this.delegate.exitems[i].c_claim_depo_datetime = tmp;
      // ----
    }
  }
  /**
   * 詳細画面のURLを返す
   * @param params
   */
  getDetUrl(params: any, event?: MouseEvent) {
    if (params.c_claim_flag_sales == 0) {
      //ここ
      this.goTo(
        "/invoiceCreationFormNs?claimnum=" + params.c_claim_number,
        event
      );
    } else {
      //ここ
      this.goTo(
        "/invoiceCreationForm?claimnum=" + params.c_claim_number,
        event
      );
    }
  }

  /**
   * 案件番号を受け取り、名称にして返す
   */
  cnvProjectNum2ShowTxt(projectNum: number | null): string {
    if (projectNum == null) {
      return "売上なし";
    }
    return projectNum?.toString();
  }
  /**
   * 請求Noを受け取り、名称にして返す
   */
  cnvClaimNum2ShowTxt(claimNum: number): string {
    if (claimNum < 59000000) {
      return "-";
    }
    return claimNum.toString();
  }
  /**
   * ステータスの番号を受け取り、名称にして返す
   */
  cnvStatusNum2Name(statusNum: number): string {
    switch (statusNum) {
      case CONST_CLAIMSTATUS_TOUROKUZUMI:
        return '<span class="badge badge-primary badge-pill" style="font-size: 1em;">請求データ登録済</span>';
      case CONST_CLAIMSTATUS_SYOUNINMATI:
        return '<span class="badge badge-warning badge-pill" style="font-size: 1em;">承認待ち</span>';
      case CONST_CLAIMSTATUS_SASIMODOSI:
        return '<span class="badge badge-danger badge-pill" style="font-size: 1em;">差戻</span>';
      case CONST_CLAIMSTATUS_SYOUNINZUMI:
        return '<span class="badge badge-info badge-pill" style="font-size: 1em;">承認済・出力待ち</span>';
      case CONST_CLAIMSTATUS_SAKUSEIZUMI:
        return '<span  class="badge badge-success badge-pill" style="font-size: 1em;">請求書作成済</span>';
      default:
        return "";
    }

    // return cnvRequestStatusNum2Name(statusNum);
  }

  /**
   * ページ更新時の処理
   * @param params
   */
  async onPageChange(params: any) {
    //テーブル更新処理
    await this.getlist(params.currentPage);
  }
  /**
   * ソート変更時の処理
   * @param params
   */
  async onSortChange(params: any) {
    //テーブル更新処理
  }
  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
  //----------------------
  //その他のメソッド（画面独自のもの等）
  //----------------------
  cnvDate(date: Date | null) {
    if (date == null) {
      return "-";
    }

    return date.toString().split("T")[0].replaceAll("-", "/");
  }
}
