export class EntityBranch {
public c_branch_id:number | null = null;
public c_branch_name:string | null = null;
public c_branch_tel:string | null = null;
public c_branch_fax:string | null = null;
public c_branch_zip:string | null = null;
public c_branch_address_1:string | null = null;
public c_branch_address_2:string | null = null;
public c_branch_flag_del:number | null = null;
public c_branch_reg_user:string | null = null;
public c_branch_reg_at:Date | null = null;
public c_branch_upd_user:string | null = null;
public c_branch_upd_at:Date | null = null;
}