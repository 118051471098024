
import { EntitySupplyImple } from "furukawa-common/src/common/entity/EntitySupplyImple";
import { Prop, Options, Vue, Watch } from "vue-property-decorator";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import {
  // CONST_OP_SUPPLY_TYPE,
  CONST_OP_TAXRATE,
  CONST_OP_ITEMTYPE_ITEM,
  CONST_OP_ITEMTYPE_SUBJECTS,
  CONST_OP_ITEMTYPE_BREAKDOWNS,
  CONST_ITEMTYPE_ITEM_KEIYAKU,
  CONST_ITEMTYPE_ITEM_KEIZOKU,
  CONST_ITEMTYPE_ITEM_KURIKOSI,
  CONST_ITEMTYPE_ITEM_MAEUKE,
  CONST_ITEMTYPE_ITEM_NESAGE,
  CONST_ITEMTYPE_ITEM_SHINKI,
  CONST_ITEMTYPE_ITEM_SYOUDAKU,
  CONST_ITEMTYPE_ITEM_TEKKYO,
  CONST_ITEMTYPE_SUBJECTS_BAITAI,
  CONST_ITEMTYPE_SUBJECTS_HIKAZEI,
  CONST_ITEMTYPE_SUBJECTS_KOUJI,
  CONST_AUTH_PAYCHECK,
} from "furukawa-common/src/common/constant/Constants";
//masters用
import { EntitySupplier } from "furukawa-common/src/common/entity/EntitySupplier";
import { item } from "@/dto/DtoItemsGetRes";
import { DtoMastersGetRes } from "furukawa-common/src/common/dto/DtoMastersGetRes";

import Mt_dtp from "@/components/atoms/Mt_dtp.vue";
import Mt_dtp4month from "@/components/atoms/Mt_dtp4month.vue";
import { DtoMdDtp } from "furukawa-common/src/common/dto/DtoMdDtp";
import "@vuepic/vue-datepicker/dist/main.css";
import { EntityClaim } from "furukawa-common/src/common/entity/EntityClaim";

@Options({
  components: {
    vSelect,
    Mt_dtp,
    Mt_dtp4month,
  },
})
export default class Mo_tableJyutu_row_supply extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  @Prop({ required: true })
  item: EntitySupplyImple;

  // @Prop({ required: true })
  // items4Supplyers: EntitySupplier[];

  @Prop({ required: true })
  mastersData: DtoMastersGetRes;

  @Prop({ required: true })
  claims: EntityClaim[];

  authPayCheck = CONST_AUTH_PAYCHECK;

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  opItemtypeItem = CONST_OP_ITEMTYPE_ITEM.map((item) =>
    item.code === 8 ? { ...item, label: "前払" } : item
  );
  opItemtypeSubjects = CONST_OP_ITEMTYPE_SUBJECTS;
  opItemtypeBreakdown = CONST_OP_ITEMTYPE_BREAKDOWNS;
  opTaxtate = CONST_OP_TAXRATE;
  opSuppliers: EntitySupplier[] = [];

  //----------------------
  //セレクトボックス用メソッド
  //----------------------
  cngItem() {
    this.item.c_supply_subject = null;
    this.item.c_supply_breakdown = null;
  }
  getOpItemtypeSubjects(): {
    label: string;
    code: number;
  }[] {
    let indices: number[] = [];

    switch (this.item.c_supply_item) {
      case CONST_ITEMTYPE_ITEM_SHINKI:
        return CONST_OP_ITEMTYPE_SUBJECTS;
      case CONST_ITEMTYPE_ITEM_KEIZOKU:
        return CONST_OP_ITEMTYPE_SUBJECTS;
      case CONST_ITEMTYPE_ITEM_KEIYAKU:
        indices.push(0); // 媒体のみ
        return indices.map((i) => CONST_OP_ITEMTYPE_SUBJECTS[i]);
      case CONST_ITEMTYPE_ITEM_SYOUDAKU:
        indices.push(0); // 媒体のみ
        return indices.map((i) => CONST_OP_ITEMTYPE_SUBJECTS[i]);
      case CONST_ITEMTYPE_ITEM_NESAGE:
        indices.push(0); // 媒体のみ
        return indices.map((i) => CONST_OP_ITEMTYPE_SUBJECTS[i]);
      case CONST_ITEMTYPE_ITEM_KURIKOSI:
        indices.push(0); // 媒体
        indices.push(1); // 工事
        return indices.map((i) => CONST_OP_ITEMTYPE_SUBJECTS[i]);
      case CONST_ITEMTYPE_ITEM_TEKKYO:
        indices.push(0); // 媒体のみ
        return indices.map((i) => CONST_OP_ITEMTYPE_SUBJECTS[i]);
      case CONST_ITEMTYPE_ITEM_MAEUKE:
        indices.push(0); // 媒体
        indices.push(1); // 工事
        return indices.map((i) => CONST_OP_ITEMTYPE_SUBJECTS[i]);
      default:
        return [{ label: "項目を選択してください", code: -1 }];
    }
  }
  getOpItemtypeBreakdown(): {
    label: string;
    code: number;
  }[] {
    let indices: number[] = [];
    switch (this.item.c_supply_subject) {
      case CONST_ITEMTYPE_SUBJECTS_BAITAI:
        indices.push(0); // 長期媒体
        indices.push(1); // 短期媒体
        indices.push(2); // 保守媒体
        indices.push(3); // 放映料
        return indices.map((i) => CONST_OP_ITEMTYPE_BREAKDOWNS[i]);
      case CONST_ITEMTYPE_SUBJECTS_KOUJI:
        indices.push(4); // 施工費
        indices.push(5); // 撤去費
        indices.push(6); // 貼替料
        indices.push(7); // 電気代
        indices.push(8); // 保守管理費
        indices.push(9); // その他
        return indices.map((i) => CONST_OP_ITEMTYPE_BREAKDOWNS[i]);
      case CONST_ITEMTYPE_SUBJECTS_HIKAZEI:
        indices.push(10); // 申請代
        indices.push(11); // 保険代
        return indices.map((i) => CONST_OP_ITEMTYPE_BREAKDOWNS[i]);
      default:
        return [{ label: "科目を選択してください", code: -1 }];
    }
  }

  //----------------------
  //モーダル用プロパティ
  //----------------------

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    //仕入先の選択肢作成
    this.opSuppliers = this.mastersData.getSupplier4NoneDelete([
      this.item.c_supplier_id!,
    ]);
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * クリック時の選択処理
   */
  async selectRow() {
    this.item.isSelect = true;
    this.$emit("selected", this.item.c_supply_id);
  }

  /**
   * 選択解除処理
   */
  async noneSelectRow() {
    this.item.isSelect = false;
  }

  //------------------

  /**
   * 金額セル入力時の処理
   * カンマを消す
   * @param obj
   */
  kanmaDel(obj: any) {
    // カンマを消す
    this.item.cnvPrice = obj.target.value.replace(/,/g, "");
    // if (this.item.cnvPrice == "0") this.item.cnvPrice = "";
  }
  /**
   * 金額セル入力時の処理
   * カンマを付ける
   * @param obj
   */
  kanmaIns(obj: any) {
    let val: string = obj.target.value;

    //うっかり入力しているかもしれないカンマを消す
    val = val.replace(/,/g, "");
    //整数に変換したのち文字列に戻す
    //この時点で数字とマイナス記号だけが残る
    let num = "" + parseInt(val);
    //正規表現で桁区切りするための良く見かける関数的な何か
    //変数 num の中身が、桁区切りされる
    while (num != (num = num.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
    //numに入っている値が数値じゃないときは空白とする
    if (isNaN(parseInt(num))) num = "";
    //桁区切りした結果（変数 num）でテキストボックスの中身を書き換える
    this.item.cnvPrice = num;
  }

  /**
   * 金額再計算イベント発生
   */
  calcProfit() {
    //再計算イベント発生
    //-----------------------
    this.$emit("reCalc");
  }

  //------------------

  /**
   * チェックボックスの値を数値に変換する
   * @param event
   */
  cnvCb(event: any): number {
    if (event.target.checked) {
      return 1;
    } else {
      return 0;
    }
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
}
