//-------------------------------
//定数。
//-------------------------------
//記入例
/**
 * ソート方法。新着(new) / ランキング(rank) / 人気(pop) // マイリストのnameとrelease
 */
// export const CONST_CODE_SORT: string[] = ["new", "rank", "pop", "name", "release"];
//-------------------------------
//仕入明細の支払確認チェックが入力可能な社員
//-------------------------------
//樋口様(77)、岡様(79)、吉田様(76)、阿児様(71)、赤沼様(50)、岩崎様(34)、伊藤様(35)、永井様(80)、佐々木 美紀様(40)
export const CONST_AUTH_PAYCHECK = [77, 79, 76, 71, 50, 34, 35, 80, 40];

//-------------------------------
//アラートメール転送用の設定値構造体。
//-------------------------------
export const CONST_SETTINGS_ALERT_MAIL_TRANSFER: {
  baseStaffId: number;
  alertType: number;
  requestType: number;
  transferStaffId: number;
}[] = [
  { baseStaffId: 57, alertType: 7, requestType: 1, transferStaffId: 80 },
  { baseStaffId: 57, alertType: 7, requestType: 7, transferStaffId: 80 },
];

//-------------------------------
//GOOGLEMAP。
//-------------------------------
export const CONST_APIKEY_GOOGLEMAP = "AIzaSyAvHe5WlWFRdw6G171wDb71fZ6-rfWzobw"; //XECINアカウントで契約

//-------------------------------
//URL関係。
//-------------------------------
export const CONST_HOST_PROD = "sys-furukawa.com"; //本番環境のホスト

//-------------------------------
//S3関係。
//-------------------------------
/**
 * Templateファイル格納用S3バケット名
 */
export const CONST_BUCKET_NAME_TEMPLATES: string = "furukawa-report-templates";
/**
 * ファイル出力用S3バケット名
 */
export const CONST_BUCKET_NAME_OUTPUT: string = "furukawa-report-output";
/**
 * DBファイル保存用バケット
 */
export const CONST_BUCKET_NAME_FILES_PROD: string = "furukawa-files-prod";
/**
 * DBファイル保存用バケット
 */
export const CONST_BUCKET_NAME_FILES_DEV: string = "furukawa-files-dev";
/**
 * 環境毎に使用できるDBファイル保存用バケットを返す。
 * Lambdaの環境変数で判断するAPI用。
 * @returns
 */
export function getBucketNameFiles4API(): string {
  if (process.env.isProd != undefined && process.env.isProd == "1") {
    //本番
    return CONST_BUCKET_NAME_FILES_PROD;
  } else {
    //開発
    return CONST_BUCKET_NAME_FILES_DEV;
  }
}
export function sanitizeS3Key(input: string): string {
  // 制御文字や特定の特殊文字を除去する
  const forbiddenCharacters = /[\x00-\x1F!#$&'()*+,/:;=?@[] ]/g;
  return input.replace(forbiddenCharacters, "");
}
//-------
/**
 * 案件毎のファイル置き場のプレフィックス（フォルダ名）
 */
export const CONST_BUCKET_PATH_PROJECTFILE: string = "projectFiles";

//-------------------------
//その他
//-------------------------
/**
 * 本番環境か開発環境かを返す
 * @returns
 */
export function isProdEnv(): boolean {
  if (window.location.host == CONST_HOST_PROD) {
    // 本番環境
    return true;
  } else {
    // 開発環境
    return false;
  }
}
//-------------------------------
//Cognito関係。
//-------------------------------
/**
 * CognitoのユーザープールID。
 */
export const CONST_COGNITO_USERPOOL_ID_DEV = "ap-northeast-1_lwCjabdT7"; // 開発環境
export const CONST_COGNITO_USERPOOL_ID_PROD = "ap-northeast-1_WfUSCpxN1"; // 本番環境

/**
 * CognitoのアプリクライアントID。
 */
export const CONST_COGNITO_APPCLIENT_ID_DEV = "7pdgderrgap5gggh7fviftlea6"; // 開発環境
export const CONST_COGNITO_APPCLIENT_ID_PROD = "23h8q3stg5j6sjl4rkfigvi7ak"; // 本番環境

/**
 * 環境毎に使用できるユーザープールIDを返す。
 * Lambdaの環境変数で判断するAPI用。
 * @returns
 */
export function getCognitoUserPoolId4API(): string {
  if (process.env.isProd != undefined && process.env.isProd == "1") {
    //本番
    return CONST_COGNITO_USERPOOL_ID_PROD;
  } else {
    //開発
    return CONST_COGNITO_USERPOOL_ID_DEV;
  }
}
/**
 * 環境毎に使用できるクライアントIDを返す。
 * Lambdaの環境変数で判断するAPI用。
 * @returns
 */
export function getCognitoAppClientId4API(): string {
  if (process.env.isProd != undefined && process.env.isProd == "1") {
    //本異
    return CONST_COGNITO_APPCLIENT_ID_PROD;
  } else {
    //開癰
    return CONST_COGNITO_APPCLIENT_ID_DEV;
  }
}
/**
 * 環境毎に使用できるユーザープールIDを返す。
 * URLで判断するフロント用。
 * @returns
 */
export function getCognitoUserPoolId4Front(): string {
  if (window.location.host == CONST_HOST_PROD) {
    // 本番環境
    return CONST_COGNITO_USERPOOL_ID_PROD;
  } else {
    // 開発環境
    return CONST_COGNITO_USERPOOL_ID_DEV;
  }
}
/**
 * 環境毎に使用できるクライアントIDを返す。
 * URLで判断するフロント用。
 * @returns
 */
export function getCognitoAppClientId4Front(): string {
  if (window.location.host == CONST_HOST_PROD) {
    //本異
    return CONST_COGNITO_APPCLIENT_ID_PROD;
  } else {
    //開癰
    return CONST_COGNITO_APPCLIENT_ID_DEV;
  }
}

//-------------------------
//社員・権限関係
//1:入力者;2:集計者;3:権限者;4:管理者;
//-------------------------
export const CONST_AUTHORITY_STAFF_NYURYOKUSYA: number = 1;
export const CONST_AUTHORITY_STAFF_SYUKEISYA: number = 2;
export const CONST_AUTHORITY_STAFF_KENGENSYA: number = 3;
export const CONST_AUTHORITY_STAFF_KANRISYA: number = 4;
export const CONST_AUTHORITY_STAFF_KINTAISYA: number = 5;
export const CONST_AUTHORITY_STAFF_HENSYUSYA: number = 6;
export const CONST_AUTHORITY_STAFF_NUM_1: string = "入力者";
export const CONST_AUTHORITY_STAFF_NUM_2: string = "集計者";
export const CONST_AUTHORITY_STAFF_NUM_3: string = "権限者";
export const CONST_AUTHORITY_STAFF_NUM_4: string = "管理者";
export const CONST_AUTHORITY_STAFF_NUM_5: string = "勤怠者";
export const CONST_AUTHORITY_STAFF_NUM_6: string = "編集者";

//-------------------------
//請求書関係
//-------------------------

export const CONST_CLAIMSTATUS = [
  { label: "請求データ登録済", code: 0 },
  { label: "承認待ち", code: 1 },
  { label: "差し戻し", code: 3 },
  { label: "承認済・出力待ち", code: 10 },
  { label: "請求書作成済", code: 20 },
];

export const CONST_CLAIMSTATUS_TOUROKUZUMI: number = 0;
export const CONST_CLAIMSTATUS_SYOUNINMATI: number = 1;
export const CONST_CLAIMSTATUS_SASIMODOSI: number = 3;
export const CONST_CLAIMSTATUS_SYOUNINZUMI: number = 10;
export const CONST_CLAIMSTATUS_SAKUSEIZUMI: number = 20;
export const CONST_CLAIMSTATUS_NUM_0: string = " 請求データ登録済";
export const CONST_CLAIMSTATUS_NUM_1: string = " 承認待ち";
export const CONST_CLAIMSTATUS_NUM_3: string = "差し戻し";
export const CONST_CLAIMSTATUS_NUM_10: string = "承認済・出力待ち";
export const CONST_CLAIMSTATUS_NUM_20: string = "請求書作成済";

//-------------------------
//商品・受注・売上・仕入関係
//-------------------------
export const CONST_OP_CHKCLAIM = [
  { label: "未確認", code: 0 },
  { label: "確認済", code: 1 },
];
export const CONST_OP_TAXRATE = [
  { label: "非課税", code: 0 },
  { label: "5%", code: 1 },
  { label: "8%", code: 2 },
  { label: "10%", code: 3 },
  { label: "内税", code: 4 },
];

export const CONST_OP_ALERT_CONTRACT = [
  { label: "1ヶ月前", code: 1 },
  { label: "2ヶ月前", code: 2 },
  { label: "3ヶ月前", code: 3 },
  { label: "4ヶ月前", code: 4 },
  { label: "5ヶ月前", code: 5 },
  { label: "6ヶ月前", code: 6 },
];
export const CONST_OP_TERM_ITEM = [
  { label: "長期", code: 1 },
  { label: "短期", code: 2 },
];
export const CONST_OP_BILLING_TYPE = [
  { label: "一括", code: 1 },
  { label: "分割", code: 2 },
];

export const CONST_OP_PROJECT_TYPE = [
  { label: "売上", code: 1 },
  { label: "仕掛", code: 2 },
  { label: "自社", code: 3 },
  { label: "開発", code: 4 },
  { label: "その他", code: 5 },
];

//------------------
export const CONST_OP_ITEMTYPE_ITEM = [
  { label: "新規", code: 1 },
  { label: "継続", code: 2 },
  { label: "契約", code: 3 },
  { label: "承諾", code: 4 },
  { label: "値下", code: 5 },
  { label: "繰越", code: 6 },
  { label: "撤去", code: 7 },
  { label: "前受", code: 8 }, //仕入は処理時に前払に変換
];

export const CONST_ITEMTYPE_ITEM_SHINKI: number = 1;
export const CONST_ITEMTYPE_ITEM_KEIZOKU: number = 2;
export const CONST_ITEMTYPE_ITEM_KEIYAKU: number = 3;
export const CONST_ITEMTYPE_ITEM_SYOUDAKU: number = 4;
export const CONST_ITEMTYPE_ITEM_NESAGE: number = 5;
export const CONST_ITEMTYPE_ITEM_KURIKOSI: number = 6;
export const CONST_ITEMTYPE_ITEM_TEKKYO: number = 7;
export const CONST_ITEMTYPE_ITEM_MAEUKE: number = 8;
export const CONST_ITEMTYPE_ITEM_NUM_1: string = "新規";
export const CONST_ITEMTYPE_ITEM_NUM_2: string = "継続";
export const CONST_ITEMTYPE_ITEM_NUM_3: string = "契約";
export const CONST_ITEMTYPE_ITEM_NUM_4: string = "承諾";
export const CONST_ITEMTYPE_ITEM_NUM_5: string = "値下";
export const CONST_ITEMTYPE_ITEM_NUM_6: string = "繰越";
export const CONST_ITEMTYPE_ITEM_NUM_7: string = "撤去";
export const CONST_ITEMTYPE_ITEM_NUM_8: string = "前受";

/**
 * 項目の番号を受け取り、名称にして返す
 * @param
 * @returns
 */
export function cnvItemTypeItemNum2Name(itemNum: number): string {
  switch (itemNum) {
    case CONST_ITEMTYPE_ITEM_SHINKI:
      return CONST_ITEMTYPE_ITEM_NUM_1;
    case CONST_ITEMTYPE_ITEM_KEIZOKU:
      return CONST_ITEMTYPE_ITEM_NUM_2;
    case CONST_ITEMTYPE_ITEM_KEIYAKU:
      return CONST_ITEMTYPE_ITEM_NUM_3;
    case CONST_ITEMTYPE_ITEM_SYOUDAKU:
      return CONST_ITEMTYPE_ITEM_NUM_4;
    case CONST_ITEMTYPE_ITEM_NESAGE:
      return CONST_ITEMTYPE_ITEM_NUM_5;
    case CONST_ITEMTYPE_ITEM_KURIKOSI:
      return CONST_ITEMTYPE_ITEM_NUM_6;
    case CONST_ITEMTYPE_ITEM_TEKKYO:
      return CONST_ITEMTYPE_ITEM_NUM_7;
    case CONST_ITEMTYPE_ITEM_MAEUKE:
      return CONST_ITEMTYPE_ITEM_NUM_8;
    default:
      throw new Error("不正なItemTypeItemNum");
  }
}
/**
 * 項目の名称を受け取り、番号にして返す
 * @param
 * @returns
 */
export function cnvItemTypeItemName2Num(itemName: string): number {
  switch (itemName) {
    case CONST_ITEMTYPE_ITEM_NUM_1:
      return CONST_ITEMTYPE_ITEM_SHINKI;
    case CONST_ITEMTYPE_ITEM_NUM_2:
      return CONST_ITEMTYPE_ITEM_KEIZOKU;
    case CONST_ITEMTYPE_ITEM_NUM_3:
      return CONST_ITEMTYPE_ITEM_KEIYAKU;
    case CONST_ITEMTYPE_ITEM_NUM_4:
      return CONST_ITEMTYPE_ITEM_SYOUDAKU;
    case CONST_ITEMTYPE_ITEM_NUM_5:
      return CONST_ITEMTYPE_ITEM_NESAGE;
    case CONST_ITEMTYPE_ITEM_NUM_6:
      return CONST_ITEMTYPE_ITEM_KURIKOSI;
    case CONST_ITEMTYPE_ITEM_NUM_7:
      return CONST_ITEMTYPE_ITEM_TEKKYO;
    case CONST_ITEMTYPE_ITEM_NUM_8:
      return CONST_ITEMTYPE_ITEM_MAEUKE;
    default:
      throw new Error("不正なItemTypeItemName");
  }
}

//------------------
export const CONST_OP_ITEMTYPE_SUBJECTS = [
  { label: "媒体", code: 1 },
  { label: "工事", code: 2 },
  { label: "非課税", code: 3 },
];

export const CONST_ITEMTYPE_SUBJECTS_BAITAI: number = 1;
export const CONST_ITEMTYPE_SUBJECTS_KOUJI: number = 2;
export const CONST_ITEMTYPE_SUBJECTS_HIKAZEI: number = 3;
export const CONST_ITEMTYPE_SUBJECTS_NUM_1: string = "媒体";
export const CONST_ITEMTYPE_SUBJECTS_NUM_2: string = "工事";
export const CONST_ITEMTYPE_SUBJECTS_NUM_3: string = "非課税";

/**
 * 科目の番号を受け取り、名称にして返す
 * @param
 * @returns
 */
export function cnvItemTypeSubjectsNum2Name(itemNum: number): string {
  switch (itemNum) {
    case CONST_ITEMTYPE_SUBJECTS_BAITAI:
      return CONST_ITEMTYPE_SUBJECTS_NUM_1;
    case CONST_ITEMTYPE_SUBJECTS_KOUJI:
      return CONST_ITEMTYPE_SUBJECTS_NUM_2;
    case CONST_ITEMTYPE_SUBJECTS_HIKAZEI:
      return CONST_ITEMTYPE_SUBJECTS_NUM_3;
    default:
      throw new Error("不正なItemTypeSubjectsNum");
  }
}
/**
 * 科目の名称を受け取り、番号にして返す
 * @param
 * @returns
 */
export function cnvItemTypeSubjectsName2Num(itemName: string): number {
  switch (itemName) {
    case CONST_ITEMTYPE_SUBJECTS_NUM_1:
      return CONST_ITEMTYPE_SUBJECTS_BAITAI;
    case CONST_ITEMTYPE_SUBJECTS_NUM_2:
      return CONST_ITEMTYPE_SUBJECTS_KOUJI;
    case CONST_ITEMTYPE_SUBJECTS_NUM_3:
      return CONST_ITEMTYPE_SUBJECTS_HIKAZEI;
    default:
      throw new Error("不正なItemTypeSubjectsName");
  }
}

//------------------

export const CONST_OP_ITEMTYPE_BREAKDOWNS = [
  { label: "長期媒体", code: 1 },
  { label: "短期媒体", code: 2 },
  { label: "保守媒体", code: 3 },
  { label: "放映料", code: 4 },
  { label: "施工費", code: 5 },
  { label: "撤去費", code: 6 },
  { label: "貼替料", code: 7 },
  { label: "電気代", code: 8 },
  { label: "保守管理費", code: 9 },
  { label: "その他", code: 10 },
  { label: "申請代", code: 11 },
  { label: "保険代", code: 12 },
];

export const CONST_ITEMTYPE_BREAKDOWNS_TYOUKIBAITAI: number = 1;
export const CONST_ITEMTYPE_BREAKDOWNS_TANKIBAITAI: number = 2;
export const CONST_ITEMTYPE_BREAKDOWNS_HOSYUBAITAI: number = 3;
export const CONST_ITEMTYPE_BREAKDOWNS_HOUEIRYOU: number = 4;
export const CONST_ITEMTYPE_BREAKDOWNS_SEKOUHI: number = 5;
export const CONST_ITEMTYPE_BREAKDOWNS_TEKKYOHI: number = 6;
export const CONST_ITEMTYPE_BREAKDOWNS_HARIKAEHI: number = 7;
export const CONST_ITEMTYPE_BREAKDOWNS_DENKIDAI: number = 8;
export const CONST_ITEMTYPE_BREAKDOWNS_HOSYUKANRIHI: number = 9;
export const CONST_ITEMTYPE_BREAKDOWNS_SONOTA: number = 10;
export const CONST_ITEMTYPE_BREAKDOWNS_SINSEIDAI: number = 11;
export const CONST_ITEMTYPE_BREAKDOWNS_HOKENDAI: number = 12;
export const CONST_ITEMTYPE_BREAKDOWNS_NUM_1: string = "長期媒体";
export const CONST_ITEMTYPE_BREAKDOWNS_NUM_2: string = "短期媒体";
export const CONST_ITEMTYPE_BREAKDOWNS_NUM_3: string = "保守媒体";
export const CONST_ITEMTYPE_BREAKDOWNS_NUM_4: string = "放映料";
export const CONST_ITEMTYPE_BREAKDOWNS_NUM_5: string = "施工費";
export const CONST_ITEMTYPE_BREAKDOWNS_NUM_6: string = "撤去費";
export const CONST_ITEMTYPE_BREAKDOWNS_NUM_7: string = "貼替料";
export const CONST_ITEMTYPE_BREAKDOWNS_NUM_8: string = "電気代";
export const CONST_ITEMTYPE_BREAKDOWNS_NUM_9: string = "保守管理費";
export const CONST_ITEMTYPE_BREAKDOWNS_NUM_10: string = "その他";
export const CONST_ITEMTYPE_BREAKDOWNS_NUM_11: string = "申請代";
export const CONST_ITEMTYPE_BREAKDOWNS_NUM_12: string = "保険代";

/**
 * 内訳の番号を受け取り、名称にして返す
 * @param
 * @returns
 */
export function cnvItemTypeBreakdownsNum2Name(itemNum: number): string {
  switch (itemNum) {
    case CONST_ITEMTYPE_BREAKDOWNS_TYOUKIBAITAI:
      return CONST_ITEMTYPE_BREAKDOWNS_NUM_1;
    case CONST_ITEMTYPE_BREAKDOWNS_TANKIBAITAI:
      return CONST_ITEMTYPE_BREAKDOWNS_NUM_2;
    case CONST_ITEMTYPE_BREAKDOWNS_HOSYUBAITAI:
      return CONST_ITEMTYPE_BREAKDOWNS_NUM_3;
    case CONST_ITEMTYPE_BREAKDOWNS_HOUEIRYOU:
      return CONST_ITEMTYPE_BREAKDOWNS_NUM_4;
    case CONST_ITEMTYPE_BREAKDOWNS_SEKOUHI:
      return CONST_ITEMTYPE_BREAKDOWNS_NUM_5;
    case CONST_ITEMTYPE_BREAKDOWNS_TEKKYOHI:
      return CONST_ITEMTYPE_BREAKDOWNS_NUM_6;
    case CONST_ITEMTYPE_BREAKDOWNS_HARIKAEHI:
      return CONST_ITEMTYPE_BREAKDOWNS_NUM_7;
    case CONST_ITEMTYPE_BREAKDOWNS_DENKIDAI:
      return CONST_ITEMTYPE_BREAKDOWNS_NUM_8;
    case CONST_ITEMTYPE_BREAKDOWNS_HOSYUKANRIHI:
      return CONST_ITEMTYPE_BREAKDOWNS_NUM_9;
    case CONST_ITEMTYPE_BREAKDOWNS_SONOTA:
      return CONST_ITEMTYPE_BREAKDOWNS_NUM_10;
    case CONST_ITEMTYPE_BREAKDOWNS_SINSEIDAI:
      return CONST_ITEMTYPE_BREAKDOWNS_NUM_11;
    case CONST_ITEMTYPE_BREAKDOWNS_HOKENDAI:
      return CONST_ITEMTYPE_BREAKDOWNS_NUM_12;
    default:
      throw new Error("不正なItemTypeBreakdownsNum");
  }
}
/**
 * 内訳の名称を受け取り、番号にして返す
 * @param
 * @returns
 */
export function cnvItemTypeBreakdownsName2Num(itemName: string): number {
  switch (itemName) {
    case CONST_ITEMTYPE_BREAKDOWNS_NUM_1:
      return CONST_ITEMTYPE_BREAKDOWNS_TYOUKIBAITAI;
    case CONST_ITEMTYPE_BREAKDOWNS_NUM_2:
      return CONST_ITEMTYPE_BREAKDOWNS_TANKIBAITAI;
    case CONST_ITEMTYPE_BREAKDOWNS_NUM_3:
      return CONST_ITEMTYPE_BREAKDOWNS_HOSYUBAITAI;
    case CONST_ITEMTYPE_BREAKDOWNS_NUM_4:
      return CONST_ITEMTYPE_BREAKDOWNS_HOUEIRYOU;
    case CONST_ITEMTYPE_BREAKDOWNS_NUM_5:
      return CONST_ITEMTYPE_BREAKDOWNS_SEKOUHI;
    case CONST_ITEMTYPE_BREAKDOWNS_NUM_6:
      return CONST_ITEMTYPE_BREAKDOWNS_TEKKYOHI;
    case CONST_ITEMTYPE_BREAKDOWNS_NUM_7:
      return CONST_ITEMTYPE_BREAKDOWNS_HARIKAEHI;
    case CONST_ITEMTYPE_BREAKDOWNS_NUM_8:
      return CONST_ITEMTYPE_BREAKDOWNS_DENKIDAI;
    case CONST_ITEMTYPE_BREAKDOWNS_NUM_9:
      return CONST_ITEMTYPE_BREAKDOWNS_HOSYUKANRIHI;
    case CONST_ITEMTYPE_BREAKDOWNS_NUM_10:
      return CONST_ITEMTYPE_BREAKDOWNS_SONOTA;
    case CONST_ITEMTYPE_BREAKDOWNS_NUM_11:
      return CONST_ITEMTYPE_BREAKDOWNS_SINSEIDAI;
    case CONST_ITEMTYPE_BREAKDOWNS_NUM_12:
      return CONST_ITEMTYPE_BREAKDOWNS_HOKENDAI;
    default:
      throw new Error("不正なItemTypeBreakdownsName");
  }
}
//-------------------------
//シミュレーション機能関係
//-------------------------
export const CONST_OP_MIGRATION_SETTING = [
  { label: "確定A", code: 1 },
  { label: "確定B", code: 2 },
  { label: "見込C", code: 3 },
  { label: "撤去", code: 4 },
  { label: "削除", code: 5 },
  { label: "請求済", code: 6 },
  { label: "手動移行済", code: 7 },
];

export const CONST_MIGRATION_SETTING_KAKUTEI_A: number = 1;
export const CONST_MIGRATION_SETTING_KAKUTEI_B: number = 2;
export const CONST_MIGRATION_SETTING_MIKOMI_C: number = 3;
export const CONST_MIGRATION_SETTING_TEKKYO: number = 4;
export const CONST_MIGRATION_SETTING_SAKUJYO: number = 5;
export const CONST_MIGRATION_SETTING_SEIKYUUZUMI: number = 6;
export const CONST_MIGRATION_SETTING_SYUDOUIKOUZUMI: number = 7;

export const CONST_MIGRATION_SETTING_NUM_1: string = "確定A";
export const CONST_MIGRATION_SETTING_NUM_2: string = "確定B";
export const CONST_MIGRATION_SETTING_NUM_3: string = "見込C";
export const CONST_MIGRATION_SETTING_NUM_4: string = "撤去";
export const CONST_MIGRATION_SETTING_NUM_5: string = "削除";
export const CONST_MIGRATION_SETTING_NUM_6: string = "請求済";
export const CONST_MIGRATION_SETTING_NUM_7: string = "手動移行済";

/**
 * 設定の番号を受け取り、名称にして返す
 * @param
 * @returns
 */
export function cnvSettingNum2Name(itemNum: number): string {
  switch (itemNum) {
    case CONST_MIGRATION_SETTING_KAKUTEI_A:
      return CONST_MIGRATION_SETTING_NUM_1;
    case CONST_MIGRATION_SETTING_KAKUTEI_B:
      return CONST_MIGRATION_SETTING_NUM_2;
    case CONST_MIGRATION_SETTING_MIKOMI_C:
      return CONST_MIGRATION_SETTING_NUM_3;
    case CONST_MIGRATION_SETTING_TEKKYO:
      return CONST_MIGRATION_SETTING_NUM_4;
    case CONST_MIGRATION_SETTING_SAKUJYO:
      return CONST_MIGRATION_SETTING_NUM_5;
    case CONST_MIGRATION_SETTING_SEIKYUUZUMI:
      return CONST_MIGRATION_SETTING_NUM_6;
    case CONST_MIGRATION_SETTING_SYUDOUIKOUZUMI:
      return CONST_MIGRATION_SETTING_NUM_7;

    default:
      throw new Error("不正なSettingNum");
  }
}
/**
 * 設定の名称を受け取り、番号にして返す
 * @param
 * @returns
 */
export function cnvSettingName2Num(itemName: string): number {
  switch (itemName) {
    case CONST_MIGRATION_SETTING_NUM_1:
      return CONST_MIGRATION_SETTING_KAKUTEI_A;
    case CONST_MIGRATION_SETTING_NUM_2:
      return CONST_MIGRATION_SETTING_KAKUTEI_B;
    case CONST_MIGRATION_SETTING_NUM_3:
      return CONST_MIGRATION_SETTING_MIKOMI_C;
    case CONST_MIGRATION_SETTING_NUM_4:
      return CONST_MIGRATION_SETTING_TEKKYO;
    case CONST_MIGRATION_SETTING_NUM_5:
      return CONST_MIGRATION_SETTING_SAKUJYO;
    case CONST_MIGRATION_SETTING_NUM_6:
      return CONST_MIGRATION_SETTING_SEIKYUUZUMI;
    case CONST_MIGRATION_SETTING_NUM_7:
      return CONST_MIGRATION_SETTING_SYUDOUIKOUZUMI;
    default:
      throw new Error("不正なSettingName");
  }
}

//-------------------------
//広告主マスタ関係
//-------------------------
export const CONST_OP_SPONSOR_GENRE = [
  { label: "不動産業", code: 1 },
  { label: "自動車", code: 2 },
  { label: "飲食業", code: 3 },
  { label: "小売業", code: 4 },
  { label: "製造業", code: 5 },
  { label: "内装業", code: 6 },
  { label: "通信業", code: 7 },
  { label: "学術業", code: 8 },
  { label: "賭博業", code: 9 },
  { label: "美容業", code: 10 },
  { label: "建設業", code: 11 },
  { label: "医療業", code: 12 },
  { label: "公衆浴場業", code: 13 },
  { label: "金融業", code: 14 },
  { label: "駐車場業", code: 15 },
  { label: "娯楽業", code: 16 },
  { label: "酒類業", code: 17 },
  { label: "葬儀業", code: 18 },
  { label: "古美術業", code: 19 },
  { label: "貴金属業", code: 20 },
  { label: "再生資源業", code: 21 },
  { label: "宿泊業", code: 22 },
  { label: "製薬業", code: 23 },
  { label: "運送業", code: 24 },
  { label: "物流業", code: 25 },
  { label: "投資業", code: 26 },
  { label: "飲料業", code: 27 },
  { label: "風俗系", code: 28 },
  { label: "介護業", code: 29 },
  { label: "その他飲食業", code: 30 },
  { label: "ペット", code: 31 },
  { label: "ブランド", code: 32 },
  { label: "商社", code: 33 },
  { label: "商業施設", code: 34 },
  { label: "スポーツ", code: 35 },
  { label: "ゴルフ", code: 36 },
  { label: "飲食料", code: 37 },
  { label: "ラブホ", code: 38 },
  { label: "クレジット", code: 39 },
  { label: "その他", code: 40 },
];

export const CONST_SPONSOR_GENRE_FUDOUSANGYOU: number = 1;
export const CONST_SPONSOR_GENRE_JIDOUSHA: number = 2;
export const CONST_SPONSOR_GENRE_INSHOKUGYOU: number = 3;
export const CONST_SPONSOR_GENRE_KOURIGYOU: number = 4;
export const CONST_SPONSOR_GENRE_SEIZOUGYOU: number = 5;
export const CONST_SPONSOR_GENRE_NAISOUGYOU: number = 6;
export const CONST_SPONSOR_GENRE_TSUUSHINGYOU: number = 7;
export const CONST_SPONSOR_GENRE_GAKUJUTSUGYOU: number = 8;
export const CONST_SPONSOR_GENRE_TOBAKUGYOU: number = 9;
export const CONST_SPONSOR_GENRE_BIYOUGYOU: number = 10;
export const CONST_SPONSOR_GENRE_KENSETSUGYOU: number = 11;
export const CONST_SPONSOR_GENRE_IRYOUGYOU: number = 12;
export const CONST_SPONSOR_GENRE_KOUSHUUYOKUJOUGYOU: number = 13;
export const CONST_SPONSOR_GENRE_KINRYOUGYOU: number = 14;
export const CONST_SPONSOR_GENRE_CHUUSHAJOUGYOU: number = 15;
export const CONST_SPONSOR_GENRE_GORAKUGYOU: number = 16;
export const CONST_SPONSOR_GENRE_SHURUIGYOU: number = 17;
export const CONST_SPONSOR_GENRE_SOUJIGYOU: number = 18;
export const CONST_SPONSOR_GENRE_KOBIJUTSUGYOU: number = 19;
export const CONST_SPONSOR_GENRE_KIKINZOKUGYOU: number = 20;
export const CONST_SPONSOR_GENRE_SAISEISHIGENGYOU: number = 21;
export const CONST_SPONSOR_GENRE_SHUKUHAKUGYOU: number = 22;
export const CONST_SPONSOR_GENRE_SEIYAKUGYOU: number = 23;
export const CONST_SPONSOR_GENRE_UNSOUGYOU: number = 24;
export const CONST_SPONSOR_GENRE_BUTSURYUUGYOU: number = 25;
export const CONST_SPONSOR_GENRE_TOUSHIGYOU: number = 26;
export const CONST_SPONSOR_GENRE_INRYOUGYOU: number = 27;
export const CONST_SPONSOR_GENRE_FUZOKUKEI: number = 28;
export const CONST_SPONSOR_GENRE_KAIGOGYOU: number = 29;
export const CONST_SPONSOR_GENRE_SONOTAINSHOKUGYOU: number = 30;
export const CONST_SPONSOR_GENRE_PETTO: number = 31;
export const CONST_SPONSOR_GENRE_BRANDO: number = 32;
export const CONST_SPONSOR_GENRE_SHOUSHIYA: number = 33;
export const CONST_SPONSOR_GENRE_SHOUYOUSHISETSU: number = 34;
export const CONST_SPONSOR_GENRE_SUPOTSU: number = 35;
export const CONST_SPONSOR_GENRE_GORUFU: number = 36;
export const CONST_SPONSOR_GENRE_INSHOKURYOU: number = 37;
export const CONST_SPONSOR_GENRE_RABUHO: number = 38;
export const CONST_SPONSOR_GENRE_KUREJITTO: number = 39;
export const CONST_SPONSOR_GENRE_SONOTA: number = 40;
export const CONST_SPONSOR_GENRE_NUM_1: string = "不動産業";
export const CONST_SPONSOR_GENRE_NUM_2: string = "自動車";
export const CONST_SPONSOR_GENRE_NUM_3: string = "飲食業";
export const CONST_SPONSOR_GENRE_NUM_4: string = "小売業";
export const CONST_SPONSOR_GENRE_NUM_5: string = "製造業";
export const CONST_SPONSOR_GENRE_NUM_6: string = "内装業";
export const CONST_SPONSOR_GENRE_NUM_7: string = "通信業";
export const CONST_SPONSOR_GENRE_NUM_8: string = "学術業";
export const CONST_SPONSOR_GENRE_NUM_9: string = "賭博業";
export const CONST_SPONSOR_GENRE_NUM_10: string = "美容業";
export const CONST_SPONSOR_GENRE_NUM_11: string = "建設業";
export const CONST_SPONSOR_GENRE_NUM_12: string = "医療業";
export const CONST_SPONSOR_GENRE_NUM_13: string = "公衆浴場業";
export const CONST_SPONSOR_GENRE_NUM_14: string = "金融業";
export const CONST_SPONSOR_GENRE_NUM_15: string = "駐車場業";
export const CONST_SPONSOR_GENRE_NUM_16: string = "娯楽業";
export const CONST_SPONSOR_GENRE_NUM_17: string = "酒類業";
export const CONST_SPONSOR_GENRE_NUM_18: string = "葬儀業";
export const CONST_SPONSOR_GENRE_NUM_19: string = "古美術業";
export const CONST_SPONSOR_GENRE_NUM_20: string = "貴金属業";
export const CONST_SPONSOR_GENRE_NUM_21: string = "再生資源業";
export const CONST_SPONSOR_GENRE_NUM_22: string = "宿泊業";
export const CONST_SPONSOR_GENRE_NUM_23: string = "製薬業";
export const CONST_SPONSOR_GENRE_NUM_24: string = "運送業";
export const CONST_SPONSOR_GENRE_NUM_25: string = "物流業";
export const CONST_SPONSOR_GENRE_NUM_26: string = "投資業";
export const CONST_SPONSOR_GENRE_NUM_27: string = "飲料業";
export const CONST_SPONSOR_GENRE_NUM_28: string = "風俗系";
export const CONST_SPONSOR_GENRE_NUM_29: string = "介護業";
export const CONST_SPONSOR_GENRE_NUM_30: string = "その他飲食業";
export const CONST_SPONSOR_GENRE_NUM_31: string = "ペット";
export const CONST_SPONSOR_GENRE_NUM_32: string = "ブランド";
export const CONST_SPONSOR_GENRE_NUM_33: string = "商社";
export const CONST_SPONSOR_GENRE_NUM_34: string = "商業施設";
export const CONST_SPONSOR_GENRE_NUM_35: string = "スポーツ";
export const CONST_SPONSOR_GENRE_NUM_36: string = "ゴルフ";
export const CONST_SPONSOR_GENRE_NUM_37: string = "飲食料";
export const CONST_SPONSOR_GENRE_NUM_38: string = "ラブホ";
export const CONST_SPONSOR_GENRE_NUM_39: string = "クレジット";
export const CONST_SPONSOR_GENRE_NUM_40: string = "その他";

/**
 * 業種の番号を受け取り、名称にして返す
 * @param
 * @returns
 */
export function cnvSponsorGenreId2Name(id: number): string {
  switch (id) {
    case CONST_SPONSOR_GENRE_FUDOUSANGYOU:
      return CONST_SPONSOR_GENRE_NUM_1;
    case CONST_SPONSOR_GENRE_JIDOUSHA:
      return CONST_SPONSOR_GENRE_NUM_2;
    case CONST_SPONSOR_GENRE_INSHOKUGYOU:
      return CONST_SPONSOR_GENRE_NUM_3;
    case CONST_SPONSOR_GENRE_KOURIGYOU:
      return CONST_SPONSOR_GENRE_NUM_4;
    case CONST_SPONSOR_GENRE_SEIZOUGYOU:
      return CONST_SPONSOR_GENRE_NUM_5;
    case CONST_SPONSOR_GENRE_NAISOUGYOU:
      return CONST_SPONSOR_GENRE_NUM_6;
    case CONST_SPONSOR_GENRE_TSUUSHINGYOU:
      return CONST_SPONSOR_GENRE_NUM_7;
    case CONST_SPONSOR_GENRE_GAKUJUTSUGYOU:
      return CONST_SPONSOR_GENRE_NUM_8;
    case CONST_SPONSOR_GENRE_TOBAKUGYOU:
      return CONST_SPONSOR_GENRE_NUM_9;
    case CONST_SPONSOR_GENRE_BIYOUGYOU:
      return CONST_SPONSOR_GENRE_NUM_10;
    case CONST_SPONSOR_GENRE_KENSETSUGYOU:
      return CONST_SPONSOR_GENRE_NUM_11;
    case CONST_SPONSOR_GENRE_IRYOUGYOU:
      return CONST_SPONSOR_GENRE_NUM_12;
    case CONST_SPONSOR_GENRE_KOUSHUUYOKUJOUGYOU:
      return CONST_SPONSOR_GENRE_NUM_13;
    case CONST_SPONSOR_GENRE_KINRYOUGYOU:
      return CONST_SPONSOR_GENRE_NUM_14;
    case CONST_SPONSOR_GENRE_CHUUSHAJOUGYOU:
      return CONST_SPONSOR_GENRE_NUM_15;
    case CONST_SPONSOR_GENRE_GORAKUGYOU:
      return CONST_SPONSOR_GENRE_NUM_16;
    case CONST_SPONSOR_GENRE_SHURUIGYOU:
      return CONST_SPONSOR_GENRE_NUM_17;
    case CONST_SPONSOR_GENRE_SOUJIGYOU:
      return CONST_SPONSOR_GENRE_NUM_18;
    case CONST_SPONSOR_GENRE_KOBIJUTSUGYOU:
      return CONST_SPONSOR_GENRE_NUM_19;
    case CONST_SPONSOR_GENRE_KIKINZOKUGYOU:
      return CONST_SPONSOR_GENRE_NUM_20;
    case CONST_SPONSOR_GENRE_SAISEISHIGENGYOU:
      return CONST_SPONSOR_GENRE_NUM_21;
    case CONST_SPONSOR_GENRE_SHUKUHAKUGYOU:
      return CONST_SPONSOR_GENRE_NUM_22;
    case CONST_SPONSOR_GENRE_SEIYAKUGYOU:
      return CONST_SPONSOR_GENRE_NUM_23;
    case CONST_SPONSOR_GENRE_UNSOUGYOU:
      return CONST_SPONSOR_GENRE_NUM_24;
    case CONST_SPONSOR_GENRE_BUTSURYUUGYOU:
      return CONST_SPONSOR_GENRE_NUM_25;
    case CONST_SPONSOR_GENRE_TOUSHIGYOU:
      return CONST_SPONSOR_GENRE_NUM_26;
    case CONST_SPONSOR_GENRE_INRYOUGYOU:
      return CONST_SPONSOR_GENRE_NUM_27;
    case CONST_SPONSOR_GENRE_FUZOKUKEI:
      return CONST_SPONSOR_GENRE_NUM_28;
    case CONST_SPONSOR_GENRE_KAIGOGYOU:
      return CONST_SPONSOR_GENRE_NUM_29;
    case CONST_SPONSOR_GENRE_SONOTAINSHOKUGYOU:
      return CONST_SPONSOR_GENRE_NUM_30;
    case CONST_SPONSOR_GENRE_PETTO:
      return CONST_SPONSOR_GENRE_NUM_31;
    case CONST_SPONSOR_GENRE_BRANDO:
      return CONST_SPONSOR_GENRE_NUM_32;
    case CONST_SPONSOR_GENRE_SHOUSHIYA:
      return CONST_SPONSOR_GENRE_NUM_33;
    case CONST_SPONSOR_GENRE_SHOUYOUSHISETSU:
      return CONST_SPONSOR_GENRE_NUM_34;
    case CONST_SPONSOR_GENRE_SUPOTSU:
      return CONST_SPONSOR_GENRE_NUM_35;
    case CONST_SPONSOR_GENRE_GORUFU:
      return CONST_SPONSOR_GENRE_NUM_36;
    case CONST_SPONSOR_GENRE_INSHOKURYOU:
      return CONST_SPONSOR_GENRE_NUM_37;
    case CONST_SPONSOR_GENRE_RABUHO:
      return CONST_SPONSOR_GENRE_NUM_38;
    case CONST_SPONSOR_GENRE_KUREJITTO:
      return CONST_SPONSOR_GENRE_NUM_39;
    case CONST_SPONSOR_GENRE_SONOTA:
      return CONST_SPONSOR_GENRE_NUM_40;
    default:
      throw new Error("不正なSponsorGenreId");
  }
}
/**
 * 業種の名称を受け取り、番号にして返す
 * @param
 * @returns
 */
export function cnvSponsorGenreName2Num(name: string): number {
  switch (name) {
    case CONST_SPONSOR_GENRE_NUM_1:
      return CONST_SPONSOR_GENRE_FUDOUSANGYOU;
    case CONST_SPONSOR_GENRE_NUM_2:
      return CONST_SPONSOR_GENRE_JIDOUSHA;
    case CONST_SPONSOR_GENRE_NUM_3:
      return CONST_SPONSOR_GENRE_INSHOKUGYOU;
    case CONST_SPONSOR_GENRE_NUM_4:
      return CONST_SPONSOR_GENRE_KOURIGYOU;
    case CONST_SPONSOR_GENRE_NUM_5:
      return CONST_SPONSOR_GENRE_SEIZOUGYOU;
    case CONST_SPONSOR_GENRE_NUM_6:
      return CONST_SPONSOR_GENRE_NAISOUGYOU;
    case CONST_SPONSOR_GENRE_NUM_7:
      return CONST_SPONSOR_GENRE_TSUUSHINGYOU;
    case CONST_SPONSOR_GENRE_NUM_8:
      return CONST_SPONSOR_GENRE_GAKUJUTSUGYOU;
    case CONST_SPONSOR_GENRE_NUM_9:
      return CONST_SPONSOR_GENRE_TOBAKUGYOU;
    case CONST_SPONSOR_GENRE_NUM_10:
      return CONST_SPONSOR_GENRE_BIYOUGYOU;
    case CONST_SPONSOR_GENRE_NUM_11:
      return CONST_SPONSOR_GENRE_KENSETSUGYOU;
    case CONST_SPONSOR_GENRE_NUM_12:
      return CONST_SPONSOR_GENRE_IRYOUGYOU;
    case CONST_SPONSOR_GENRE_NUM_13:
      return CONST_SPONSOR_GENRE_KOUSHUUYOKUJOUGYOU;
    case CONST_SPONSOR_GENRE_NUM_14:
      return CONST_SPONSOR_GENRE_KINRYOUGYOU;
    case CONST_SPONSOR_GENRE_NUM_15:
      return CONST_SPONSOR_GENRE_CHUUSHAJOUGYOU;
    case CONST_SPONSOR_GENRE_NUM_16:
      return CONST_SPONSOR_GENRE_GORAKUGYOU;
    case CONST_SPONSOR_GENRE_NUM_17:
      return CONST_SPONSOR_GENRE_SHURUIGYOU;
    case CONST_SPONSOR_GENRE_NUM_18:
      return CONST_SPONSOR_GENRE_SOUJIGYOU;
    case CONST_SPONSOR_GENRE_NUM_19:
      return CONST_SPONSOR_GENRE_KOBIJUTSUGYOU;
    case CONST_SPONSOR_GENRE_NUM_20:
      return CONST_SPONSOR_GENRE_KIKINZOKUGYOU;
    case CONST_SPONSOR_GENRE_NUM_21:
      return CONST_SPONSOR_GENRE_SAISEISHIGENGYOU;
    case CONST_SPONSOR_GENRE_NUM_22:
      return CONST_SPONSOR_GENRE_SHUKUHAKUGYOU;
    case CONST_SPONSOR_GENRE_NUM_23:
      return CONST_SPONSOR_GENRE_SEIYAKUGYOU;
    case CONST_SPONSOR_GENRE_NUM_24:
      return CONST_SPONSOR_GENRE_UNSOUGYOU;
    case CONST_SPONSOR_GENRE_NUM_25:
      return CONST_SPONSOR_GENRE_BUTSURYUUGYOU;
    case CONST_SPONSOR_GENRE_NUM_26:
      return CONST_SPONSOR_GENRE_TOUSHIGYOU;
    case CONST_SPONSOR_GENRE_NUM_27:
      return CONST_SPONSOR_GENRE_INRYOUGYOU;
    case CONST_SPONSOR_GENRE_NUM_28:
      return CONST_SPONSOR_GENRE_FUZOKUKEI;
    case CONST_SPONSOR_GENRE_NUM_29:
      return CONST_SPONSOR_GENRE_KAIGOGYOU;
    case CONST_SPONSOR_GENRE_NUM_30:
      return CONST_SPONSOR_GENRE_SONOTAINSHOKUGYOU;
    case CONST_SPONSOR_GENRE_NUM_31:
      return CONST_SPONSOR_GENRE_PETTO;
    case CONST_SPONSOR_GENRE_NUM_32:
      return CONST_SPONSOR_GENRE_BRANDO;
    case CONST_SPONSOR_GENRE_NUM_33:
      return CONST_SPONSOR_GENRE_SHOUSHIYA;
    case CONST_SPONSOR_GENRE_NUM_34:
      return CONST_SPONSOR_GENRE_SHOUYOUSHISETSU;
    case CONST_SPONSOR_GENRE_NUM_35:
      return CONST_SPONSOR_GENRE_SUPOTSU;
    case CONST_SPONSOR_GENRE_NUM_36:
      return CONST_SPONSOR_GENRE_GORUFU;
    case CONST_SPONSOR_GENRE_NUM_37:
      return CONST_SPONSOR_GENRE_INSHOKURYOU;
    case CONST_SPONSOR_GENRE_NUM_38:
      return CONST_SPONSOR_GENRE_RABUHO;
    case CONST_SPONSOR_GENRE_NUM_39:
      return CONST_SPONSOR_GENRE_KUREJITTO;
    case CONST_SPONSOR_GENRE_NUM_40:
      return CONST_SPONSOR_GENRE_SONOTA;

    default:
      throw new Error("不正なSponsorGenreName");
  }
}

//-------

export const CONST_OP_SPONSOR_TYPE = [
  { label: "専任物", code: 1 },
  { label: "管理物", code: 2 },
];

export const CONST_SPONSOR_TYPE_SENNINBUTU: number = 1;
export const CONST_SPONSOR_TYPE_KANRIBUTU: number = 2;
export const CONST_SPONSOR_TYPE_NUM_1: string = "専任物";
export const CONST_SPONSOR_TYPE_NUM_2: string = "管理物";

/**
 * 種別の番号を受け取り、名称にして返す
 * @param
 * @returns
 */
export function cnvSponsorTypeId2Name(id: number): string {
  switch (id) {
    case CONST_SPONSOR_TYPE_SENNINBUTU:
      return CONST_SPONSOR_TYPE_NUM_1;
    case CONST_SPONSOR_TYPE_KANRIBUTU:
      return CONST_SPONSOR_TYPE_NUM_2;

    default:
      throw new Error("不正なSponsorGenreId");
  }
}

/**
 * 種別の名称を受け取り、番号にして返す
 * @param
 * @returns
 */
export function cnvSponsorTypeName2Num(name: string): number {
  switch (name) {
    case CONST_SPONSOR_TYPE_NUM_1:
      return CONST_SPONSOR_TYPE_SENNINBUTU;
    case CONST_SPONSOR_TYPE_NUM_2:
      return CONST_SPONSOR_TYPE_KANRIBUTU;

    default:
      throw new Error("不正なSponsorTypeName");
  }
}

//-------------------------
//申請書関係
//-------------------------
export const CONST_REQUEST_TYPE_KARIBARAI: number = 1;
export const CONST_REQUEST_TYPE_ZANGYO: number = 2;
export const CONST_REQUEST_TYPE_REMOTEWORK: number = 3;
export const CONST_REQUEST_TYPE_SHUTCHO: number = 4;
export const CONST_REQUEST_TYPE_SETTAI: number = 5;
export const CONST_REQUEST_TYPE_SHAYOSHASHIYO: number = 6;
export const CONST_REQUEST_TYPE_KEIHISEISAN: number = 7;
export const CONST_REQUEST_TYPE_KYUKATODOKE: number = 8;
export const CONST_REQUEST_TYPE_RINGISHO: number = 9;
export const CONST_REQUEST_TYPE_KYUSHOKUNEGAI: number = 10;
export const CONST_REQUEST_TYPE_KYUYOSHOYOKOZATOROKU: number = 11;
export const CONST_REQUEST_TYPE_JUSHOHENKOTSUKINTEATE: number = 12;
export const CONST_REQUEST_TYPE_KEIHIKOZATOROKU: number = 13;
export const CONST_REQUEST_TYPE_NUM_1: string = "仮払申請書";
export const CONST_REQUEST_TYPE_NUM_2: string = "時間外残業申請書";
export const CONST_REQUEST_TYPE_NUM_3: string = "リモートワーク申請書";
export const CONST_REQUEST_TYPE_NUM_4: string = "出張申請書";
export const CONST_REQUEST_TYPE_NUM_5: string = "接待申請書";
export const CONST_REQUEST_TYPE_NUM_6: string = "業務用車両の使用許可届";
export const CONST_REQUEST_TYPE_NUM_7: string = "経費精算書";
export const CONST_REQUEST_TYPE_NUM_8: string = "休暇届";
export const CONST_REQUEST_TYPE_NUM_9: string = "稟議書";
export const CONST_REQUEST_TYPE_NUM_10: string = "休職願";
export const CONST_REQUEST_TYPE_NUM_11: string = "給与賞与振込口座登録依頼書";
export const CONST_REQUEST_TYPE_NUM_12: string = "住所変更及び通勤手当申請書";
export const CONST_REQUEST_TYPE_NUM_13: string = "経費振込口座登録依頼書";

export const CONST_REQUEST_STATUS_MISINSEI: number = 0;
export const CONST_REQUEST_STATUS_SINSEITYU: number = 1;
export const CONST_REQUEST_STATUS_SASIMODOSI: number = 2;
export const CONST_REQUEST_STATUS_SYOUNINZUMI: number = 3;
export const CONST_REQUEST_STATUS_TORISAGE: number = 4;

export const CONST_REQUEST_STATUS_NUM_0: string = "未申請";
export const CONST_REQUEST_STATUS_NUM_1: string = "申請中";
export const CONST_REQUEST_STATUS_NUM_2: string = "差戻";
export const CONST_REQUEST_STATUS_NUM_3: string = "承認済";
export const CONST_REQUEST_STATUS_NUM_4: string = "取下";

export const CONST_APPROVAL_STATUS_SYOUNIN: number = 1;
export const CONST_APPROVAL_STATUS_SASIMODOSI: number = 2;
export const CONST_APPROVAL_STATUS_JYOUISYOUNIN: number = 3;

export const CONST_APPROVAL_STATUS_NUM_1: string = "承認";
export const CONST_APPROVAL_STATUS_NUM_2: string = "差戻";
export const CONST_APPROVAL_STATUS_NUM_3: string = "上位承認";

/**
 * 申請書タイプの番号から、名称文字列に変換
 * @param requestType
 * @returns
 */
export function cnvRequestTypeNum2Name(requestType: number): string {
  switch (requestType) {
    case CONST_REQUEST_TYPE_KARIBARAI:
      return CONST_REQUEST_TYPE_NUM_1;
    case CONST_REQUEST_TYPE_ZANGYO:
      return CONST_REQUEST_TYPE_NUM_2;
    case CONST_REQUEST_TYPE_REMOTEWORK:
      return CONST_REQUEST_TYPE_NUM_3;
    case CONST_REQUEST_TYPE_SHUTCHO:
      return CONST_REQUEST_TYPE_NUM_4;
    case CONST_REQUEST_TYPE_SETTAI:
      return CONST_REQUEST_TYPE_NUM_5;
    case CONST_REQUEST_TYPE_SHAYOSHASHIYO:
      return CONST_REQUEST_TYPE_NUM_6;
    case CONST_REQUEST_TYPE_KEIHISEISAN:
      return CONST_REQUEST_TYPE_NUM_7;
    case CONST_REQUEST_TYPE_KYUKATODOKE:
      return CONST_REQUEST_TYPE_NUM_8;
    case CONST_REQUEST_TYPE_RINGISHO:
      return CONST_REQUEST_TYPE_NUM_9;
    case CONST_REQUEST_TYPE_KYUSHOKUNEGAI:
      return CONST_REQUEST_TYPE_NUM_10;
    case CONST_REQUEST_TYPE_KYUYOSHOYOKOZATOROKU:
      return CONST_REQUEST_TYPE_NUM_11;
    case CONST_REQUEST_TYPE_JUSHOHENKOTSUKINTEATE:
      return CONST_REQUEST_TYPE_NUM_12;
    case CONST_REQUEST_TYPE_KEIHIKOZATOROKU:
      return CONST_REQUEST_TYPE_NUM_13;
    default:
      throw new Error("不正なrequestType");
  }
}
/**
 * 申請書タイプの名称文字列から、番号に変換
 * @param requestType
 * @returns
 */
export function cnvRequestTypeName2Num(requestType: string): number {
  switch (requestType) {
    case CONST_REQUEST_TYPE_NUM_1:
      return CONST_REQUEST_TYPE_KARIBARAI;
    case CONST_REQUEST_TYPE_NUM_2:
      return CONST_REQUEST_TYPE_ZANGYO;
    case CONST_REQUEST_TYPE_NUM_3:
      return CONST_REQUEST_TYPE_REMOTEWORK;
    case CONST_REQUEST_TYPE_NUM_4:
      return CONST_REQUEST_TYPE_SHUTCHO;
    case CONST_REQUEST_TYPE_NUM_5:
      return CONST_REQUEST_TYPE_SETTAI;
    case CONST_REQUEST_TYPE_NUM_6:
      return CONST_REQUEST_TYPE_SHAYOSHASHIYO;
    case CONST_REQUEST_TYPE_NUM_7:
      return CONST_REQUEST_TYPE_KEIHISEISAN;
    case CONST_REQUEST_TYPE_NUM_8:
      return CONST_REQUEST_TYPE_KYUKATODOKE;
    case CONST_REQUEST_TYPE_NUM_9:
      return CONST_REQUEST_TYPE_RINGISHO;
    case CONST_REQUEST_TYPE_NUM_10:
      return CONST_REQUEST_TYPE_KYUSHOKUNEGAI;
    case CONST_REQUEST_TYPE_NUM_11:
      return CONST_REQUEST_TYPE_KYUYOSHOYOKOZATOROKU;
    case CONST_REQUEST_TYPE_NUM_12:
      return CONST_REQUEST_TYPE_JUSHOHENKOTSUKINTEATE;
    case CONST_REQUEST_TYPE_NUM_13:
      return CONST_REQUEST_TYPE_KEIHIKOZATOROKU;
    default:
      throw new Error("不正なrequestType");
  }
}

/**
 * 申請書ステータス番号を名称文字列に変換する
 * @param requestStatus
 * @returns
 */
export function cnvRequestStatusNum2Name(requestStatus: number): string {
  switch (requestStatus) {
    case CONST_REQUEST_STATUS_SINSEITYU:
      return CONST_REQUEST_STATUS_NUM_1;
    case CONST_REQUEST_STATUS_SASIMODOSI:
      return CONST_REQUEST_STATUS_NUM_2;
    case CONST_REQUEST_STATUS_SYOUNINZUMI:
      return CONST_REQUEST_STATUS_NUM_3;
    case CONST_REQUEST_STATUS_TORISAGE:
      return CONST_REQUEST_STATUS_NUM_4;
    default:
      throw new Error("不正なrequestStatus");
  }
}

/**
 * 申請書ステータスの名称文字列を番号に変更する
 * @param requestStatus
 * @returns
 */
export function cnvRequestStatusName2Num(requestStatus: string): number {
  switch (requestStatus) {
    case CONST_REQUEST_STATUS_NUM_1:
      return CONST_REQUEST_STATUS_SINSEITYU;
    case CONST_REQUEST_STATUS_NUM_2:
      return CONST_REQUEST_STATUS_SASIMODOSI;
    case CONST_REQUEST_STATUS_NUM_3:
      return CONST_REQUEST_STATUS_SYOUNINZUMI;
    case CONST_REQUEST_STATUS_NUM_4:
      return CONST_REQUEST_STATUS_TORISAGE;
    default:
      throw new Error("不正なrequestStatus");
  }
}

/**
 * 承認ステータス番号を名称文字列に変換する
 * @param approvalStatus
 * @returns
 */
export function cnvApprovalStatusNum2Name(approvalStatus: number): string {
  switch (approvalStatus) {
    case CONST_APPROVAL_STATUS_SYOUNIN:
      return CONST_APPROVAL_STATUS_NUM_1;
    case CONST_APPROVAL_STATUS_SASIMODOSI:
      return CONST_APPROVAL_STATUS_NUM_2;
    case CONST_APPROVAL_STATUS_JYOUISYOUNIN:
      return CONST_APPROVAL_STATUS_NUM_3;
    default:
      throw new Error("不正なapprovalStatus");
  }
}
/**
 * 承認ステータスの名称文字列を番号に変更する
 * @param approvalStatus
 * @returns
 */
export function cnvApprovalStatusName2Num(approvalStatus: string): number {
  switch (approvalStatus) {
    case CONST_APPROVAL_STATUS_NUM_1:
      return CONST_APPROVAL_STATUS_SYOUNIN;
    case CONST_APPROVAL_STATUS_NUM_2:
      return CONST_APPROVAL_STATUS_SASIMODOSI;
    case CONST_APPROVAL_STATUS_NUM_3:
      return CONST_APPROVAL_STATUS_JYOUISYOUNIN;
    default:
      throw new Error("不正なapprovalStatus");
  }
}

export const CONST_OP_REQUEST_TYPE = [
  { label: "仮払申請書", code: 1 },
  { label: "時間外残業申請書", code: 2 },
  // { label: "リモートワーク申請書", code: 3 },
  { label: "出張申請書", code: 4 },
  { label: "接待申請書", code: 5 },
  { label: "業務用車両の使用許可届", code: 6 },
  { label: "（仮払立替）経費精算書", code: 7 },
  { label: "休暇届", code: 8 },
  { label: "稟議書", code: 9 },
  { label: "休職願", code: 10 },
  { label: "給与賞与振込口座登録依頼書", code: 11 },
  { label: "住所変更及び通勤手当申請書", code: 12 },
  { label: "経費振込口座登録依頼書", code: 13 },
];

export const CONST_OP_REQUEST_STATUS = [
  { label: "未申請  ", code: 10 },
  { label: "申請中", code: 1 },
  { label: "差戻", code: 2 },
  { label: "承認済", code: 3 },
  { label: "取下", code: 4 },
];
export const CONST_OP_APPROVAL_STATUS = [
  { label: "承認", code: 1 },
  { label: "差戻", code: 2 },
  { label: "上位承認", code: 3 },
];

//申請書個別
//-------------------
//時間外残業申請書
//---------------
export const CONST_OP_OVERTIME_TYPE = [
  { label: "時間外残業", code: 1 },
  { label: "休日勤務", code: 2 },
];

//休暇届
//---------------
export const CONST_OP_HOLIDAY_PART_TYPE = [
  { label: "午前休", code: 1 },
  { label: "午後休", code: 2 },
];

export const CONST_OP_HOLIDAY_TYPE = [
  { label: "有給休暇", code: 1 },
  { label: "産前産後休暇", code: 2 },
  { label: "夏期休暇", code: 3 },
  { label: "代休・振替", code: 4 },
  { label: "生理休暇", code: 5 },
  { label: "転勤休暇", code: 6 },
  { label: "慶事休暇", code: 7 },
  { label: "特別休暇", code: 8 },
  { label: "その他", code: 9 },
];

//経費精算書
//---------------
export const CONST_OP_EXPENSES_INVOICE = [
  // { label: "不要", code: 1 },
  // { label: "必要", code: 2 },
  // { label: "不明", code: 3 },
  { label: "T", code: 4 },
  { label: "特例", code: 5 },
  { label: "なし", code: 6 },
  { label: "８％", code: 7 },
  { label: "０％", code: 8 },
];
export const CONST_OP_EXPENSES_TYPE = [
  { label: "1	旅費交通費", code: 1 },
  { label: "2	交際費", code: 2 },
  { label: "3	会議費", code: 3 },
  { label: "4	通信費", code: 4 },
  { label: "5	租税公課", code: 5 },
  { label: "6	消耗品費", code: 6 },
  { label: "7	原価算入", code: 7 },
  { label: "8	その他", code: 8 },
];

//-------------------------
//勤怠関係
//-------------------------

export const CONST_ATTENDANCE_TIME_DELIMITER_STR: string = "05:00:00";
export const CONST_ATTENDANCE_TIME_DELIMITER_NUM: number = 5;

export const CONST_ATTENDANCE_GETMODE_MINE: string = "MINE";
export const CONST_ATTENDANCE_GETMODE_VIEW: string = "VIEW";
export const CONST_ATTENDANCE_GETMODE_NORMAL: string = "NORMAL";

export const CONST_ATTENDANCE_POSTMODE_WORK: string = "WORK";
export const CONST_ATTENDANCE_POSTMODE_LEAVING: string = "LEAVING";
export const CONST_ATTENDANCE_POSTMODE_GOOUTSTART: string = "GOOUTSTART";
export const CONST_ATTENDANCE_POSTMODE_GOOUTEND: string = "GOOUTEND";

export const CONST_ATTENDANCE_POSTMODE_WORK_NUM: number = 1;
export const CONST_ATTENDANCE_POSTMODE_LEAVING_NUM: number = 2;
export const CONST_ATTENDANCE_POSTMODE_GOOUTSTART_NUM: number = 3;
export const CONST_ATTENDANCE_POSTMODE_GOOUTEND_NUM: number = 4;

export const CONST_ATTENDANCE_STATUS_MISYUKIN: string = "未出勤";
export const CONST_ATTENDANCE_STATUS_SYUKIN: string = "出勤中";
export const CONST_ATTENDANCE_STATUS_GAISYUTU: string = "外出中";
export const CONST_ATTENDANCE_STATUS_TAIKIN: string = "退勤済";

//-------------------------------
//文書関係。
//-------------------------------
export const CONST_OP_DOC_ISSUER = [
  { label: "受領", code: 0 },
  { label: "発行", code: 1 },
];

export const CONST_DOC_ISSUER_JYURYOU: number = 0;
export const CONST_DOC_ISSUER_HAKKOU: number = 1;

export const CONST_DOC_ISSUER_NUM_1: string = "受領";
export const CONST_DOC_ISSUER_NUM_2: string = "発行";

/**
 * 文書発行フラグを名称文字列に変換する
 * @param approvalStatus
 * @returns
 */
export function cnvDocIssuerNum2Name(docIssuerNum: number): string {
  switch (docIssuerNum) {
    case CONST_DOC_ISSUER_JYURYOU:
      return CONST_DOC_ISSUER_NUM_1;
    case CONST_DOC_ISSUER_HAKKOU:
      return CONST_DOC_ISSUER_NUM_2;
    default:
      throw new Error("不正なDocIssuerNum");
  }
}
/**
 * 文書発行フラグの名称文字列を番号に変更する
 * @param approvalStatus
 * @returns
 */
export function cnvDocIssuerStr2Num(docIssuerStr: string): number {
  switch (docIssuerStr) {
    case CONST_DOC_ISSUER_NUM_1:
      return CONST_DOC_ISSUER_JYURYOU;
    case CONST_DOC_ISSUER_NUM_2:
      return CONST_DOC_ISSUER_HAKKOU;
    default:
      throw new Error("不正なDocIssuerStr");
  }
}

//-------------------
export const CONST_OP_DOC_TYPE = [
  { label: "請求書", code: 1 },
  { label: "領収書", code: 2 },
  { label: "注文書", code: 3 },
  { label: "見積書", code: 4 },
  { label: "納品書", code: 5 },
  { label: "契約書", code: 6 },
  { label: "公的機関への申請書", code: 7 },
  { label: "金融機関取引", code: 9 },
  { label: "その他", code: 8 },
];

export const CONST_DOC_TYPE_SEIKYUSYO: number = 1;
export const CONST_DOC_TYPE_RYOUSYUSYO: number = 2;
export const CONST_DOC_TYPE_TYUMONSYO: number = 3;
export const CONST_DOC_TYPE_MITUMORISYO: number = 4;
export const CONST_DOC_TYPE_NOUHINSYO: number = 5;
export const CONST_DOC_TYPE_KEIYAKUSYO: number = 6;
export const CONST_DOC_TYPE_SINSEISYO: number = 7;
export const CONST_DOC_TYPE_SONOTA: number = 8;
export const CONST_DOC_TYPE_KINYUKIKANTORIHIKI: number = 9;

export const CONST_DOC_TYPE_NUM_1: string = "請求書";
export const CONST_DOC_TYPE_NUM_2: string = "領収書";
export const CONST_DOC_TYPE_NUM_3: string = "注文書";
export const CONST_DOC_TYPE_NUM_4: string = "見積書";
export const CONST_DOC_TYPE_NUM_5: string = "納品書";
export const CONST_DOC_TYPE_NUM_6: string = "契約書";
export const CONST_DOC_TYPE_NUM_7: string = "公的機関への申請書";
export const CONST_DOC_TYPE_NUM_8: string = "その他";
export const CONST_DOC_TYPE_NUM_9: string = "金融機関取引";

/**
 * 文書種類番号を名称文字列に変換する
 * @param approvalStatus
 * @returns
 */
export function cnvDocTypeNum2Name(docTypeNum: number): string {
  switch (docTypeNum) {
    case CONST_DOC_TYPE_SEIKYUSYO:
      return CONST_DOC_TYPE_NUM_1;
    case CONST_DOC_TYPE_RYOUSYUSYO:
      return CONST_DOC_TYPE_NUM_2;
    case CONST_DOC_TYPE_TYUMONSYO:
      return CONST_DOC_TYPE_NUM_3;
    case CONST_DOC_TYPE_MITUMORISYO:
      return CONST_DOC_TYPE_NUM_4;
    case CONST_DOC_TYPE_NOUHINSYO:
      return CONST_DOC_TYPE_NUM_5;
    case CONST_DOC_TYPE_KEIYAKUSYO:
      return CONST_DOC_TYPE_NUM_6;
    case CONST_DOC_TYPE_SINSEISYO:
      return CONST_DOC_TYPE_NUM_7;
    case CONST_DOC_TYPE_SONOTA:
      return CONST_DOC_TYPE_NUM_8;
    case CONST_DOC_TYPE_KINYUKIKANTORIHIKI:
      return CONST_DOC_TYPE_NUM_9;
    default:
      throw new Error("不正なDocTypeNum");
  }
}
/**
 * 文書種類の名称文字列を番号に変更する
 * @param approvalStatus
 * @returns
 */
export function cnvDocTypeStr2Num(docTypeStr: string): number {
  switch (docTypeStr) {
    case CONST_DOC_TYPE_NUM_1:
      return CONST_DOC_TYPE_SEIKYUSYO;
    case CONST_DOC_TYPE_NUM_2:
      return CONST_DOC_TYPE_RYOUSYUSYO;
    case CONST_DOC_TYPE_NUM_3:
      return CONST_DOC_TYPE_TYUMONSYO;
    case CONST_DOC_TYPE_NUM_4:
      return CONST_DOC_TYPE_MITUMORISYO;
    case CONST_DOC_TYPE_NUM_5:
      return CONST_DOC_TYPE_NOUHINSYO;
    case CONST_DOC_TYPE_NUM_6:
      return CONST_DOC_TYPE_KEIYAKUSYO;
    case CONST_DOC_TYPE_NUM_7:
      return CONST_DOC_TYPE_SINSEISYO;
    case CONST_DOC_TYPE_NUM_8:
      return CONST_DOC_TYPE_SONOTA;
    case CONST_DOC_TYPE_NUM_9:
      return CONST_DOC_TYPE_KINYUKIKANTORIHIKI;
    default:
      throw new Error("不正なDocTypeStr");
  }
}

//-------------------

export const CONST_OP_DOC_MANAGETYPE = [
  { label: "経費精算", code: 43 },
  { label: "売上（媒体料）", code: 1 },
  { label: "売上（その他）", code: 2 },
  { label: "仕入（媒体料）", code: 3 },
  { label: "仕入（その他）", code: 4 },
  { label: "外注加工費", code: 5 },
  { label: "材料仕入", code: 6 },
  { label: "仕掛金", code: 7 },
  // { label: "役員報酬", code: 8 },
  // { label: "給料手当", code: 9 },
  { label: "従業員賞与", code: 10 },
  { label: "雑給/退職金", code: 11 },
  { label: "法定福利費", code: 12 },
  { label: "福利厚生費", code: 13 },
  { label: "広告宣伝費", code: 14 },
  { label: "支払手数料", code: 15 },
  { label: "旅費交通費", code: 16 },
  { label: "減価償却費", code: 17 },
  { label: "リース料", code: 18 },
  { label: "地代家賃", code: 19 },
  { label: "修繕費", code: 20 },
  { label: "事務用消耗品費", code: 21 },
  { label: "備品･消耗品費", code: 22 },
  { label: "通信費", code: 23 },
  { label: "水道光熱費", code: 24 },
  { label: "租税公課", code: 25 },
  { label: "接待交際費", code: 26 },
  { label: "保険料", code: 27 },
  { label: "諸会費", code: 28 },
  { label: "管理諸費", code: 29 },
  { label: "会議費", code: 30 },
  { label: "研修費", code: 31 },
  { label: "雑費", code: 32 },
  { label: "受取利息", code: 33 },
  { label: "受取配当金", code: 34 },
  { label: "業務受託収入", code: 35 },
  { label: "雑収入", code: 36 },
  { label: "支払利息", code: 37 },
  { label: "手形売却損", code: 38 },
  { label: "業務委託費", code: 39 },
  { label: "雑損失", code: 40 },
  { label: "特別損益（売却益）", code: 41 },
  { label: "特別損益（除却損）", code: 42 },
  { label: "銀行関係", code: 44 },
  { label: "売掛金", code: 45 },
];

export const CONST_DOC_MANAGETYPE_KEIHISEISAN: number = 43;
export const CONST_DOC_MANAGETYPE_URIAGE_BAITAI: number = 1;
export const CONST_DOC_MANAGETYPE_URIAGE_SONOTA: number = 2;
export const CONST_DOC_MANAGETYPE_SIIRE_BAITAI: number = 3;
export const CONST_DOC_MANAGETYPE_SIIRE_SONOTA: number = 4;
export const CONST_DOC_MANAGETYPE_GAITYUKAKOUHI: number = 5;
export const CONST_DOC_MANAGETYPE_ZAIRYOUSIIRE: number = 6;
export const CONST_DOC_MANAGETYPE_SIKAKARIKIN: number = 7;
// export const CONST_DOC_MANAGETYPE_YAKUINNHOUSYU: number = 8;
// export const CONST_DOC_MANAGETYPE_KYURYOTEATE: number = 9;
export const CONST_DOC_MANAGETYPE_JUUGYOINSHOYO: number = 10;
export const CONST_DOC_MANAGETYPE_ZAKKYUTAISHOKUKIN: number = 11;
export const CONST_DOC_MANAGETYPE_HOTEIFUKURIBI: number = 12;
export const CONST_DOC_MANAGETYPE_FUKURIKOSEIBI: number = 13;
export const CONST_DOC_MANAGETYPE_KOKOKUSENDENHI: number = 14;
export const CONST_DOC_MANAGETYPE_SHIHARAITESURYO: number = 15;
export const CONST_DOC_MANAGETYPE_RYOHIKOTSUHI: number = 16;
export const CONST_DOC_MANAGETYPE_GENKASHOKYAKUHI: number = 17;
export const CONST_DOC_MANAGETYPE_RISURYO: number = 18;
export const CONST_DOC_MANAGETYPE_JIDAIYACHIN: number = 19;
export const CONST_DOC_MANAGETYPE_SHUZENHI: number = 20;
export const CONST_DOC_MANAGETYPE_JIMUYOSHOHINHI: number = 21;
export const CONST_DOC_MANAGETYPE_BIHINSHOHINHI: number = 22;
export const CONST_DOC_MANAGETYPE_TSUSHINHI: number = 23;
export const CONST_DOC_MANAGETYPE_SUIDOKOUNETSUHI: number = 24;
export const CONST_DOC_MANAGETYPE_SOZEIKOKA: number = 25;
export const CONST_DOC_MANAGETYPE_SETAIKOSAISHI: number = 26;
export const CONST_DOC_MANAGETYPE_HOKENRYO: number = 27;
export const CONST_DOC_MANAGETYPE_SHOKAISHI: number = 28;
export const CONST_DOC_MANAGETYPE_KANRISHOHI: number = 29;
export const CONST_DOC_MANAGETYPE_KAIGISHI: number = 30;
export const CONST_DOC_MANAGETYPE_KENSHUHI: number = 31;
export const CONST_DOC_MANAGETYPE_ZAPPI: number = 32;
export const CONST_DOC_MANAGETYPE_UKETORIRISHI: number = 33;
export const CONST_DOC_MANAGETYPE_UKETORIHAITOKIN: number = 34;
export const CONST_DOC_MANAGETYPE_GYOMUJUTAKUSHUNYU: number = 35;
export const CONST_DOC_MANAGETYPE_ZASSHUNYU: number = 36;
export const CONST_DOC_MANAGETYPE_SHIHARAIRISHI: number = 37;
export const CONST_DOC_MANAGETYPE_TEGATABAIKYAKUSON: number = 38;
export const CONST_DOC_MANAGETYPE_GYOMUITAKUHI: number = 39;
export const CONST_DOC_MANAGETYPE_ZASSONSHITSU: number = 40;
export const CONST_DOC_MANAGETYPE_TOKUBETSUSONEK_BAIKYAKUEKI: number = 41;
export const CONST_DOC_MANAGETYPE_TOKUBETSUSONEKI_JOYAKUSON: number = 42;
export const CONST_DOC_MANAGETYPE_GINKOUKANKEI: number = 44;
export const CONST_DOC_MANAGETYPE_URIKAKEKIN: number = 45;

export const CONST_DOC_MANAGETYPE_NUM_43: string = "経費精算";
export const CONST_DOC_MANAGETYPE_NUM_1: string = "売上（媒体料）";
export const CONST_DOC_MANAGETYPE_NUM_2: string = "売上（その他）";
export const CONST_DOC_MANAGETYPE_NUM_3: string = "仕入（媒体料）";
export const CONST_DOC_MANAGETYPE_NUM_4: string = "仕入（その他）";
export const CONST_DOC_MANAGETYPE_NUM_5: string = "外注加工費";
export const CONST_DOC_MANAGETYPE_NUM_6: string = "材料仕入";
export const CONST_DOC_MANAGETYPE_NUM_7: string = "仕掛金";
// export const CONST_DOC_MANAGETYPE_NUM_8: string = "役員報酬";
// export const CONST_DOC_MANAGETYPE_NUM_9: string = "給料手当";
export const CONST_DOC_MANAGETYPE_NUM_10: string = "従業員賞与";
export const CONST_DOC_MANAGETYPE_NUM_11: string = "雑給/退職金";
export const CONST_DOC_MANAGETYPE_NUM_12: string = "法定福利費";
export const CONST_DOC_MANAGETYPE_NUM_13: string = "福利厚生費";
export const CONST_DOC_MANAGETYPE_NUM_14: string = "広告宣伝費";
export const CONST_DOC_MANAGETYPE_NUM_15: string = "支払手数料";
export const CONST_DOC_MANAGETYPE_NUM_16: string = "旅費交通費";
export const CONST_DOC_MANAGETYPE_NUM_17: string = "減価償却費";
export const CONST_DOC_MANAGETYPE_NUM_18: string = "リース料";
export const CONST_DOC_MANAGETYPE_NUM_19: string = "地代家賃";
export const CONST_DOC_MANAGETYPE_NUM_20: string = "修繕費";
export const CONST_DOC_MANAGETYPE_NUM_21: string = "事務用消耗品費";
export const CONST_DOC_MANAGETYPE_NUM_22: string = "備品･消耗品費";
export const CONST_DOC_MANAGETYPE_NUM_23: string = "通信費";
export const CONST_DOC_MANAGETYPE_NUM_24: string = "水道光熱費";
export const CONST_DOC_MANAGETYPE_NUM_25: string = "租税公課";
export const CONST_DOC_MANAGETYPE_NUM_26: string = "接待交際費";
export const CONST_DOC_MANAGETYPE_NUM_27: string = "保険料";
export const CONST_DOC_MANAGETYPE_NUM_28: string = "諸会費";
export const CONST_DOC_MANAGETYPE_NUM_29: string = "管理諸費";
export const CONST_DOC_MANAGETYPE_NUM_30: string = "会議費";
export const CONST_DOC_MANAGETYPE_NUM_31: string = "研修費";
export const CONST_DOC_MANAGETYPE_NUM_32: string = "雑費";
export const CONST_DOC_MANAGETYPE_NUM_33: string = "受取利息";
export const CONST_DOC_MANAGETYPE_NUM_34: string = "受取配当金";
export const CONST_DOC_MANAGETYPE_NUM_35: string = "業務受託収入";
export const CONST_DOC_MANAGETYPE_NUM_36: string = "雑収入";
export const CONST_DOC_MANAGETYPE_NUM_37: string = "支払利息";
export const CONST_DOC_MANAGETYPE_NUM_38: string = "手形売却損";
export const CONST_DOC_MANAGETYPE_NUM_39: string = "業務委託費";
export const CONST_DOC_MANAGETYPE_NUM_40: string = "雑損失";
export const CONST_DOC_MANAGETYPE_NUM_41: string = "特別損益（売却益）";
export const CONST_DOC_MANAGETYPE_NUM_42: string = "特別損益（除却損）";
export const CONST_DOC_MANAGETYPE_NUM_44: string = "銀行関係";
export const CONST_DOC_MANAGETYPE_NUM_45: string = "売掛金";

/**
 * 文書種別番号を名称文字列に変換する
 * @param approvalStatus
 * @returns
 */
export function cnvDocManageTypeNum2Name(docManageTypeNum: number): string {
  switch (docManageTypeNum) {
    case CONST_DOC_MANAGETYPE_KEIHISEISAN:
      return CONST_DOC_MANAGETYPE_NUM_43;
    case CONST_DOC_MANAGETYPE_URIAGE_BAITAI:
      return CONST_DOC_MANAGETYPE_NUM_1;
    case CONST_DOC_MANAGETYPE_URIAGE_SONOTA:
      return CONST_DOC_MANAGETYPE_NUM_2;
    case CONST_DOC_MANAGETYPE_SIIRE_BAITAI:
      return CONST_DOC_MANAGETYPE_NUM_3;
    case CONST_DOC_MANAGETYPE_SIIRE_SONOTA:
      return CONST_DOC_MANAGETYPE_NUM_4;
    case CONST_DOC_MANAGETYPE_GAITYUKAKOUHI:
      return CONST_DOC_MANAGETYPE_NUM_5;
    case CONST_DOC_MANAGETYPE_ZAIRYOUSIIRE:
      return CONST_DOC_MANAGETYPE_NUM_6;
    case CONST_DOC_MANAGETYPE_SIKAKARIKIN:
      return CONST_DOC_MANAGETYPE_NUM_7;
    // case CONST_DOC_MANAGETYPE_YAKUINNHOUSYU:
    //   return CONST_DOC_MANAGETYPE_NUM_8;
    // case CONST_DOC_MANAGETYPE_KYURYOTEATE:
    //   return CONST_DOC_MANAGETYPE_NUM_9;
    case CONST_DOC_MANAGETYPE_JUUGYOINSHOYO:
      return CONST_DOC_MANAGETYPE_NUM_10;
    case CONST_DOC_MANAGETYPE_ZAKKYUTAISHOKUKIN:
      return CONST_DOC_MANAGETYPE_NUM_11;
    case CONST_DOC_MANAGETYPE_HOTEIFUKURIBI:
      return CONST_DOC_MANAGETYPE_NUM_12;
    case CONST_DOC_MANAGETYPE_FUKURIKOSEIBI:
      return CONST_DOC_MANAGETYPE_NUM_13;
    case CONST_DOC_MANAGETYPE_KOKOKUSENDENHI:
      return CONST_DOC_MANAGETYPE_NUM_14;
    case CONST_DOC_MANAGETYPE_SHIHARAITESURYO:
      return CONST_DOC_MANAGETYPE_NUM_15;
    case CONST_DOC_MANAGETYPE_RYOHIKOTSUHI:
      return CONST_DOC_MANAGETYPE_NUM_16;
    case CONST_DOC_MANAGETYPE_GENKASHOKYAKUHI:
      return CONST_DOC_MANAGETYPE_NUM_17;
    case CONST_DOC_MANAGETYPE_RISURYO:
      return CONST_DOC_MANAGETYPE_NUM_18;
    case CONST_DOC_MANAGETYPE_JIDAIYACHIN:
      return CONST_DOC_MANAGETYPE_NUM_19;
    case CONST_DOC_MANAGETYPE_SHUZENHI:
      return CONST_DOC_MANAGETYPE_NUM_20;
    case CONST_DOC_MANAGETYPE_JIMUYOSHOHINHI:
      return CONST_DOC_MANAGETYPE_NUM_21;
    case CONST_DOC_MANAGETYPE_BIHINSHOHINHI:
      return CONST_DOC_MANAGETYPE_NUM_22;
    case CONST_DOC_MANAGETYPE_TSUSHINHI:
      return CONST_DOC_MANAGETYPE_NUM_23;
    case CONST_DOC_MANAGETYPE_SUIDOKOUNETSUHI:
      return CONST_DOC_MANAGETYPE_NUM_24;
    case CONST_DOC_MANAGETYPE_SOZEIKOKA:
      return CONST_DOC_MANAGETYPE_NUM_25;
    case CONST_DOC_MANAGETYPE_SETAIKOSAISHI:
      return CONST_DOC_MANAGETYPE_NUM_26;
    case CONST_DOC_MANAGETYPE_HOKENRYO:
      return CONST_DOC_MANAGETYPE_NUM_27;
    case CONST_DOC_MANAGETYPE_SHOKAISHI:
      return CONST_DOC_MANAGETYPE_NUM_28;
    case CONST_DOC_MANAGETYPE_KANRISHOHI:
      return CONST_DOC_MANAGETYPE_NUM_29;
    case CONST_DOC_MANAGETYPE_KAIGISHI:
      return CONST_DOC_MANAGETYPE_NUM_30;
    case CONST_DOC_MANAGETYPE_KENSHUHI:
      return CONST_DOC_MANAGETYPE_NUM_31;
    case CONST_DOC_MANAGETYPE_ZAPPI:
      return CONST_DOC_MANAGETYPE_NUM_32;
    case CONST_DOC_MANAGETYPE_UKETORIRISHI:
      return CONST_DOC_MANAGETYPE_NUM_33;
    case CONST_DOC_MANAGETYPE_UKETORIHAITOKIN:
      return CONST_DOC_MANAGETYPE_NUM_34;
    case CONST_DOC_MANAGETYPE_GYOMUJUTAKUSHUNYU:
      return CONST_DOC_MANAGETYPE_NUM_35;
    case CONST_DOC_MANAGETYPE_ZASSHUNYU:
      return CONST_DOC_MANAGETYPE_NUM_36;
    case CONST_DOC_MANAGETYPE_SHIHARAIRISHI:
      return CONST_DOC_MANAGETYPE_NUM_37;
    case CONST_DOC_MANAGETYPE_TEGATABAIKYAKUSON:
      return CONST_DOC_MANAGETYPE_NUM_38;
    case CONST_DOC_MANAGETYPE_GYOMUITAKUHI:
      return CONST_DOC_MANAGETYPE_NUM_39;
    case CONST_DOC_MANAGETYPE_ZASSONSHITSU:
      return CONST_DOC_MANAGETYPE_NUM_40;
    case CONST_DOC_MANAGETYPE_TOKUBETSUSONEK_BAIKYAKUEKI:
      return CONST_DOC_MANAGETYPE_NUM_41;
    case CONST_DOC_MANAGETYPE_TOKUBETSUSONEKI_JOYAKUSON:
      return CONST_DOC_MANAGETYPE_NUM_42;
    case CONST_DOC_MANAGETYPE_GINKOUKANKEI:
      return CONST_DOC_MANAGETYPE_NUM_44;
    case CONST_DOC_MANAGETYPE_URIKAKEKIN:
      return CONST_DOC_MANAGETYPE_NUM_45;

    default:
      throw new Error("不正なDocManageTypeNum");
  }
}
/**
 * 文書種別の名称文字列を番号に変更する
 * @param approvalStatus
 * @returns
 */
export function cnvDocManageTypeStr2Num(docManageTypeStr: string): number {
  switch (docManageTypeStr) {
    case CONST_DOC_MANAGETYPE_NUM_43:
      return CONST_DOC_MANAGETYPE_KEIHISEISAN;
    case CONST_DOC_MANAGETYPE_NUM_1:
      return CONST_DOC_MANAGETYPE_URIAGE_BAITAI;
    case CONST_DOC_MANAGETYPE_NUM_2:
      return CONST_DOC_MANAGETYPE_URIAGE_SONOTA;
    case CONST_DOC_MANAGETYPE_NUM_3:
      return CONST_DOC_MANAGETYPE_SIIRE_BAITAI;
    case CONST_DOC_MANAGETYPE_NUM_4:
      return CONST_DOC_MANAGETYPE_SIIRE_SONOTA;
    case CONST_DOC_MANAGETYPE_NUM_5:
      return CONST_DOC_MANAGETYPE_GAITYUKAKOUHI;
    case CONST_DOC_MANAGETYPE_NUM_6:
      return CONST_DOC_MANAGETYPE_ZAIRYOUSIIRE;
    case CONST_DOC_MANAGETYPE_NUM_7:
      return CONST_DOC_MANAGETYPE_SIKAKARIKIN;
    // case CONST_DOC_MANAGETYPE_NUM_8:
    //   return CONST_DOC_MANAGETYPE_YAKUINNHOUSYU;
    // case CONST_DOC_MANAGETYPE_NUM_9:
    //   return CONST_DOC_MANAGETYPE_KYURYOTEATE;
    case CONST_DOC_MANAGETYPE_NUM_10:
      return CONST_DOC_MANAGETYPE_JUUGYOINSHOYO;
    case CONST_DOC_MANAGETYPE_NUM_11:
      return CONST_DOC_MANAGETYPE_ZAKKYUTAISHOKUKIN;
    case CONST_DOC_MANAGETYPE_NUM_12:
      return CONST_DOC_MANAGETYPE_HOTEIFUKURIBI;
    case CONST_DOC_MANAGETYPE_NUM_13:
      return CONST_DOC_MANAGETYPE_FUKURIKOSEIBI;
    case CONST_DOC_MANAGETYPE_NUM_14:
      return CONST_DOC_MANAGETYPE_KOKOKUSENDENHI;
    case CONST_DOC_MANAGETYPE_NUM_15:
      return CONST_DOC_MANAGETYPE_SHIHARAITESURYO;
    case CONST_DOC_MANAGETYPE_NUM_16:
      return CONST_DOC_MANAGETYPE_RYOHIKOTSUHI;
    case CONST_DOC_MANAGETYPE_NUM_17:
      return CONST_DOC_MANAGETYPE_GENKASHOKYAKUHI;
    case CONST_DOC_MANAGETYPE_NUM_18:
      return CONST_DOC_MANAGETYPE_RISURYO;
    case CONST_DOC_MANAGETYPE_NUM_19:
      return CONST_DOC_MANAGETYPE_JIDAIYACHIN;
    case CONST_DOC_MANAGETYPE_NUM_20:
      return CONST_DOC_MANAGETYPE_SHUZENHI;
    case CONST_DOC_MANAGETYPE_NUM_21:
      return CONST_DOC_MANAGETYPE_JIMUYOSHOHINHI;
    case CONST_DOC_MANAGETYPE_NUM_22:
      return CONST_DOC_MANAGETYPE_BIHINSHOHINHI;
    case CONST_DOC_MANAGETYPE_NUM_23:
      return CONST_DOC_MANAGETYPE_TSUSHINHI;
    case CONST_DOC_MANAGETYPE_NUM_24:
      return CONST_DOC_MANAGETYPE_SUIDOKOUNETSUHI;
    case CONST_DOC_MANAGETYPE_NUM_25:
      return CONST_DOC_MANAGETYPE_SOZEIKOKA;
    case CONST_DOC_MANAGETYPE_NUM_26:
      return CONST_DOC_MANAGETYPE_SETAIKOSAISHI;
    case CONST_DOC_MANAGETYPE_NUM_27:
      return CONST_DOC_MANAGETYPE_HOKENRYO;
    case CONST_DOC_MANAGETYPE_NUM_28:
      return CONST_DOC_MANAGETYPE_SHOKAISHI;
    case CONST_DOC_MANAGETYPE_NUM_29:
      return CONST_DOC_MANAGETYPE_KANRISHOHI;
    case CONST_DOC_MANAGETYPE_NUM_30:
      return CONST_DOC_MANAGETYPE_KAIGISHI;
    case CONST_DOC_MANAGETYPE_NUM_31:
      return CONST_DOC_MANAGETYPE_KENSHUHI;
    case CONST_DOC_MANAGETYPE_NUM_32:
      return CONST_DOC_MANAGETYPE_ZAPPI;
    case CONST_DOC_MANAGETYPE_NUM_33:
      return CONST_DOC_MANAGETYPE_UKETORIRISHI;
    case CONST_DOC_MANAGETYPE_NUM_34:
      return CONST_DOC_MANAGETYPE_UKETORIHAITOKIN;
    case CONST_DOC_MANAGETYPE_NUM_35:
      return CONST_DOC_MANAGETYPE_GYOMUJUTAKUSHUNYU;
    case CONST_DOC_MANAGETYPE_NUM_36:
      return CONST_DOC_MANAGETYPE_ZASSHUNYU;
    case CONST_DOC_MANAGETYPE_NUM_37:
      return CONST_DOC_MANAGETYPE_SHIHARAIRISHI;
    case CONST_DOC_MANAGETYPE_NUM_38:
      return CONST_DOC_MANAGETYPE_TEGATABAIKYAKUSON;
    case CONST_DOC_MANAGETYPE_NUM_39:
      return CONST_DOC_MANAGETYPE_GYOMUITAKUHI;
    case CONST_DOC_MANAGETYPE_NUM_40:
      return CONST_DOC_MANAGETYPE_ZASSONSHITSU;
    case CONST_DOC_MANAGETYPE_NUM_41:
      return CONST_DOC_MANAGETYPE_TOKUBETSUSONEK_BAIKYAKUEKI;
    case CONST_DOC_MANAGETYPE_NUM_42:
      return CONST_DOC_MANAGETYPE_TOKUBETSUSONEKI_JOYAKUSON;
    case CONST_DOC_MANAGETYPE_NUM_44:
      return CONST_DOC_MANAGETYPE_GINKOUKANKEI;
    case CONST_DOC_MANAGETYPE_NUM_45:
      return CONST_DOC_MANAGETYPE_URIKAKEKIN;

    default:
      throw new Error("不正なDocManageTypeStr");
  }
}

//-------------------------------
//文書の権限関係。
//-------------------------------
/**
 * 重要文書にアクセス可能な人の配列
 */
export const CONST_DOC_AUTH_IMPORTANT = [
  { staffid: 28, staffname: "三浦 実" },
  { staffid: 21, staffname: "角森 学" },
  { staffid: 77, staffname: "樋口 朋子" },
  { staffid: 79, staffname: "岡 奈美江" },
  { staffid: 80, staffname: "永井 つむぎ" },
  { staffid: 35, staffname: "伊藤 英利" },
  { staffid: 50, staffname: "赤沼 みゆき" },
  // { staffid: 12, staffname: "佐々木 祐行" }, //テスト用
];
/**
 * 機密文書にアクセス可能な人の配列
 */
export const CONST_DOC_AUTH_CONFIDENTIAL = [
  { staffid: 42, staffname: "小安 徳郎" },
  { staffid: 45, staffname: "山守 志彦" },
  { staffid: 46, staffname: "小久保 裕文" },
  { staffid: 34, staffname: "岩﨑 一樹" },
  { staffid: 36, staffname: "山本 晋治" },
  { staffid: 71, staffname: "阿児 慎一郎" },
  { staffid: 76, staffname: "吉田 寛子" },
];
/**
 * 文書種別番号と、文書種別、権限番号（1:一般文書;2:重要文書;3:機密文書;）の構造体。
 */
export const CONST_DOC_AUTH_STRUCTURE = [
  { managTypeNum: 1, manageType: "売上（媒体料）", authType: 1 },
  { managTypeNum: 2, manageType: "売上（その他）", authType: 1 },
  { managTypeNum: 3, manageType: "仕入（媒体料）", authType: 1 },
  { managTypeNum: 4, manageType: "仕入（その他）", authType: 1 },
  { managTypeNum: 5, manageType: "外注加工費", authType: 1 },
  { managTypeNum: 6, manageType: "材料仕入", authType: 1 },
  { managTypeNum: 7, manageType: "仕掛金", authType: 1 },
  { managTypeNum: 8, manageType: "役員報酬", authType: 3 },
  { managTypeNum: 9, manageType: "給料手当", authType: 3 },
  { managTypeNum: 10, manageType: "従業員賞与", authType: 3 },
  { managTypeNum: 11, manageType: "雑給/退職金", authType: 3 },
  { managTypeNum: 12, manageType: "法定福利費", authType: 3 },
  { managTypeNum: 13, manageType: "福利厚生費", authType: 3 },
  { managTypeNum: 14, manageType: "広告宣伝費", authType: 2 },
  { managTypeNum: 15, manageType: "支払手数料", authType: 2 },
  { managTypeNum: 16, manageType: "旅費交通費", authType: 2 },
  { managTypeNum: 17, manageType: "減価償却費", authType: 2 },
  { managTypeNum: 18, manageType: "リース料", authType: 2 },
  { managTypeNum: 19, manageType: "地代家賃", authType: 2 },
  { managTypeNum: 20, manageType: "修繕費", authType: 2 },
  { managTypeNum: 21, manageType: "事務用消耗品費", authType: 2 },
  { managTypeNum: 22, manageType: "備品･消耗品費", authType: 2 },
  { managTypeNum: 23, manageType: "通信費", authType: 2 },
  { managTypeNum: 24, manageType: "水道光熱費", authType: 2 },
  { managTypeNum: 25, manageType: "租税公課", authType: 2 },
  { managTypeNum: 26, manageType: "接待交際費", authType: 2 },
  { managTypeNum: 27, manageType: "保険料", authType: 2 },
  { managTypeNum: 28, manageType: "諸会費", authType: 2 },
  { managTypeNum: 29, manageType: "管理諸費", authType: 2 },
  { managTypeNum: 30, manageType: "会議費", authType: 2 },
  { managTypeNum: 31, manageType: "研修費", authType: 2 },
  { managTypeNum: 32, manageType: "雑費", authType: 2 },
  { managTypeNum: 33, manageType: "受取利息", authType: 3 },
  { managTypeNum: 34, manageType: "受取配当金", authType: 3 },
  { managTypeNum: 35, manageType: "業務受託収入", authType: 3 },
  { managTypeNum: 36, manageType: "雑収入", authType: 3 },
  { managTypeNum: 37, manageType: "支払利息", authType: 3 },
  { managTypeNum: 38, manageType: "手形売却損", authType: 3 },
  { managTypeNum: 39, manageType: "業務委託費", authType: 3 },
  { managTypeNum: 40, manageType: "雑損失", authType: 3 },
  { managTypeNum: 41, manageType: "特別損益（売却益）", authType: 3 },
  { managTypeNum: 42, manageType: "特別損益（除却損）", authType: 3 },
  { managTypeNum: 43, manageType: "経費精算", authType: 2 },
  { managTypeNum: 44, manageType: "銀行関係", authType: 3 },
  { managTypeNum: 45, manageType: "売掛金", authType: 2 },
];

//-------------------------------
//本番のlambda名。
//-------------------------------
export const CONST_LAMBDA_NAME_PROD = "lambda_furukawa_prod";

//-------------------------------
//ログ関係。
//-------------------------------

/**
 * 開発用環境時に、ログを処理するlambda名
 */
export const CONST_LAMBDA_NAME_DEVLOGING = "lambda_furukawa_devset2";

/**
 * 本番用のログLambdaの名称
 */
export const CONST_LOG_LAMBDA_NAME_PROD = "lambda_furukawa_prod_log";
/**
 * 開発用のログLambdaの名称
 */
export const CONST_LOG_LAMBDA_NAME_DEV = "lambda_furukawa_stag_log";

/**
 * 環境毎に使用できるログ用Lambdaの名前を返す。
 * 単純にisProdフラグでの管理になっており、
 * @returns
 */
export function getLogLambdaName(): string {
  if (process.env.isProd != undefined && process.env.isProd == "1") {
    //本番
    return CONST_LOG_LAMBDA_NAME_PROD;
  } else {
    //開発
    return CONST_LOG_LAMBDA_NAME_DEV;
  }
}

//-------------------------------
//その他。
//-------------------------------
/**
 * 各時間を受け取り、ステータスを表す文字列を返す
 * @param
 * @returns
 */
export function getAttendanceStatusStr(
  timeWork: string | null,
  timeLeaving: string | null,
  timeGooutStart: string | null,
  timeGooutEnd: string | null
): string {
  if (
    timeWork == null &&
    timeLeaving == null &&
    timeGooutStart == null &&
    timeGooutEnd == null
  ) {
    return CONST_ATTENDANCE_STATUS_MISYUKIN;
  }
  if (
    timeWork != null &&
    timeLeaving == null &&
    timeGooutStart != null &&
    timeGooutEnd == null
  ) {
    return CONST_ATTENDANCE_STATUS_GAISYUTU;
  }
  if (timeWork != null && timeLeaving == null) {
    return CONST_ATTENDANCE_STATUS_SYUKIN;
  }
  if (timeWork != null && timeLeaving != null) {
    return CONST_ATTENDANCE_STATUS_TAIKIN;
  }

  throw new Error("不正な状態");
}

/**
 * 会社種類
 */

export const CONST_COPTYPE_SITEINASI: number = 1;
export const CONST_COPTYPE_KABUSIKI: number = 2;
export const CONST_COPTYPE_YUGEN: number = 3;
export const CONST_COPTYPE_IPPANSYADAN: number = 4;
export const CONST_COPTYPE_GOUDOU: number = 5;
export const CONST_COPTYPE_SYAKAIHUKUSI: number = 6;
export const CONST_COPTYPE_SYAKAIIRYOU: number = 7;
export const CONST_COPTYPE_IRYOU: number = 8;
export const CONST_COPTYPE_GAKKOU: number = 9;

export const CONST_COPTYPE_NUM_1: string = "指定なし";
export const CONST_COPTYPE_NUM_2: string = "株式会社";
export const CONST_COPTYPE_NUM_3: string = "有限会社";
export const CONST_COPTYPE_NUM_4: string = "一般社団法人";
export const CONST_COPTYPE_NUM_5: string = "合同会社";
export const CONST_COPTYPE_NUM_6: string = "社会福祉法人";
export const CONST_COPTYPE_NUM_7: string = "社会医療法人";
export const CONST_COPTYPE_NUM_8: string = "医療法人";
export const CONST_COPTYPE_NUM_9: string = "学校法人";

export const CONST_OP_COMPANY = [
  { label: "指定なし", code: 1 },
  { label: "株式会社", code: 2 },
  { label: "有限会社", code: 3 },
  { label: "一般社団法人", code: 4 },
  { label: "合同会社", code: 5 },
  { label: "社会福祉法人", code: 6 },
  { label: "社会医療法人", code: 7 },
  { label: "医療法人", code: 8 },
  { label: "学校法人", code: 9 },
];

/**
 * 期
 */
export const CONST_OP_YEAR = [
  { label: "59", code: 59 },
  { label: "60", code: 60 },
  { label: "61", code: 61 },
  { label: "62", code: 62 },
  { label: "63", code: 63 },
  { label: "64", code: 64 },
];

/**
 * 部署
 */
export const CONST_OP_STAFFDEPART = [
  { label: "営業部", code: 1 },
  // { label: "営業推進部", code: 2 },
  { label: "経営企画室", code: 3 },
];

/**
 * 日報種類
 */
export const CONST_OP_DAILYREPORT_TYPE = [
  { label: "決定", code: 1 },
  { label: "提案", code: 2 },
  { label: "調査", code: 3 },
  { label: "その他", code: 4 },
];

/**
 * 仕入れ先種類
 */
export const CONST_OP_SUPPLIERTYPE = [
  { label: "一般", code: 1 },
  { label: "同業", code: 2 },
];

/**
 * 請求書送付先住所
 */
export const CONST_OP_CLAIM_ADD = [
  { label: "同上", code: 1 },
  { label: "別住所", code: 2 },
];

/**
 * システム権限
 */
export const CONST_OP_AUTHTYPE = [
  { label: "入力者", code: 1 },
  { label: "集計者", code: 2 },
  { label: "権限者", code: 3 },
  { label: "管理者", code: 4 },
  { label: "勤怠者", code: 5 },
  { label: "編集者", code: 6 },
];

/**
 * 表示
 */
export const CONST_OP_SHOWORDER = [
  { label: "表示しない", code: 0 },
  { label: "表示する", code: 1 },
];

/**
 * 表記
 */
export const CONST_OP_4SIGNAGE_POSI = [
  { label: "前", code: 1 },
  { label: "後", code: 2 },
];

export const CONST_OP_4BANK_TYPE = [
  { label: "普通預金", code: 1 },
  { label: "当座預金", code: 2 },
];

export const CONST_OP_ADD_CHG = [
  { label: "新規登録", code: 1 },
  { label: "変更登録", code: 2 },
];

export const CONST_OP_BANK_TYPE = [
  { label: "銀行", code: 1 },
  { label: "信金", code: 2 },
  { label: "信組", code: 3 },
  { label: "農協", code: 4 },
];

export const CONST_REPLACEMENT_MAP: Record<string, string> = {
  Ａ: "A",
  Ｂ: "B",
  Ｃ: "C",
  Ｄ: "D",
  Ｅ: "E",
  Ｆ: "F",
  Ｇ: "G",
  Ｈ: "H",
  Ｉ: "I",
  Ｊ: "J",
  Ｋ: "K",
  Ｌ: "L",
  Ｍ: "M",
  Ｎ: "N",
  Ｏ: "O",
  Ｐ: "P",
  Ｑ: "Q",
  Ｒ: "R",
  Ｓ: "S",
  Ｔ: "T",
  Ｕ: "U",
  Ｖ: "V",
  Ｗ: "W",
  Ｘ: "X",
  Ｙ: "Y",
  Ｚ: "Z",
  ａ: "a",
  ｂ: "b",
  ｃ: "c",
  ｄ: "d",
  ｅ: "e",
  ｆ: "f",
  ｇ: "g",
  ｈ: "h",
  ｉ: "i",
  ｊ: "j",
  ｋ: "k",
  ｌ: "l",
  ｍ: "m",
  ｎ: "n",
  ｏ: "o",
  ｐ: "p",
  ｑ: "q",
  ｒ: "r",
  ｓ: "s",
  ｔ: "t",
  ｕ: "u",
  ｖ: "v",
  ｗ: "w",
  ｘ: "x",
  ｙ: "y",
  ｚ: "z",
  "　": " ",
  "＠": "@",
  "＆": "&",
  "０": "0",
  "１": "1",
  "２": "2",
  "３": "3",
  "４": "4",
  "５": "5",
  "６": "6",
  "７": "7",
  "８": "8",
  "９": "9",
};

export const CONST_OP_CLAIM_ROUDING = [
  { label: "処理なし", code: 3 },
  { label: "四捨五入", code: 0 },
  { label: "切り上げ", code: 1 },
  { label: "切り捨て", code: 2 },
];

export const CONST_CLAIM_ROUDING_SISYAGONYU: number = 0;
export const CONST_CLAIM_ROUDING_KIRIAGE: number = 1;
export const CONST_CLAIM_ROUDING_KIRISUTE: number = 2;
export const CONST_CLAIM_ROUDING_SYORINASI: number = 3;
export const CONST_CLAIM_ROUDING_NUM_0: string = "四捨五入";
export const CONST_CLAIM_ROUDING_NUM_1: string = "切り上げ";
export const CONST_CLAIM_ROUDING_NUM_2: string = "切り捨て";
export const CONST_CLAIM_ROUDING_NUM_3: string = "処理なし";
