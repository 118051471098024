import { commonRegx } from "xcommon-node/src/common/commonRegx";

export class DtoSalesInfosGetRes {
    public tokyo_media: number = 0;
    public tokyo_other: number = 0;
    public osaka_media: number = 0;
    public osaka_other: number = 0;
    public nagoya_media: number = 0;
    public nagoya_other: number = 0;
    public fukuoka_media: number = 0;
    public fukuoka_other: number = 0;
    public sapporo_media: number = 0;
    public sapporo_other: number = 0;

    public salesTotal: number = 0;
    public budget: number = 0;
}
