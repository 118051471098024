export class EntityReport {
public c_report_id:number | null = null;
public c_staff_id:number | null = null;
public c_report_date:Date | null = null;
public c_report_remarks:string | null = null;
public c_report_flag_del:number | null = null;
public c_report_reg_user:string | null = null;
public c_report_reg_at:Date | null = null;
public c_report_upd_user:string | null = null;
public c_report_upd_at:Date | null = null;
}