import { DtoFilesDeleteReq } from "./abst/DtoFilesDeleteReq";

export class DtoFilesDeleteReqImple extends DtoFilesDeleteReq {
  public fileId: number | null = null;

  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------

    return res;
  }
}
