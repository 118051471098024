export class EntityStaff {
public c_staff_id:number | null = null;
public c_staff_number:string | null = null;
public c_staff_name:string | null = null;
public c_staff_name_pt:string | null = null;
public c_staff_mail:string | null = null;
public c_branch_id:number | null = null;
public c_staff_depart:number | null = null;
public c_staff_elder_user_id_1:number | null = null;
public c_staff_elder_user_id_2:number | null = null;
public c_staff_elder_user_id_3:number | null = null;
public c_staff_elder_user_id_4:number | null = null;
public c_staff_elder_user_id_5:number | null = null;
public c_staff_elder_user_id_6:number | null = null;
public c_staff_elder_user_id_7:number | null = null;
public c_staff_elder_user_id_8:number | null = null;
public c_staff_elder_user_id_9:number | null = null;
public c_staff_elder_user_id_10:number | null = null;
public c_staff_login_id:string | null = null;
public c_staff_login_pass:string | null = null;
public c_staff_auth_type:number | null = null;
public c_staff_show_order:number | null = null;
public c_staff_show_order_attendandce:number | null = null;
public c_staff_application_staff_id_1:number | null = null;
public c_staff_application_staff_id_2:number | null = null;
public c_staff_application_staff_id_3:number | null = null;
public c_staff_individual_staff_id_1:number | null = null;
public c_staff_individual_staff_id_2:number | null = null;
public c_staff_individual_staff_id_3:number | null = null;
public c_staff_alert_mail:string | null = null;
public c_staff_flag_mail_claim_request:number | null = null;
public c_staff_flag_mail_claim_approval:number | null = null;
public c_staff_flag_mail_claim_return:number | null = null;
public c_staff_flag_mail_contract_period_sales:number | null = null;
public c_staff_flag_mail_contract_period_supply:number | null = null;
public c_staff_flag_mail_request_request:number | null = null;
public c_staff_flag_mail_request_approval:number | null = null;
public c_staff_flag_mail_request_return:number | null = null;
public c_staff_flag_mail_request_withdrawal:number | null = null;
public c_staff_billing_person:string | null = null;
public c_staff_flag_insurance:number | null = null;
public c_staff_flag_show:number | null = null;
public c_staff_flag_del:number | null = null;
public c_staff_reg_user:string | null = null;
public c_staff_reg_at:Date | null = null;
public c_staff_upd_user:string | null = null;
public c_staff_upd_at:Date | null = null;
}