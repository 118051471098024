import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { DtoGoalsGetReq } from "./abst/DtoGoalsGetReq";
import { DtoApiPager } from "./abst/DtoApiPager";

export class DtoGoalsGetReqImple extends DtoGoalsGetReq {
  //目標マスタ一覧の検索条件
  public year: number | null = null;
  public staffId: string | null = null;
  public branchId: string | null = null;
  public depertId: string | null = null;

  public pager = new DtoApiPager();

  public IsValidImple(chkPager: boolean): string[] {
    let res: string[] = new Array();
    //ページャー用入力値チェック
    if (chkPager) {
      this.pager.IsValid4Pager(res);
    } else {
      res = res.concat(super.IsValid());
    }

    return res;
  }
}
