export class EntityGoal {
public c_goal_id:number | null = null;
public c_staff_id:number | null = null;
public c_goal_year:number | null = null;
public c_goal_earnings:number | null = null;
public c_goal_gross:number | null = null;
public c_goal_flag_del:number | null = null;
public c_goal_reg_user:string | null = null;
public c_goal_reg_at:Date | null = null;
public c_goal_upd_user:string | null = null;
public c_goal_upd_at:Date | null = null;
}