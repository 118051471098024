import axios from "axios";
import { AxiosError } from "axios";
import { CONST_BASE_IS_DEBAG, CONST_URL_API } from "@/constants/constant";
import { Cl_staffImple } from "./Cl_staffImple";
import Store from "@/store/index";

export default class Cl_api {
  public static async postRequest(apiName: string, param: any): Promise<any> {
    try {
      await Cl_staffImple.tokenRefresh(Store);
      const headersData = {
        Authorization: JSON.parse(localStorage.getItem("furukawa")!).token_id,
      };

      const response = await axios.post(CONST_URL_API + apiName, param, {
        headers: headersData,
      });

      return response.data;
    } catch (error: any) {
      if (this.isAxiosError(error)) {
        if (CONST_BASE_IS_DEBAG) {
          console.log((error as AxiosError).name);
          console.log((error as AxiosError).message);
          console.log((error as AxiosError).stack);
          console.log((error as AxiosError).response);
        }
      }
      throw error;
    }
  }

  public static async putRequest(apiName: string, param: any): Promise<any> {
    try {
      await Cl_staffImple.tokenRefresh(Store);
      const headersData = {
        Authorization: JSON.parse(localStorage.getItem("furukawa")!).token_id,
      };

      const response = await axios.put(CONST_URL_API + apiName, param, {
        headers: headersData,
      });

      return response.data;
    } catch (error: any) {
      if (this.isAxiosError(error)) {
        if (CONST_BASE_IS_DEBAG) {
          console.log((error as AxiosError).name);
          console.log((error as AxiosError).message);
          console.log((error as AxiosError).stack);
          console.log((error as AxiosError).response);
        }
      }
      throw error;
    }
  }

  public static async getRequest(apiName: string): Promise<any> {
    try {
      await Cl_staffImple.tokenRefresh(Store);
      const headersData = {
        Authorization: JSON.parse(localStorage.getItem("furukawa")!).token_id,
      };

      const response = await axios.get(CONST_URL_API + apiName, {
        headers: headersData,
      });

      return response.data;
    } catch (error: any) {
      if (this.isAxiosError(error)) {
        if (CONST_BASE_IS_DEBAG) {
          console.log((error as AxiosError).name);
          console.log((error as AxiosError).message);
          console.log((error as AxiosError).stack);
          console.log((error as AxiosError).response);
        }
      }
      throw error;
    }
  }

  public static async deleteRequest(apiName: string): Promise<any> {
    try {
      await Cl_staffImple.tokenRefresh(Store);
      const headersData = {
        Authorization: JSON.parse(localStorage.getItem("furukawa")!).token_id,
      };

      const response = await axios.delete(CONST_URL_API + apiName, {
        headers: headersData,
      });

      return response.data;
    } catch (error: any) {
      if (this.isAxiosError(error)) {
        if (CONST_BASE_IS_DEBAG) {
          console.log((error as AxiosError).name);
          console.log((error as AxiosError).message);
          console.log((error as AxiosError).stack);
          console.log((error as AxiosError).response);
        }
      }
      throw error;
    }
  }
  //-------------
  /**
   * AxiosErrorかどうかを返す。
   * @param error
   * @returns
   */
  public static isAxiosError(error: any): error is AxiosError {
    return !!error.isAxiosError;
  }
}
