import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoProjectsGetReqImple } from "furukawa-common/src/common/dto/DtoProjectsGetReqImple";
import { DtoProjectsGetResImple } from "furukawa-common/src/common/dto/DtoProjectsGetResImple";
import { DtoProjectsPostReq } from "furukawa-common/src/common/dto/abst/DtoProjectsPostReq";
import { DtoProjectsPostRes } from "furukawa-common/src/common/dto/abst/DtoProjectsPostRes";
import { DtoProjectsPutReq } from "furukawa-common/src/common/dto/abst/DtoProjectsPutReq";
import { DtoProjectsPutRes } from "furukawa-common/src/common/dto/abst/DtoProjectsPutRes";
import { DtoProjectsDeleteReq } from "furukawa-common/src/common/dto/abst/DtoProjectsDeleteReq";
import { DtoProjectsDeleteRes } from "furukawa-common/src/common/dto/abst/DtoProjectsDeleteRes";

export class Cl_project {
  public static async getList(
    dto: DtoProjectsGetReqImple
  ): Promise<DtoProjectsGetResImple> {
    return Object.assign(
      new DtoProjectsGetResImple(),
      await CallAPI.getRequest(
        "/projects?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoProjectsGetReqImple
  ): Promise<DtoProjectsGetResImple> {
    return Object.assign(
      new DtoProjectsGetResImple(),
      await CallAPI.getRequest("/projects/" + dto.c_project_id)
    );
  }

  public static async post(
    dto: DtoProjectsPostReq
  ): Promise<DtoProjectsPostRes> {
    return Object.assign(
      new DtoProjectsPostRes(),
      await CallAPI.postRequest("/projects", dto)
    );
  }

  public static async put(dto: DtoProjectsPutReq): Promise<DtoProjectsPutRes> {
    return Object.assign(
      new DtoProjectsPutRes(),
      await CallAPI.putRequest("/projects/" + dto.c_project_id, dto)
    );
  }

  public static async del(
    dto: DtoProjectsDeleteReq
  ): Promise<DtoProjectsDeleteRes> {
    return Object.assign(
      new DtoProjectsDeleteRes(),
      await CallAPI.deleteRequest("/projects?" + Cl_common.cnvObj2GetParam(dto))
    );
  }
}
