
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";

@Options({
  components: {
    vSelect,
    Mo_modal_alert,
  },
})
export default class SalesByCustomer extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  btnDisable = false;

  optionsOp = [
    { label: "59" },
    { label: "60" },
    { label: "61" },
    { label: "62" },
  ];
  optionsOp2 = [
    { label: "第一四半期" },
    { label: "第二四半期" },
    { label: "第三四半期" },
    { label: "第四四半期" },
  ];
}
