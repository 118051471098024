export class EntityClaim {
public c_claim_id:number | null = null;
public c_project_id:number | null = null;
public c_branch_id:number | null = null;
public c_claim_number:number | null = null;
public c_claim_datetime:Date | null = null;
public c_client_id:number | null = null;
public c_staff_id:number | null = null;
public c_claim_month:Date | null = null;
public c_claim_date_exp_payment:Date | null = null;
public c_claim_title_1:string | null = null;
public c_claim_title_2:string | null = null;
public c_claim_sponsor_name:string | null = null;
public c_claim_subject:string | null = null;
public c_claim_breakdown:string | null = null;
public c_claim_paylimit:Date | null = null;
public c_claim_flag_delivery:number | null = null;
public c_claim_remarks:string | null = null;
public c_claim_status:number | null = null;
public c_claim_flag_sales:number | null = null;
public c_claim_subtotal:number | null = null;
public c_claim_total:number | null = null;
public c_claim_depo_datetime:Date | null = null;
public c_claim_authorizer1:number | null = null;
public c_claim_authorizer2:number | null = null;
public c_claim_authorizer3:number | null = null;
public c_claim_authorizer4:number | null = null;
public c_claim_authorizer5:number | null = null;
public c_claim_authorizer6:number | null = null;
public c_claim_authorizer7:number | null = null;
public c_claim_authorizer8:number | null = null;
public c_claim_authorizer9:number | null = null;
public c_claim_authorizer10:number | null = null;
public c_claim_authorizer:number | null = null;
public c_claim_memo:string | null = null;
public c_claim_flag_enable:number | null = null;
public c_claim_flag_del:number | null = null;
public c_claim_reg_user:string | null = null;
public c_claim_reg_at:Date | null = null;
public c_claim_upd_user:string | null = null;
public c_claim_upd_at:Date | null = null;
}