import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoAlertsGetReqImple } from "furukawa-common/src/common/dto/DtoAlertsGetReqImple";
import { DtoAlertsGetResImple } from "furukawa-common/src/common/dto/DtoAlertsGetResImple";
import { DtoAlertsPostReq } from "furukawa-common/src/common/dto/abst/DtoAlertsPostReq";
import { DtoAlertsPostRes } from "furukawa-common/src/common/dto/abst/DtoAlertsPostRes";
import { DtoAlertsPutReq } from "furukawa-common/src/common/dto/abst/DtoAlertsPutReq";
import { DtoAlertsPutRes } from "furukawa-common/src/common/dto/abst/DtoAlertsPutRes";
import { DtoAlertsDeleteReq } from "furukawa-common/src/common/dto/abst/DtoAlertsDeleteReq";
import { DtoAlertsDeleteRes } from "furukawa-common/src/common/dto/abst/DtoAlertsDeleteRes";

export class Cl_alert {
  public static async getList(
    dto: DtoAlertsGetReqImple
  ): Promise<DtoAlertsGetResImple> {
    return Object.assign(
      new DtoAlertsGetResImple(),
      await CallAPI.getRequest(
        "/alerts?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoAlertsGetReqImple
  ): Promise<DtoAlertsGetResImple> {
    return Object.assign(
      new DtoAlertsGetResImple(),
      await CallAPI.getRequest("/alerts/" + dto.c_alert_id)
    );
  }

  public static async post(dto: DtoAlertsPostReq): Promise<DtoAlertsPostRes> {
    return Object.assign(
      new DtoAlertsPostRes(),
      await CallAPI.postRequest("/alerts", dto)
    );
  }

  public static async put(dto: DtoAlertsPutReq): Promise<DtoAlertsPutRes> {
    return Object.assign(
      new DtoAlertsPutRes(),
      await CallAPI.putRequest("/alerts/" + dto.c_alert_id, dto)
    );
  }

  public static async del(
    dto: DtoAlertsDeleteReq
  ): Promise<DtoAlertsDeleteRes> {
    return Object.assign(
      new DtoAlertsDeleteRes(),
      await CallAPI.deleteRequest("/alerts?" + Cl_common.cnvObj2GetParam(dto))
    );
  }
}
