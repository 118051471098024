import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { DtoFileMastersGetReq } from "./abst/DtoFileMastersGetReq";
import { DtoApiPager } from "./abst/DtoApiPager";

export class DtoFileMastersGetReqImple extends DtoFileMastersGetReq {
  public type: string | null = null;

  public fromCode: number | null = null;
  public toCode: number | null = null;
  public name: string | null = null;
  public name2: string | null = null;
  public branch: number | null = null;
  public strNumber: string | null = null;
  public type2: number | null = null;
  public date: string | null = null;
  public year: number | null = null;

  //商品マスタ一覧用
  public itemManagName: string | null = null;
  public itemName: string | null = null;
  public itemType: string[] | null = null;
  public itemOwner: string[] | null = null;
  public itemArea: string | null = null;

  //請求書用
  public deliverySlip: number | null = null; // 納品書フラグ 1:あり / 0:なし
  public claimNumber: number | null = null;
  public projectNumber: number | null = null; //ログ用
  public projectId: number | null = null; //ログ用
  public claimId: number | null = null; //ログ用
  /**
   * 1:スタンプをセットする / 0:スタンプをセットしない
   */
  public isSetStmp: number | null = null;
  /**
   * 1:2025フォーマット / 0:2018フォーマット
   */
  public isFormat_2025: number | null = null;

  // 申請書一覧用
  public requestidstart: string | null = null;
  public requestidend: string | null = null;
  public requesttype: number | null = null;
  public requeststatsu: number | null = null;
  public authorizerid: number | null = null;
  public applicantid: number | null = null;

  // 各申請書用
  requestId: number | null = null;

  //出勤表個別用
  /**
   * 社員IDを指定
   */
  public attendanceid: number | null = null;
  /**
   * 対象月をyyyy-MMで指定
   */
  public attendancemo: string | null = null;
}
