export class commonDateFurukawa {

    /**
     * 渡された日付をyyyy-MM-ddの文字列型で返す
     * @param date 
     * @returns 
     */
    static formatDateString(date: Date): string {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    /**
     * 渡された日付の末日をyyyy-MM-ddの文字列型で返す
     * @param date 
     * @returns 
     */
    static getLastDayOfMonth_str(date: Date): string {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const lastDay = new Date(year, month, 0).getDate();
        return `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
    }

    /**
     * 渡された日付をyyyy/MMの文字列型で返す
     * @param date 
     * @returns 
     */
    static formatDateString_ym(date: Date): string {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}/${month}`;
    }


}