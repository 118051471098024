import { EntityHistory } from "../entity/EntityHistory";
import { EntityStaff } from "../entity/EntityStaff";
import { DtoApiPager } from "./abst/DtoApiPager";
import { DtoHistorysGetRes } from "./abst/DtoHistorysGetRes";

export class DtoHistorysGetResImple extends DtoHistorysGetRes {
  public exitems: Exitem[] = [];
  public pager = new DtoApiPager();

  public async setEximtes(imap: Map<number, number>, staffData: EntityStaff[]) {

    let tmp: Exitem;
    for (let index = 0; index < this.items.length; index++) {
      tmp = new Exitem();

      tmp.c_history_id = this.items[index].c_history_id;
      tmp.c_staff_id = this.items[index].c_staff_id;
      tmp.c_history_datetime = this.items[index].c_history_datetime;
      tmp.c_history_title = this.items[index].c_history_title;
      tmp.c_history_det = this.items[index].c_history_det;
      tmp.c_history_reg_user = this.items[index].c_history_reg_user;
      tmp.c_history_reg_at = this.items[index].c_history_reg_at;
      tmp.c_history_upd_user = this.items[index].c_history_upd_user;
      tmp.c_history_upd_at = this.items[index].c_history_upd_at;

      tmp.staffName = staffData[imap.get(this.items[index].c_staff_id!)!].c_staff_name;

      this.exitems.push(tmp);
    }

  }
}

export class Exitem extends EntityHistory {
  public staffName: string | null = null;
}