
import { Prop, Options, Vue, Watch } from "vue-property-decorator";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import Mo_tableJyutu_row_sales from "@/components/molecules/Mo_tableJyutu_row_sales.vue";
import Mo_tableJyutu_row_supply from "@/components/molecules/Mo_tableJyutu_row_supply.vue";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import Or_tableClaimNum from "@/components/organisms/Or_tableClaimNum.vue";

//プロジェクト
import { DtoProjectsGetResImple } from "furukawa-common/src/common/dto/DtoProjectsGetResImple";

//masters用
import { EntitySupplier } from "furukawa-common/src/common/entity/EntitySupplier";

//詳細用
import { EntitySalesImple } from "furukawa-common/src/common/entity/EntitySalesImple";
import { EntitySupplyImple } from "furukawa-common/src/common/entity/EntitySupplyImple";
import { Cl_common } from "@/functions/Cl_common";
import { DtoClaimNumsPostReq } from "furukawa-common/src/common/dto/DtoClaimNumsPostReq";
import { DtoClaimNumsPostRes } from "furukawa-common/src/common/dto/DtoClaimNumsPostRes";
import {
  CONST_OP_ITEMTYPE_BREAKDOWNS,
  CONST_OP_ITEMTYPE_ITEM,
  CONST_OP_ITEMTYPE_SUBJECTS,
  CONST_OP_TERM_ITEM,
  CONST_ITEMTYPE_SUBJECTS_BAITAI,
  CONST_ITEMTYPE_SUBJECTS_KOUJI,
  CONST_ITEMTYPE_SUBJECTS_HIKAZEI,
  CONST_ITEMTYPE_ITEM_SHINKI,
  CONST_ITEMTYPE_ITEM_KEIZOKU,
  CONST_ITEMTYPE_ITEM_KEIYAKU,
  CONST_ITEMTYPE_ITEM_SYOUDAKU,
  CONST_ITEMTYPE_ITEM_NESAGE,
  CONST_ITEMTYPE_ITEM_KURIKOSI,
  CONST_ITEMTYPE_ITEM_TEKKYO,
  CONST_ITEMTYPE_ITEM_MAEUKE,
} from "furukawa-common/src/common/constant/Constants";
import { DtoMastersGetRes } from "furukawa-common/src/common/dto/DtoMastersGetRes";

import { CommonProject } from "furukawa-common/src/common/service/CommonProject";
import { CommonDate4FU } from "furukawa-common/src/common/function/CommonDate4FU";

import Mt_dtp from "@/components/atoms/Mt_dtp.vue";
import { DtoMdDtp } from "furukawa-common/src/common/dto/DtoMdDtp";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { EntityClient } from "furukawa-common/src/common/entity/EntityClient";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_tableJyutu_row_sales,
    Mo_tableJyutu_row_supply,
    Mo_modal_alert,
    Mt_dtp,
    VueDatePicker,
    Or_tableClaimNum,
  },
})
export default class Or_tableJyutu extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  @Prop({ required: true })
  projectData: DtoProjectsGetResImple;
  // @Prop({ required: true })
  // items4Supplyers: EntitySupplier[];
  @Prop({ required: true })
  mastersData: DtoMastersGetRes;

  @Prop({ required: true })
  doReget: { doReget: boolean };

  @Prop({ required: true })
  doPut: { doPut: boolean };

  @Prop({ required: true })
  profitData: {
    sumSales4medium: string;
    sumSales4construction: string;
    sumSales4notax: string;
    sumSalesTotal: string;
    sumSupplys4medium: string;
    sumSupplys4construction: string;
    sumSupplys4notax: string;
    sumSupplysTotal: string;
    profit4medium: string;
    profit4construction: string;
    profit4notax: string;
    profitTotal: string;
    profitRatio4medium: string;
    profitRatio4construction: string;
    profitRatio4notax: string;
    profitRatioAvr: string;
  };

  //売上明細の一括入力用情報
  //------------------
  evDatas4Sales: {
    // selectVariation: EntityVariationImple;
    // data: DtoItemsGetResImple;
    isSelected: boolean;
    detStartDate: string;
    detCount: number;
  } = {
    // selectVariation: new EntityVariationImple(),
    // data: new DtoItemsGetResImple(),
    isSelected: false,
    detStartDate: "",
    detCount: -1,
  };

  bulk_U_itemtype_item = "";
  bulk_U_itemtype_subjects = "";
  bulk_U_itemtype_breakdown = "";
  bulk_U_PriceSales = "";
  bulk_U_PriceSupply = "";
  bulk_U_clirnt: number | null = null;

  //仕入明細の一括入力用情報
  //------------------
  evDatas4Supply: {
    // selectVariation: EntityVariationImple;
    // data: DtoItemsGetResImple;
    isSelected: boolean;
    detStartDate: string;
    detCount: number;
  } = {
    // selectVariation: new EntityVariationImple(),
    // data: new DtoItemsGetResImple(),
    isSelected: false,
    detStartDate: "",
    detCount: -1,
  };

  bulk_S_itemtype_item = "";
  bulk_S_itemtype_subjects = "";
  bulk_S_itemtype_breakdown = "";
  bulk_S_Supplyer: number | null = null;
  bulk_S_PriceSupply = "";

  //明細行の商品入力用情報
  //------------------
  /**
   * 押下された商品選択ボタンが売上明細か仕入明細化を判定。
   */
  isSalesRow = false;
  /**
   * 押下された商品選択ボタンのID。売上idか仕入idが入る。
   */
  callId = 0;
  evDatas4row: {
    // selectVariation: EntityVariationImple;
    // data: DtoItemsGetResImple;
    isSelected: boolean;
    detStartDate: string;
    detCount: number;
  } = {
    // selectVariation: new EntityVariationImple(),
    // data: new DtoItemsGetResImple(),
    isSelected: false,
    detStartDate: "",
    detCount: -1,
  };

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  opItemtypeItem = CONST_OP_ITEMTYPE_ITEM;
  // opItemtypeSubjects = CONST_OP_ITEMTYPE_SUBJECTS;
  // opItemtypeBreakdown = CONST_OP_ITEMTYPE_BREAKDOWNS;

  bulk_U_SupplyType = "";
  bulk_S_SupplyType = "";

  //----------------------
  //セレクトボックス用メソッド
  //----------------------
  @Watch("bulk_U_itemtype_item")
  cngItem4salse() {
    this.bulk_U_itemtype_subjects = "";
    this.bulk_U_itemtype_breakdown = "";
  }
  getOpItemtypeSubjects4salse(): {
    label: string;
    code: number;
  }[] {
    let indices: number[] = [];

    switch (Number(this.bulk_U_itemtype_item)) {
      case CONST_ITEMTYPE_ITEM_SHINKI:
        return CONST_OP_ITEMTYPE_SUBJECTS;
      case CONST_ITEMTYPE_ITEM_KEIZOKU:
        return CONST_OP_ITEMTYPE_SUBJECTS;
      case CONST_ITEMTYPE_ITEM_KEIYAKU:
        indices.push(0); // 媒体のみ
        return indices.map((i) => CONST_OP_ITEMTYPE_SUBJECTS[i]);
      case CONST_ITEMTYPE_ITEM_SYOUDAKU:
        indices.push(0); // 媒体のみ
        return indices.map((i) => CONST_OP_ITEMTYPE_SUBJECTS[i]);
      case CONST_ITEMTYPE_ITEM_NESAGE:
        indices.push(0); // 媒体のみ
        return indices.map((i) => CONST_OP_ITEMTYPE_SUBJECTS[i]);
      case CONST_ITEMTYPE_ITEM_KURIKOSI:
        indices.push(0); // 媒体
        indices.push(1); // 工事
        return indices.map((i) => CONST_OP_ITEMTYPE_SUBJECTS[i]);
      case CONST_ITEMTYPE_ITEM_TEKKYO:
        indices.push(0); // 媒体のみ
        return indices.map((i) => CONST_OP_ITEMTYPE_SUBJECTS[i]);
      case CONST_ITEMTYPE_ITEM_MAEUKE:
        indices.push(0); // 媒体
        indices.push(1); // 工事
        return indices.map((i) => CONST_OP_ITEMTYPE_SUBJECTS[i]);
      default:
        return [{ label: "項目を選択してください", code: -1 }];
    }
  }
  getOpItemtypeBreakdown4salse(): {
    label: string;
    code: number;
  }[] {
    let indices: number[] = [];
    switch (Number(this.bulk_U_itemtype_subjects)) {
      case CONST_ITEMTYPE_SUBJECTS_BAITAI:
        indices.push(0); // 長期媒体
        indices.push(1); // 短期媒体
        indices.push(2); // 保守媒体
        indices.push(3); // 放映料
        return indices.map((i) => CONST_OP_ITEMTYPE_BREAKDOWNS[i]);
      case CONST_ITEMTYPE_SUBJECTS_KOUJI:
        indices.push(4); // 施工費
        indices.push(5); // 撤去費
        indices.push(6); // 貼替料
        indices.push(7); // 電気代
        indices.push(8); // 保守管理費
        indices.push(9); // その他
        return indices.map((i) => CONST_OP_ITEMTYPE_BREAKDOWNS[i]);
      case CONST_ITEMTYPE_SUBJECTS_HIKAZEI:
        indices.push(10); // 申請代
        indices.push(11); // 保険代
        return indices.map((i) => CONST_OP_ITEMTYPE_BREAKDOWNS[i]);
      default:
        return [{ label: "科目を選択してください", code: -1 }];
    }
  }
  // --------
  @Watch("bulk_S_itemtype_item")
  cngItem4supply() {
    this.bulk_S_itemtype_subjects = "";
    this.bulk_S_itemtype_breakdown = "";
  }
  getOpItemtypeSubjects4supply(): {
    label: string;
    code: number;
  }[] {
    let indices: number[] = [];

    switch (Number(this.bulk_S_itemtype_item)) {
      case CONST_ITEMTYPE_ITEM_SHINKI:
        return CONST_OP_ITEMTYPE_SUBJECTS;
      case CONST_ITEMTYPE_ITEM_KEIZOKU:
        return CONST_OP_ITEMTYPE_SUBJECTS;
      case CONST_ITEMTYPE_ITEM_KEIYAKU:
        indices.push(0); // 媒体のみ
        return indices.map((i) => CONST_OP_ITEMTYPE_SUBJECTS[i]);
      case CONST_ITEMTYPE_ITEM_SYOUDAKU:
        indices.push(0); // 媒体のみ
        return indices.map((i) => CONST_OP_ITEMTYPE_SUBJECTS[i]);
      case CONST_ITEMTYPE_ITEM_NESAGE:
        indices.push(0); // 媒体のみ
        return indices.map((i) => CONST_OP_ITEMTYPE_SUBJECTS[i]);
      case CONST_ITEMTYPE_ITEM_KURIKOSI:
        indices.push(0); // 媒体
        indices.push(1); // 工事
        return indices.map((i) => CONST_OP_ITEMTYPE_SUBJECTS[i]);
      case CONST_ITEMTYPE_ITEM_TEKKYO:
        indices.push(0); // 媒体のみ
        return indices.map((i) => CONST_OP_ITEMTYPE_SUBJECTS[i]);
      case CONST_ITEMTYPE_ITEM_MAEUKE:
        indices.push(0); // 媒体
        indices.push(1); // 工事
        return indices.map((i) => CONST_OP_ITEMTYPE_SUBJECTS[i]);
      default:
        return [{ label: "項目を選択してください", code: -1 }];
    }
  }
  getOpItemtypeBreakdown4supply(): {
    label: string;
    code: number;
  }[] {
    let indices: number[] = [];
    switch (Number(this.bulk_S_itemtype_subjects)) {
      case CONST_ITEMTYPE_SUBJECTS_BAITAI:
        indices.push(0); // 長期媒体
        indices.push(1); // 短期媒体
        indices.push(2); // 保守媒体
        indices.push(3); // 放映料
        return indices.map((i) => CONST_OP_ITEMTYPE_BREAKDOWNS[i]);
      case CONST_ITEMTYPE_SUBJECTS_KOUJI:
        indices.push(4); // 施工費
        indices.push(5); // 撤去費
        indices.push(6); // 貼替料
        indices.push(7); // 電気代
        indices.push(8); // 保守管理費
        indices.push(9); // その他
        return indices.map((i) => CONST_OP_ITEMTYPE_BREAKDOWNS[i]);
      case CONST_ITEMTYPE_SUBJECTS_HIKAZEI:
        indices.push(10); // 申請代
        indices.push(11); // 保険代
        return indices.map((i) => CONST_OP_ITEMTYPE_BREAKDOWNS[i]);
      default:
        return [{ label: "科目を選択してください", code: -1 }];
    }
  }

  /**
   * 仕入先の選択肢を構築する用
   */
  getOpSuppliers(): EntitySupplier[] {
    return this.mastersData.getSupplier4NoneDelete(null);
  }
  /**
   * 売上先の選択肢を構築する用
   */
  getOpClients(): EntityClient[] {
    return this.mastersData.getClient4NoneDelete(null);
  }

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    //    //仕入先の選択肢作成
    // this.opSuppliers = await this.mastersData.getSupplier4NoneDelete(null);
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  async clickTabSalse() {
    const tabSalesTmp: HTMLElement = this.$refs.tabSales as HTMLElement;
    if (tabSalesTmp) {
      tabSalesTmp.click();
    }
  }
  /**
   * 明細行並び替え　売上上用
   */
  async sortRowUp4Sales() {
    for (let i = 0; i < this.projectData.sales.length; ++i) {
      if (this.projectData.sales[i].isSelect) {
        if (i == 0) {
          return;
        }

        const element = this.projectData.sales.splice(i, 1)[0];
        this.projectData.sales.splice(i - 1, 0, element);

        await this.resetRowNum4Sales();
        return;
      }
    }

    //モーダルのメッセージを設定
    this.message4ModalErrorConf = "対象となる明細行を選択してください";
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.showModal();
  }
  /**
   * 明細行並び替え　売上下用
   */
  async sortRowDown4Sales() {
    for (let i = 0; i < this.projectData.sales.length; ++i) {
      if (this.projectData.sales[i].isSelect) {
        if (i == this.projectData.sales.length) {
          return;
        }

        const element = this.projectData.sales.splice(i, 1)[0];
        this.projectData.sales.splice(i + 1, 0, element);

        await this.resetRowNum4Sales();
        return;
      }
    }

    //モーダルのメッセージを設定
    this.message4ModalErrorConf = "対象となる明細行を選択してください";
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.showModal();
  }
  /**
   * 明細行並び替え　仕入上用
   */
  async sortRowUp4Supply() {
    for (let i = 0; i < this.projectData.supplys.length; ++i) {
      if (this.projectData.supplys[i].isSelect) {
        if (i == 0) {
          return;
        }

        const element = this.projectData.supplys.splice(i, 1)[0];
        this.projectData.supplys.splice(i - 1, 0, element);

        await this.resetRowNum4Supply();
        return;
      }
    }

    //モーダルのメッセージを設定
    this.message4ModalErrorConf = "対象となる明細行を選択してください";
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.showModal();
  }
  /**
   * 明細行並び替え　仕入下用
   */
  async sortRowDown4Supply() {
    for (let i = 0; i < this.projectData.supplys.length; ++i) {
      if (this.projectData.supplys[i].isSelect) {
        if (i == this.projectData.supplys.length) {
          return;
        }

        const element = this.projectData.supplys.splice(i, 1)[0];
        this.projectData.supplys.splice(i + 1, 0, element);

        await this.resetRowNum4Supply();
        return;
      }
    }

    //モーダルのメッセージを設定
    this.message4ModalErrorConf = "対象となる明細行を選択してください";
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.showModal();
  }

  //--------
  /**
   * 売上明細行を追加する
   */
  async addRowSales() {
    let tmp = new EntitySalesImple();

    //IDは選択処理で必要なので設定する
    tmp.c_sales_id = (await this.getMaxId4Sales()) + 1;
    // tmp.c_sales_price = 0;
    // tmp.cnvPrice = "0";
    tmp.c_sales_taxrate = 3;
    tmp.cnvDateIssue.date = new Date()
      .toLocaleString("ja-JP", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replaceAll("/", "-");
    tmp.cnvDateMonth.date = new Date()
      .toLocaleString("ja-JP", { year: "numeric", month: "2-digit" })
      .replaceAll("/", "-");
    tmp.cnvItemName = "商品を選択してください";
    if (this.projectData.c_client_id != null) {
      tmp.c_client_id = this.projectData.c_client_id;
    }

    this.projectData.sales.push(tmp);
    await this.resetRowNum4Sales();
  }
  /**
   * 仕入明細行を追加する
   */
  async addRowSupply() {
    let tmp = new EntitySupplyImple();

    //IDは選択処理で必要なので設定する
    tmp.c_supply_id = (await this.getMaxId4Supply()) + 1;
    // tmp.c_supply_price = 0;
    // tmp.cnvPrice = "0";
    tmp.c_supply_taxrate = 3;
    tmp.c_supply_flag_chkclaim = 0;
    tmp.cnvItemName = "商品を選択してください";

    this.projectData.supplys.push(tmp);
    await this.resetRowNum4Supply();
  }
  //--------
  /**
   * 売上明細行を削除する
   */
  async delRowSales() {
    this.projectData.sales.pop();
    await this.resetRowNum4Sales();
  }
  /**
   * 選択中の売上明細行を削除する
   */
  async delRowSales4selectes() {
    for (let i = 0; i < this.projectData.sales.length; ++i) {
      if (this.projectData.sales[i].isSelect) {
        //削除対象の明細行に請求書が紐付いていないか確認
        //-------
        if (this.projectData.sales[i].c_claim_id != null) {
          //モーダルのメッセージを設定
          this.message4ModalErrorConf =
            "請求No：" +
            this.projectData.sales[i].c_sales_climnum_tmp +
            "の請求書が存在する為、売上明細行を削除できません。請求書の再編集または、削除を行ってください";
          //モーダル表示
          const modal: any = this.$refs.Mo_modal_errorConf;
          modal.showModal();
          return;
        }

        this.projectData.sales.splice(i, 1);
        await this.resetRowNum4Sales();
        break;
      }
    }
  }
  /**
   * 仕入明細行を削除する
   */
  async delRowSupply() {
    this.projectData.supplys.pop();
    await this.resetRowNum4Supply();
  }
  /**
   * 選択中の仕入明細行を削除する
   */
  async delRowSupply4selectes() {
    for (let i = 0; i < this.projectData.supplys.length; ++i) {
      if (this.projectData.supplys[i].isSelect) {
        this.projectData.supplys.splice(i, 1);
        await this.resetRowNum4Supply();
        break;
      }
    }
  }
  //--------
  /**
   * 選択中の売上明細行をコピーする
   */
  async copyRowSales() {
    for (let i = 0; i < this.projectData.sales.length; ++i) {
      if (this.projectData.sales[i].isSelect) {
        let tmp = new EntitySalesImple();

        tmp = Object.assign(new EntitySalesImple(), this.projectData.sales[i]);

        //データ調整
        tmp.c_sales_climnum_tmp = null;
        tmp.c_claim_id = null;
        tmp.cnvClaimnumTmp = "";
        tmp.c_sales_id = (await this.getMaxId4Sales()) + 1;
        tmp.isSelect = false;
        tmp.c_sales_date_cmp_payment = null;

        //DTPコピー
        let dtpIssue = new DtoMdDtp();
        let dtpMonth = new DtoMdDtp();
        let dtpStart = new DtoMdDtp();
        let dtpEnd = new DtoMdDtp();
        let dtpExp = new DtoMdDtp();
        let dtpCmp = new DtoMdDtp();

        dtpIssue.date = tmp.cnvDateIssue.date;
        dtpMonth.date = tmp.cnvDateMonth.date;
        dtpStart.date = tmp.cnvDateStart.date;
        dtpEnd.date = tmp.cnvDateEnd.date;
        dtpExp.date = tmp.cnvDateExpPayment.date;
        dtpCmp.date = tmp.cnvDateCmpPayment.date;

        tmp.cnvDateIssue = dtpIssue;
        tmp.cnvDateMonth = dtpMonth;
        tmp.cnvDateStart = dtpStart;
        tmp.cnvDateEnd = dtpEnd;
        tmp.cnvDateExpPayment = dtpExp;
        tmp.cnvDateCmpPayment = dtpCmp;
        tmp.cnvDateCmpPayment.date = null;

        this.projectData.sales.push(tmp);

        await this.resetRowNum4Sales();
        break;
      }
    }
  }
  /**
   * 選択中の仕入明細行をコピーする
   */
  async copyRowSupply() {
    for (let i = 0; i < this.projectData.supplys.length; ++i) {
      if (this.projectData.supplys[i].isSelect) {
        let tmp = new EntitySupplyImple();

        tmp = Object.assign(
          new EntitySupplyImple(),
          this.projectData.supplys[i]
        );

        //データ調整
        tmp.c_supply_climnum_tmp = null;
        tmp.c_claim_id = null;
        tmp.cnvClaimnumTmp = "";
        tmp.c_supply_flag_paid = null;
        tmp.c_supply_flag_chkclaim = 0;
        tmp.c_supply_id = (await this.getMaxId4Supply()) + 1;
        tmp.isSelect = false;

        //DTPコピー
        let dtpMonth = new DtoMdDtp();
        let dtpStart = new DtoMdDtp();
        let dtpEnd = new DtoMdDtp();
        let dtpExp = new DtoMdDtp();

        dtpMonth.date = tmp.cnvDateMonth.date;
        dtpStart.date = tmp.cnvDateStart.date;
        dtpEnd.date = tmp.cnvDateEnd.date;
        dtpExp.date = tmp.cnvDateExpPayment.date;

        tmp.cnvDateMonth = dtpMonth;
        tmp.cnvDateStart = dtpStart;
        tmp.cnvDateEnd = dtpEnd;
        tmp.cnvDateExpPayment = dtpExp;

        this.projectData.supplys.push(tmp);

        await this.resetRowNum4Supply();
        break;
      }
    }
  }

  //--------

  //--------
  /**
   * 売上明細行の行番号を振りなおす
   */
  async resetRowNum4Sales() {
    for (let i = 0; i < this.projectData.sales.length; ++i) {
      this.projectData.sales[i].c_sales_rownum = i + 1;
    }
  }
  /**
   * 仕入明細行の行番号を振りなおす
   */
  async resetRowNum4Supply() {
    for (let i = 0; i < this.projectData.supplys.length; ++i) {
      this.projectData.supplys[i].c_supply_rownum = i + 1;
    }
  }
  //--------
  /**
   * 売上明細行の選択時処理
   */
  async selectRow4Sales(ev: number) {
    // console.log(ev);
    for (let i = 0; i < this.projectData.sales.length; ++i) {
      if (ev == this.projectData.sales[i].c_sales_id) {
        continue;
      }
      this.projectData.sales[i].isSelect = false;
    }
  }
  /**
   * 仕入明細行の選択時処理
   */
  async selectRow4Supply(ev: number) {
    // console.log(ev);
    for (let i = 0; i < this.projectData.supplys.length; ++i) {
      if (ev == this.projectData.supplys[i].c_supply_id) {
        continue;
      }
      this.projectData.supplys[i].isSelect = false;
    }
  }
  //--------

  //--------
  /**
   * 収支計算を行う
   */
  async profitCalc() {
    //宣言
    //---------------------------------
    let sumSales4medium = 0;
    let sumSales4construction = 0;
    let sumSales4notax = 0;
    let sumSalesTotal = 0;
    let sumSupplys4medium = 0;
    let sumSupplys4construction = 0;
    let sumSupplys4notax = 0;
    let sumSupplysTotal = 0;
    let profit4medium = 0;
    let profit4construction = 0;
    let profit4notax = 0;
    let profitTotal = 0;
    let profitRatio4medium = 0.0;
    let profitRatio4construction = 0.0;
    let profitRatio4notax = 0.0;
    let profitRatioAvr = 0.0;

    //集計
    //---------------------------------
    //売上
    for (let i = 0; i < this.projectData.sales.length; ++i) {
      //項目が前受で、計上月が空白の場合は、集計対象外とする
      //-------
      if (
        this.projectData.sales[i].c_sales_item == CONST_ITEMTYPE_ITEM_MAEUKE &&
        (this.projectData.sales[i].cnvDateMonth.date == "" ||
          this.projectData.sales[i].cnvDateMonth.date == null)
      ) {
        continue;
      }

      //科目
      //-------
      switch (this.projectData.sales[i].c_sales_subject) {
        case CONST_ITEMTYPE_SUBJECTS_BAITAI:
          //媒体
          sumSales4medium += Number(
            this.projectData.sales[i].cnvPrice.replaceAll(",", "")
          );
          break;
        case CONST_ITEMTYPE_SUBJECTS_KOUJI:
          // 工事
          sumSales4construction += Number(
            this.projectData.sales[i].cnvPrice.replaceAll(",", "")
          );
          break;
        case CONST_ITEMTYPE_SUBJECTS_HIKAZEI:
          //非課税
          sumSales4notax += Number(
            this.projectData.sales[i].cnvPrice.replaceAll(",", "")
          );
          break;
        default:
          break;
      }
    }
    //仕入
    for (let i = 0; i < this.projectData.supplys.length; ++i) {
      switch (this.projectData.supplys[i].c_supply_subject) {
        case CONST_ITEMTYPE_SUBJECTS_BAITAI:
          //媒体
          sumSupplys4medium += Number(
            this.projectData.supplys[i].cnvPrice.replaceAll(",", "")
          );
          break;
        case CONST_ITEMTYPE_SUBJECTS_KOUJI:
          // 工事
          sumSupplys4construction += Number(
            this.projectData.supplys[i].cnvPrice.replaceAll(",", "")
          );
          break;
        case CONST_ITEMTYPE_SUBJECTS_HIKAZEI:
          //非課税
          sumSupplys4notax += Number(
            this.projectData.supplys[i].cnvPrice.replaceAll(",", "")
          );
          break;
        default:
          break;
      }
    }

    //合計と利益率
    // profit = sumSales - sumSupplys;
    // profitRatio = Number(((profit / sumSales) * 100).toFixed(2));
    sumSalesTotal = sumSales4medium + sumSales4construction + sumSales4notax;
    sumSupplysTotal =
      sumSupplys4medium + sumSupplys4construction + sumSupplys4notax;

    profit4medium = sumSales4medium - sumSupplys4medium;
    profit4construction = sumSales4construction - sumSupplys4construction;
    profit4notax = sumSales4notax - sumSupplys4notax;
    profitTotal = profit4medium + profit4construction + profit4notax;

    profitRatio4medium = Number(
      ((profit4medium / sumSales4medium) * 100).toFixed(1)
    );
    profitRatio4construction = Number(
      ((profit4construction / sumSales4construction) * 100).toFixed(1)
    );
    profitRatio4notax = Number(
      ((profit4notax / sumSales4notax) * 100).toFixed(1)
    );
    profitRatioAvr = Number(((profitTotal / sumSalesTotal) * 100).toFixed(1));

    // --------------
    // let sumRatio = 0.0;
    // if (!isNaN(profitRatio4medium) && profitRatio4medium != -Infinity) {
    //   sumRatio += profitRatio4medium;
    // }
    // if (
    //   !isNaN(profitRatio4construction) &&
    //   profitRatio4construction != -Infinity
    // ) {
    //   sumRatio += profitRatio4construction;
    // }
    // if (!isNaN(profitRatio4notax) && profitRatio4notax != -Infinity) {
    //   sumRatio += profitRatio4notax;
    // }

    // profitRatioAvr = Number((sumRatio / 3).toFixed(1));

    // console.log(profitRatio4medium);
    // console.log(profitRatio4construction);
    // console.log(profitRatio4notax);
    // console.log(
    //   profitRatio4medium + profitRatio4construction + profitRatio4notax
    // );

    //代入と調整
    //---------------------------------

    this.profitData.sumSales4medium = sumSales4medium.toLocaleString("ja-JP");
    this.profitData.sumSales4construction =
      sumSales4construction.toLocaleString("ja-JP");
    this.profitData.sumSales4notax = sumSales4notax.toLocaleString("ja-JP");
    this.profitData.sumSalesTotal = sumSalesTotal.toLocaleString("ja-JP");

    this.profitData.sumSupplys4medium =
      sumSupplys4medium.toLocaleString("ja-JP");
    this.profitData.sumSupplys4construction =
      sumSupplys4construction.toLocaleString("ja-JP");
    this.profitData.sumSupplys4notax = sumSupplys4notax.toLocaleString("ja-JP");
    this.profitData.sumSupplysTotal = sumSupplysTotal.toLocaleString("ja-JP");

    this.profitData.profit4medium = profit4medium.toLocaleString("ja-JP");
    this.profitData.profit4construction =
      profit4construction.toLocaleString("ja-JP");
    this.profitData.profit4notax = profit4notax.toLocaleString("ja-JP");
    this.profitData.profitTotal = profitTotal.toLocaleString("ja-JP");

    // this.profitData.profitRatio4medium = profitRatio4medium.toString();
    // this.profitData.profitRatio4construction = profitRatio4construction.toString();
    // this.profitData.profitRatio4notax = profitRatio4notax.toString();
    // this.profitData.profitRatioAvr = profitRatioAvr.toString();

    //パーセンテージ調整
    if (!isNaN(profitRatio4medium) && profitRatio4medium != -Infinity) {
      this.profitData.profitRatio4medium = profitRatio4medium.toString();
    } else {
      this.profitData.profitRatio4medium = "0";
    }
    if (
      !isNaN(profitRatio4construction) &&
      profitRatio4construction != -Infinity
    ) {
      this.profitData.profitRatio4construction =
        profitRatio4construction.toString();
    } else {
      this.profitData.profitRatio4construction = "0";
    }
    if (!isNaN(profitRatio4notax) && profitRatio4notax != -Infinity) {
      this.profitData.profitRatio4notax = profitRatio4notax.toString();
    } else {
      this.profitData.profitRatio4notax = "0";
    }
    if (!isNaN(profitRatioAvr) && profitRatioAvr != -Infinity) {
      this.profitData.profitRatioAvr = profitRatioAvr.toString();
    } else {
      this.profitData.profitRatioAvr = "0";
    }

    // console.log("Infinity");
    console.log(this.profitData);

    //カンマを付ける
    // while (
    //   this.profitData.sumSales !=
    //   (this.profitData.sumSales = this.profitData.sumSales.replace(
    //     /^(-?\d+)(\d{3})/,
    //     "$1,$2"
    //   ))
    // );

    // while (
    //   this.profitData.sumSupplys !=
    //   (this.profitData.sumSupplys = this.profitData.sumSupplys.replace(
    //     /^(-?\d+)(\d{3})/,
    //     "$1,$2"
    //   ))
    // );
    // while (
    //   this.profitData.profit !=
    //   (this.profitData.profit = this.profitData.profit.replace(
    //     /^(-?\d+)(\d{3})/,
    //     "$1,$2"
    //   ))
    // );
  }

  //--------
  /**
   * 売上明細行のsales_idの最大値取得
   */
  async getMaxId4Sales(): Promise<number> {
    let res = 0;
    for (let i = 0; i < this.projectData.sales.length; ++i) {
      if (
        this.projectData.sales[i].c_sales_id != null &&
        res < this.projectData.sales[i].c_sales_id!
      ) {
        res = this.projectData.sales[i].c_sales_id!;
      }
    }

    return res;
  }
  /**
   * 仕入明細行のsupply_idの最大値取得
   */
  async getMaxId4Supply(): Promise<number> {
    let res = 0;
    for (let i = 0; i < this.projectData.supplys.length; ++i) {
      if (
        this.projectData.supplys[i].c_supply_id != null &&
        res < this.projectData.supplys[i].c_supply_id!
      ) {
        res = this.projectData.supplys[i].c_supply_id!;
      }
    }

    return res;
  }

  /**
   * 売上一括入力実行
   */
  async bulk_U_input() {
    //入力チェック
    //-------------------------
    // Cl_common.logger(this.evDatas4Sales);
    // Cl_common.logger(this.bulk_U_SupplyType);
    // Cl_common.logger(this.bulk_U_StartDate);
    //ボタン無効＋ローダー表示
    //----
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });
    try {
      //請求方法がNULLの場合は処理不可
      //-----------
      if (
        this.projectData.c_project_billing_type != 1 &&
        this.projectData.c_project_billing_type != 2
      ) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "請求方法を指定してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }
      //開始日と終了日が入力されていない場合は処理不可
      //-----------
      if (
        this.projectData.cnvSalesdateStart.date == null ||
        this.projectData.cnvSalesdateEnd.date == null ||
        this.projectData.cnvSalesdateStart.date == "" ||
        this.projectData.cnvSalesdateEnd.date == ""
      ) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "販売契約期間を入力してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }

      //総売上額が入力されていない場合は処理不可
      //-----------
      if (
        this.bulk_U_PriceSales == null ||
        this.bulk_U_PriceSales == undefined ||
        this.bulk_U_PriceSales == ""
      ) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "売上総額を入力してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }
      //項目が入力されていない場合は処理不可
      //-----------
      if (this.bulk_U_itemtype_item == null) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "項目を選択してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }
      //科目が入力されていない場合は処理不可
      //-----------
      if (this.bulk_U_itemtype_subjects == null) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "科目を選択してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }
      //内訳が入力されていない場合は処理不可
      //-----------
      if (this.bulk_U_itemtype_breakdown == null) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "内訳を選択してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }

      //入力後のチェック
      //-------------------------
      let billingType = this.projectData.c_project_billing_type!;
      let dateStart = new Date(this.projectData.cnvSalesdateStart.date);
      let dateEnd = new Date(this.projectData.cnvSalesdateEnd.date);
      //入力後のチェック
      if (dateEnd <= dateStart) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf =
          "開始日よりも後の終了日を指定してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }
      let priceSales = Number(this.bulk_U_PriceSales.replaceAll(",", ""));
      //一括入力実行
      //-------------------------
      //データ作成
      //-------
      const bulkSales = await CommonProject.creBulk4Sales(
        (await this.getMaxId4Sales()) + 1,
        billingType,
        this.bulk_U_itemtype_item,
        this.bulk_U_itemtype_subjects,
        this.bulk_U_itemtype_breakdown,
        dateStart,
        dateEnd,
        priceSales
      );

      //明細にデータ入力
      //-------
      //売上入力
      this.projectData.sales = this.projectData.sales.concat(bulkSales);
      await this.resetRowNum4Sales();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * 仕入一括入力実行
   */
  async bulk_S_input() {
    //入力チェック
    //-------------------------
    // Cl_common.logger(this.evDatas4Supply);
    // Cl_common.logger(this.bulk_S_SupplyType);
    // Cl_common.logger(this.bulk_S_StartDate);
    //ボタン無効＋ローダー表示
    //----
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });
    try {
      //開始日と終了日が入力されていない場合は処理不可
      //-----------
      if (
        this.projectData.cnvSupplydateStart.date == null ||
        this.projectData.cnvSupplydateEnd.date == null ||
        this.projectData.cnvSupplydateStart.date == "" ||
        this.projectData.cnvSupplydateEnd.date == ""
      ) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "仕入契約期間を入力してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }
      //総仕入額が入力されていない場合は処理不可
      //-----------
      if (
        this.bulk_S_PriceSupply == null ||
        this.bulk_S_PriceSupply == undefined ||
        this.bulk_S_PriceSupply == ""
      ) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "仕入総額を入力してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }

      //項目が入力されていない場合は処理不可
      //-----------
      if (this.bulk_S_itemtype_item == null) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "項目を選択してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }
      //科目が入力されていない場合は処理不可
      //-----------
      if (this.bulk_S_itemtype_subjects == null) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "科目を選択してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }
      //内訳が入力されていない場合は処理不可
      //-----------
      if (this.bulk_S_itemtype_breakdown == null) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "内訳を選択してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }

      //----------

      //入力後のチェック
      //-------------------------
      //日付
      let dateStart = new Date(this.projectData.cnvSupplydateStart.date);
      let dateEnd = new Date(this.projectData.cnvSupplydateEnd.date);
      //入力後のチェック
      if (dateEnd <= dateStart) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf =
          "開始日よりも後の終了日を指定してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }
      //総仕入額
      let priceSupply = Number(this.bulk_S_PriceSupply.replaceAll(",", ""));
      //仕入先ID
      let supplierId = this.bulk_S_Supplyer!;

      //一括入力実行
      //-------------------------
      const bulkSupply = await CommonProject.creBulk4Supply(
        (await this.getMaxId4Supply()) + 1,
        this.bulk_S_itemtype_item,
        this.bulk_S_itemtype_subjects,
        this.bulk_S_itemtype_breakdown,
        dateStart,
        dateEnd,
        priceSupply,
        supplierId
      );
      this.projectData.supplys = this.projectData.supplys.concat(bulkSupply);
      await this.resetRowNum4Supply();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  /**
   * 売上一括入力用の商品選択ボタン押下時のModal呼び出し
   */
  async showModal4ClaimNum(): Promise<void> {
    //起動チェック
    if (this.projectData.c_project_id == null) {
      this.message4ModalErrorConf =
        "請求書Noを発行するには、案件を登録してください";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
      return;
    }

    //保存する為のイベント発生
    this.doPut.doPut = !this.doPut.doPut;

    //請求書No用モーダル起動
    const modalComp: any = this.$refs.Or_tableClaimNum;
    modalComp.showModal4ClaimNum();
  }
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }

  //----------------------
  //その他のメソッド
  //----------------------
  /**
   * 親コンポーネントに再getを要請するイベント。
   * 請求書Noのモーダルを閉じた時用
   */
  async getEmit() {
    // Cl_common.logger("reget");
    this.doReget.doReget = !this.doReget.doReget;
  }
  /**
   * 金額セル入力時の処理
   * カンマを消す
   * @param obj
   */
  kanmaDel(obj: any) {
    // カンマを消す
    obj.target.value = obj.target.value.replace(/,/g, "");
    // if (this.item.cnvPrice == "0") this.item.cnvPrice = "";
  }
  /**
   * 金額セル入力時の処理
   * カンマを付ける
   * @param obj
   */
  kanmaIns(obj: any) {
    let val: string = obj.target.value;

    //うっかり入力しているかもしれないカンマを消す
    val = val.replace(/,/g, "");
    //整数に変換したのち文字列に戻す
    //この時点で数字とマイナス記号だけが残る
    let num = "" + parseInt(val);
    //正規表現で桁区切りするための良く見かける関数的な何か
    //変数 num の中身が、桁区切りされる
    while (num != (num = num.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
    //numに入っている値が数値じゃないときは空白とする
    if (isNaN(parseInt(num))) num = "";
    //桁区切りした結果（変数 num）でテキストボックスの中身を書き換える
    obj.target.value = num;
  }
}
