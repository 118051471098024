import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntityProject } from "../../entity/EntityProject";
import { EntitySalesImple } from "../../entity/EntitySalesImple";
import { EntitySupplyImple } from "../../entity/EntitySupplyImple";

export class DtoProjectsPutReq extends EntityProject {
  //詳細用
  //-----------------------
  /**
   * 売上用
   */
  public sales: EntitySalesImple[] = [];
  /**
   * 仕入用
   */
  public supplys: EntitySupplyImple[] = [];

  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------
    if (
      !commonRegx.isMatch(
        this.c_project_id!.toString(),
        "^\\d+$",
        commonRegx._stdOption
      )
    ) {
      res.push("type error");
    }

    return res;
  }
}
