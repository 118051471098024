import {
  CONST_OP_ITEMTYPE_BREAKDOWNS,
  CONST_OP_ITEMTYPE_ITEM,
  CONST_OP_ITEMTYPE_SUBJECTS,
  CONST_OP_TAXRATE,
} from "../constant/Constants";
import { EntitySearchSupplys } from "../entity/EntitySearchSupplys";
import { DtoApiPager } from "./abst/DtoApiPager";

export class DtoSearchSupplysGetRes extends EntitySearchSupplys {
  public items: EntitySearchSupplys[] = [];
  public pager = new DtoApiPager();
  public exitems: Exitem[] = [];

  // 集計情報
  //-------
  public sum_supply_price: number = 0;

  public async setEximtes(
    clientmap: Map<number, string>,
    suppliermap: Map<number, string>
  ) {
    let tmp: Exitem;
    for (let index = 0; index < this.items.length; index++) {
      tmp = new Exitem();

      tmp.c_supply_id = this.items[index].c_supply_id;
      tmp.c_project_id = this.items[index].c_project_id;
      tmp.c_supply_rownum = this.items[index].c_supply_rownum;
      tmp.c_claim_id = this.items[index].c_claim_id;
      tmp.c_supply_month = this.items[index].c_supply_month;
      tmp.c_supply_item = this.items[index].c_supply_item;
      tmp.c_supply_subject = this.items[index].c_supply_subject;
      tmp.c_supply_breakdown = this.items[index].c_supply_breakdown;
      tmp.c_supplier_id = this.items[index].c_supplier_id;
      tmp.c_supply_price = this.items[index].c_supply_price;
      tmp.c_supply_date_exp_payment =
        this.items[index].c_supply_date_exp_payment;
      tmp.c_supply_remarks = this.items[index].c_supply_remarks;
      tmp.c_project_number = this.items[index].c_project_number;
      tmp.c_project_name = this.items[index].c_project_name;
      tmp.c_project_sponsor_name = this.items[index].c_project_sponsor_name;
      tmp.pro_c_client_id = this.items[index].pro_c_client_id;

      tmp.c_supplier_name = suppliermap.get(this.items[index].c_supplier_id!)!;
      tmp.pro_c_client_name = clientmap.get(
        this.items[index].pro_c_client_id!
      )!;

      tmp.c_branch_name = this.items[index].c_branch_name;
      tmp.c_client_name = this.items[index].c_client_name;
      tmp.c_supply_flag_paid = this.items[index].c_supply_flag_paid;
      tmp.c_supply_taxrate = this.items[index].c_supply_taxrate;

      if (tmp.c_supply_item != null) {
        const found = CONST_OP_ITEMTYPE_ITEM.find(
          (e) => e.code === tmp.c_supply_item
        );
        tmp.c_supply_item_name = found?.label!;
      } else {
        tmp.c_supply_item_name = "";
      }

      if (tmp.c_supply_subject != null) {
        const found = CONST_OP_ITEMTYPE_SUBJECTS.find(
          (e) => e.code === tmp.c_supply_subject
        );
        tmp.c_supply_subject_name = found?.label!;
      } else {
        tmp.c_supply_subject_name = "";
      }

      if (tmp.c_supply_breakdown != null) {
        const found = CONST_OP_ITEMTYPE_BREAKDOWNS.find(
          (e) => e.code === tmp.c_supply_breakdown
        );
        tmp.c_supply_breakdown_name = found?.label!;
      } else {
        tmp.c_supply_breakdown_name = "";
      }

      if (tmp.c_supply_taxrate != null) {
        const found = CONST_OP_TAXRATE.find(
          (e) => e.code === tmp.c_supply_taxrate
        );
        tmp.c_supply_taxrate_name = found?.label!;
      } else {
        tmp.c_supply_taxrate_name = "";
      }

      if (tmp.c_supply_flag_paid != null) {
        if (tmp.c_supply_flag_paid == "1") {
          tmp.c_supply_flag_paid_name = "確定";
        } else {
          tmp.c_supply_flag_paid_name = "";
        }
      }

      tmp.c_staff_name = this.items[index].c_staff_name!;
      tmp.c_item_name = this.items[index].c_item_name!;
      tmp.c_variation_name = this.items[index].c_variation_name!;
      tmp.c_variation_type = this.items[index].c_variation_type!;
      tmp.c_supply_flag_chkclaim = this.items[index].c_supply_flag_chkclaim!;

      this.exitems.push(tmp);
    }
  }
}

export class Exitem extends EntitySearchSupplys {
  public c_supply_item_name: string | null = null;
  public c_supply_subject_name: string | null = null;
  public c_supply_breakdown_name: string | null = null;
  public c_supply_taxrate_name: string | null = null;
  public c_supply_flag_paid_name: string | null = null;
}
