import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntityReport } from "../../entity/EntityReport";
import { EntityReportDet } from "../../entity/EntityReportDet";

export class DtoReportsPutReq extends EntityReport {

  //詳細用
  //-----------------------
  public details: EntityReportDet[] = [];

  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------
    if (!commonRegx.isMatch(this.c_report_id!.toString(), "^\\d+$", commonRegx._stdOption)) {
      res.push("type error");
    }

    return res;
  }
}
