import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { DtoOutputqueuesGetReq } from "./abst/DtoOutputqueuesGetReq";
import { DtoApiPager } from "./abst/DtoApiPager";

export class DtoOutputqueuesGetReqImple extends DtoOutputqueuesGetReq {
  public pager = new DtoApiPager();

  public IsValidImple(chkPager: boolean): string[] {
    let res: string[] = new Array();
    //ページャー用入力値チェック
    // if (chkPager) {
    //   this.pager.IsValid4Pager(res);
    // } else {
    //   res = res.concat(super.IsValid());
    // }

    return res;
  }
}
