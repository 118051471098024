import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!

  return (_openBlock(), _createBlock(_component_VueDatePicker, {
    class: "mdDtp",
    modelValue: _ctx.contdate.date,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.contdate.date) = $event)),
    format: "yyyy/MM/dd",
    locale: "ja",
    "model-type": "yyyy-MM-dd",
    "week-start": "0",
    "enable-time-picker": false,
    "month-change-on-scroll": false,
    teleport: true,
    "day-class": _ctx.getDayClass,
    "wrapper-class": "dtpInputWrapper",
    "input-class": "dtpInput",
    "auto-apply": "",
    "text-input": "",
    "hide-input-icon": ""
  }, null, 8, ["modelValue", "day-class"]))
}