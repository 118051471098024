import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoFileReportsGetReqImple } from "furukawa-common/src/common/dto/DtoFileReportsGetReqImple";
import { DtoFileReportsGetResImple } from "furukawa-common/src/common/dto/DtoFileReportsGetResImple";

export class Cl_filereport {
  public static async get(
    dto: DtoFileReportsGetReqImple
  ): Promise<DtoFileReportsGetResImple> {
    return Object.assign(
      new DtoFileReportsGetResImple(),
      await CallAPI.getRequest(
        "/filereports/?" + Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
