import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoRequestCarusesGetReqImple } from "furukawa-common/src/common/dto/DtoRequestCarusesGetReqImple";
import { DtoRequestCarusesGetResImple } from "furukawa-common/src/common/dto/DtoRequestCarusesGetResImple";
import { DtoRequestCarusesPostReq } from "furukawa-common/src/common/dto/abst/DtoRequestCarusesPostReq";
import { DtoRequestCarusesPostRes } from "furukawa-common/src/common/dto/abst/DtoRequestCarusesPostRes";
import { DtoRequestCarusesPutReq } from "furukawa-common/src/common/dto/abst/DtoRequestCarusesPutReq";
import { DtoRequestCarusesPutRes } from "furukawa-common/src/common/dto/abst/DtoRequestCarusesPutRes";
import { DtoRequestCarusesDeleteReq } from "furukawa-common/src/common/dto/abst/DtoRequestCarusesDeleteReq";
import { DtoRequestCarusesDeleteRes } from "furukawa-common/src/common/dto/abst/DtoRequestCarusesDeleteRes";

export class Cl_requestcaruse {
  public static async getList(
    dto: DtoRequestCarusesGetReqImple
  ): Promise<DtoRequestCarusesGetResImple> {
    return Object.assign(
      new DtoRequestCarusesGetResImple(),
      await CallAPI.getRequest(
        "/requestCaruses?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoRequestCarusesGetReqImple
  ): Promise<DtoRequestCarusesGetResImple> {
    return Object.assign(
      new DtoRequestCarusesGetResImple(),
      await CallAPI.getRequest("/requestCaruses/" + dto.c_request_caruse_id)
    );
  }

  public static async post(
    dto: DtoRequestCarusesPostReq
  ): Promise<DtoRequestCarusesPostRes> {
    return Object.assign(
      new DtoRequestCarusesPostRes(),
      await CallAPI.postRequest("/requestCaruses", dto)
    );
  }

  public static async put(
    dto: DtoRequestCarusesPutReq
  ): Promise<DtoRequestCarusesPutRes> {
    return Object.assign(
      new DtoRequestCarusesPutRes(),
      await CallAPI.putRequest(
        "/requestCaruses/" + dto.c_request_caruse_id,
        dto
      )
    );
  }

  public static async del(
    dto: DtoRequestCarusesDeleteReq
  ): Promise<DtoRequestCarusesDeleteRes> {
    return Object.assign(
      new DtoRequestCarusesDeleteRes(),
      await CallAPI.deleteRequest(
        "/requestCaruses?" + Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
