
import { Prop, Options, Vue, Watch } from "vue-property-decorator";
import { EntityClaimDet } from "furukawa-common/src/common/entity/EntityClaimDet";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import {
  CONST_OP_DAILYREPORT_TYPE,
  CONST_OP_TAXRATE,
} from "furukawa-common/src/common/constant/Constants";
import { EntityReportDet } from "furukawa-common/src/common/entity/EntityReportDet";
import { EntityReportDetImple } from "furukawa-common/src/common/entity/EntityReportDetImple";

@Options({
  components: {
    vSelect,
  },
})
export default class Mo_tableReportList_det extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  @Prop({ required: true })
  item: EntityReportDetImple;

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------

  //----------------------
  //モーダル用プロパティ
  //----------------------

  //----------------------
  //制御用メソッド
  //----------------------
  // async mounted() {}

  //----------------------
  //基本操作用メソッド
  //----------------------

  //----------------------
  //モーダル用メソッド
  //----------------------
}
