import { EntityReport } from "./EntityReport";
import { EntityReportDet } from "./EntityReportDet";
import { EntityReportDetImple } from "./EntityReportDetImple";

export class EntityReportImple extends EntityReport {
  public c_report_det_row: EntityReportDetImple[] = [];
  public c_report_date_str: string | null = null;
  public c_staff_name: string | null = null;

  public cnvRemarks: string = "";
}
