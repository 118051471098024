export class EntityDoc4det {
public c_doc_id:number | null = null;
public c_staff_id:number | null = null;
public c_client_id:number | null = null;
public c_supplier_id:number | null = null;
public c_claim_id:number | null = null;
public c_request_expenses_id:number | null = null;
public c_doc_name:string | null = null;
public c_doc_type:number | null = null;
public c_doc_price:number | null = null;
public c_doc_customer:string | null = null;
public c_doc_date:Date | null = null;
public c_doc_customerstaff:string | null = null;
public c_doc_flag_issuer:number | null = null;
public c_doc_search:string | null = null;
public c_doc_flag_enab:number | null = null;
public c_doc_flag_reserve:number | null = null;
public c_doc_flag_del:number | null = null;
public c_doc_reg_user:string | null = null;
public c_doc_reg_at:Date | null = null;
public c_doc_upd_user:string | null = null;
public c_doc_upd_at:Date | null = null;
public c_staff_number:string | null = null;
public c_staff_name:string | null = null;
public c_staff_name_pt:string | null = null;
public c_staff_flag_show:number | null = null;
public c_staff_flag_del:number | null = null;
public c_client_name:string | null = null;
public c_client_flag_del:number | null = null;
public c_client_show_order:number | null = null;
public c_supplier_name:string | null = null;
public c_supplier_flag_del:number | null = null;
public c_supplier_show_order:number | null = null;
public c_client_name4cliam:string | null = null;
public c_client_flag_del4cliam:string | null = null;
public c_client_show_order4cliam:string | null = null;
public c_project_id:number | null = null;
public c_branch_id:number | null = null;
public c_claim_number:number | null = null;
public c_claim_datetime:Date | null = null;
public c_client_id4cliam:string | null = null;
public c_staff_id4cliam:string | null = null;
public c_claim_month:Date | null = null;
public c_claim_subtotal:number | null = null;
public c_claim_total:number | null = null;
public c_claim_flag_sales:number | null = null;
public c_claim_status:number | null = null;
public c_claim_flag_del:number | null = null;
public c_claim_flag_enable:number | null = null;
public c_request_id:number | null = null;
public c_request_expenses_date_draft:Date | null = null;
public c_request_expenses_total:number | null = null;
public c_request_expenses_list:string | null = null;
public c_request_type:number | null = null;
public c_request_datetime:Date | null = null;
public c_request_flag_del:number | null = null;
public c_staff_id4req:string | null = null;
public c_doc_managtype:number | null = null;
public c_staff_id4project:number | null = null;
public c_doc_remarks:string | null = null;
public c_staff_number4project:string | null = null;
public c_staff_name4project:string | null = null;
public c_staff_name_pt4project:string | null = null;
public c_staff_flag_show4project:string | null = null;
public c_staff_flag_del4project:string | null = null;
}