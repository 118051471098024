
import { Prop, Options, Vue, Watch } from "vue-property-decorator";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import Mo_modal_n_force from "@/components/molecules/Mo_modal_n_force.vue";
import { DtoProjectsGetResImple } from "furukawa-common/src/common/dto/DtoProjectsGetResImple";
import { DtoClaimNumsGetReq } from "furukawa-common/src/common/dto/DtoClaimNumsGetReq";
import { DtoClaimNumsGetRes } from "furukawa-common/src/common/dto/DtoClaimNumsGetRes";
import { DtoClaimNumsPostReq } from "furukawa-common/src/common/dto/DtoClaimNumsPostReq";
import { DtoClaimNumsPostRes } from "furukawa-common/src/common/dto/DtoClaimNumsPostRes";
import { Cl_common } from "@/functions/Cl_common";
import { Cl_claimNum } from "@/functions/Cl_claimNum";
import { DtoClaimsPutReq } from "furukawa-common/src/common/dto/abst/DtoClaimsPutReq";
import { Cl_claim } from "@/functions/Cl_claim";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_modal_n_force,
  },
})
export default class Or_tableClaimNum extends Vue {
  //----------------------
  //バリデーション
  //----------------------
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  @Prop({ required: true })
  projectData: DtoProjectsGetResImple;

  delegate = new DtoClaimNumsGetRes();

  //----------------------
  //DOM連携用メソッド
  //----------------------

  //ボタン制御用
  btnDisable = false;
  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  //----------------------
  //モーダル用プロパティ
  //----------------------
  //----------------------
  //制御用メソッド
  //----------------------
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * 一覧データを読み込む
   */
  async getlist() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoClaimNumsGetReq();
      dto.projectid = this.projectData.c_project_id;

      //API実行
      //-------
      this.delegate = await Cl_claimNum.get(dto);
      //レスポンス処理
      //-------
    } catch (error) {
      //エラー発生で一覧を初期化
      this.delegate = new DtoClaimNumsGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * データを新たに登録する
   */
  async add() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      if (this.projectData == null || this.projectData.c_project_id == null) {
        return;
      }

      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoClaimNumsPostReq();
      dto.isSales = 1;
      dto.projectid = this.projectData.c_project_id;

      //API実行
      //-------
      const res = await Cl_claimNum.post(dto);

      //レスポンスの処理
      //-------
    } catch (error) {
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });

      await this.getlist();
    }
  }

  /**
   * データを更新する
   */
  async upd(claimId: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");

    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");
      //準備
      //-------
      let dto = new DtoClaimsPutReq();

      //削除処理：削除フラグを立てる。
      dto.c_claim_id = claimId;
      dto.c_claim_flag_del = 1;

      //API実行
      //-------
      await Cl_claim.put(dto);
      //レスポンスの処理
      //-------
    } catch (error) {
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });

      await this.getlist();
    }
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  /**
   * 自身を表示する
   */
  async showModal4ClaimNum(): Promise<void> {
    //起動時に読み込み
    this.getlist();

    const modalComp: any = this.$refs.modal_claimNo;
    modalComp.showModal();
  }
  /**
   * 自身を閉じる
   */
  async closeModal4ClaimNo(): Promise<void> {
    const modalComp: any = this.$refs.modal_claimNo;
    modalComp.closeModal();
    this.$emit("modalclosed");
  }
  //----------------------
  //その他のメソッド（画面独自のもの等）
  //----------------------
}
