
//基本
import { Prop, Options, Vue, Watch } from "vue-property-decorator";
import { DtoApiError } from "furukawa-common/src/common/dto/abst/DtoApiError";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import Or_tableProfit from "@/components/organisms/Or_tableProfit.vue";
import Or_tableSupply from "@/components/organisms/Or_tableSupply.vue";
import Or_tableDouble from "@/components/organisms/Or_tableDouble.vue";
import { Cl_common } from "@/functions/Cl_common";
import { commonRegx } from "xcommon-node/src/common/commonRegx";
import Or_tableJyutu from "@/components/organisms/Or_tableJyutu.vue";

//案件用・ファイル
// import { Cl_projectImple } from "@/functions/Cl_projectImple";
import { Cl_project } from "@/functions/Cl_project";
import { DtoProjectsGetReqImple } from "furukawa-common/src/common/dto/DtoProjectsGetReqImple";
import { DtoProjectsGetResImple } from "furukawa-common/src/common/dto/DtoProjectsGetResImple";
import { DtoProjectsPutReq } from "furukawa-common/src/common/dto/abst/DtoProjectsPutReq";
import { DtoProjectsPostReq } from "furukawa-common/src/common/dto/abst/DtoProjectsPostReq";
import { DtoMdDtp } from "furukawa-common/src/common/dto/DtoMdDtp";
import Mt_dtp from "@/components/atoms/Mt_dtp.vue";
import { DtoFilesPostReqImple } from "furukawa-common/src/common/dto/DtoFilesPostReqImple";
import { DtoFilesPostResImple } from "furukawa-common/src/common/dto/DtoFilesPostResImple";
import axios, { AxiosError } from "axios";

//masters用
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersgetReq } from "furukawa-common/src/common/dto/DtoMastersGetReq";
import { DtoMastersGetRes } from "furukawa-common/src/common/dto/DtoMastersGetRes";

//claim用
import { Cl_claim } from "@/functions/Cl_claim";
import { DtoClaimsPostReqImple } from "furukawa-common/src/common/dto/DtoClaimsPostReqImple";
import { DtoClaimsPostResImple } from "furukawa-common/src/common/dto/DtoClaimsPostResImple";
import { EntitySalesImple } from "furukawa-common/src/common/entity/EntitySalesImple";

//定数
import {
  CONST_OP_ALERT_CONTRACT,
  CONST_OP_PROJECT_TYPE,
} from "furukawa-common/src/common/constant/Constants";
import { Cl_file } from "@/functions/Cl_file";
import { EntityFile } from "furukawa-common/src/common/entity/EntityFile";
import { DtoFilesDeleteReqImple } from "furukawa-common/src/common/dto/DtoFilesDeleteReqImple";
import { DtoFilesGetReqImple } from "furukawa-common/src/common/dto/DtoFilesGetReqImple";
import { EntityStaff } from "../../../furukawa-common/src/common/entity/EntityStaff";

@Options({
  components: {
    vSelect,
    Mo_modal_alert,
    Or_tableProfit,
    Or_tableSupply,
    Or_tableDouble,
    Mt_dtp,
    Or_tableJyutu,
  },
})
export default class ProjectInput extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoProjectsGetResImple();
  delegate4list = new DtoMastersGetRes();

  btnDisable = false;
  isEditMode = true;

  /**
   * 子コンポーネントからのget指示用
   */
  doReget: { doReget: boolean } = { doReget: false };
  /**
   * 子コンポーネントからのput指示用
   */
  doPut: { doPut: boolean } = { doPut: false };

  //収益計算データ用
  profitDatas: {
    sumSales4medium: string;
    sumSales4construction: string;
    sumSales4notax: string;
    sumSalesTotal: string;
    sumSupplys4medium: string;
    sumSupplys4construction: string;
    sumSupplys4notax: string;
    sumSupplysTotal: string;
    profit4medium: string;
    profit4construction: string;
    profit4notax: string;
    profitTotal: string;
    profitRatio4medium: string;
    profitRatio4construction: string;
    profitRatio4notax: string;
    profitRatioAvr: string;
  } = {
    sumSales4medium: "0",
    sumSales4construction: "0",
    sumSales4notax: "0",
    sumSalesTotal: "0",
    sumSupplys4medium: "0",
    sumSupplys4construction: "0",
    sumSupplys4notax: "0",
    sumSupplysTotal: "0",
    profit4medium: "0",
    profit4construction: "0",
    profit4notax: "0",
    profitTotal: "0",
    profitRatio4medium: "0",
    profitRatio4construction: "0",
    profitRatio4notax: "0",
    profitRatioAvr: "0",
  };

  //調整用
  jyutyuNumber = "";
  remarks = "";

  /**
   * 既存データが読み込まれているかのフラグ。
   * 新規登録と既存更新の切り分けに使用。
   */
  isLoaded = false;

  /**
   * 削除対象ファイル
   */
  delFileTarget: EntityFile | null = null;

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  opProjectType = CONST_OP_PROJECT_TYPE;
  opAlertContract = CONST_OP_ALERT_CONTRACT;
  /**
   * 社員の選択肢を構築する用
   */
  getOpStaffs(No: number): EntityStaff[] | undefined {
    switch (No) {
      //営業担当
      case 1:
        return this.delegate4list.getStaffSortNum4NoneDelete([
          this.delegate.c_staff_id4sales!,
        ]);
      //仕入担当
      case 2:
        return this.delegate4list.getStaffSortNum4NoneDelete([
          this.delegate.c_staff_id4supply!,
        ]);
    }
  }
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalDelConf = "";
  message4ModalErrorConf = "";
  message4ModalDelFileConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getMastersList();
    await this.get();
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4Staff4sortNum = 0;
      // dtoMaster.getStaff = 1;
      // dtoMaster.delFlag4staff = 1;
      dtoMaster.getClients = 1;
      dtoMaster.delFlag4clients = 0;
      dtoMaster.getSuppliers = 1;
      dtoMaster.delFlag4suppliers = 0;
      dtoMaster.getBranch = 1;
      dtoMaster.getSponsor = 1;
      dtoMaster.delFlag4Sponsor = 1;

      //カレンダー休日反映用
      dtoMaster.getHoliday = 1;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);
      Cl_common.logger(this.delegate4list);

      //レスポンス処理
      //-------
    } catch (error) {
      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * データを読み込む
   */
  async get() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");
      this.isLoaded = false;

      //パラメータにIDが指定されていなければ、新規追加モードでデータを読み込まない
      //-------
      let id = Cl_common.getParam("record_id");

      console.log("id");
      console.log(id);

      //準備
      //-------
      let dto = new DtoProjectsGetReqImple();

      dto.c_project_id = Number(id); //APIのURL構成の関係で、案件番号を受注IDに入れているので注意

      //API実行
      //-------
      this.delegate = await Cl_project.get(dto);
      // Cl_common.logger("koko");
      Cl_common.logger(this.delegate);

      //IDがnullならURLを補完
      if (id == null || id == "") {
        this.$router.replace(
          "/projectInput?record_id=" + this.delegate.c_project_number
        );
      }
      // if (this.delegate.c_project_id == null) {
      //   const delegateTmp = this.delegate;

      //   //新しいdelegateを作成し、新規追加モードにする
      //   this.delegate = new DtoProjectsGetResImple();
      //   this.delegate.jyutyuNumberPrev = delegateTmp.jyutyuNumberPrev;
      //   this.delegate.jyutyuNumberNext = delegateTmp.jyutyuNumberNext;
      //   this.delegate.c_project_type = 1;
      //   this.delegate.c_project_billing_type = 1;
      //   this.jyutyuNumber = "";
      //   this.isEditMode = false;
      //   return;
      // } else {
      //   //編集
      this.isEditMode = true;
      // }

      //備考展開
      if (this.delegate.c_project_remarks != null) {
        this.remarks = this.delegate.c_project_remarks;
      }

      //案件番号展開
      if (this.delegate.c_project_number != null) {
        this.jyutyuNumber = this.delegate.c_project_number.toString();
      } else {
        //削除後はnullで返ってくる
        await this.$router.push("/projectInput").then(() => {
          location.reload();
        });
      }

      //売上明細、仕入明細の日付等展開
      await this.cnvDetails4Get();

      //読み込み完了フラグをON
      this.isLoaded = true;

      //読み込みの度に売上明細を選択
      const tableJuty: any = this.$refs.or_tableJyutu;
      tableJuty.clickTabSalse();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
      this.delegate = new DtoProjectsGetResImple();
      this.isLoaded = false;
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });

      //収益計算
      //-----------------
      const tableJyutu: any = this.$refs.or_tableJyutu;
      await tableJyutu.profitCalc();
    }
  }
  /**
   * データを更新する
   * isNoModalをtrueにすると処理後のModal表示を抑制する
   */
  async upd(isDel: boolean, isNoModal: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------

    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    if (!isNoModal) {
      Array.from(panel).map((element: Element) => {
        element.classList.add("enable-loader");
      });
    }

    try {
      //入力確認　TODO：専用のモジュールに置き換えたい
      //-----------------
      if (!isDel) {
        if (!(await this.isVaild())) {
          //モーダル表示
          const modal: any = this.$refs.Mo_modal_errorConf;
          modal.showModal();
          return;
        }
      }

      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoProjectsPutReq();
      if (isDel) {
        //削除処理：削除フラグを立てる。
        dto.c_project_id = this.delegate.c_project_id;
        dto.c_project_flag_del = 1;
      } else {
        //日付をdelegateに戻す
        this.cnvDetails4Upd();

        //備考欄を戻す
        if (this.remarks != null) {
          this.delegate.c_project_remarks = this.remarks;
        }

        //更新処理：delegateからdtoにデータ投入。
        dto = Object.assign(new DtoProjectsPutReq(), this.delegate);
      }

      //API実行
      //-------
      await Cl_project.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      if (isDel) {
        //削除処理：
        this.message4ModalSuccess = "正常に削除されました";
      } else {
        //更新処理：
        this.message4ModalSuccess = "正常に変更されました";
      }

      //モーダルを表示
      if (!isNoModal) {
        const modal: any = this.$refs.Mo_modal_success;
        modal.showModal();
      }
    } catch (error) {
      Cl_common.logger(error);

      //エラーメッセージのバリエーション
      if (this.isAxiosError(error)) {
        let response = Object.assign(
          new DtoApiError(),
          (error as AxiosError).response!.data
        );
        switch (response.message) {
          case "delete sales data is not allowed.":
            this.message4ModalErrorConf =
              "請求書が存在する為、案件を削除できません。先に請求書を削除してください。";
            break;
          case "update sales data is not allowed.for approved claim number.":
            this.message4ModalErrorConf =
              "請求No：" +
              response.info +
              "は既に承認されているため、変更できません。請求No：" +
              response.info +
              "の承認を解除した後に入力するか、集計者以上の権限を持つ方にご依頼ください。";
            break;

          default:
            this.message4ModalErrorConf = "エラーが発生しました";
            break;
        }
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }

      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      if (!isNoModal) {
        this.btnDisable = false;
        Array.from(panel).map((element: Element) => {
          element.classList.remove("enable-loader");
        });
      }
    }
  }
  /**
   * データを追加する
   */
  async add(fiscalYear: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //入力確認　TODO：専用のモジュールに置き換えたい
      //-----------------
      // if (!(await this.isVaild())) {
      //   //モーダル表示
      //   // Cl_common.logger(this.message4ModalErrorConf);
      //   const modal: any = this.$refs.Mo_modal_errorConf;
      //   modal.showModal();
      //   return;
      // }

      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoProjectsPostReq();

      dto.businessNum = fiscalYear;

      // //日付をdelegateに戻す
      // this.cnvDetails4Upd();
      // dto.c_project_flag_del = 0;

      // //備考欄を戻す
      // if (this.remarks != null) {
      //   this.delegate.c_project_remarks = this.remarks;
      // }

      //delegateからdtoにデータ投入。
      // dto = Object.assign(new DtoProjectsPostReq(), this.delegate);

      //API実行
      //-------
      const res = await Cl_project.post(dto);

      //DOM連携用の変数をリセット
      //-------
      this.remarks = "";

      //レスポンスの処理
      //-------
      //編集画面のURL作成
      this.$router.push({
        path: "/projectInput",
        query: { record_id: res.c_project_number },
      });

      //モーダルのメッセージを設定
      this.message4ModalSuccess = "正常に登録されました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * 明細内のデータを調整
   * APIのデータをgetした時用。
   */
  async cnvDetails4Get() {
    let tmp = new DtoMdDtp();

    //案件
    //---------------------
    tmp = new DtoMdDtp();
    if (this.delegate.c_project_salesdate_start != null) {
      tmp.date = this.delegate.c_project_salesdate_start
        .toString()
        .split("T")[0];
    }
    this.delegate.cnvSalesdateStart = tmp;
    // ----
    tmp = new DtoMdDtp();
    if (this.delegate.c_project_salesdate_end != null) {
      tmp.date = this.delegate.c_project_salesdate_end.toString().split("T")[0];
    }
    this.delegate.cnvSalesdateEnd = tmp;
    // ----
    tmp = new DtoMdDtp();
    if (this.delegate.c_project_supplydate_start != null) {
      tmp.date = this.delegate.c_project_supplydate_start
        .toString()
        .split("T")[0];
    }
    this.delegate.cnvSupplydateStart = tmp;
    // ----
    tmp = new DtoMdDtp();
    if (this.delegate.c_project_supplydate_end != null) {
      tmp.date = this.delegate.c_project_supplydate_end
        .toString()
        .split("T")[0];
    }
    this.delegate.cnvSupplydateEnd = tmp;
    // ----

    //売上明細
    //---------------------
    for (let i = 0; i < this.delegate.sales.length; ++i) {
      //日付
      tmp = new DtoMdDtp();
      if (this.delegate.sales[i].c_sales_issue != null) {
        tmp.date = this.delegate.sales[i]
          .c_sales_issue!.toString()
          .split("T")[0];
      }
      this.delegate.sales[i].cnvDateIssue = tmp;
      // ----

      tmp = new DtoMdDtp();
      if (this.delegate.sales[i].c_sales_month != null) {
        tmp.date = commonRegx.maches4GroupName(
          this.delegate.sales[i].c_sales_month!.toString(),
          "(?<gn>^\\d+[-]\\d+)[-]",
          commonRegx._stdOption
        );
      }
      this.delegate.sales[i].cnvDateMonth = tmp;
      // ----

      tmp = new DtoMdDtp();
      if (this.delegate.sales[i].c_sales_date_exp_payment != null) {
        tmp.date = this.delegate.sales[i]
          .c_sales_date_exp_payment!.toString()
          .split("T")[0];
      }
      this.delegate.sales[i].cnvDateExpPayment = tmp;
      // ----

      tmp = new DtoMdDtp();
      if (this.delegate.sales[i].c_sales_date_cmp_payment != null) {
        tmp.date = this.delegate.sales[i]
          .c_sales_date_cmp_payment!.toString()
          .split("T")[0];
      }
      this.delegate.sales[i].cnvDateCmpPayment = tmp;

      //請求書番号
      if (this.delegate.sales[i].c_sales_climnum_tmp != null) {
        this.delegate.sales[i].cnvClaimnumTmp =
          this.delegate.sales[i].c_sales_climnum_tmp!.toString();
      } else {
        this.delegate.sales[i].cnvClaimnumTmp = "";
      }

      //金額
      if (this.delegate.sales[i].c_sales_price == null) {
        this.delegate.sales[i].cnvPrice = "0";
      } else {
        this.delegate.sales[i].cnvPrice =
          this.delegate.sales[i].c_sales_price!.toString();
        //カンマ補完
        while (
          this.delegate.sales[i].cnvPrice !=
          (this.delegate.sales[i].cnvPrice = this.delegate.sales[
            i
          ].cnvPrice!.replace(/^(-?\d+)(\d{3})/, "$1,$2"))
        );
      }
    }

    //仕入明細
    //---------------------

    for (let i = 0; i < this.delegate.supplys.length; ++i) {
      //日付
      tmp = new DtoMdDtp();
      if (this.delegate.supplys[i].c_supply_month != null) {
        tmp.date = commonRegx.maches4GroupName(
          this.delegate.supplys[i].c_supply_month!.toString(),
          "(?<gn>^\\d+[-]\\d+)[-]",
          commonRegx._stdOption
        );
      }
      this.delegate.supplys[i].cnvDateMonth = tmp;
      // ----
      tmp = new DtoMdDtp();
      if (this.delegate.supplys[i].c_supply_date_exp_payment != null) {
        tmp.date = this.delegate.supplys[i]
          .c_supply_date_exp_payment!.toString()
          .split("T")[0];
      }
      this.delegate.supplys[i].cnvDateExpPayment = tmp;
      // ---

      //請求書番号
      if (this.delegate.supplys[i].c_supply_climnum_tmp != null) {
        this.delegate.supplys[i].cnvClaimnumTmp =
          this.delegate.supplys[i].c_supply_climnum_tmp!.toString();
      } else {
        this.delegate.supplys[i].cnvClaimnumTmp = "";
      }

      //金額
      if (this.delegate.supplys[i].c_supply_price == null) {
        this.delegate.supplys[i].cnvPrice = "0";
      } else {
        this.delegate.supplys[i].cnvPrice =
          this.delegate.supplys[i].c_supply_price!.toString();
        //カンマ補完
        while (
          this.delegate.supplys[i].cnvPrice !=
          (this.delegate.supplys[i].cnvPrice = this.delegate.supplys[
            i
          ].cnvPrice!.replace(/^(-?\d+)(\d{3})/, "$1,$2"))
        );
      }
    }
  }
  /**
   * 明細内のデータを調整
   * APIのデータをupdした時用。
   */
  async cnvDetails4Upd() {
    //案件
    //---------------------
    if (this.delegate.cnvSalesdateStart.date != null) {
      this.delegate.c_project_salesdate_start = new Date(
        this.delegate.cnvSalesdateStart!.date!
      );
    } else {
      this.delegate.c_project_salesdate_start = null;
    }
    // ----
    if (this.delegate.cnvSalesdateEnd.date != null) {
      this.delegate.c_project_salesdate_end = new Date(
        this.delegate.cnvSalesdateEnd!.date!
      );
    } else {
      this.delegate.c_project_salesdate_end = null;
    }
    // ----
    if (this.delegate.cnvSupplydateStart.date != null) {
      this.delegate.c_project_supplydate_start = new Date(
        this.delegate.cnvSupplydateStart!.date!
      );
    } else {
      this.delegate.c_project_supplydate_start = null;
    }
    // ----
    if (this.delegate.cnvSupplydateEnd.date != null) {
      this.delegate.c_project_supplydate_end = new Date(
        this.delegate.cnvSupplydateEnd!.date!
      );
    } else {
      this.delegate.c_project_supplydate_end = null;
    }
    // ----

    //売上明細
    //---------------------
    for (let i = 0; i < this.delegate.sales.length; ++i) {
      //日付
      if (this.delegate.sales[i].cnvDateIssue.date != null) {
        this.delegate.sales[i].c_sales_issue = new Date(
          this.delegate.sales[i].cnvDateIssue!.date!
        );
      } else {
        this.delegate.sales[i].c_sales_issue = null;
      }

      if (this.delegate.sales[i].cnvDateMonth.date != null) {
        this.delegate.sales[i].c_sales_month = new Date(
          this.delegate.sales[i].cnvDateMonth!.date!
        );
      } else {
        this.delegate.sales[i].c_sales_month = null;
      }

      if (this.delegate.sales[i].cnvDateExpPayment.date != null) {
        this.delegate.sales[i].c_sales_date_exp_payment = new Date(
          this.delegate.sales[i].cnvDateExpPayment!.date!
        );
      } else {
        this.delegate.sales[i].c_sales_date_exp_payment = null;
      }

      if (this.delegate.sales[i].cnvDateCmpPayment.date != null) {
        this.delegate.sales[i].c_sales_date_cmp_payment = new Date(
          this.delegate.sales[i].cnvDateCmpPayment!.date!
        );
      } else {
        this.delegate.sales[i].c_sales_date_cmp_payment = null;
      }

      //請求書番号
      if (
        this.delegate.sales[i].cnvClaimnumTmp != null &&
        this.delegate.sales[i].cnvClaimnumTmp != "" &&
        Number.isInteger(Number(this.delegate.sales[i].cnvClaimnumTmp))
      ) {
        this.delegate.sales[i].c_sales_climnum_tmp = Number(
          this.delegate.sales[i].cnvClaimnumTmp
        );
      } else {
        this.delegate.sales[i].c_sales_climnum_tmp = null;
      }

      //金額
      if (
        this.delegate.sales[i].cnvPrice == null ||
        this.delegate.sales[i].cnvPrice == undefined
      ) {
        this.delegate.sales[i].c_sales_price = 0;
      } else {
        this.delegate.sales[i].c_sales_price = Number(
          this.delegate.sales[i].cnvPrice.replaceAll(",", "")
        );
      }
    }

    //仕入明細
    //---------------------
    for (let i = 0; i < this.delegate.supplys.length; ++i) {
      //日付
      if (this.delegate.supplys[i].cnvDateMonth.date != null) {
        this.delegate.supplys[i].c_supply_month = new Date(
          this.delegate.supplys[i].cnvDateMonth!.date!
        );
      } else {
        this.delegate.supplys[i].c_supply_month = null;
      }

      if (this.delegate.supplys[i].cnvDateExpPayment.date != null) {
        this.delegate.supplys[i].c_supply_date_exp_payment = new Date(
          this.delegate.supplys[i].cnvDateExpPayment!.date!
        );
      } else {
        this.delegate.supplys[i].c_supply_date_exp_payment = null;
      }

      //請求書番号
      if (
        this.delegate.supplys[i].cnvClaimnumTmp != null &&
        this.delegate.supplys[i].cnvClaimnumTmp != "" &&
        Number.isInteger(Number(this.delegate.supplys[i].cnvClaimnumTmp))
      ) {
        this.delegate.supplys[i].c_supply_climnum_tmp = Number(
          this.delegate.supplys[i].cnvClaimnumTmp
        );
      } else {
        this.delegate.supplys[i].c_supply_climnum_tmp = null;
      }
      //金額
      if (
        this.delegate.supplys[i].cnvPrice == null ||
        this.delegate.supplys[i].cnvPrice == undefined
      ) {
        this.delegate.supplys[i].c_supply_price = 0;
      } else {
        this.delegate.supplys[i].c_supply_price = Number(
          this.delegate.supplys[i].cnvPrice.replaceAll(",", "")
        );
      }
    }
  }
  /**
   * データ削除用のモーダルを表示
   */
  async delConf() {
    //モーダルのメッセージを設定
    this.message4ModalDelConf =
      "案件番号:" +
      this.delegate.c_project_number +
      "　" +
      "を削除します。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.showModal();
  }
  /**
   * データを削除する
   */
  async del() {
    //削除フラグのあるテーブルなので更新処理。
    this.closeModal4deleteConf();
    this.upd(true, false);
  }

  /**
   * 案件をコピーする
   */
  async cpyProject(businessNum: number) {
    if (this.delegate.c_project_id == null) {
      return;
    }

    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      // 一旦保存
      //-----------------
      await this.upd(false, true);

      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoProjectsPostReq();

      //パラメータ設定
      //-----------------
      dto.isProjectCopy = 1;
      dto.projectid4copy = this.delegate.c_project_id;
      dto.projectcopyNum = this.delegate.c_project_number;
      dto.businessNum = businessNum;

      //API実行
      //-------
      Cl_common.logger(dto);
      const res = await Cl_project.post(dto);
      //レスポンスの処理
      //-------
      await this.$router.push({
        path: "/projectInput",
        query: { record_id: res.c_project_number },
      });
      await this.get();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * 前の案件番号に遷移
   */
  async goPrev() {
    if (this.delegate.jyutyuNumberPrev == null) {
      return;
    }

    await this.$router.push(
      "/projectInput?record_id=" + this.delegate.jyutyuNumberPrev
    );
    await this.get();
  }
  /**
   * 次の案件番号に遷移
   */
  async goNext() {
    if (this.delegate.jyutyuNumberNext == null) {
      return;
    }

    await this.$router.push(
      "/projectInput?record_id=" + this.delegate.jyutyuNumberNext
    );
    await this.get();
  }
  //----------------------
  /**
   * ファイルアップロードボタン押下時の処理
   */
  async clickFileUp() {
    const element: any = this.$refs.uploadFile;
    element.click();
  }
  /**
   * ファイルアップロード処理
   */
  async postFile() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      const element: any = this.$refs.uploadFile;
      const uploadFile = element.files[0];
      // console.log(element.files[0].size);

      if (element.files[0].size > 10000000) {
        this.message4ModalErrorConf =
          "アップロードできるファイルサイズは、10Mまでです";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }

      if (uploadFile != undefined) {
        let dtoPostReq = new DtoFilesPostReqImple();

        //マップ画像保存。
        //------------------
        //要素からファイル情報取得。
        const exix = uploadFile.name.split(".").pop();

        // if (
        //   exix != "JPEG" &&
        //   exix != "jpeg" &&
        //   exix != "JPG" &&
        //   exix != "jpg" &&
        //   exix != "PNG" &&
        //   exix != "png" &&
        //   exix != "BMP" &&
        //   exix != "bmp" &&
        //   exix != "GIF" &&
        //   exix != "gif"
        // ) {
        //   this.message4ModalErrorConf =
        //     "アップロードできるのは、Jpeg/PNG/BMP/GIFのみです";
        //   //モーダル表示
        //   const modal: any = this.$refs.Mo_modal_errorConf;
        //   modal.showModal();
        //   return;
        // }

        //アップロードURL取得API実行
        dtoPostReq.projectId = this.delegate.c_project_id;
        dtoPostReq.fileType = exix;
        dtoPostReq.c_file_name = uploadFile.name;

        Cl_common.logger(dtoPostReq);
        const res = await Cl_file.post(dtoPostReq);
        let uploadUrl = res.url;

        if (uploadUrl == null || uploadUrl === "") {
          this.message4ModalErrorConf = "ファイルのアップロードに失敗しました";
          //モーダル表示
          const modal: any = this.$refs.Mo_modal_errorConf;
          modal.showModal();
          return;
        } else {
          await axios.put(uploadUrl!, uploadFile);
        }
      }

      await this.get();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * ファイル削除用のモーダルを表示
   */
  async delFileConf(file: EntityFile) {
    //削除対象ファイルを設定
    this.delFileTarget = null;
    this.delFileTarget = file;
    //モーダルのメッセージを設定
    this.message4ModalDelFileConf =
      file.c_file_name + "　" + "を削除します。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_deleteFileConf;
    modal.showModal();
  }
  /**
   * ファイル削除
   */
  async delFile() {
    this.closeModal4deleteFileConf();
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      if (this.delFileTarget == null) {
        throw new Error();
      }

      let dtoDeleteReq = new DtoFilesDeleteReqImple();

      dtoDeleteReq.fileId = this.delFileTarget.c_file_id;

      Cl_common.logger(dtoDeleteReq);
      const res = await Cl_file.del(dtoDeleteReq);

      await this.get();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  async dlFile(file: EntityFile) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });
    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");
      //準備
      //-------
      let dto = new DtoFilesGetReqImple();
      dto.c_file_id = file.c_file_id;

      //API実行
      //-------
      const res = await Cl_file.get(dto);
      Cl_common.logger(res);
      Cl_common.logger(res.url);
      //レスポンス処理
      //-------
      location.href = res.url!;
      //レスポンスの処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  //----------------------
  /**
   * 請求書作成処理
   */
  async creClaim(event?: MouseEvent) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行 creClaim");

      //準備
      //-------
      //１．明細行選択なし状態と必須項目未入力で、エラーメッセージを表示
      //　　必須入力事項は、請求Noと金額のみ
      //-----
      //選択なし
      let selectedRow: EntitySalesImple | null = null;
      for (let i = 0; i < this.delegate.sales.length; ++i) {
        if (this.delegate.sales[i].isSelect) {
          selectedRow = this.delegate.sales[i];
          break;
        }
      }

      if (selectedRow == null) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf =
          "請求書を作成するには、対象となる売上明細行を選択してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();

        return;
      }
      //必須入力なし
      // Cl_common.logger(selectedRow);
      if (
        selectedRow.cnvDateIssue.date == "" ||
        selectedRow.cnvDateIssue.date == null ||
        selectedRow.cnvPrice == "" ||
        selectedRow.cnvPrice == "0" ||
        selectedRow.cnvClaimnumTmp == ""
      ) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf =
          "請求書を作成するには、請求No、請求日、金額を入力してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();

        return;
      }
      if (this.delegate.c_branch_id == null) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf =
          "請求書を作成するには、拠点を選択してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();

        return;
      }

      //POST API実行
      //-------
      //現状を保存
      //-----
      Cl_common.logger(selectedRow);
      await this.upd(false, true);

      //API実行
      //-----
      let dto = new DtoClaimsPostReqImple();
      dto.branchId = this.delegate.c_branch_id;
      dto.sales = selectedRow;
      dto.projectNum = this.delegate.c_project_number;
      let res = await Cl_claim.post(dto);
      Cl_common.logger(res);
      //レスポンスの処理
      //-------
      //画面遷移
      //-----
      //請求書作成画面は売上無しで請求書の作成はできないので、遷移する必要あるか？
      if (res.claimUrl != "") {
        //遷移
        // this.$router.push(res.claimUrl!);

        if (event && (event.ctrlKey || event.metaKey)) {
          window.open(res.claimUrl!);
        } else {
          this.$router.push(res.claimUrl!);
        }
      } else {
        if (res.c_claim_flag_del == 1) {
          //削除済み
          //-----
          //モーダルのメッセージを設定
          this.message4ModalErrorConf =
            "請求書No：" +
            selectedRow.cnvClaimnumTmp +
            "は、削除されてます。管理者にお問い合わせください。";
          //モーダル表示
          const modal: any = this.$refs.Mo_modal_errorConf;
          modal.showModal();

          return;
        }

        if (res.c_claim_flag_sales == 0) {
          //売上なしで同一の請求書番号あり
          //-----
          //モーダルのメッセージを設定
          this.message4ModalErrorConf =
            "請求書No：" +
            selectedRow.cnvClaimnumTmp +
            "は、売上なし請求書として登録済みです。";
          //モーダル表示
          const modal: any = this.$refs.Mo_modal_errorConf;
          modal.showModal();

          return;
        }
      }
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4deleteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.closeModal();
  }
  async closeModal4deleteFileConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteFileConf;
    modal.closeModal();
  }
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
  async showModal4insurance(): Promise<void> {
    const modal: any = this.$refs.comp_insurance;
    modal.showModal();
  }
  async closeModal4insurance(): Promise<void> {
    const modal: any = this.$refs.comp_insurance;
    modal.closeModal();
  }

  //----------------------
  //その他のメソッド（画面独自のもの等）
  //----------------------
  @Watch("doReget.doReget")
  reget() {
    this.get();
  }
  @Watch("doPut.doPut")
  doput() {
    this.upd(false, true);
  }
  /**
   * 保存時のバリデーション処理
   * 　TODO：専用のモジュールに置き換えたい
   */
  async isVaild(): Promise<boolean> {
    //請求方法
    if (this.delegate.c_project_billing_type == null) {
      //モーダルのメッセージを設定　表示はしない
      this.message4ModalErrorConf = "請求方法を設定してください";
      return false;
    }
    //---------------

    return true;
  }

  /**
   * AxiosErrorかどうかを返す。
   * @param error
   * @returns
   */
  isAxiosError(error: any): error is AxiosError {
    return !!error.isAxiosError;
  }

  // /**
  //  * 指定時間処理を停止する関数
  //  * @param {number} ms 待機するミリ秒数
  //  */
  // async sleep(ms: number) {
  //   return new Promise((resolve) => setTimeout(resolve, ms));
  // }
}
