export class EntitySearchSupplys {
  public c_supply_id: number | null = null;
  public c_project_id: number | null = null;
  public c_supply_rownum: number | null = null;
  public c_claim_id: number | null = null;
  public c_supply_month: Date | null = null;
  public c_supply_item: number | null = null;
  public c_supply_subject: number | null = null;
  public c_supply_breakdown: number | null = null;
  public c_supplier_id: number | null = null;
  public c_supplier_name: string | null = null;
  public c_supply_price: number | null = null;
  public c_supply_date_exp_payment: Date | null = null;
  public c_supply_remarks: string | null = null;
  public c_branch_name: string | null = null;
  public c_client_name: string | null = null;
  public c_supply_taxrate: number | null = null;
  public c_supply_flag_paid: string | null = null;
  public c_supply_flag_chkclaim: number | null = null;

  //Join project
  public c_project_number: number | null = null;
  public c_project_name: string | null = null;
  public c_project_sponsor_name: string | null = null;
  public pro_c_client_id: number | null = null;
  public pro_c_client_name: string | null = null;

  public c_staff_name: string | null = null;
  public c_item_name: string | null = null;
  public c_variation_name: string | null = null;
  public c_variation_type: number | null = null;
}
