import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntityClient } from "../entity/EntityClient";
import { EntitySearchSales } from "../entity/EntitySearchSales";
import { DtoApiPager } from "./abst/DtoApiPager";
import {
  CONST_OP_ITEMTYPE_BREAKDOWNS,
  CONST_OP_ITEMTYPE_ITEM,
  CONST_OP_ITEMTYPE_SUBJECTS,
  CONST_OP_TAXRATE,
} from "../constant/Constants";

export class DtoSearchSalesGetRes extends EntitySearchSales {
  public items: EntitySearchSales[] = [];
  public pager = new DtoApiPager();
  public exitems: Exitem[] = [];

  // 集計情報
  //-------
  public sum_sales_price: number = 0;

  public async setEximtes(clientmap: Map<number, string>) {
    let tmp: Exitem;
    for (let index = 0; index < this.items.length; index++) {
      tmp = new Exitem();

      tmp.c_sales_id = this.items[index].c_sales_id;
      tmp.c_project_id = this.items[index].c_project_id;
      tmp.c_sales_rownum = this.items[index].c_sales_rownum;
      tmp.c_claim_id = this.items[index].c_claim_id;
      tmp.c_sales_issue = this.items[index].c_sales_issue;
      tmp.c_sales_month = this.items[index].c_sales_month;
      tmp.c_sales_item = this.items[index].c_sales_item;
      tmp.c_sales_subject = this.items[index].c_sales_subject;
      tmp.c_sales_breakdown = this.items[index].c_sales_breakdown;
      // tmp.c_client_id = this.items[index].c_client_id;
      tmp.c_sales_price = this.items[index].c_sales_price;
      tmp.c_sales_date_exp_payment = this.items[index].c_sales_date_exp_payment;
      tmp.c_sales_date_cmp_payment = this.items[index].c_sales_date_cmp_payment;
      tmp.c_project_number = this.items[index].c_project_number;
      tmp.c_project_name = this.items[index].c_project_name;
      tmp.c_project_sponsor_name = this.items[index].c_project_sponsor_name;
      tmp.pro_c_client_id = this.items[index].pro_c_client_id;
      tmp.c_branch_name = this.items[index].c_branch_name;
      tmp.c_sales_taxrate = this.items[index].c_sales_taxrate!;

      tmp.c_client_name = this.items[index].c_client_name;
      tmp.pro_c_client_name = clientmap.get(
        this.items[index].pro_c_client_id!
      )!;

      if (tmp.c_sales_item != null) {
        var found = CONST_OP_ITEMTYPE_ITEM.find(
          (e) => e.code === tmp.c_sales_item
        );
        tmp.c_sales_item_name = found?.label!;
      } else {
        tmp.c_sales_item_name = "";
      }
      // console.log("c_sales_item");
      // console.log(tmp.c_sales_item );
      // console.log("c_sales_item_name");
      // console.log(tmp.c_sales_item_name);

      if (tmp.c_sales_subject != null) {
        const found = CONST_OP_ITEMTYPE_SUBJECTS.find(
          (e) => e.code === tmp.c_sales_subject
        );
        tmp.c_sales_subject_name = found?.label!;
      } else {
        tmp.c_sales_subject_name = "";
      }

      if (tmp.c_sales_breakdown != null) {
        const found = CONST_OP_ITEMTYPE_BREAKDOWNS.find(
          (e) => e.code === tmp.c_sales_breakdown
        );
        tmp.c_sales_breakdown_name = found?.label!;
      } else {
        tmp.c_sales_breakdown_name = "";
      }

      if (tmp.c_sales_taxrate != null) {
        const found = CONST_OP_TAXRATE.find(
          (e) => e.code === tmp.c_sales_taxrate
        );
        tmp.c_sales_taxrate_name = found?.label!;
      } else {
        tmp.c_sales_taxrate_name = "";
      }

      tmp.c_staff_name = this.items[index].c_staff_name!;
      tmp.c_item_name = this.items[index].c_item_name!;
      tmp.c_variation_name = this.items[index].c_variation_name!;
      tmp.c_variation_type = this.items[index].c_variation_type!;
      tmp.c_sales_remarks = this.items[index].c_sales_remarks!;

      this.exitems.push(tmp);
    }
  }
}

export class Exitem extends EntitySearchSales {
  public c_sales_item_name: string | null = null;
  public c_sales_subject_name: string | null = null;
  public c_sales_breakdown_name: string | null = null;
  public c_sales_taxrate_name: string | null = null;
}
