
import { Options, Vue } from "vue-class-component";
import { Cl_common } from "@/functions/Cl_common";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import { DtoMessagesPostReq } from "furukawa-common/src/common/dto/abst/DtoMessagesPostReq";
import { Cl_message } from "@/functions/Cl_message";
import { DtoMessagesPutReq } from "furukawa-common/src/common/dto/abst/DtoMessagesPutReq";
import { DtoMessagesGetReqImple } from "furukawa-common/src/common/dto/DtoMessagesGetReqImple";
import { DtoMessagesGetResImple } from "furukawa-common/src/common/dto/DtoMessagesGetResImple";
import { DtoMdDtp } from "furukawa-common/src/common/dto/DtoMdDtp";
import Mt_dtp from "@/components/atoms/Mt_dtp.vue";
import { DtoMastersgetReq } from "furukawa-common/src/common/dto/DtoMastersGetReq";
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersGetRes } from "furukawa-common/src/common/dto/DtoMastersGetRes";

@Options({
  components: {
    Mo_modal_alert,
    Mt_dtp,
  },
})
export default class BulletinBoardInfomationEdit extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoMessagesGetResImple();
  delegate4list = new DtoMastersGetRes();
  btnDisable = false;
  isEditMode = true;
  start = new DtoMdDtp();
  end = new DtoMdDtp();

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalDelConf = "";
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.get();
    await this.getMastersList();
  }

  //----------------------
  //基本操作用メソッド
  //----------------------}
  /**
   * データを読み込む
   */
  async get() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //パラメータにIDが指定されていなければ、新規追加モードでデータを読み込まない
      //-------
      let id = Cl_common.getParam("id");
      if (id == "") {
        //新規追加
        this.isEditMode = false;
        //初期値設定
        //this.delegate.c_client_company_type = 1;
        //this.delegate.c_client_flag_company_signage_posi = 1;
        return;
      } else {
        //編集
        this.isEditMode = true;
      }

      //準備
      //-------
      let dto = new DtoMessagesGetReqImple();

      dto.c_message_id = Number(id);

      //API実行
      //-------
      this.delegate = await Cl_message.get(dto);
      //レスポンス処理
      //-------
      //IDがnullなら一覧へ遷移
      if (this.delegate.c_message_id == null) {
        this.$router.push("/bulletinBoardInfomationList");
      }
      this.start.date = this.delegate.c_message_start!.toString().split("T")[0];
      this.end.date = this.delegate.c_message_end!.toString().split("T")[0];

      // if (this.start.date != null) {
      //   this.delegate.c_message_start = new Date(
      //     this.start.date
      //   ).toLocaleDateString();
      // }

      // if (this.end.date != null) {
      //   this.delegate.c_message_end = new Date(
      //     this.end.date
      //   ).toLocaleDateString();
      // }
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      //検索選択肢用
      dtoMaster.getHoliday = 1;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);

      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを更新する
   */
  async upd(isDel: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoMessagesPutReq();
      if (isDel) {
        //削除処理：削除フラグを立てる。
        dto.c_message_id = this.delegate.c_message_id;
        dto.c_message_flag_del = 1;
      } else {
        //更新処理：delegateからdtoにデータ投入。
        dto = Object.assign(new DtoMessagesPutReq(), this.delegate);
        if (this.start.date != undefined) {
          dto.c_message_start = new Date(this.start.date);
        }
        if (this.end.date != undefined) {
          dto.c_message_end = new Date(this.end.date);
        }
      }

      //API実行
      //-------
      await Cl_message.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      if (isDel) {
        //削除処理：
        this.message4ModalSuccess = "正常に削除されました";
      } else {
        //更新処理：
        this.message4ModalSuccess = "正常に変更されました";
      }

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを新たに登録する
   */
  async add() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = Object.assign(new DtoMessagesPostReq(), this.delegate);
      dto.c_message_flag_del = 0;
      if (this.start.date != undefined) {
        dto.c_message_start = new Date(this.start.date);
      }
      if (this.end.date != undefined) {
        dto.c_message_end = new Date(this.end.date);
      }

      //API実行
      //-------
      const res = await Cl_message.post(dto);

      //レスポンスの処理
      //-------
      //編集画面のURL作成
      this.$router.push({
        path: "/bulletinBoardInfomationEdit",
        query: { id: res.c_message_id },
      });

      this.start.date = res.c_message_start!.toString().split("T")[0];
      this.end.date = res.c_message_end!.toString().split("T")[0];

      //モーダルのメッセージを設定
      this.message4ModalSuccess = "データを更新しました。";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを削除する
   */
  async del() {
    //削除フラグのあるテーブルなので更新処理。
    this.closeModal4deleteConf();
    this.upd(true);
  }

  /**
   * データを削除する
   */
  async delConf() {
    //モーダルのメッセージを設定
    this.message4ModalDelConf = "削除してもよろしいでしょうか。";

    //モーダルを表示
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.showModal();
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4deleteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.closeModal();
  }
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
