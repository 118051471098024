export class EntitySupplier {
public c_supplier_id:number | null = null;
public c_supplier_name:string | null = null;
public c_supplier_name_pt:string | null = null;
public c_supplier_company_type:number | null = null;
public c_supplier_flag_company_signage_posi:number | null = null;
public c_branch_id:number | null = null;
public c_supplier_type:number | null = null;
public c_supplier_tel:string | null = null;
public c_supplier_fax:string | null = null;
public c_supplier_zip:string | null = null;
public c_supplier_address_1:string | null = null;
public c_supplier_address_2:string | null = null;
public c_supplier_paysite:number | null = null;
public c_supplier_staff_name:string | null = null;
public c_supplier_staff_name_pt:string | null = null;
public c_supplier_remarks:string | undefined;
public c_supplier_comment:string | undefined;
public c_supplier_flag_del:number | null = null;
public c_supplier_show_order:number | null = null;
public c_supplier_reg_user:string | null = null;
public c_supplier_reg_at:Date | null = null;
public c_supplier_upd_user:string | null = null;
public c_supplier_upd_at:Date | null = null;
}