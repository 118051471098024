//-------------------------------
//基本設定。
//-------------------------------
/**
 * デバッグスイッチ
 */
export const CONST_BASE_IS_DEBAG = true;

/**
 * 一覧デフォ設定 1あたり件数
 */
export const CONST_LIST_DEFO_COUNT = 10;

/**
 * APIのURL
 */
export const CONST_URL_API =
  // "https://juodsiyny0.execute-api.ap-northeast-1.amazonaws.com/1_0_0"; //ステージング環境
  // "https://juodsiyny0.execute-api.ap-northeast-1.amazonaws.com/1_0_0"; //devset1
  // "https://juodsiyny0.execute-api.ap-northeast-1.amazonaws.com/1_0_0"; //devset2
  // "https://juodsiyny0.execute-api.ap-northeast-1.amazonaws.com/1_0_0"; //devset3
  "https://juodsiyny0.execute-api.ap-northeast-1.amazonaws.com/1_0_0"; //devset4
